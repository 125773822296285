import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getErps } from 'client/api';
import { SistemaERP } from 'client/interfaces';

export const useErps = (enabled = true) =>
  useQuery<SistemaERP[], AxiosError>(['erps'], getErps, {
    staleTime: 1000 * 60 * 5,
    enabled,
  });
