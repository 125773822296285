import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { IGroupClient } from 'client/interfaces/groupClient';

interface IGroupClientMember {
  groupInfo: IGroupClient;
  setGroupInfo(groupInfo: IGroupClient): void;
}

export const useGroupClientMember = create<IGroupClientMember>()(
  persist(
    (set) => ({
      groupInfo: {
        codgrupo: 0,
        codempresa: '',
        codfilial: '',
        nomeGrupo: '',
      },
      setGroupInfo: (unity) => set(() => ({ groupInfo: unity })),
      clearGroupInfo: () =>
        set(() => ({
          groupInfo: {
            codgrupo: 0,
            codempresa: '',
            codfilial: '',
            nomeGrupo: '',
          },
        })),
    }),
    {
      name: '@vmais:groupClient',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
