import styled from 'styled-components';

export const RouterHeaderBox = styled.section`
  position: absolute;
  left: 22.5%;
  top: 10px;
  z-index: 2;
  display: flex;
  gap: 25px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  width: 75%;

  .btn-light {
    background-color: #fff !important;
  }
`;

export const RouterHeaderMapToggle = styled.div`
  height: 3.2rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 5px;
  overflow: hidden;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    padding: 10px;
    cursor: pointer;
  }
  .icon:hover {
    background-color: #cccccc70;
  }
  .icon svg {
    font-size: 1.85rem;
  }
  .iconChecked {
    background-color: #cccccc70;
  }
`;
export const HeaderFloatMenu = styled.div`
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
`;
export const CloseButton = styled.div`
  font-size: 1rem;
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    color: #000;
    font-size: 1.1rem;
  }
`;

export const MenuBox = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  margin-top: 10px;
  padding: 20px 0;
  position: absolute;
  right: 0;
  top: 100%;
  visibility: hidden;
  width: clamp(250px, 300px, 350px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 10px 10px 10px;
`;

export const MapMainContainer = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  .map-container {
    position: relative;
    height: 88dvh;
    border: 3px solid #fff;
    border-radius: 5px;
  }
`;

export const SideBarHeaderInputBox = styled.div`
  position: relative;
  background-color: #fafafa;
  width: clamp(200px, 250px, 300px);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 3.25rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;

export const SideBarHeaderInput = styled.input`
  position: relative;
  width: 85%;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  border: none;
  outline: none;
  padding-left: 10px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
`;
export const SideBarHeaderInputButton = styled.div`
  background-color: rgb(61 65 71);
  color: #fff;
  width: 15%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
`;
