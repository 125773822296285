import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
  gap: 10px;
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: clamp(200px, 100px, 100px);
`;

export const BoxTextInput = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  @media (max-width: 1024px) {
    width: 100% !important;
    width: clamp(50px, 100%, 100%);
  }
  input {
    width: 100%;
  }
`;
