import styled from 'styled-components';

interface IMapProps {
  showMenu?: boolean;
  codColorSetor?: string;
}

export const Content = styled.section<IMapProps>`
  background-color: #fafafa;
  height: 85%;
  width: 100%;
  overflow-y: scroll;
  opacity: ${(props) => (props.showMenu ? 'none' : '0')};
  transition: ${(props) => (!props.showMenu ? 'opacity 0.4s ease' : '')};

  @media (max-width: 992px) {
    border-radius: none;
    height: calc(80% - 10px);
  }
`;

export const MapSideBarMainBox = styled.div<IMapProps>`
  position: absolute;
  top: 1vh;
  right: 0;
  z-index: 1000;

  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  height: 80dvh;
  transition: all 0.4s ease;

  margin: 2vh;
  border-radius: 10px;
  width: ${(props) => (props.showMenu ? '28vw' : '2vw')};

  @media (max-width: 992px) {
    z-index: 99;
    width: 100%;
    height: 80vh;
    top: ${(props) => (props.showMenu ? '30px' : 'calc(100% - 50px)')};
    border-radius: none;
    margin: 0;
    right: 0;
  }
`;

export const SideBarHeaderClosed = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  writing-mode: vertical-rl;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  @media (max-width: 992px) {
    writing-mode: horizontal-tb;
    top: 26%;
    z-index: 99;
  }
`;

export const SideBarHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  position: static;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  padding: 5px;
  overflow: hidden;
`;

export const HeaderFilterBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  align-items: end;
  text-align: left;
  width: 100%;
  padding: 10px 0;
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;
export const TextInputBox = styled.span`
  width: clamp(55%, 65%, 75%);
  outline: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

export const SetorTableIconTd = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  padding: 1 !important;
  display: flex;
  justify-content: space-around;
  gap: 5px;
  align-items: center;
  padding: 5px;

  span {
    width: 40%;
  }
`;

export const SetorCodColorBackground = styled.div<IMapProps>`
  color: var(--white);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  justify-content: left;
  height: 75px;
  opacity: 0.8;
  margin-left: -7px;
  background-color: ${(props) => (props.codColorSetor ? '#' + props.codColorSetor : 'var(--dark-900)')};
  min-width: 12px !important;

  @media (max-width: 992px) {
    height: 1px;
    width: 100%;
    padding: 3px;
    border-radius: 0px 0px 10px 10px;
  }
`;

export const VagoTag = styled.span`
  background-color: var(--orange-400);
  border-radius: 15px;
  border: 1px solid var(--orange-400);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 2px 2px;
  cursor: pointer;
  color: var(--white);
`;

export const InactiveTag = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(255, 17, 17, 0.1) !important;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  color: rgba(170, 17, 17, 0.95);
  transition: all 0.4s ease;
  cursor: pointer;
  padding: 0 15px;
  border-radius: 2rem;
  font-weight: 700;
  font-size: 1rem;
  border: 1px solid rgba(170, 17, 17, 0.25);
  &:hover {
    background-color: rgb(255, 17, 17, 0.25) !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    color: rgba(170, 17, 17, 0.95);
  }
`;

export const SetorCodBackground = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  color: var(--dark-900);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 0.9rem;
  cursor: pointer;
  opacity: 0.8;
  position: relative;
  white-space: nowrap;
  color: #fff;
  gap: 5px;
`;

export const MapSideBarOpenBtn = styled.div<IMapProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  width: 30px;
  height: 50px;
  background-color: var(--red);
  z-index: 999999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;

  svg {
    color: var(--white);
    transition: all 1.2s ease;
    transform: ${(props) => (!props.showMenu ? 'rotate(180deg)' : 'none')};
  }

  @media (max-width: 992px) {
    top: -30px;
    right: 50%;
    width: 50px;
    height: 30px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: translateX(45%);
    margin: 0 auto;

    svg {
      color: var(--white);
      transition: all 1.2s ease;
      transform: ${(props) => (!props.showMenu ? 'rotate(-90deg)' : 'rotate(90deg)')};
    }
  }
`;

export const FileInputBox = styled.div`
  input {
    display: none;
  }
`;

export const SetorCardBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  gap: 15px;
  position: relative;

  @media (max-width: 1024px) {
    padding-left: 5px;
    border-left: 1px solid #60606020;
  }
`;

export const SetorCardTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: last baseline;
`;
export const SetorName = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;
export const SetorCardTitleName = styled.span`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SetorCardClientCount = styled.span`
  background-color: #f5f5fe;
  border-radius: 2px;
  padding: 2px;
`;

export const SetorVendaPotencialRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
`;

export const SetorVendaPotencialRowContent = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 32px;
`;

export const PercBarBox = styled.div`
  display: flex;
  background-color: #60606025;
  width: clamp(40px, 50px, 100%);
  height: 0.8rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

type PercBarProps = {
  width: number;
  color: string;
};

export const PercBar = styled.div<PercBarProps>`
  height: 100%;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.color}B9;
  position: absolute;
`;

type PercBarTextProps = {
  color: string;
};

export const PercBarText = styled.div<PercBarTextProps>`
  position: absolute;
  right: 5px;
  top: 0px;
  color: ${(props) => props.color};
  font-size: 0.7rem !important;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: first baseline;
  text-align: center;
`;
