const handleCardName = (name: string) => {
  return name.slice(0, 25).toUpperCase();
};

const handleCardNumero = (number: string) => {
  let v: any = number;
  v = v.replace(/\D/g, '');
  if (v.length > 4) {
    v = v.match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,5})/);
    if (v[1] == '') {
      return;
    } else if (v[1] != '' && v[2] != '' && v[3] != '' && v[4] != '') {
      v = v[1] + ' ' + v[2] + ' ' + v[3] + ' ' + v[4];
    } else if (v[1] != '' && v[2] != '' && v[3] != '') {
      v = v[1] + ' ' + v[2] + ' ' + v[3];
    } else if (v[1] != '' && v[2] != '') {
      v = v[1] + ' ' + v[2];
    } else if (v[1] != '') {
      v = v[1];
    }
  }
  return v.slice(0, 19);
};

const handleCardDate = (date: string) => {
  let v: any = date;
  if (v.length >= 3) {
    v = v.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})/);
    v[1] = v[1] > 12 ? 12 : v[1];
    v = !v[1] ? v[1] : v[1] + '/' + v[2];
  }
  v = v.slice(0, 7);
  return v;
};

const handleCardCvv = (cod: string) => {
  let v = cod;
  v = v.replace(/\D/g, '').slice(0, 3);
  return v;
};

const mask = {
  handleCardNumero: handleCardNumero,
  handleCardDate: handleCardDate,
  handleCardCvv: handleCardCvv,
  handleCardName: handleCardName,
};

export default mask;
