import * as S from './styles';

export interface IToastBox {
  icon: string | any;
  title?: string;
  text: string;
  variant?: 'default' | 'error' | 'success' | 'warning';
  renderButton?: boolean;
  onClick?: () => void;
  onClickCancel?: () => void;

}
const ToastBox = ({ icon, text, title, onClick, renderButton, onClickCancel, variant = 'default', ...rest }: IToastBox) => {
  return (
    <S.Box variant={variant} {...rest}>
      {typeof icon === 'string' ? <img src={icon} /> : <div>{icon}</div>}
      <S.BoxText>
        <S.Title>{title}</S.Title>
        <S.Text>{text}</S.Text>
        {renderButton && (
          <div>
            <S.ButtonSave onClick={onClick}>Salvar</S.ButtonSave>
            <S.ButtonCancel onClick={onClickCancel}>Cancelar</S.ButtonCancel>
          </div>
        )}
      </S.BoxText>
    </S.Box>
  );
};

export default ToastBox;
