import { PropagateLoader } from 'react-spinners';

import logo from 'assets/images/logo/v-mais.svg';

import * as S from './styles';

const LoaderScreen = () => {
  return (
    <S.Box>
      <img src={logo} />
      <PropagateLoader size="13px" color="#228F6B" />
    </S.Box>
  );
};

export default LoaderScreen;
