import { MenuRestrincao, SaveMenuRestricaoDTO } from 'client/interfaces/menu.interfaces';
import api from 'services/api';

const _controller = 'MenuRestricao';

export const saveMenuRestricao = async (data: SaveMenuRestricaoDTO): Promise<void> => {
  await api.post(_controller, data);
};

export const getMenuRestricao = async (CodMenu: number): Promise<MenuRestrincao[]> => {
  const { data } = await api.get(_controller, { params: { CodMenu } });
  return data;
};

export const deleteMenuRestricao = async (codEmpresa: number, codMenu: number): Promise<void> => {
  await api.delete(`${_controller}?CodEmpresa=${codEmpresa}&codMenu=${codMenu}`);
};
