import styled from 'styled-components';

export const EditPolyMainBox = styled.section`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9 !important;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  margin: 5px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .descriptionPolyBox {
    display: flex;
    flex-direction: column;
  }
  .buttonPolyBox {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;
