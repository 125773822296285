import { InputHTMLAttributes } from 'react';

import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  height?: string;
  classNameI?: string;
  iconSvg?: string | any;
}

const Input = ({ height, iconSvg, classNameI, ...rest }: InputProps) => {
  return (
    <S.Container>
      <S.Input height={height} {...rest} />

      <S.IconWrapper>
        {iconSvg ? typeof iconSvg === 'string' ? <img src={iconSvg} /> : <>{iconSvg}</> : <></>}
      </S.IconWrapper>
    </S.Container>
  );
};

export default Input;
