import { useEffect, useState } from 'react';
import * as S from './styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

import VmButton from 'components/VmButton/VmButton';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';

import { postUserResetPassword } from 'client/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useUser, useUserByCompany } from 'client/hooks';

import { Dropdown } from 'primereact/dropdown';

export default function ResetUserAccess() {
  const { setTitlePage } = useTitlePageStore();

  const { data: user } = useUser();
  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();

  const { data: usuarios } = useUserByCompany(selectedEmpresa?.codEmpresa);

  const defaultSelectedUser = {
    admin: false,
    codEmpresa: 2072021,
    codUsuario: null,
    nome: 'Selecione um usuário',
    permisions: [],
  };

  const [selectedUser, setSelectedUser] = useState(defaultSelectedUser);

  useEffect(() => {
    setTitlePage('Redefinir Acesso de Usuário');
  }, []);

  const handleUserAccesReset = () => {
    if (!selectedUser || selectedUser.codUsuario == 0 || !selectedUser.codUsuario) {
      toast.warning('Selecione um usuário!');
      return;
    }
    if (selectedUser.nome) {
      Swal.fire({
        title: 'Tem certeza?',
        text: `O usuário "${selectedUser.nome}" terá de cadastrar uma nova senha de acesso.`,
        icon: 'question',
        confirmButtonText: 'Redefinir!',
        cancelButtonText: 'Voltar',
        showCloseButton: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          postUserResetPassword(
            selectedUser.codUsuario,
            selectedEmpresa.codEmpresa,
            user.codUsuario,
          )
            .then((res) => {
              console.log('res :', res);
              setSelectedUser(defaultSelectedUser);
              toast.success('Acesso redefinido com sucesso.');
            })
            .catch((err: Error) => {
              console.log('err :', err);
              toast.error('Erro inesperado ao redefinir acesso.');
            });
        } else {
          toast.warning('Redefinição de acesso cancelada!');
        }
      });
    } else {
      toast.error('Usuário não encontrado!');
    }
  };
  return (
    <S.ResetMainBox>
      <S.ResetFormBox>
        <SelectEmpresa />
        <S.ResetInputBox>
          <label>Usuário</label>
          <Dropdown
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.value)}
            options={[
              ...[
                {
                  admin: false,
                  codEmpresa: 2072021,
                  codUsuario: null,
                  nome: 'Selecione um usuário',
                  permisions: [],
                },
              ],
              ...(usuarios ?? []),
            ]}
            className="md:w-10rem"
            optionLabel="nome"
            itemTemplate={(usr) => (usr.codUsuario ? `[${usr.codUsuario}] - ${usr.nome}` : usr.nome)}
          />
        </S.ResetInputBox>
        <VmButton
          options={{
            label: 'Redefinir',
            style: 'btn-success',
          }}
          onClick={() => handleUserAccesReset()}
        />
      </S.ResetFormBox>
    </S.ResetMainBox>
  );
}
