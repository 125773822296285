import styled from 'styled-components';

export const CliPopMainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 1rem;
  padding: 10px !important;
  width: clamp(300px, 350px, 400px);
  > span {
    width: 100%;
    text-align: left !important;
    border-bottom: 1px solid #cccccc70;
  }
`;

export const CliPopBloqueioBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  font-size: 1rem;
  gap: 10px 0px;
  width: 100%;
`;

export const MarkerHeader = styled.div`
  width: 100%;
  height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;

  p {
    color: #5b5d73;
    font-size: 15px;
    font-weight: 600;
  }
`;

export const MarkerBody = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const MarkerRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  span {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      color: #9497b8;
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export const Badge = styled.div`
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid #bfc9df;
  margin-right: 10px;
  svg {
    font-size: 1.25rem;
  }
`;

export const BadgeSquare = styled.div`
  width: fit-content;
  padding: 5px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: 1px solid #bfc9df;

  span {
    font-size: 14px;
  }
`;

export const Description = styled.p`
  color: #a8aabc;
  font-size: 14px;
`;

export const DataDescription = styled.p`
  color: #a8aabc;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: blue;
  text-decoration: underline;
  text-decoration-color: blue;
`;

export const Separator = styled.div`
  background: #f6f6f6;
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 4px;
`;
