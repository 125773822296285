import axios, { AxiosRequestConfig } from 'axios';
import api, { BASE_API_URL, ORIGIN } from 'services/api';
import { FotoProduto, NewFotoProduto, ProdutoProps } from 'client/interfaces';
import storage from 'services/storage';

const _controller = 'produtos';

export const getFotos = async (): Promise<FotoProduto[]> => {
  const { data } = await api.get(`${_controller}/fotos`);
  return data;
};

export const saveFotos = async (data: NewFotoProduto): Promise<void> => {
  const formData: FormData = new FormData();
  formData.append('foto', data.foto);
  formData.append('codProd', data.codProd);
  formData.append('codEmpresa', data.codEmpresa.toString());
  formData.append('descricao', data.descricao);
  formData.append('dtAlteracao', data.dtAlteracao ?? '');
  formData.append('dtCadastro', data.dtCadastro ?? '');
  formData.append('nome', data.nome);
  formData.append('fotoType', data.fotoType.toString());

  const token = storage.getItem<string>('token');

  const config: AxiosRequestConfig = {
    baseURL: BASE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': ORIGIN,
      Authorization: `Bearer ${token ?? ''}`,
    },
  };

  await axios.post(`${_controller}/fotos`, formData, config);
};

export const updateFotos = async (data: NewFotoProduto): Promise<void> => {
  const formData: FormData = new FormData();
  formData.append('foto', data.foto);
  formData.append('codProd', data.codProd);
  formData.append('codEmpresa', data.codEmpresa.toString());
  formData.append('descricao', data.descricao ?? '');
  formData.append('dtAlteracao', data.dtAlteracao ?? '');
  formData.append('dtCadastro', data.dtCadastro ?? '');
  formData.append('nome', data.nome);
  formData.append('fotoType', data.fotoType.toString());

  await api.put(`${_controller}/fotos`, formData);
};

export const getProdutos = async (codEmpresa: number): Promise<ProdutoProps[]> => {
  const { data } = await api.get(`Produtos/GetProdutos/${codEmpresa}/*`);
  return data;
};

export const getProdutosBySearchParam = async (codEmpresa: number, codFilial: number, search: string): Promise<ProdutoProps[]> => {
  const { data } = await api.get(`Produtos/GetProdutos/${codEmpresa}/${codFilial}/${search}`);
  return data;
};
