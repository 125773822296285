import * as S from './styles';
import FooterMenu from 'components/FooterMenu';

export default function VmContainer({ children }: any) {
  return (
    <S.ContainerMainBox translate='no'>
      {children}
      <FooterMenu />
    </S.ContainerMainBox>
  );
}
