import { PageTitle } from '_metronic/layout/core';
import { Header, SqlForm, SqlResultContainer } from 'modules/vmaissql/components';

import { Container } from './styles';

const SqlPage = () => (
  <Container>
    <PageTitle breadcrumbs={[]}>VMaisSQL</PageTitle>
    <SqlForm />
    <SqlResultContainer />
  </Container>
);

export default SqlPage;
