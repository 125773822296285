const storagePrefix = 'vmais';

const storageService = {
  getItem: <T>(key: string) => {
    const value = localStorage.getItem(`${storagePrefix}_${key}`);
    if (value != null) {
      return JSON.parse(value) as T;
    }
    return null;
  },
  setItem: (key: string, value: unknown) => {
    localStorage.setItem(`${storagePrefix}_${key}`, JSON.stringify(value));
  },
  clearStorage: () => {
    localStorage.clear();
  },
};

export default storageService;
