import styled from 'styled-components';

export const Body = styled.div`
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 15px;
`;

export const TitleSection = styled.h1``;

export const ResetFormBox = styled.div`
  display: flex;
  border: 1px solid #cccccc70;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 7.5px;
  padding: 20px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
