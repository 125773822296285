import { useEmpresas } from 'client/hooks/empresas';
import { useState } from 'react';

interface UseCompaniesSelectProtocols {
  optionsCompanies: {
    value: number;
    label: string;
  }[];
  handleSelectChange: (event: any) => void;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: string;
  codEmpresaVMais: number;
}

export const UseCompaniesSelect = (): UseCompaniesSelectProtocols => {
  const { data: empresas } = useEmpresas();

  const [selectedOption, setSelectedOption] = useState('');
  const codEmpresaVMais = 2072021;

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const optionsCompanies = empresas?.map((item) => ({
    value: item.codEmpresa,
    label: `${item.fantasia} - [${item.codEmpresa}]`,
  }));

  return {
    optionsCompanies,
    selectedOption,
    setSelectedOption,
    handleSelectChange,
    codEmpresaVMais,
  };
};
