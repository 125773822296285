import { BaseMenuDto, MenuWithSub, SaveMenuAcessoDto, SaveSubMenuAcessoDto } from 'client/interfaces';
import api from 'services/api';

const _controller = 'menus';

export const getMenusWithSub = async (codEmpresa: number): Promise<MenuWithSub[]> => {
  const { data } = await api.get(`${_controller}`, { params: { codEmpresa } });
  return data;
};

export const getAcessoMenus = async (codUsuario: number): Promise<number[]> => {
  const { data } = await api.get(`${_controller}/usuario/${codUsuario}/menu-acesso`);
  return data;
};

export const getAcessoSubMenus = async (codUsuario: number): Promise<number[]> => {
  const { data } = await api.get(`${_controller}/usuario/${codUsuario}/sub-menu-acesso`);
  return data;
};

export const deleteMenusWithSub = async (cods: number[]): Promise<void> => {
  await api.delete(`${_controller}/bulk`, { data: cods });
};

export const saveMenusWithSub = async (data: MenuWithSub[]): Promise<void> => {
  await api.post(`${_controller}`, data);
};

export const saveAcessoMenus = async (data: SaveMenuAcessoDto): Promise<void> => {
  await api.post(`${_controller}/menu-acesso`, data);
};

export const saveAcessoSubMenus = async (data: SaveSubMenuAcessoDto): Promise<void> => {
  await api.post(`${_controller}/sub-menu-acesso`, data);
};

interface IRemoveAcessoMenu {
  codMenu: number;
  codEmpresa: number;
  codUsuario: number;
}

export const removeAcessoMenus = async ({ codMenu, codEmpresa, codUsuario }: IRemoveAcessoMenu): Promise<void> => {
  await api.delete(`${_controller}/${codMenu}/${codEmpresa}/${codUsuario}/menu-acesso`);
};

export const alterAccessMenus = async ({ codMenu, codEmpresa, codUsuario }: IRemoveAcessoMenu): Promise<void> => {
  await api.put(`${_controller}/${codMenu}/${codEmpresa}/${codUsuario}/gerenciar/menu-acesso`);
};

export const removeAcessoSubMenus = async (codSubMenu: number): Promise<void> => {
  await api.delete(`${_controller}/${codSubMenu}/sub-menu-acesso`);
};

export const getUserMenus = async (): Promise<BaseMenuDto[]> => {
  const { data } = await api.get(`${_controller}/usuario`);
  return data;
};