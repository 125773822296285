export interface UserData {
  codUsuario: number;
  codEmpresa: number;
  nome: string;
  admin: false;
  celular: string;
  dtNascimento: string;
  nomeEmpresa: string;
  email: string;
}

export type MissingProperties = 'CPF' | 'CELULAR' | 'EMAIL' | 'SENHA' | 'BIRTH_DATE' | 'NOME';

export enum UserValidateStatus {
  Registered = 'Registered',
  PendingRegistration = 'PendingRegistration',
  Inactive = 'Inactive',
  Invalid = 'Invalid',
}

export interface UserValidateResponse {
  status: UserValidateStatus;
  missingProperties: MissingProperties[];
  nomeUsuario: string;
}
