import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import authReducer, { logout } from 'modules/auth/reducer/auth';
import vmaisSqlReducer from 'modules/vmaissql/reducer/vmaissql';
import menuAcessoReducer from 'modules/acessoUsuario/reducer/menuAcesso';

const appReducer = combineReducers({
  auth: authReducer,
  vmaisSql: vmaisSqlReducer,
  menuAcesso: menuAcessoReducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === logout.toString()) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
