import styled from 'styled-components';

export const Box = styled.label`
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const LineBox = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  label {
    max-width: 40% !important;
  }
`;

export const BoxButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ICorOption = {
  cor: string;
};

export const CorOption = styled.div<ICorOption>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
  &:hover {
    color: ${(props) => '#' + props.cor};
  }
`;

export const Cor = styled.div<ICorOption>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => '#' + props.cor};
  transition: all 0.2s ease;
`;

export const SelectOption = styled.span``;

type ICloseOption = {
  show: boolean;
};

export const SelectOptionCloseButton = styled.span<ICloseOption>`
  position: absolute;
  display: ${(props) => (props.show ? 'block' : 'none')};
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: all 0.4s ease;

  &:hover {
    font-size: 1.45rem;
  }
`;
