import styled from 'styled-components';

export const ReportMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const ReportHeaderBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  border-radius: 7.5px;
  border: 1px solid #cccccc70;
  background-color: #fff;
  width: auto;
  position: sticky;
  top: 20px;
  left: 0px;
  z-index: 9;
`;

export const ReportInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ReportBodyBox = styled.section`
  width: 100%;
  height: 68dvh;
  background-color: #00000010;
  border-radius: 7.5px;
  overflow: hidden;
  border: 1px solid #cccccc70;
  background-color: #fff;
`;

export const ReportTableScriptField = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
interface ReportTableAtivoFieldProps {
  ativo?: string;
}

export const ReportTableAtivoField = styled.div<ReportTableAtivoFieldProps>`
  color: ${(props) => (props.ativo == 'S' ? `green` : 'red')} !important;
  svg {
    font-weight: bolder !important;
    font-size: 1.5rem !important;
  }
`;

export const ReportTableActionField = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  svg {
    font-weight: bolder !important;
    font-size: 1.5rem !important;
  }
`;
