import { useTitlePageStore } from 'storesZustand/titlePageStore';
import * as S from './styles';
import { useEffect } from 'react';

export default function Relatorios7000Designer() {
  const { setTitlePage } = useTitlePageStore();
  useEffect(() => {
    setTitlePage('7000 - Designer de Relatórios');
  }, []);

  return (
    <S.ReportMainBox>
      <S.ReportIFrameBox
        src="http://${process.env.REACT_APP_REPORT_BASE_URL}/"
        title="Vmais Report Designer"
      />
    </S.ReportMainBox>
  );
}
