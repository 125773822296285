export const OPTIONS_SISTEM_ERP = [
  {
    value: '0',
    label: 'vmais',
  },
  {
    value: '1',
    label: 'winthor',
  },
];

export const OPTIONS_ACTIVE_INATIVE = [
  {
    value: 'S',
    label: 'Ativa',
  },
  {
    value: 'N',
    label: 'Inativa',
  },
];
