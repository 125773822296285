import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { BsFillUnlockFill, BsPlusLg } from 'react-icons/bs';

import { MenuSubDto, MenuWithSub } from 'client/interfaces';
import ButtonComponent from 'components/Button';
import ClickIcon from 'components/ClickIcon';
import { textEditor } from 'components/datatable';
import { useModalAddFunctionalityToCompany } from 'storesZustand/modalAddFunctionalityToCompany';

import ModalAddFunctionalityToCompanySubMenu, {
  IRowDataSubMenu,
} from './modalsSubMenu/ModalAddFunctionalityToCompanySubMenu';

interface SubItemMenuProps {
  menu: MenuWithSub;
  createNew: (codMenu: number) => void;
  removeItem: (menuSub: MenuSubDto) => void;
  setEdited: (codMenu: number) => void;
}

const SubItemMenu = ({ menu, createNew, removeItem, setEdited }: SubItemMenuProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCellEditComplete = (e: any) => {
    const { rowData, newValue, field, originalEvent: event } = e;

    if (newValue.trim().length > 0) {
      rowData[field] = newValue;
      setEdited(menu.codMenu);
    } else event.preventDefault();
  };

  const header = (
    <div className="d-grid gap-2 d-md-flex">
      <ButtonComponent icon={<BsPlusLg />} text="Novo" onClick={() => createNew(menu.codMenu)} />
    </div>
  );

  const confirmDeleteItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: MenuSubDto) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Tem certeza que deseja excluir esse submenu?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeItem(data),
    });
  };

  const deleteTemplate = (data: MenuSubDto) => (
    <Button
      type="button"
      icon="pi pi-trash"
      onClick={(e) => confirmDeleteItem(e, data)}
      className="p-button-rounded p-button-danger p-button-text"
    />
  );

  const actionBodyTemplate = (handleAddFunctionalityToCompany: () => void) => {
    return <ClickIcon icon={<BsFillUnlockFill />} onClick={handleAddFunctionalityToCompany} variant="secondary" />;
  };
  const { setStatus, setSelectedCompany } = useModalAddFunctionalityToCompany();

  const [openModalAddFunctionalityMenu, setModalAddFunctionalityMenu] = useState(false);

  const [rowDataMenu, setRowDataMenu] = useState<IRowDataSubMenu>({
    codMenu: 0,
    codMenuSub: 0,
    CodSubMenu: 0,
    nomePermissao: '',
  });

  function handleAddFunctionalityToCompany(rowData: IRowDataSubMenu) {
    setModalAddFunctionalityMenu(true);
    setRowDataMenu(rowData);
    setStatus('default');
    setSelectedCompany({
      codEmpresa: 0,
      cnpj: '',
      razaosocial: '',
      fantasia: '',
      caminhoLogo: '',
    });
  }

  return (
    <>
      <DataTable
        value={menu.menuSub}
        header={header}
        sortMode="single"
        sortField="grupo"
        sortOrder={1}
        editMode="cell"
        dataKey="codMenuSub"
        emptyMessage="Nenhum submenu adicionado"
      >
        <Column field="codSubMenu" header="Código" headerStyle={{ width: '3em' }} />
        <Column field="nomePermissao" header="Permissão" editor={textEditor} onCellEditComplete={onCellEditComplete} />
        <Column headerStyle={{ width: '3em' }} body={deleteTemplate} />
        <Column
          headerStyle={{ width: '3em' }}
          body={(rowData: IRowDataSubMenu) => actionBodyTemplate(() => handleAddFunctionalityToCompany(rowData))}
        />
      </DataTable>
      <ModalAddFunctionalityToCompanySubMenu
        setOpenModal={setModalAddFunctionalityMenu}
        openModal={openModalAddFunctionalityMenu}
        data={rowDataMenu}
      />
    </>
  );
};

export default SubItemMenu;
