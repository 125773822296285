import { FilterMatchMode, FilterOperator } from "primereact/api";

export const initialFiltersColumnsParamsTable = {
    codEmpresa: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    codFilial: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    codUsur: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    nomeVendedor: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    versaoAtual: { value: null, matchMode: FilterMatchMode.EQUALS },
    ultversao: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    dtaltversao: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    versaoAtualizada: { value: null, matchMode: FilterMatchMode.EQUALS },
    controleGps: { value: null, matchMode: FilterMatchMode.EQUALS },
    seguirSequenciaRota: { value: null, matchMode: FilterMatchMode.EQUALS },
    controlarAlmoco: { value: null, matchMode: FilterMatchMode.EQUALS },
    hrIniAlmoco: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    hrFimAlmoco: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    hrIniJornada: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    hrFimJornada: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    almocoMinutos: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    raioCheckin: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    cliForaDaRota: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    gpsForaRota: { value: null, matchMode: FilterMatchMode.EQUALS },
    QtCliAusenteRetorno: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    permitelb: { value: null, matchMode: FilterMatchMode.EQUALS },
    cadcliente: { value: null, matchMode: FilterMatchMode.EQUALS },
    alteraContatoCli: { value: null, matchMode: FilterMatchMode.EQUALS },
    alteraped: { value: null, matchMode: FilterMatchMode.EQUALS },
    cancelaped: { value: null, matchMode: FilterMatchMode.EQUALS },
    contrasenha: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    usaDescCxmaster: { value: null, matchMode: FilterMatchMode.EQUALS },
    percSugCxmaster: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    diasOrcamento: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    obrigaleremail: { value: null, matchMode: FilterMatchMode.EQUALS },
    imei1: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    imei2: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    imei3: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    serial1: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    fabricante: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    marca: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    modelo: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    tamanhoTela: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    versaoAndroid: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    tipoAparelho: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  };