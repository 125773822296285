import { useJsApiLoader } from '@react-google-maps/api';
import { AxiosError } from 'axios';
import L from 'leaflet';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useRef, useState } from 'react';
import { BsCheckLg, BsFillPinMapFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import VmButton from 'components/VmButton/VmButton';
import { MapContext } from 'providers/map';

import * as S from './styles';
import { editClientPoint, registerClientPoint } from 'client/api/ponto';
import useMediaQuery from 'hooks/useMediaQuery';

import { GoogleMap, Marker } from '@react-google-maps/api';
import { useMapSectorsStore } from 'storesZustand/mapSectorsStore';

interface IAddressModal {
  isAdress?: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  cliData?: any;
  isEdit?: boolean;
  toEditPoint?: any;
  codVendedor?: string | number;
  title?: string;
  getPointList?: () => void;
  codMapaParam: string;
  codEmpresaParam: string;
}

interface ICoordinates {
  lat: number;
  lng: number;
}

const AddressModal = ({
  isAdress,
  showModal,
  setShowModal,
  cliData,
  isEdit,
  toEditPoint,
  codVendedor,
  title,
  getPointList,
  codEmpresaParam,
  codMapaParam,
}: IAddressModal) => {
  // CRIA INSTÂNCIA DE NEVEGAÇÃO DO REACT ROUTER DOM
  const navigate = useNavigate();
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { codEmpresa, getRoteirizaData } = useContext(MapContext);
  const codUser = useMapSectorsStore((state) => state.codUser);

  const [showMap, setShowMap] = useState<any>(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAUHxQUnO76uq2HBu2X6xzaLZPapIFv--0',
    libraries: ['drawing', 'places'],
  });

  useEffect(() => {
    if (isLoaded && !showMap) {
      setShowMap(isLoaded);
    }
  }, [isLoaded]);

  const mapRef: any = useRef(null);

  useEffect(() => {
    getRoteirizaData().then((res: any) => {
      if (res.latlngs && !toEditPoint) {
        setCurrentCoords(res.latlngs[0]);
        if (mapRef.current != null) {
          setCurrentCoords(res.latlngs[0]);
          mapRef.current.flyTo(res.latlngs[0], 14, {
            animate: true,
            duration: 1.2,
          });
        }
      }
    });
  }, [mapRef.current]);

  const [currentCoords, setCurrentCoords] = useState<ICoordinates>({ lat: 0, lng: 0 });

  useEffect(() => {
    if (currentCoords.lat === undefined || currentCoords.lng === undefined) {
      setCurrentCoords({ lat: 0, lng: 0 });
    }
  }, [showModal]);

  const geolocalizar = () => {
    let query = '';
    query = address !== '' ? address : '';
    query = number !== '' && query !== '' ? query + ', ' + number : query + number;
    query = neighborhood !== '' && query !== '' ? query + ', ' + neighborhood : query + neighborhood;
    query = city !== '' && query !== '' ? query + ', ' + city : query + city;
    query = uf !== '' && query !== '' ? query + ', ' + uf : query + uf;
    query = cep !== '' && query !== '' ? query + ', ' + cep : query + cep;

    query = query
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replaceAll('%', '%25');

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address: query }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        const coords = { lat: location.lat() ?? 0, lng: location.lng() ?? 0 };
        setCurrentCoords(coords);
        if (mapRef.current != null) {
          mapRef.current.flyTo(coords, 18, {
            animate: true,
            duration: 1.2,
          });
        }
      } else {
        toast.error('Endereço não localizado!');
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  // DEFINE STATES DE CONTROLE DOS DADOS DO FORMUULÁRIO DE BUSCA PELA LOCALIZAÇÃO DO ENDEREÇO
  const [address, setAddress] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [uf, setUf] = useState<string>('');
  const [cep, setCep] = useState<string>('');
  const [desc, setDesc] = useState<string>('');

  const handleFieldsFill = (data: any) => {
    setAddress(data.endereco);
    setNumber(data.numero);
    setNeighborhood(data.bairro);
    setCity(data.cidade);
    setUf(data.uf);
    setCep(data.cep);
    setDesc(data.descricao);
  };

  useEffect(() => {
    if (cliData != null) {
      handleFieldsFill(cliData);
      setAddress(cliData.endLogradouro);
      setNumber(cliData.endNumero);
      setNeighborhood(cliData.endBairro);
      setCity(cliData.endCidade);
    }
  }, [cliData]);

  useEffect(() => {
    if (toEditPoint) {
      handleFieldsFill(toEditPoint);
      setCurrentCoords({ lat: +toEditPoint.latitude ?? 0, lng: +toEditPoint.longitude ?? 0 });
      if (mapRef.current != null) {
        mapRef.current.flyTo({ lat: +toEditPoint.latitude ?? 0, lng: +toEditPoint.longitude ?? 0 }, 18, {
          animate: true,
          duration: 1.2,
        });
      }
    }
  }, [toEditPoint]);

  // FUNÇÃO QUE LIMPA DADOS DO FORMULÁRIO E DO MAPA AO FECHAR MODAL
  const clearAllData = () => {
    setCurrentCoords({ lat: 0, lng: 0 });
    setAddress('');
    setNumber('');
    setNeighborhood('');
    setCity('');
    setUf('');
    setCep('');
    setDesc('');
  };

  // MARKER DO MAPA PERSONALIZADO
  const customMarkerIcon = () => {
    const color = '#cb2a29';
    let svgTemplate = null;

    svgTemplate = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker" opacity="0.7"  >
      <path fill-opacity=".35" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 
      3.964-.526 8.743C25.514 30.245 16 32 16 32z"/>
      <path fill="#${color}" stroke="#fff" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 
      .125 26 11.875C26 18.062 15.938 32 15.938 32zM16 6a4 4 0 100 8 4 4 0 000-8z"/>
    </svg>`;

    return new L.DivIcon({
      className: 'map-label',
      html: svgTemplate,
      iconSize: [40, 40],
      iconAnchor: [30, 44],
      popupAnchor: [7, -16],
    });
  };

  const handleMarkerPositionChanged = (position) => {
    if (position.lat && position.lng) {
      setCurrentCoords(position);
    }
  };
  const mapStyles = {
    height: '40dvh',
    width: '100%',
  };

  const mapOptions = {
    disableDefaultUI: true,
    styles: [],
  };

  const DraggableMarker = () => {
    const [markerPosition, setMarkerPosition] = useState(currentCoords || { lat: 0, lng: 0 });

    const handleMarkerDragEnd = (e) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      setMarkerPosition({ lat, lng });

      if (handleMarkerPositionChanged) {
        handleMarkerPositionChanged({ lat, lng });
      }
    };

    return <Marker position={markerPosition} draggable={true} onDragEnd={handleMarkerDragEnd} />;
  };

  async function handleGeoRegister() {
    if (!desc) {
      toast.error('Campo "Descrição" não preenchido!');
      return;
    }

    let routerData: any = await getRoteirizaData();

    if (!routerData) {
      navigate(`/regioes/${codEmpresaParam}/${codUser}/${codMapaParam}`);
      return;
    }

    const toRegisterPoint = {
      descricao: desc.toString(),
      codVendedor: codVendedor || routerData.codRca.toString(),
      latitude: currentCoords.lat.toString(),
      longitude: currentCoords.lng.toString(),
      codEmpresa: codEmpresa.toString(),
      casa: 'N',
      endereco: address,
      numero: number,
      bairro: neighborhood,
      cidade: city,
      uf: cep,
      cep: uf,
    };

    // VALIDA SE É EDIT DE ENDEREÇO DE CLIENTE
    if (isAdress) {
      if (currentCoords.lat === 0 || currentCoords.lng === 0) {
        alert('Dados incompletos!');
        return;
      }
      editClientPoint(toRegisterPoint)
        .then((res: any) => {
          toast.success('Ponto editado com sucesso!');
          setShowModal(false);
          clearAllData();
          getPointList();
        })
        .catch((err: Error) => {
          toast.error('Erro ao editar ponto!');
        });
    } else {
      if (desc === '' || currentCoords.lat === 0 || currentCoords.lng === 0) {
        alert('Dados incompletos!');
        return;
      }

      let toEditPointData: any = {};

      if (isEdit) {
        toEditPointData = {
          codPonto: toEditPoint.codPonto.toString(),
          descricao: desc.toString(),
          codVendedor: toEditPoint.codVendedor.toString(),
          latitude: currentCoords.lat.toString().slice(0, 20),
          longitude: currentCoords.lng.toString().slice(0, 20),
          casa: toEditPoint.casa.toString(),
          codEmpresa: toEditPoint.codEmpresa.toString(),
          endereco: address,
          numero: number,
          bairro: neighborhood,
          cidade: city,
          uf: cep,
          cep: uf,
        };
      }

      if (isEdit) {
        editClientPoint(toEditPointData)
          .then((res: any) => {
            toast.success('Ponto editado com sucesso!');
            setShowModal(false);
            clearAllData();
            getPointList();
          })
          .catch((err: AxiosError) => {
            if (err?.request.status) toast.error('Erro ao editar ponto!');
          });
      } else {
        registerClientPoint(toRegisterPoint)
          .then((res: any) => {
            toast.success('Ponto inserido com sucesso!');
            setShowModal(false);
            clearAllData();
            getPointList();
          })
          .catch((err: AxiosError) => {
            console.log('err :', err);
            if (err?.request.status) toast.error('Erro ao inserir ponto!');
          });
      }
    }
  }

  return (
    <div>
      <Dialog
        header={title ? title : isEdit ? 'Editar Local' : 'Novo Local'}
        visible={showModal}
        onHide={() => {
          setShowModal(false);
          clearAllData();
        }}
        style={{ width: isWebScreen ? '50vw' : '90vw' }}
      >
        <S.ModalMainBox>
          <S.ModalSubBox>
            <span className="p-float-label" style={{ marginTop: !isWebScreen ? '20px' : '' }}>
              <InputText id="desc" value={desc} onChange={(e) => setDesc(e.target.value)} />
              <label htmlFor="desc">Decrição</label>
            </span>

            <span className="p-float-label">
              <InputText id="endereco" value={address} onChange={(e) => setAddress(e.target.value)} />
              <label htmlFor="endereco">Endereço</label>
            </span>

            <S.InputRow className="d-flex">
              <span className="p-float-label">
                <InputText id="numero" value={number} onChange={(e) => setNumber(e.target.value)} />
                <label htmlFor="numero">Número</label>
              </span>

              <span className="p-float-label">
                <InputText id="bairro" value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} />
                <label htmlFor="bairro">Bairro</label>
              </span>
            </S.InputRow>

            <S.InputRow className={'d-flex'}>
              <span className="p-float-label">
                <InputText id="cidade" value={city} onChange={(e) => setCity(e.target.value)} />
                <label htmlFor="cidade">Cidade</label>
              </span>

              <span className="p-float-label">
                <InputText id="uf" value={uf} onChange={(e) => setUf(e.target.value)} />
                <label htmlFor="uf">UF</label>
              </span>
            </S.InputRow>

            <span className="p-float-label">
              <InputText id="cep" value={cep} onChange={(e) => setCep(e.target.value)} />
              <label htmlFor="cep">CEP</label>
            </span>

            <S.InputRow className="d-flex">
              <VmButton
                options={{
                  icon: <BsFillPinMapFill />,
                  label: 'Geolocalizar',
                  iconPosition: 'left',
                  rounded: false,
                  style: 'btn-info',
                  disabled: desc === '' && address === '',
                }}
                onClick={() => geolocalizar()}
              />
              <VmButton
                options={{
                  icon: <BsCheckLg />,
                  iconPosition: 'left',
                  label: 'Salvar',
                  disabled: desc === '' && address === '',
                  rounded: false,
                  style: 'btn-success',
                }}
                onClick={() => handleGeoRegister()}
              />
            </S.InputRow>
          </S.ModalSubBox>

          <S.ModalSubBox>
            Coordenadas: <br />
            <S.InputRow className="d-flex">
              <span className="p-float-label">
                <InputText
                  id="Lat"
                  value={currentCoords.lat}
                  onChange={(e) =>
                    setCurrentCoords((prev: any) => {
                      return {
                        ...prev,
                        lat: e.target.value,
                      };
                    })
                  }
                />
                <label htmlFor="Lat">Lat</label>
              </span>

              <span className="p-float-label">
                <InputText
                  id="Lng"
                  value={currentCoords.lng}
                  onChange={(e) =>
                    setCurrentCoords((prev: any) => {
                      return {
                        ...prev,
                        lng: e.target.value,
                      };
                    })
                  }
                />
                <label htmlFor="Lng">Lng</label>
              </span>
            </S.InputRow>
            {showMap && (
              <GoogleMap mapContainerStyle={mapStyles} zoom={18} center={currentCoords} options={mapOptions}>
                <DraggableMarker />
              </GoogleMap>
            )}
            {/* <MapContainer
              center={currentCoords}
              zoom={18}
              scrollWheelZoom={true}
              style={{ height: '40vh', width: '100%' }}
              maxZoom={18}
              minZoom={2}
              zoomControl={true}
              zoomAnimation={true}
              fadeAnimation={true}
              preferCanvas={true}
              ref={mapRef}
            >
              <TileLayer
                attribution='&amp;copy <a href="http:// osm.org/copyright">OpenStreetMap</a>'
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
              />
              <DraggableMarker />
            </MapContainer> */}
          </S.ModalSubBox>
        </S.ModalMainBox>
      </Dialog>
    </div>
  );
};

export default AddressModal;
