import { ReactNode } from 'react';

import * as S from './styles';

interface IContainerPage {
  children: ReactNode;
}

const ContainerPage = ({ children }: IContainerPage) => {
  return <S.Container>{children}</S.Container>;
};

export default ContainerPage;
