import styled from 'styled-components';

export const BoxImport = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`;

export const RowItens = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 20rem;
  justify-content: space-between;
`;

export const RowButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 20%;
`;

export const BoxTable = styled.section`
  margin: 10px;
`;

export const ImportCliModalFileBox = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: start;
  justify-content: space-around;
  margin-top: 10px;
  border-top: 1px solid var(--dark-200);

  input {
    display: none;
  }
`;

export const ImportCliModalInstructionsBox = styled.section`
  width: 100%;
`;

export const TitleSection = styled.span`
  margin-bottom: 10px;
  margin-top: 10px;
`;


export const ProgressSection = styled.section`

`;
