import { useEffect, useState } from 'react';
import VmFooter from '../Footer';
import * as S from './styles';

import { useLocation } from 'react-router-dom';


export default function VmContent({ children }: any) {

  const [useVmHeader, setUseVmHeader] = useState<boolean>(true);

  const location = useLocation();

  useEffect(() => {
    if(location.pathname == '/pedido' || location.pathname == '/pedido/novo'){
      setUseVmHeader(false);
    }else if(!useVmHeader){
      setUseVmHeader(true);
    }
  }, [location.key]);

  return (
    <S.ContentMainBox fullSize={!useVmHeader}>
      {children}
      {/* <VmFooter /> */}
    </S.ContentMainBox>
  );
}
