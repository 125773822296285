import { IEditVisitAnalise } from 'client/interfaces';

import api from 'services/api';

export const editVisita = async (toEditVisita: IEditVisitAnalise): Promise<void> => {
  const queryString = Object.keys(toEditVisita)
    .map((key) => `${key}=${encodeURIComponent(toEditVisita[key])}`)
    .join('&');
  await api.put(`/RaioGps?` + queryString);
};
