import { BsPlusLg } from 'react-icons/bs';
import * as S from './styles';
import { Toolbar } from 'primereact/toolbar';
import VmButton from 'components/VmButton/VmButton';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UseCompaniesSelect } from 'hooks/useCompanies/useCompaniesSelect';
import { BlockTableTemplate } from 'components/datatable/templates';
import { BlockUI } from 'primereact/blockui';
import { TableGroupClients } from './TableGroupClients';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelectActive } from 'hooks/useSelectActive/useSelectActice';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { FilterByClients } from 'components/FilterByClients';
import { InputText } from 'primereact/inputtext';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { UseUsersByCompany } from 'hooks/useCompanies/useUserByCompany';
import { UseByUserFiliais } from 'hooks/useFiliais/useByRoleFiliais';

export const ListGroupClients = () => {
  const user = useAppSelector(userSelector);

  const [selectedUserByCompany, setSelectedUserByCompany] = useState(user?.codUsuario);
  const [selectedCompany, setSelectedCompany] = useState(user?.codEmpresa);
  const [selectedClient, setSelectedClient] = useState(0);
  const [filterNameGroup, setFilterNameGroup] = useState('');

  const { optionsCompanies, codEmpresaVMais } = UseCompaniesSelect();
  const { optionsUsersByCompany } = UseUsersByCompany(selectedCompany);
  const {
    optionsFiliais,
    handleSelectChange: handleSelectFilial,
    selectedOption: selectedFilial,
    setSelectedOption: setSelectedFilial,
    refetchFiliais,
  } = UseByUserFiliais({
    codEmpresa: Number(selectedCompany),
    codUser: Number(selectedUserByCompany),
  });
  const { optionsActiveAndInactive, active, handleActive } = useSelectActive();
  const navigate = useNavigate();

  const handleFilterNameGroup = (event) => {
    setFilterNameGroup(event.target.value);
  };

  useEffect(() => {
    setSelectedCompany(user?.codEmpresa);
    setSelectedUserByCompany(user?.codUsuario);
  }, []);

  useEffect(() => {
    refetchFiliais();
  }, [selectedUserByCompany]);

  useEffect(() => {
    if (optionsFiliais && optionsFiliais.length == 1) {
      const defaultFilial: any = [];
      defaultFilial[0] = optionsFiliais[0].value;
      setSelectedFilial(defaultFilial);
    }
  }, [optionsFiliais]);

  const leftToolbarTemplate = () => (
    <S.HeaderBox>
      <S.HeaderFilters>
        <VmButton
          style={{
            marginRight: '10px',
            marginTop: '12px',
            height: '40px',
          }}
          options={{
            style: 'btn-success',
            label: 'Adicionar',
            icon: <BsPlusLg />,
            iconPosition: 'right',
          }}
          onClick={() => {
            navigate('/grupo-clientes/criar');
          }}
        />
      </S.HeaderFilters>

      <S.HeaderFilters>
        {user && codEmpresaVMais === user?.codEmpresa && (
          <S.BoxFilters>
            <label>Empresa</label>
            <Dropdown
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.value)}
              options={optionsCompanies}
              placeholder="Selecione uma empresa"
              optionLabel="label"
              filter
              optionValue="value"
              style={{ width: '150px', marginRight: '10px' }}
            />
          </S.BoxFilters>
        )}

        {user && codEmpresaVMais === user?.codEmpresa && (
          <S.BoxFilters>
            <label>Usuário</label>
            <Dropdown
              value={selectedUserByCompany}
              onChange={(e) => setSelectedUserByCompany(e.value)}
              options={optionsUsersByCompany}
              placeholder="Selecione um Usuário"
              optionLabel="label"
              optionValue="value"
              style={{ width: '150px', marginRight: '10px' }}
            />
          </S.BoxFilters>
        )}

        <S.BoxFilters>
          <label>Filial</label>
          <MultiSelect
            disabled={!selectedUserByCompany || (optionsFiliais && optionsFiliais.length == 0)}
            style={{ width: '150px', marginRight: '10px' }}
            id="multiselect"
            placeholder="Selecione uma Filial"
            value={selectedFilial}
            options={optionsFiliais}
            onChange={handleSelectFilial}
          />
        </S.BoxFilters>

        <S.BoxFilters>
          <label>Ativo/Inativo</label>
          {optionsFiliais?.length && selectedFilial.length ? (
            <Dropdown
              value={active}
              onChange={handleActive}
              options={optionsActiveAndInactive}
              placeholder="Ativo/Inativo"
              optionLabel="label"
              optionValue="value"
              className="w-full md:w-14rem"
              style={{ width: '100px', marginRight: '10px' }}
            />
          ) : (
            <Dropdown
              disabled
              options={optionsActiveAndInactive}
              placeholder="Ativo/Inativo"
              className="w-full md:w-14rem"
              style={{ width: '100px', marginRight: '10px' }}
            />
          )}
        </S.BoxFilters>

        <S.BoxFilters>
          <label>Cliente</label>
          {optionsFiliais?.length && selectedFilial.length ? (
            <FilterByClients
              codEmpresa={Number(selectedCompany)}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
            />
          ) : (
            <Dropdown
              disabled
              options={[]}
              placeholder="Selecione um Cliente"
              className="w-full md:w-14rem"
              style={{ width: '150px', marginRight: '10px' }}
            />
          )}
        </S.BoxFilters>

        <S.BoxFilters>
          <label>Pesquisar</label>
          {optionsFiliais?.length && selectedFilial.length ? (
            <InputText
              style={{ width: '200px' }}
              value={filterNameGroup}
              onChange={handleFilterNameGroup}
              placeholder="Filtrar por Nome do Grupo"
            />
          ) : (
            <InputText style={{ width: '200px' }} disabled placeholder="Filtrar por Nome do Grupo" />
          )}
        </S.BoxFilters>
      </S.HeaderFilters>
    </S.HeaderBox>
  );

  return (
    <S.ContainerPage>
      <Toolbar className="mb-4" left={leftToolbarTemplate} />

      <BlockUI
        containerStyle={{ zIndex: 0 }}
        blocked={selectedFilial == ''}
        template={BlockTableTemplate('Selecione uma Empresa e Filial')}
      >
        {selectedCompany && selectedFilial ? (
          <TableGroupClients
            codEmpresa={String(selectedCompany)}
            codFilial={selectedFilial}
            active={active}
            selectedClient={selectedClient}
            filterNameGroup={filterNameGroup}
          />
        ) : (
          <>
            <DataTable
              value={[]}
              header=""
              dataKey="id"
              editMode="cell"
              emptyMessage="Nenhum grupo encontrado."
              paginator
              rowsPerPageOptions={[10, 25, 50]}
              rows={10}
            >
              <Column header="Nome Grupo" field="nomeGrupo" />
              <Column header="Qtd. Clientes" field="qtdClientes" />
              <Column header="Exibir FV" field="exibirFv" />
              <Column header="Código Empresa" field="codempresa" />
              <Column header="Código Filial" field="codfilial" />
              <Column header="Ativo/Inativo" field="ativo" />
              <Column header="" field="codfilial" />
              <Column header="" field="codfilial" />
            </DataTable>
          </>
        )}
      </BlockUI>
    </S.ContainerPage>
  );
};
