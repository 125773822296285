import { useGroupClientMember } from 'storesZustand/groupClientMember';
import * as S from './styles';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import VmButton from 'components/VmButton/VmButton';
import * as XLSX from 'xlsx';

import { BsChevronLeft, BsPlusLg } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ModalAddMemberToGroupClient } from '../ModalAddMemberToGroupClient';
import { AiFillFileExcel, AiOutlineDelete } from 'react-icons/ai';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { useClientGroup } from 'client/hooks/ClientGroup';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

import { deleteAllClientsGroup, deleteClientGroup } from 'client/api/clientGroup';
import { IClientGroup } from 'client/interfaces/clientGroup';
import { useQueryClient } from '@tanstack/react-query';
import { ModalImportMembersXLS } from '../ModalImportMembersXLS';
import { formatCPFOrCNPJ } from 'utils/formatCNPJ';

export const ListGroupClientMembers = () => {
  const groupInfo = useGroupClientMember((state) => state.groupInfo);

  const { codEmpresa: codEmpresaParam } = useParams<{ codEmpresa?: string }>();

  const { data: clientsGroup, isLoading } = useClientGroup(codEmpresaParam, groupInfo.codgrupo);

  const setTitlePage = useTitlePageStore((state) => state.setTitlePage);
  const titlePage = useTitlePageStore((state) => state.titlePage);

  const queryClient = useQueryClient();

  const [openModalAddMembers, setOpenModalAddMembers] = useState(false);
  const [openModalImportMembers, setOpenModalImportMembers] = useState(false);

  const navigate = useNavigate();

  const filterClientsToExport = clientsGroup?.map((item: IClientGroup) => ({
    COD_EMPRESA: item.codEmpresa,
    COD_GRUPO: item.codGrupo,
    CODCLI: item.codCli,
    CNPJ: item.cnpj,
    NOME_CLIENTE: item.nomeCli,
  }));

  function handleExportTableXLS() {
    const fileName: string = `CLIENTES_GRUPO_${groupInfo.codgrupo}`;
    const ws = XLSX.utils.json_to_sheet(filterClientsToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  }

  const handleRemoveAllClients = () => {
    Swal.fire({
      title: `Deseja Remover Todos Clientes do Grupo ${groupInfo?.nomeGrupo}?`,
      icon: 'error',
      confirmButtonText: 'Remover',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAllClientsGroup(String(groupInfo.codgrupo), String(groupInfo.codempresa))
          .then(() => {
            toast.success('Sucesso ao Remover Clientes do Grupo.');

            queryClient.invalidateQueries({
              queryKey: ['client-group'],
            });
          })
          .catch(() => {
            toast.error('Erro ao Remover Clientes do Grupo');
          });
      } else {
        toast.warning('Remoção de Clientes Cancelada!');
      }
    });
  };

  const leftToolbarTemplate = () => (
    <>
      <VmButton
        style={{
          marginRight: '10px',
        }}
        options={{
          style: 'btn-info',
          icon: <BsChevronLeft />,
          iconPosition: 'right',
          rounded: true,
          tooltip: {
            text: 'Voltar para Lista de Grupos',
            position: 'right',
            show: true,
          },
        }}
        onClick={() => {
          navigate('/grupo-clientes');
        }}
      />

      <VmButton
        style={{
          marginRight: '10px',
        }}
        options={{
          style: 'btn-success',
          label: 'Vincular Clientes',
          icon: <BsPlusLg />,
          iconPosition: 'right',
        }}
        onClick={() => {
          setOpenModalAddMembers(!openModalAddMembers);
        }}
      />

      <VmButton
        style={{
          marginRight: '10px',
        }}
        options={{
          style: 'btn-success',
          label: 'Importar Planilha de Clientes',
          icon: <BsPlusLg />,
          iconPosition: 'right',
        }}
        onClick={() => {
          setOpenModalImportMembers(!openModalImportMembers);
        }}
      />

      <VmButton
        style={{
          marginRight: '10px',
        }}
        options={{
          icon: <AiFillFileExcel />,
          label: 'Exportar',
          iconPosition: 'left',
          style: 'btn-success',
        }}
        onClick={() => handleExportTableXLS()}
      />

      <VmButton
        style={{
          marginRight: '10px',
        }}
        options={{
          icon: <AiOutlineDelete />,
          label: `Remover Todos do Grupo `,
          iconPosition: 'left',
          style: 'btn-danger',
        }}
        onClick={() => handleRemoveAllClients()}
      />
    </>
  );

  const handleRemoveClient = (rowData: IClientGroup) => {
    const handleOpenModal = (rowData) => {
      Swal.fire({
        title: `Deseja remover o cliente ${rowData.codCli} do grupo ?`,
        icon: 'error',
        confirmButtonText: 'Remover do Grupo',
        cancelButtonText: 'Voltar',
        showCloseButton: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteClientGroup(rowData.codCli, rowData.codGrupo)
            .then(() => {
              toast.success('Sucesso ao Remover Cliente do Grupo.');
              queryClient.invalidateQueries({
                queryKey: ['client-group'],
              });
              queryClient.invalidateQueries({
                queryKey: ['useClientsToAddGroup'],
              });
            })
            .catch(() => {
              toast.error('Erro ao Remover Cliente do Grupo');
            });
        } else {
          toast.warning('Remoção de Cliente Cancelada!');
        }
      });
    };

    return (
      <VmButton
        options={{
          icon: <AiOutlineDelete />,
          iconPosition: 'left',
          rounded: true,
          tooltip: {
            show: true,
            text: 'Editar',
            position: 'right',
          },
          style: 'btn-light',
        }}
        style={{ marginRight: '10px' }}
        onClick={() => {
          handleOpenModal(rowData);
        }}
      />
    );
  };

  useEffect(() => {
    setTitlePage(`Grupo: ${groupInfo.nomeGrupo} ${[groupInfo.codgrupo]}`);
  }, [titlePage, groupInfo]);

  return (
    <S.ContainerPage>
      <Toolbar className="mb-4 " left={leftToolbarTemplate} />

      <DataTable
        value={clientsGroup}
        loading={isLoading}
        dataKey="id"
        editMode="cell"
        rows={10}
        paginator
        rowsPerPageOptions={[10, 25, 50]}
        emptyMessage="Nenhum dado encontrado"
      >
        <Column header="Nome Cliente" field="nomeCli" />

        <Column header="CodCliente" field="codCli" />
        <Column header="CNPJ" body={(rowData) => formatCPFOrCNPJ(rowData.cnpj)} />
        <Column header="" field="cod" body={handleRemoveClient} />
      </DataTable>

      <ModalAddMemberToGroupClient
        isOpenModal={openModalAddMembers}
        setIsOpenModal={setOpenModalAddMembers}
        codEmpresa={codEmpresaParam}
        codGrupo={groupInfo.codgrupo}
      />

      <ModalImportMembersXLS
        isOpenModal={openModalImportMembers}
        setIsOpenModal={setOpenModalImportMembers}
        codEmpresa={codEmpresaParam}
      />
    </S.ContainerPage>
  );
};
