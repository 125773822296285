import { useEffect, useState } from 'react';
import * as S from './styles';
import { BsFillGridFill, BsGrid } from 'react-icons/bs';
import { MdOutlineDoubleArrow } from 'react-icons/md';
import { TbCalendarStats } from 'react-icons/tb';

interface IPonto {
  sequencia: number;
  codCli?: number;
  nomeCli?: string;
  lat: number;
  lng: number;
  diaSemana?: string;
  dtProxVisita?: string;
  cor?: string;
  freqVisita?: number;
  distancia?: number;
}

interface IPosition {
  latitudePonto: number;
  longitudePonto: number;
  descricaoPonto: string;
  codPonto: number;
  diaSemana: string;
  dtProxVisita: string;
  clientesDaRota: IPonto[];
  cor: string;
  mes: string;
  semana: string;
}

type RouterDailyPaginatorProps = {
  paginator: {
    showPrev: boolean;
    handlePages: (boolean) => void;
    setCurrentPositions: any;
    positions: any;
    page: any;
    cores: any;
    showNext: boolean;
    weekName: string;
  };
  setSelectedMarker: any;
  currentPositions: any;
};

export default function RouterDailyPaginator({
  paginator,
  setSelectedMarker,
  currentPositions,
}: RouterDailyPaginatorProps) {
  const [selectedDay, setSelectedDay] = useState('');

  useEffect(() => {
    const localSelectedDay = window.sessionStorage.getItem('routerSelectedDay');
    if (localSelectedDay) {
      setSelectedDay(localSelectedDay);
    }
  }, []);

  const handleGetLocalSlectedDay = () => {
    const localSelectedDay = window.sessionStorage.getItem('routerSelectedDay');
    return localSelectedDay ?? '';
  };

  return (
    <S.PaginatorBox>
      <S.PaginatorButtonPrev active={paginator.showPrev}>
        <MdOutlineDoubleArrow
          onClick={(e) => {
            if (paginator.showPrev) {
              paginator.handlePages(true);
            }
          }}
        />
      </S.PaginatorButtonPrev>
      <S.PaginatorDayCardIcon
        onClick={(e) => {
          paginator.setCurrentPositions(paginator.positions);
          setSelectedDay('');
          setSelectedMarker(null);
          window.sessionStorage.removeItem('routerSelectedDay');
          window.sessionStorage.removeItem('routerSelectedMarker');
        }}
      >
        {paginator.positions.filter((rout: any) => paginator.page === rout.semana + '/' + rout.mes).length == 1 ||
        currentPositions.length ==
          paginator.positions.filter((rout: any) => paginator.page === rout.semana + '/' + rout.mes).length ? (
          <BsFillGridFill />
        ) : (
          <BsGrid />
        )}
      </S.PaginatorDayCardIcon>
      {paginator.positions
        .filter((rout: any) => paginator.page === rout.semana + '/' + rout.mes)
        .map((position: IPosition) => (
          <S.PaginatorDayCard
            onClick={(e) => {
              paginator.setCurrentPositions([position]);
              setSelectedDay(new Date(position.dtProxVisita).toLocaleDateString('pt-BR'));
              setSelectedMarker(null);
              window.sessionStorage.setItem(
                'routerSelectedDay',
                new Date(position.dtProxVisita).toLocaleDateString('pt-BR'),
              );
              window.sessionStorage.removeItem('routerSelectedMarker');
              setSelectedMarker(null);
            }}
            key={position.codPonto + position.diaSemana + position.dtProxVisita + position.latitudePonto}
          >
            <S.PaginatorDayCardBar style={{ backgroundColor: paginator.cores[position.diaSemana] }}>
              .
            </S.PaginatorDayCardBar>
            <S.PaginatorDayCardText
              active={new Date(position.dtProxVisita).toLocaleDateString('pt-BR') == handleGetLocalSlectedDay()}
            >
              <span>{position.diaSemana.slice(0, 3)}</span>
              <span>
                {new Date(position.dtProxVisita).getDate() > 9
                  ? new Date(position.dtProxVisita).getDate()
                  : '0' + new Date(position.dtProxVisita).getDate()}
              </span>
            </S.PaginatorDayCardText>
          </S.PaginatorDayCard>
        ))}
      <S.PaginatorButtonNext active={paginator.showNext}>
        <MdOutlineDoubleArrow
          onClick={(e) => {
            if (paginator.showNext) {
              paginator.handlePages(false);
            }
          }}
        />
      </S.PaginatorButtonNext>
      <S.PaginatorWeekBox>
        <TbCalendarStats /> <span> Semana: {paginator.weekName} </span>
      </S.PaginatorWeekBox>
    </S.PaginatorBox>
  );
}
