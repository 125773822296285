import * as S from './styles';
import { FaLock } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export default function NotRoutedListCard({
  points,
  setMapCenter,
  searchText,
  selectedMarker,
  setSelectedMarker,
  currentPositions,
  positions,
  setCurrentPositions,
}: {
  points: any[];
  setMapCenter: any;
  searchText: string;
  selectedMarker: any;
  setSelectedMarker: any;
  currentPositions: any;
  positions: any[];
  setCurrentPositions: any;
}) {
  const [isCardExpanded, setIsCardExpanded] = useState(false);

  useEffect(() => {
    const localSelectedDay = window.sessionStorage.getItem('routerSelectedDay') ?? '';
    const newPositions = positions.filter(
      (position: any) => new Date(position.dtProxVisita).toLocaleDateString('pt-BR') == localSelectedDay,
    );
    if (newPositions.length > 0) {
      setCurrentPositions(newPositions);
    }
  }, []);

  const handleSelectDayBasedOnSelectedClient = (cli: any) => {
    if (currentPositions.length > 1) {
      window.sessionStorage.setItem('routerSelectedDay', new Date(cli.dtProxVisita).toLocaleDateString('pt-BR'));
      const newPositions = positions.filter(
        (position: any) =>
          new Date(position.dtProxVisita).toLocaleDateString('pt-BR') ==
          new Date(cli.dtProxVisita).toLocaleDateString('pt-BR'),
      );
      if (newPositions.length > 0) {
        setCurrentPositions(newPositions);
      }
    }
  };

  return (
    <S.PointMainCard expanded={isCardExpanded} style={{marginTop: '7px', borderTop: '1px solid #CCCCCC', paddingTop: '7px'}}>
      {/* CARD DO TITLE */}
      {points.filter(
        (cli: any) => cli.codCli.toString().includes(searchText) || cli.nomeCli.includes(searchText),
      ).length > 0 && (
        <S.PointMainCardTitle onClick={() => setIsCardExpanded(!isCardExpanded)}>
          <S.PointMainCardTitleDayString color={'#FF8000'} style={{fontWeight: 'bold'}}>
            <span>NÃO ROTEIRIZADOS</span>
          </S.PointMainCardTitleDayString>
          <S.PointMainCardTitleKm color={'#000'}>
            <div>
              <S.PointsCliTotal>Clientes: {points.length ?? 0}</S.PointsCliTotal>
            </div>
            <S.CloseCardIcon>{isCardExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}</S.CloseCardIcon>
          </S.PointMainCardTitleKm>
        </S.PointMainCardTitle>
      )}

      {points
        .filter((cli: any) => cli.codCli.toString().includes(searchText) || cli.nomeCli.includes(searchText))
        .map((cli: any, index: number) => {
          return (
            <S.PointCard
              onClick={() => {
                if (+cli.lat && +cli.lng) setMapCenter({ lat: +cli.lat, lng: +cli.lng });
                setSelectedMarker(cli);
                handleSelectDayBasedOnSelectedClient(cli);
              }}
              active={selectedMarker && selectedMarker.codCli == cli.codCli}
            >
              <S.PointCardIconMarker
                color={cli.visitaBloqueio == 'S' && '#CCCCCC'}
                dots={
                  index ==
                  points.filter(
                    (cli: any) => cli.codCli.toString().includes(searchText) || cli.nomeCli.includes(searchText),
                  ).length -
                    1
                    ? ''
                    : '.........'
                }
              >
                <img src={`${process.env.REACT_APP_BASE_URL}/images/icon_maps/icon_cliente_nao_router.png`} />
                {cli.visitaBloqueio == 'S' ? (
                  <S.BlockedVisitIcon>
                    <FaLock />
                  </S.BlockedVisitIcon>
                ) : (
                  ''
                )}
              </S.PointCardIconMarker>
              <S.PointCardContent>
                <S.PointCardCliName color={cli.visitaBloqueio == 'N' ? '#000000' : '#49505799'}>
                  [{cli.codCli}] {cli.nomeCli}
                </S.PointCardCliName>
                <S.PointCardCliData>{`${cli.fantasia ?? ''}`}</S.PointCardCliData>
                <S.PointCardCliData>{`${cli.endereco ?? ''} ${cli.endNumero ?? ''}, ${cli.endBairro ?? ''} - ${
                  cli.endCidade ?? ''
                }`}</S.PointCardCliData>
                <S.PointCardCliData>{`Distancia: ${cli.distancia} Km`}</S.PointCardCliData>
              </S.PointCardContent>
              {cli.dtProxVisita && new Date(cli.dtProxVisita).toLocaleDateString('pt-BR') != 'Invalid Date' && (
                <S.PointCardDateTag color={cli.visitaBloqueio == 'S' && '#CCCCCC'}>
                  {new Date(cli.dtProxVisita).toLocaleDateString('pt-BR')}
                </S.PointCardDateTag>
              )}
            </S.PointCard>
          );
        })}
    </S.PointMainCard>
  );
}
