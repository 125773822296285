import { IAccessData, IGetAccessData } from 'client/interfaces/acessoDados.interface';

import api from 'services/api';

const _controller = 'AcessoDados';

export const postAccessData = async (data: IAccessData[]): Promise<void> => {
  await api.post(_controller, data);
};

export const getAccessData = async (codEmpresa: number): Promise<IGetAccessData[]> => {
  const { data } = await api.get(`${_controller}/${codEmpresa}`);
  return data;
};

export const deleteAccessData = async (codEmpresa: number, pk: number): Promise<void> => {
  await api.delete(`${_controller}?CodEmpresa=${codEmpresa}&Pk=${pk}`);
};
