import styled from 'styled-components';

export const ReportMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const ReportIFrameBox = styled.iframe`
  width: 125%;
  height: 125%;
  transform-origin: 0 0 0;
  scale: 0.8;
  border: none;

  @media only screen and (min-width: 1920px) {
    scale: 1;
  }
`;
