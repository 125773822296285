import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { useState } from 'react';
import { BsSearch, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { useEmpresas } from 'client/hooks';
import { ComandoSql } from 'client/interfaces';
import { EmpresaBasico } from 'client/interfaces';
import VmButton from 'components/VmButton/VmButton';
import { useAppDispatch, useAppSelector } from 'store';

import { runSqlCommandAsync } from '../reducer/vmaissql';
// import { empresaSelector, setEmpresa } from 'store';

import { empresaSelector, setEmpresa } from '../reducer/vmaissql';
import Header from './Header';
import NewSqlCommandModal from './NewSqlCommandModal';
import SqlCommandModal from './SqlCommandModal';
import * as S from './styles';

function SqlForm() {
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isNewOpen, setIsNewOpen] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [sqlText, setSqlText] = useState('');
  const [comandoInsert, setComandoInsert] = useState('');
  const [comandoDelete, setComandoDelete] = useState('');
  const [selectedCommand, setSelectedCommand] = useState<ComandoSql | null>(null);
  const empresa = useAppSelector(empresaSelector);
  const dispatch = useAppDispatch();

  const onCloseModal = (command: ComandoSql | null) => {
    setSelectedCommand(command);
    if (command != null) {
      setSqlText(command.txtSql);
      setComandoInsert(command.comandoInsert ?? '');
      setComandoDelete(command.comandoDelete ?? '');
    }
    setIsOpen(false);
  };

  const actions = [
    {
      label: 'Atualizar',
      icon: 'pi pi-pencil',
      disabled: selectedCommand?.codSql == null || selectedCommand?.codSql === 0,
      command: () => {
        setIsNew(false);
        setIsNewOpen(true);
      },
    },
  ];

  const onExecute = async () => {
    if (sqlText != null && empresa != null) {
      try {
        setIsRunning(true);
        await dispatch(runSqlCommandAsync({ empresaId: empresa.codEmpresa ?? 0, sqlText })).unwrap();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        toast.error(
          <>
            <p>Erro ao rodar comando</p>
            <p>{e}</p>
          </>,
        );
      } finally {
        setIsRunning(false);
      }
    }
  };

  const { data: empresas } = useEmpresas();
  // const dispatch = useAppDispatch();
  // const empresa = useAppSelector(empresaSelector);

  const handleSelect = (e: { value: EmpresaBasico }) => {
    dispatch(setEmpresa(e.value));
  };

  return (
    <>
      <S.Header>
        <label className="mb-1 mt-8" htmlFor="empresa-select">
          Empresa
        </label>
        <S.BoxHeader>
          <Dropdown
            value={empresa}
            options={empresas}
            onChange={handleSelect}
            optionLabel="fantasia"
            placeholder="Selecione uma empresa"
            filter
            filterBy="fantasia"
            style={{ width: '300px', marginRight: '10px', height: '37px' }}
          />

          <VmButton
            options={{
              icon: <BsSearch />,
              label: 'Consulta',
              iconPosition: 'left',
              rounded: false,
              style: 'btn-success',
            }}
            onClick={() => setIsOpen(true)}
          />
        </S.BoxHeader>
      </S.Header>
      <S.ContainerSQL>
        <div className="fv-row">
          <label className="form-label fs-6 fw-bolder text-dark">Query SQL</label>
          <textarea
            value={sqlText}
            onChange={(e) => setSqlText(e.target.value)}
            className="form-control"
            style={{ minHeight: '200px' }}
          />
        </div>
        <S.BoxButtons>
          <Button
            disabled={sqlText.length === 0}
            onClick={() => setSqlText('')}
            icon="pi pi-times"
            label="Limpar"
            style={{
              backgroundColor: '#228F6B',
              width: '150px',
              border: 'none',
            }}
          />
          <Button
            style={{
              backgroundColor: '#228F6B',
              width: '150px',
              marginLeft: '10px',
              border: 'none',
            }}
            disabled={empresa == null || sqlText.length === 0 || isRunning}
            tooltip="Selecione uma empresa primeiro"
            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
            label="Executar"
            loading={isRunning}
            icon="pi pi-play"
            onClick={onExecute}
          />
        </S.BoxButtons>
        {!!selectedCommand?.intervalo && (
          <>
            <div className="fv-row">
              <label className="form-label fs-6 fw-bolder text-dark">Comando Insert</label>
              <textarea
                value={comandoInsert}
                onChange={(e) => setComandoInsert(e.target.value)}
                onBlur={(e) =>
                  selectedCommand && setSelectedCommand({ ...selectedCommand, comandoInsert: e.target.value })
                }
                className="form-control"
                style={{ minHeight: '200px' }}
              />
            </div>
            <div className="fv-row">
              <label className="form-label fs-6 fw-bolder text-dark">Comando Delete</label>
              <textarea
                value={comandoDelete}
                onChange={(e) => setComandoDelete(e.target.value)}
                onBlur={(e) =>
                  selectedCommand && setSelectedCommand({ ...selectedCommand, comandoDelete: e.target.value })
                }
                className="form-control"
                style={{ minHeight: '200px' }}
              />
            </div>
          </>
        )}
        <S.BoxButton>
          <SplitButton
            disabled={sqlText.length === 0 || isRunning}
            label="Salvar Novo"
            icon="pi pi-save"
            onClick={() => {
              setIsNewOpen(true);
              setIsNew(true);
            }}
            model={actions}
            style={{
              backgroundColor: '#228F6B',
              width: '200px',
              border: 'none',
            }}
          />
        </S.BoxButton>
      </S.ContainerSQL>
      {isOpen && <SqlCommandModal isOpen={isOpen} onClose={onCloseModal} />}
      {isNewOpen && (
        <NewSqlCommandModal
          command={isNew ? null : selectedCommand}
          txtSql={sqlText}
          isOpen={isNewOpen}
          onClose={(command) => {
            setIsNewOpen(false);
            setSelectedCommand(command);
          }}
        />
      )}
    </>
  );
}

export default SqlForm;
