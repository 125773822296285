import { useQueryClient } from '@tanstack/react-query';
import { postAccessData } from 'client/api/acessoDados';
import { useAccessData } from 'client/hooks/acessoDados';
import { UseManagers } from 'hooks/useManagers/useManagers';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IFormManager {
  manager: string;
}

interface IParamsManager {
  codEmpresa: number;
  codUsuario: number;
  codfuncLib: number;
}

export const useManagerAccessData = ({ codEmpresa, codUsuario, codfuncLib }: IParamsManager) => {
  const queryClient = useQueryClient();

  const { optionsManagers } = UseManagers({ codEmpresa: codEmpresa });

  const { data: accessData } = useAccessData(codEmpresa);

  const accessDataManager = useMemo(() => {
    if (accessData) {
      return accessData?.filter((objeto) => objeto.tipoAcesso === 'G' && objeto.codUsuario === codUsuario);
    }
    return [];
  }, [accessData, codUsuario]);

  const { handleSubmit, control, watch, setValue, reset } = useForm<IFormManager>({});

  const values = watch();

  const onSubmitManager = async (data) => {
    const selectedAll = data.manager.length === optionsManagers.length;

    const allManager = {
      codEmpresa: codEmpresa,
      tipoAcesso: 'G',
      codUsuario: codUsuario,
      codAcesso: 999,
      codfuncLib: codfuncLib,
    };

    await postAccessData(
      selectedAll
        ? [allManager]
        : data.manager.map((item) => {
            return {
              codEmpresa: codEmpresa,
              tipoAcesso: 'G',
              codUsuario: codUsuario,
              codAcesso: item,
              codfuncLib: codfuncLib,
            };
          }),
    )
      .then(() => {
        queryClient.invalidateQueries(['acesso-dados']);
        toast.success('Sucesso ao conceder Acesso');
      })
      .catch(() => {
        toast.error('Error ao conceder Acesso');
      })
      .finally(() => {
        reset();
      });
  };

  return {
    handleSubmit,
    onSubmitManager,
    control,
    values,
    setValue,
    accessDataManager,
    optionsManagers,
  };
};
