import styled from 'styled-components';

export const RegrasConfigMainBox = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 7.5px;
  width: auto;
  left: 0px;
  z-index: 9;
  height: 66dvh;
`;

export const RegrasConfigItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 49.3%;
  max-height: 64dvh;
  overflow: auto;
  gap: 5px;
`;
