import * as S from './styles';

import { useEffect, useMemo, useRef, useState } from 'react';

import VmButton from 'components/VmButton/VmButton';

import { TfiReload } from 'react-icons/tfi';
import { BsFullscreen } from 'react-icons/bs';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

import useMediaQuery from 'hooks/useMediaQuery';

interface IControlHeaderParams {
  setIsFullScreenEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreenEnabled: boolean;
  getVisitControlFullData: () => void;
}

export default function ControlHeader({ setIsFullScreenEnabled, isFullScreenEnabled, getVisitControlFullData }: IControlHeaderParams) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { setTitlePage } = useTitlePageStore();

  const handleFullScreenMode = () => {
    const header = document.getElementById('kt_header');

    const ktBody = document.getElementById('kt_body');

    if (isFullScreenEnabled) {
      if (header) {
        header.style.display = 'flex';
        ktBody.removeAttribute('data-kt-aside-minimize');
      }
      setIsFullScreenEnabled(false);
    } else {
      if (header) {
        ktBody.setAttribute('data-kt-aside-minimize', 'on');
      }
      setIsFullScreenEnabled(true);
    }
  };

  const minutesToTimeNotation = useMemo(() => {
    const innerMinutesToTimeNotation = (minutes: number): string => {
      if (minutes < 0) {
        throw new Error('Número não pode ser negativo!');
      }

      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;

      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}`;
    };

    return innerMinutesToTimeNotation;
  }, []);

  const [timePeriod, setTimePeriod] = useState(0.5);

  const intervalRef = useRef<any>(null);<p></p>

  function handleDataGetEachXTime() {
    let button = document.getElementById('timerButton');

    let seconds = timePeriod * 60;
    button.innerHTML = minutesToTimeNotation(seconds);
    seconds += 1;

    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      if (seconds - 1 == 0) {
        const refreshButton = document.getElementById('refreshDataButton');
        if (refreshButton) {
          const clickEvent = new Event('click', {
            bubbles: true,
            cancelable: true,
          });
          refreshButton.dispatchEvent(clickEvent);
        }
      }
      seconds = seconds <= 1 ? timePeriod * 60 : seconds - 1;
      button.innerHTML = minutesToTimeNotation(seconds - 1);
    }, 1000);
  }

  useEffect(() => {
    setTitlePage('Controle de Visitas');
    handleDataGetEachXTime();
  }, [timePeriod]);

  return (
    <S.ControlHeaderMainBox>
      <VmButton
        options={{
          icon: <span id={'timerButton'}>00:59</span>,
          iconPosition: 'right',
          style: 'btn-success',
        }}
      />
      <S.TimerSelect
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setTimePeriod(+e.target.value);
        }}
      >
        <option value={0.25}>15s</option>
        <option value={0.5} selected>
          30s
        </option>
        <option value={1}>1m</option>
        <option value={2}>2m</option>
        <option value={5}>5m</option>
        <option value={10}>10m</option>
      </S.TimerSelect>
      <VmButton
        options={{
          icon: <TfiReload />,
          iconPosition: 'right',
          style: 'btn-dark',
          rounded: true,
          tooltip: {
            show: true,
            position: 'bottom',
            text: 'Recarregar dados',
          },
        }}
        id={'refreshDataButton'}
        onClick={() => {
          getVisitControlFullData();
          handleDataGetEachXTime();
        }}
      />
      {isWebScreen && (
        <VmButton
          options={{
            icon: <BsFullscreen />,
            iconPosition: 'right',
            style: 'btn-dark',
            rounded: true,
            tooltip: {
              show: true,
              position: 'bottom',
              text: 'Tela cheia',
            },
          }}
          onClick={() => handleFullScreenMode()}
        />
      )}
    </S.ControlHeaderMainBox>
  );
}
