import styled from 'styled-components';

export const RouterPaginationMainBox = styled.section`
  width: clamp(30%, 40%, 98%);
  height: clamp(50px, 60px, 70px);
  margin: 10px auto;
  position: absolute;
  z-index: 2;
  bottom: 1.5vh;
  margin-left: 7.5%;
  padding: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 2%;
  font-weight: bold;

  @media (max-width: 768px) {
    width: 98%;
    bottom: 15vh;
    margin: 0 auto;
  }
`;

export const TotalKmBox = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #ffffffd9;
  border-radius: 5px;
  width: 18%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
`;

export const TotalKmBoxTitle = styled.div`
  font-weight: bolder;
`;

export const TotalKmBoxValue = styled.div``;

export const RouterPaginationBox = styled.section`
  width: clamp(60%, 70%, 80%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #ffffffd9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PaginationSubBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  *::selection {
    background-color: transparent;
  }
`;

export const Arrow = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
  color: #808082;
`;
