import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useContext } from 'react';

import { MapContext } from 'providers/map';

import * as S from './styles';
import VmButton from 'components/VmButton/VmButton';
import { BsFillPinMapFill } from 'react-icons/bs';

import { IMapaClient } from 'client/interfaces/index';
import { updateFrequenceVisit } from 'client/api/mapaCli';
import { toast } from 'react-toastify';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

interface ICliListParams {
  codEmpresaParam: string;
  codMapaParam: string;
  setShowEditAddressModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMarker?: React.Dispatch<React.SetStateAction<IMapaClient>>;
}

const CliList = ({ codEmpresaParam, codMapaParam, setShowEditAddressModal, setSelectedMarker }: ICliListParams) => {
  const { mapGridShow, clientesList, reloadAllData } = useContext(MapContext);

  const clienteNameTemplate = (rowData: IMapaClient) => {
    return (
      <span>
        [{rowData.codCli}] {rowData.razaosocial}
      </span>
    );
  };

  const setorNameTemplate = (rowData: IMapaClient) => {
    if (rowData.codSetor != null && rowData.codSetor != 0) {
      return (
        <span>
          [{rowData.codSetor}] {rowData.nomeSetor}
        </span>
      );
    } else {
      return (
        <span>
        </span>
      );
    }
  };

  const cidadeTemplate = (rowData: IMapaClient) => {
    return (
      <span>
        [{rowData.endUf}] {rowData.endCidade}
      </span>
    );
  };

  const editCoordsTemplate = (rowData: IMapaClient) => {
    return (
      <VmButton
        style={{ marginLeft: '10px' }}
        options={{
          style: 'btn-info',
          rounded: true,
          icon: <BsFillPinMapFill />,
          iconPosition: 'right',
          tooltip: {
            position: 'left',
            show: true,
            text: 'Editar coordenadas.',
          },
        }}
        onClick={() => {
          setSelectedMarker(rowData);
          setShowEditAddressModal(true);
        }}
      />
    );
  };

  const handleDataInput = (value: string) => {
    const period = 'end';
    let v: any = value;

    v = v.replace(/\D/g, '');

    if (v.length >= 8) {
      v = v.match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

      v = !v[2] ? v[1] : v[1] + '/' + v[2] + '/' + v[3];

      let splitDate = v.split('/');

      if (splitDate[1] == '02') {
        if (+splitDate[2].slice(2, 2) % 4 == 0 && +splitDate[0] > 28) {
          if (period == 'end') {
            splitDate[0] = '28';
          } else {
            splitDate[0] = '01';
          }
        }
      } else {
        if (
          (splitDate[1] == '04' || splitDate[1] == '06' || splitDate[1] == '09' || splitDate[1] == '11') &&
          splitDate[0] > '30'
        ) {
          if (period == 'end') {
            splitDate[0] = '30';
          } else {
            splitDate[0] = '01';
          }
        }
      }
      if (+splitDate[1] > 12) {
        splitDate[1] = '12';
      }
      if (+splitDate[0] > 31) {
        splitDate[0] = '31';
      }

      value = splitDate.join('/');
    }
    return value;
  };

  const dateEditor = (options: any) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback(handleDataInput(e.target.value))}
      />
    );
  };

  const numberEditor = (options: any) => {
    return <InputNumber value={options.value} onValueChange={(e: any) => options.editorCallback(e.value)} />;
  };

  const onCellEditComplete = async (e: any) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    const newRowData = {
      codEmpresa: +codEmpresaParam,
      codMapa: +codMapaParam,
      codCli: rowData.codCli,
      freqVisita: field == 'freqVisita' ? +newValue : +rowData.freqVisita,
      tempoMinVisita: field == 'tempoMinVisita' ? +newValue : +rowData.tempoMinVisita,
      dtProxVisita:
        field == 'dtProxVisita' && newValue != null
          ? newValue
          : rowData.dtProxVisita && rowData.dtProxVisita != null
          ? rowData.dtProxVisita
          : '',
      sequencia: field == 'sequencia' ? +newValue : +rowData.sequencia,
      bloqRoteiro: rowData.bloqRoteiro ?? 'N',
      agendamentoDoCliente: rowData.agendamentoDoCliente ?? 'N',
      domingoManha: rowData.domingoManha ?? 'N',
      domingoTarde: rowData.domingoTarde ?? 'N',
      segundaManha: rowData.segundaManha ?? 'N',
      segundaTarde: rowData.segundaTarde ?? 'N',
      tercaManha: rowData.tercaManha ?? 'N',
      tercaTarde: rowData.tercaTarde ?? 'N',
      quartaManha: rowData.quartaManha ?? 'N',
      quartaTarde: rowData.quartaTarde ?? 'N',
      quintaManha: rowData.quintaManha ?? 'N',
      quintaTarde: rowData.quintaTarde ?? 'N',
      sextaManha: rowData.sextaManha ?? 'N',
      sextaTarde: rowData.sextaTarde ?? 'N',
      sabadoManha: rowData.sabadoManha ?? 'N',
      sabadoTarde: rowData.sabadoTarde ?? 'N',
    };

    await updateFrequenceVisit(newRowData)
      .then((res) => {
        rowData[field] = newValue;
        reloadAllData();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(`Falha ao editar '${field}' do cliente!`);
        }
      });
  };

  return (
    <S.Container
      style={{
        zIndex: !mapGridShow ? '2' : '0',
        opacity: !mapGridShow ? '1' : '0',
        height: !mapGridShow ? '100%' : '0%',
        pointerEvents: !mapGridShow ? 'auto' : 'none',
      }}
    >
      <S.ListTable>
        <DataTable
          value={clientesList}
          tableStyle={{ minWidth: '100%' }}
          paginator
          rows={8}
          style={{ fontSize: '.8rem' }}
          rowClassName={(rowData) => (rowData.ativo === 'N' ? 'text-danger' : '')}
          responsiveLayout="scroll"
          scrollHeight="calc(80vh - 110px)"
          scrollDirection="both"
        >
          <Column field="razaosocial" header="CLIENTE" body={clienteNameTemplate} sortable />
          <Column field="codSetor" header="SETOR" body={setorNameTemplate} sortable />
          <Column field="endCidade" header="CIDADE" sortable body={cidadeTemplate} />
          <Column field="endLogradouro" header="LOGRADOURO" sortable />
          <Column field="latitude" header="LATITUDE" sortable />
          <Column field="longitude" header="LONGITUDE" sortable />

          <Column
            field="dtProxVisita"
            header="PRÓXIMA VISITA"
            sortable
            align="right"
            editor={(options) => dateEditor(options)}
            onCellEditComplete={onCellEditComplete}
          />
          <Column
            field="sequencia"
            header="SEQUÊNCIA"
            sortable
            align="right"
            editor={(options) => numberEditor(options)}
            onCellEditComplete={onCellEditComplete}
          />
          <Column
            field="freqVisita"
            header="FREQ.VISITA"
            sortable
            align="right"
            editor={(options) => numberEditor(options)}
            onCellEditComplete={onCellEditComplete}
          />
          <Column
            field="tempoMinVisita"
            header="TEMPO_MINIMO_VISITA"
            sortable
            align="right"
            editor={(options) => numberEditor(options)}
            onCellEditComplete={onCellEditComplete}
          />

          <Column field="vlPotencial" header="POTENCIAL" sortable />
          <Column field="vlMesAtual" header="VENDA ULT. TRI." sortable />
          <Column field="vlMedUltTrim" header="VENDA MÊS" sortable />

          <Column field="" header="" body={editCoordsTemplate} />
        </DataTable>
      </S.ListTable>
    </S.Container>
  );
};

export default CliList;
