import { create } from 'zustand';

interface IParamsCodScreenwriter{
  codMapa: string;
  codSetor: string;
  codVendedor: string;
  codEmpresa: string;
}

interface IScreenwriterStore {
  paramsCodScreenwriter: IParamsCodScreenwriter
  setParamsCodScreenwriter(paramsCodScreenwriter: IParamsCodScreenwriter): void;
}

export const useScreenwriterStore = create<IScreenwriterStore>()((set) => ({
  paramsCodScreenwriter: {
    codEmpresa: '',
    codMapa: '',
    codVendedor: '',
    codSetor: '',
  },
  setParamsCodScreenwriter: (paramsCodScreenwriter) => set(() => ({ paramsCodScreenwriter})),
}));
