import { create } from "zustand";

import { IPontoAgenda } from "client/interfaces/pontoAgenda.interface";

interface ICalendarStore {
    points: IPontoAgenda[];
    setPoints(points: IPontoAgenda[]): void;
}

export const useCalendarStore = create<ICalendarStore>()((set) => ({
    points: [],
    setPoints: (points) => set(() => ({points}))
}))