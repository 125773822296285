import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useContext, useState } from 'react';
import { BsCheck } from 'react-icons/bs';

import VmButton from 'components/VmButton/VmButton';
import { MapContext } from 'providers/map';

// IMPORTA STYLES DO COMPONENT
import * as S from './styles';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

interface IVinculoSetor {
  clientes: any;
  coordinates: any;
  polygonos: any;
  CreatePoly: any;
  showVinculoSetor: boolean;
  setShowVinculoSetor: any;
}

export default function VinculoSetorModal({
  clientes,
  coordinates,
  polygonos,
  CreatePoly,
  showVinculoSetor,
  setShowVinculoSetor,
}: IVinculoSetor) {
  const { setores, reloadAllData } = useContext(MapContext);

  // DEFINE STATE PARA ARMAZENAR SETOR SELECIONADO
  const [actualSetor, setActualSetor] = useState<any>(null);

  const DialogFooterPolygon = (
    <S.ModalButtonsBox>
      <VmButton
        options={{
          icon: 'X',
          label: 'Cancelar',
          iconPosition: 'left',
          rounded: false,
          style: 'btn-danger',
          tooltip: {
            show: false,
            text: 'Cancelar',
            position: 'bottom',
          },
        }}
        style={{ paddingRight: '10px' }}
        onClick={() => setShowVinculoSetor(false)}
      />
      <VmButton
        options={{
          icon: <BsCheck />,
          label: 'Salvar',
          iconPosition: 'left',
          rounded: false,
          style: 'btn-success',
          tooltip: {
            show: false,
            text: 'Cancelar',
            position: 'bottom',
          },
        }}
        style={{ paddingRight: '10px' }}
        onClick={(e: any) => {
          if (actualSetor !== null) {
            setShowVinculoSetor(false);
            CreatePoly(
              coordinates,
              clientes,
              polygonos.length + 1,
              actualSetor,
              reloadAllData,
              polygonos,
              setShowVinculoSetor,
            );
          }
        }}
      />
    </S.ModalButtonsBox>
  );

  return (
    <div>
      <Dialog
        visible={showVinculoSetor}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={'Vincule a um Setor'}
        modal
        className="p-fluid"
        onHide={() => setShowVinculoSetor(false)}
        footer={DialogFooterPolygon}
      >
        <form className={'novoSetorForm'}>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              <Dropdown
                options={setores.filter(
                  (setor: any) => setor.polygono !== undefined && setor.polygono.length === 0 && setor.codSetor != -1,
                )}
                optionLabel="setor"
                placeholder={(actualSetor && actualSetor.setor) || 'Vincule a um Setor'}
                filter
                className="w-full md:w-14rem"
                onChange={(e) => {
                  setActualSetor(e.target.value);
                }}
              />
            </label>
          </div>
        </form>
      </Dialog>
    </div>
  );
}
