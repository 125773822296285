import { PageTitle } from '_metronic/layout/core';

import DashboardPage from './DashboardPage';

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <DashboardPage />
    </>
  );
};

export default DashboardWrapper;
