import { useQueryClient } from '@tanstack/react-query';
import { postAccessData } from 'client/api/acessoDados';
import { useAccessData } from 'client/hooks/acessoDados';
import { UseSupervisor } from 'hooks/useSupervisor/useSupervisor';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IForm {
  supervisor: string;
}

interface IParamsSupervisor {
  codEmpresa: number;
  codUsuario: number;
  codfuncLib: number;
}

export const useSupervisorAccessData = ({ codEmpresa, codUsuario, codfuncLib }: IParamsSupervisor) => {
  const queryClient = useQueryClient();

  const { optionsSupervisor, isLoading } = UseSupervisor({ codEmpresa: codEmpresa });

  const { data: accessData } = useAccessData(codEmpresa);

  const accessDataSupervisor = useMemo(() => {
    if (accessData) {
      return accessData?.filter((objeto) => objeto.tipoAcesso === 'S' && objeto.codUsuario === codUsuario);
    }
    return [];
  }, [accessData, codUsuario]);

  const { handleSubmit, control, watch, setValue, reset } = useForm<IForm>({});

  const values = watch();

  const onSubmit = async (data) => {
    const selectedAll = data.supervisor.length === optionsSupervisor.length;

    const allSupervisor = {
      codEmpresa: codEmpresa,
      tipoAcesso: 'S',
      codUsuario: codUsuario,
      codAcesso: 999,
      codfuncLib: codfuncLib,
    };

    await postAccessData(
      selectedAll
        ? [allSupervisor]
        : data.supervisor.map((item) => {
            return {
              codEmpresa: codEmpresa,
              tipoAcesso: 'S',
              codUsuario: codUsuario,
              codAcesso: item,
              codfuncLib: codfuncLib,
            };
          }),
    )
      .then(() => {
        queryClient.invalidateQueries(['acesso-dados']);
        toast.success('Sucesso ao conceder Acesso');
      })
      .catch(() => {
        toast.error('Error ao conceder Acesso');
      })
      .finally(() => {
        reset();
      });
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    values,
    setValue,
    accessDataSupervisor,
    isLoading,
    optionsSupervisor,
  };
};
