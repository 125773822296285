import { getRouterData, getRouterCompData } from 'client/api';

export async function getCliData(codSetor: string, codVendedor: string, codEmpresa: string) {
  return await getRouterData(codEmpresa, codSetor, codVendedor)
    .then((res: any) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export async function getCliCompData(codSetor: string, codEmpresa: string) {
  return await getRouterCompData(codEmpresa, codSetor)
    .then((res: any) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

const paginate = (positions: any) => {
  if (positions.length == 0) {
    return [];
  }
  let week = positions[0].semana + '/' + positions[0].mes;
  let pages = [week];
  positions.map((pos: any) => {
    if (week !== pos.semana + '/' + pos.mes) {
      pages.push(pos.semana + '/' + pos.mes);
    }
    week = pos.semana + '/' + pos.mes;
  });

  return pages;
};

export { paginate };
