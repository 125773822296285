import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getClientsCompany, getRotaPontoAgenda } from 'client/api/pontoAgenda';
import { IPontoAgenda, IPontoAgendaCompany } from 'client/interfaces/pontoAgenda.interface';

export const usePontoAgenda = (CodEmpresa: number, CodVendedor: number, enabled = true) =>
  useQuery<IPontoAgenda[], AxiosError>(
    ['rotaPontoAgenda', CodEmpresa],
    async () => await getRotaPontoAgenda(CodEmpresa, CodVendedor),
    {
      enabled,
    },
  );

export const useClientsCompany = (codEmpresa: number, CodSetor: number, enabled = true) =>
  useQuery<IPontoAgendaCompany[], AxiosError>(
    ['rotaPontoAgenda', codEmpresa, CodSetor],
    async () => await getClientsCompany(codEmpresa, CodSetor),
    {
      enabled,
    },
  );
