import styled from 'styled-components';

export const ResetMainBox = styled.section`
  background-color: #fafafa;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
`;

export const ResetFormBox = styled.form`
  display: flex;
  border: 1px solid #cccccc70;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 7.5px;
  padding: 20px;
`;

export const BoxPassword = styled.div`
  border: 1px solid #cccccc70;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 7.5px;
  padding: 20px;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
