import styled from 'styled-components';
interface ZoomButton {
  disabled?: boolean;
}

export const MapZoomMainBox = styled.section`
  width: 3rem;
  height: 6rem;
  position: absolute;
  right: calc( 100% - 3.5rem );
  bottom: 4dvh;
  z-index: 2;
  margin-left: 7.5%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #606062;
  border-radius: 2.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-weight: bold;

  @media (max-width: 768px) {
    bottom: 22vh;
  }
`;

export const ZoomButton = styled.div<ZoomButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 5px;
  color: #000000;
  font-size: 1.75rem;
  height: 45%;
  width: 90%;
  cursor: pointer;
  background-color: #606062;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  color: ${(props) => (props.disabled ? '#808082' : '#FFF')};

  &:hover {
    background-color: #ffffff05;
    color: #808082;
  }
  *:hover {
    color: #808082;
  }
`;

export const HLine = styled.div`
  width: 80%;
  height: 1px;
  background-color: #fff;
  opacity: 0.7;
`;
