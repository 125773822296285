import * as S from './styles';

export default function SuccessPage() {
  return (
    <S.SuccesBox>
      <S.SuccessContent>
        <S.SuccessIcon>
          <svg version="1.1" viewBox="0 0 130.2 130.2">
            <circle
              className="reactivus-path reactivus-circle"
              fill="none"
              stroke="#73AF5570"
              strokeWidth="6"
              strokeMiterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              className="reactivus-path reactivus-check"
              fill="none"
              stroke="#73AF55"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
        </S.SuccessIcon>
        <S.SuccessTitle>Sucesso!</S.SuccessTitle>
        <S.SuccessSubTitle>Seu pagamento foi efetuado, obrigado.</S.SuccessSubTitle>
      </S.SuccessContent>
    </S.SuccesBox>
  );
}
