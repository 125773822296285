import styled from 'styled-components';

export const NovaRegraModalFormBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

export const NovaRegraModalFormRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 25px;
`;
export const NovaRegraModalFormInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 25px;
`;

export const NovaRegraModalFormFooter = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 25px;
`;

export const RegraInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  input::focus {
    outline: none;
    border: none;
  }
`;

export const RegraDateInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  input {
    color: #606060;
    border: none;
    outline: none;
    background-color: #fff !important;
    width: 100%;
    height: 2.5rem;
    border: 1px solid #ced4da;
    border-radius: 2.5px;
    padding: 0px 5px;
  }
  input::focus {
    outline: none;
    border: none;
  }
`;

export const NovaRegraModalTable = styled.div`
  font-size: .9rem;
  table{
    font-size: .9rem !important;
    width: 100% !important;
  }
  tbody .p-checkbox{
    background-color: #00000010;
    margin-left: 22.5px;
  }
`;

export const NovaRegraModalChecboxTemplate = styled.div`
  background-color: #00000010 !important;
  padding-left: 10px !important;
`;
