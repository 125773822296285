export default function clientSvg1(value: string | number, color: string) {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" class="marker" opacity="0.8" height="48px" width="48px">
    <circle cx="50%" cy="30%" r="10" fill="#FFF" />
<path fill-opacity="0.35" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z" />
<path fill="${color}" stroke="#fff" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 .125 26 11.875C26 18.062 15.938 32 15.938 32zM16 6a6 6 0 100 12 6 6 0 000-12z" />
<text x="46.5%" y="35%" text-anchor="middle" dominant-baseline="central" fill="${color}" font-size="11px" font-weight="bolder" font-family="Arial, sans-serif">${
    value || 0
  }</text>
</svg> `;
}
