import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getFiliaisByUser, getFiliaisEmpresa } from 'client/api/filiais';
import { IFiliais } from 'client/interfaces/filiais.interface';

export const useFiliais = (CodEmpresa: number, enabled = true) =>
  useQuery<IFiliais[], AxiosError>(['filiais', CodEmpresa], async () => await getFiliaisEmpresa(CodEmpresa), {
    enabled,
  });

export const useByUserFiliais = (CodEmpresa: number, CodUser: number, enabled = true) =>
  useQuery<IFiliais[], AxiosError>(['filiais/ByUser'], async () => await getFiliaisByUser(CodEmpresa, CodUser), {
    enabled,
  });
