import { ColumnEditorOptions } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText, InputTextProps } from 'primereact/inputtext';

export const textEditor = (options: ColumnEditorOptions, error?: boolean) => (
  <InputText
    style={{
      border: `${error ? '1px solid red' : ''}`,
    }}
    type="text"
    value={options.value ?? ''}
    onChange={(e) => options.editorCallback?.(e.target.value)}
  />
);

export const numberEditor = (options: ColumnEditorOptions, props?: InputTextProps,  required?: boolean) => (
  <InputText
    {...props}
    keyfilter="num"
    value={options.value}
    onChange={(e) => options.editorCallback?.(e.target.value)}
  />
);

export const numberEditorReq = (options: ColumnEditorOptions, props?: InputTextProps) => (
  <InputText
    {...props}
    required
    keyfilter="num"
    value={options.value}
    onChange={(e) => options.editorCallback?.(e.target.value)}
  />
);

export const booleanEditor = (options: ColumnEditorOptions) => (
  <InputSwitch checked={options.value} onChange={(e) => options.editorCallback?.(e.target.value)} />
);
