import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  input {
    border-radius: 5px;
    border: 1px solid var(--gray-100);
  }
`;

export const Separator = styled.div`
  padding: 10px;
`;
