import { useContext } from 'react';

import gif from 'assets/gift/cliMapLoader.gif';
import { MapContext } from 'providers/map';

import * as S from './styles';

interface ILoad {
  title?: string;
}

const LoadCliMap = ({ title }: ILoad) => {
  const { loadCliMap, loadCliMapMessage } = useContext(MapContext);

  if (loadCliMap) {
    return (
      <S.Container>
        <img src={gif} />
        {title || loadCliMapMessage && <span>{title ?? loadCliMapMessage}</span>}
      </S.Container>
    );
  } else {
    return <></>;
  }
};

export default LoadCliMap;
