import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import { MapProvider } from 'providers/map';
import { PedidoProvider } from 'providers/pedidoProvider';
import AppRoutes from 'routes';

import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store/store';

import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';
import './_metronic/assets/sass/style.scss';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'react-toastify/dist/ReactToastify.min.css';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <MapProvider>
        <PedidoProvider>
          <PersistGate loading="loading" persistor={persistor}>
            <ToastContainer style={{ marginTop: 65 }} position="top-right" newestOnTop theme="colored" />
            <AppRoutes />
          </PersistGate>
        </PedidoProvider>
      </MapProvider>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);

reportWebVitals();
