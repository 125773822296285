import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ModalTableBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  table tr th * {
    font-weight: bolder;
  }
`;

export const ModalTableCliName = styled.span`
  white-space: nowrap;
`;

export const ModalTitleBox = styled.span`
  white-space: nowrap;
`;

export const ModalBody = styled.span`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const IconTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  margin-left: 1rem;
`;
