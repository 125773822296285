import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './editRegraModal.styles';
import { Dialog } from 'primereact/dialog';
import { EditRegraModalProps, RegraProps } from 'client/interfaces';
import { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { useFiliais } from 'client/hooks/filiais';
import VmButton from 'components/VmButton/VmButton';
import { toast } from 'react-toastify';
import { postEditRegra, postNovaRegra } from 'client/api/regraComercial';
import styled from 'styled-components';
import { InputTextarea } from 'primereact/inputtextarea';

export default function EditRegraModal({
  showEditRegraModal,
  setShowEditRegraModal,
  handleGetRegrasComerciais,
  regraToEdit,
  codEmpresa,
}: EditRegraModalProps) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { data: filiais } = useFiliais(codEmpresa);

  const [editRegra, setEditRegra] = useState(null);

  const textareaRef = useRef(null);

  useEffect(() => {
    if (showEditRegraModal) {
      setEditRegra(regraToEdit);
    }
  }, [regraToEdit]);

  const validateFormFields = () => {
    for (const key in editRegra) {
      if (editRegra.hasOwnProperty(key)) {
        if (
          editRegra[key] === '' &&
          key !== 'sql' &&
          key !== 'descricao' &&
          key !== 'img1' &&
          key !== 'img2' &&
          key !== 'img3' &&
          key !== 'nomefunccad' &&
          key !== 'descricao'
        ) {
          toast.warn(`O campo '${key}' está vazio.`);
          return;
        }
        if (key === 'filial' && editRegra[key] === 0) {
          toast.warn(`O campo '${key}' está vazio.`);
          return;
        }
      }
    }
    handleEditRegra();
  };

  const handleEditRegra = () => {
    const regraToEdit = { ...editRegra };
    regraToEdit.descricao = textareaRef.current ? textareaRef.current.value : '';
    postEditRegra(regraToEdit)
      .then((res) => {
        if (res.succeeded) {
          handleGetRegrasComerciais();
          toast.success('Regra editada com sucesso');
          setShowEditRegraModal(false);
        }
      })
      .catch((err) => {
        toast.error('Falha inesperada ao editar regra');
        console.log('err :', err);
      });
  };

  const StyledTextarea = styled(InputTextarea)`
    resize: none;
  `;

  return (
    <Dialog
      header={editRegra ? `Editar regra [${editRegra.codregra}] - ${editRegra.nomeregra}` : 'Editar regra'}
      visible={showEditRegraModal}
      style={{ width: isWebScreen ? '35vw' : '90%' }}
      onHide={() => {
        setShowEditRegraModal(false);
      }}
    >
      <S.EditRegraModalFormBox>
        <S.EditRegraModalFormRow>
          <S.RegraInputBox>
            <label htmlFor="report">Regra</label>
            <InputText
              id="rule"
              placeholder="Nome da regra"
              style={{
                width: 'clamp(200px, 250px, 300px)',
              }}
              value={editRegra ? editRegra.nomeregra : ''}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    nomeregra: e.target.value,
                  };
                });
              }}
            />
          </S.RegraInputBox>

          <S.RegraInputBox>
            <label>Filial</label>
            <Dropdown
              value={filiais?.filter((filial: any) => editRegra && +filial.codFilial == +editRegra.filial)[0]}
              options={filiais}
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    filial: e.value.codFilial,
                  };
                });
              }}
              optionLabel="nomeFilial"
              emptyMessage="Nenhuma Filial encontrada :/"
              itemTemplate={(filial: any) => {
                return `[${filial.codFilial}] - ${filial.nomeFilial}`;
              }}
            />
          </S.RegraInputBox>
        </S.EditRegraModalFormRow>

        <S.EditRegraModalFormRow>
          <S.RegraDateInputBox>
            <label>Data Inicial</label>
            <input
              type={'date'}
              id={'dateStart'}
              value={editRegra ? editRegra.dataInicio.split('/').reverse().join('-') : ''}
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    dataInicio: e.target.value.split('-').reverse().join('/'),
                  };
                });
              }}
            />
          </S.RegraDateInputBox>
          <S.RegraDateInputBox>
            <label>Data Final</label>
            <input
              type={'date'}
              id={'dateEnd'}
              value={editRegra ? editRegra.dataFim.split('/').reverse().join('-') : ''}
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    dataFim: e.target.value.split('-').reverse().join('/'),
                  };
                });
              }}
            />
          </S.RegraDateInputBox>

          <S.RegraInputBox>
            <label htmlFor="report">Ativa</label>
            <InputSwitch
              checked={editRegra && editRegra.ativa == 'S'}
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    ativa: e.value ? 'S' : 'N',
                  };
                });
              }}
            />
          </S.RegraInputBox>
          <S.RegraInputBox>
            <label htmlFor="report">Envia FV</label>
            <InputSwitch
              checked={editRegra && editRegra.enviafv == 'S'}
              onChange={(e) => {
                setEditRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    enviafv: e.value ? 'S' : 'N',
                  };
                });
              }}
            />
          </S.RegraInputBox>
        </S.EditRegraModalFormRow>

        <S.EditRegraModalFormRow>
          <S.RegraInputBox>
            <label htmlFor="report">Decrição da regra</label>
            <StyledTextarea ref={textareaRef} defaultValue={editRegra ? editRegra.descricao : ''} rows={5} cols={65} />
          </S.RegraInputBox>
        </S.EditRegraModalFormRow>

        <S.EditRegraModalFormFooter>
          <VmButton
            options={{
              label: 'Salvar',
              style: 'btn-success',
              size: 'btn-md',
            }}
            onClick={() => {
              validateFormFields();
            }}
          />
        </S.EditRegraModalFormFooter>
      </S.EditRegraModalFormBox>
    </Dialog>
  );
}
