import { useState } from 'react';

export const useSelectActive = () => {
  const [active, setActive] = useState('S');

  const optionsActiveAndInactive = [
    {
      value: 'T',
      label: 'Todos',
    },
    {
      value: 'S',
      label: 'Ativo',
    },
    {
      value: 'N',
      label: 'Inativo',
    },
  ];

  const handleActive = (event) => {
    setActive(event.target.value);
  };

  return {
    optionsActiveAndInactive,
    setActive,
    active,
    handleActive,
  };
};
