import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './novaRegraModal.styles';
import { Dialog } from 'primereact/dialog';
import { NovaRegraModalProps, RegraProps } from 'client/interfaces';
import { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { useFiliais } from 'client/hooks/filiais';
import VmButton from 'components/VmButton/VmButton';
import { toast } from 'react-toastify';
import { postNovaRegra } from 'client/api/regraComercial';
import { useParams } from 'react-router-dom';
import { regraTypeOptions } from '../../regraComercial.types';
import { userInfo } from 'os';
import { useUser } from 'client/hooks';
import { InputTextarea } from 'primereact/inputtextarea';
import styled from 'styled-components';

export default function NovaRegraModal({
  showNovaRegraModal,
  setShowNovaRegraModal,
  codEmpresa,
  handleGetRegrasComerciais,
}: NovaRegraModalProps) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { data: user } = useUser();

  const { id: regraType } = useParams();

  const { data: filiais } = useFiliais(codEmpresa);

  const textareaRef = useRef(null);

  const [newRegra, setNewRegra] = useState({
    codregra: 0,
    nomeregra: '',
    ativa: 'S',
    dataInicio: new Date().toLocaleDateString().split('/').reverse().join('-'),
    dataFim: new Date().toLocaleDateString().split('/').reverse().join('-'),
    descricao: '',
    qtdemin: 0,
    mixmin: 0,
    valormin: 0,
    premiodesconto: 0,
    premiotipodesc: 'Z',
    premiocodprod: 0,
    aplicaAuto: 'N',
    acumulaBenef: 'N',
    filial: 0,
    premiodescontomin: 0,
    percminproduto: 0,
    mixfamiliaproduto: 'N',
    acumulaBenefLim: 0,
    validarcasadinha: 'N',
    creditobnf: 'N',
    valormax: 0,
    enviafv: 'S',
    codregramedalha: '0',
    validartotped: 'N',
    tipo: regraTypeOptions[regraType].type,
    alertarabertura: 'N',
    alertarfechamento: 'N',
    vendaobrig: 'N',
    tipodescbnf: 'N',
    codfunccad: 0,
    sql: '',
    validarqtemb: 'N',
    dtaltera: new Date().toLocaleDateString().split('/').reverse().join('-'),
    bnfComoVenda: 'S',
    qdemingrupo: 0,
    qdemaxgrupo: 0,
    valordesconto: 0,
    dtfimimportacao: new Date().toLocaleDateString().split('/').reverse().join('-'),
    bnfNoPedidoDeVenda: 'N',
    qdemax: 0,
    gerarbnfantecipada: 'N',
    enviaecommerce: 'N',
    codigoschavemedalha: 'N',
    enviaTelemarketing: 'N',
    alertaAtingiuCombo: 'N',
    alertaNaoAtingiuCombo: 'N',
    codgrupocombos: 0,
    aplicaOferta: 'N',
    codEmpresa: codEmpresa,
    regraItems: [],
    regraRestricao: [],
    img1: '',
    img2: '',
    img3: '',
    nomefunccad: '',
  });

  useEffect(() => {
    if (user && user.codUsuario) {
      setNewRegra((prevState: RegraProps) => {
        return {
          ...prevState,
          codfunccad: user.codUsuario ?? 0,
        };
      });
    }
  }, [user]);

  const validateFormFields = () => {
    for (const key in newRegra) {
      if (newRegra.hasOwnProperty(key)) {
        if (
          newRegra[key] === '' &&
          key !== 'sql' &&
          key !== 'descricao' &&
          key !== 'img1' &&
          key !== 'img2' &&
          key !== 'img3' &&
          key !== 'nomefunccad'
        ) {
          toast.warn(`O campo '${key}' está vazio.`);
          return;
        }
        if (key === 'filial' && newRegra[key] === 0) {
          toast.warn(`O campo '${key}' está vazio.`);
          return;
        }
      }
    }
    handleInsertNewRegra();
  };

  const handleInsertNewRegra = () => {
    const regraToInsert = newRegra;
    regraToInsert.descricao = textareaRef.current ? textareaRef.current.value : '';
    postNovaRegra(regraToInsert)
      .then((res) => {
        if (res.succeeded) {
          handleGetRegrasComerciais();
          toast.success('Nova regra inserida com sucesso');
          setShowNovaRegraModal(false);
        }
      })
      .catch((err) => {
        toast.error('Falha inesperada ao inserir nova regra');
        console.log('err :', err);
      });
  };

  const StyledTextarea = styled(InputTextarea)`
    resize: none;
  `;

  return (
    <Dialog
      header={'Nova regra'}
      visible={showNovaRegraModal}
      style={{ width: isWebScreen ? '35vw' : '90%' }}
      onHide={() => {
        setShowNovaRegraModal(false);
      }}
    >
      <S.NovaRegraModalFormBox>
        <S.NovaRegraModalFormRow>
          <S.RegraInputBox>
            <label htmlFor="report">Regra</label>
            <InputText
              id="rule"
              placeholder="Nome da regra"
              style={{
                width: 'clamp(200px, 250px, 300px)',
              }}
              value={newRegra.nomeregra}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    nomeregra: e.target.value,
                  };
                });
              }}
            />
          </S.RegraInputBox>

          <S.RegraInputBox>
            <label>Filial</label>
            <Dropdown
              value={filiais?.filter((filial: any) => filial.codFilial == newRegra.filial)[0]}
              options={filiais}
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    filial: e.value.codFilial,
                  };
                });
              }}
              optionLabel="nomeFilial"
              emptyMessage="Nenhuma Filial encontrada :/"
              itemTemplate={(filial: any) => {
                return `[${filial.codFilial}] - ${filial.nomeFilial}`;
              }}
            />
          </S.RegraInputBox>
        </S.NovaRegraModalFormRow>

        <S.NovaRegraModalFormRow>
          <S.RegraDateInputBox>
            <label>Data Inicial</label>
            <input
              type={'date'}
              id={'dateStart'}
              value={newRegra.dataInicio}
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    dataInicio: e.target.value,
                  };
                });
              }}
            />
          </S.RegraDateInputBox>
          <S.RegraDateInputBox>
            <label>Data Final</label>
            <input
              type={'date'}
              id={'dateEnd'}
              value={newRegra.dataFim}
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    dataFim: e.target.value,
                  };
                });
              }}
            />
          </S.RegraDateInputBox>

          <S.RegraInputBox>
            <label htmlFor="report">Ativa</label>
            <InputSwitch
              checked={newRegra.ativa == 'S'}
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    ativa: e.value ? 'S' : 'N',
                  };
                });
              }}
            />
          </S.RegraInputBox>
          <S.RegraInputBox>
            <label htmlFor="report">Envia FV</label>
            <InputSwitch
              checked={newRegra.enviafv == 'S'}
              onChange={(e) => {
                setNewRegra((prevState: RegraProps) => {
                  return {
                    ...prevState,
                    enviafv: e.value ? 'S' : 'N',
                  };
                });
              }}
            />
          </S.RegraInputBox>
        </S.NovaRegraModalFormRow>

        <S.NovaRegraModalFormRow>
          <S.RegraInputBox>
            <label htmlFor="report">Decrição da regra</label>
            <StyledTextarea defaultValue={newRegra.descricao} ref={textareaRef} rows={5} cols={65} />
          </S.RegraInputBox>
        </S.NovaRegraModalFormRow>

        <S.NovaRegraModalFormFooter>
          <VmButton
            options={{
              label: 'Salvar',
              style: 'btn-success',
              size: 'btn-md',
            }}
            onClick={() => {
              validateFormFields();
            }}
          />
        </S.NovaRegraModalFormFooter>
      </S.NovaRegraModalFormBox>
    </Dialog>
  );
}
