import * as S from './styles';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import ControlHeader from './components/controlHeader/index';
import ControlSideBar from './components/controlSideBar';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { useVisitControlPointsData, useVisitControlStatusData } from 'client/hooks/roteirizador';
import { IPointsVisitControl } from 'client/interfaces/roteirizador.interfaces';

export default function ControleVisitas() {
  const user = useAppSelector(userSelector);

  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [cliMarkers, setCliMarkers] = useState(null);
  const [mapZoom, setMapZoom] = useState(5);
  const [mapCenter, setMapCenter] = useState({
    lat: -15.79530797472881,
    lng: -47.88850280934874,
  });
  const [selectedMarker, setSelectedMarker] = useState(null);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      codCompany: user?.codEmpresa,
      codUserByCompany: user?.codUsuario,
      codSupervisor: '0',
      codSeller: '0',
      filterGlobal: '',
    },
  });

  const values = watch();

  const { data: dataPoints, refetch: refetchDataPoints } = useVisitControlPointsData(
    String(values.codCompany),
    values.codSeller,
    values.codSupervisor,
  );
  const { data: dataStatus, refetch: refetchDataStatus } = useVisitControlStatusData(
    String(values.codCompany),
    values.codSeller,
    values.codSupervisor,
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAUHxQUnO76uq2HBu2X6xzaLZPapIFv--0',
    libraries: ['drawing', 'places'],
  });

  const renderMarkers = () => {
    let markersCli = dataPoints?.map((cli: IPointsVisitControl) => {
      return (
        <Marker
          icon={{
            url: cli.ICONE,
          }}
          key={Math.random()}
          position={{ lat: +cli.LATITUDE, lng: +cli.LONGITUDE }}
          onClick={(e) => setSelectedMarker(cli)}
        />
      );
    });
    setCliMarkers(markersCli);
    if (!cliMarkers) {
      setMapZoom(5);
    }
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  const getVisitControlFullData = () => {
    refetchDataStatus();
    refetchDataPoints();
  };

  useEffect(() => {
    getVisitControlFullData();
  }, [values.codCompany, values.codSeller, values.codSupervisor]);

  useEffect(() => {
    renderMarkers();
  }, [dataPoints]);

  useEffect(() => {
    if (!showMap) {
      setShowMap(isLoaded);
    }
  }, [isLoaded]);

  return (
    <S.MapMainBox toTop={isFullScreenEnabled}>
      {showMap && (
        <GoogleMap
          mapContainerClassName="map-container"
          center={mapCenter}
          zoom={mapZoom}
          options={{
            mapTypeControl: false,
            mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            streetViewControl: true,
            streetViewControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            fullscreenControl: false,
          }}
        >
          {cliMarkers && <div id={'markersBox'}>{cliMarkers}</div>}

          {selectedMarker && (
            <InfoWindow
              position={{ lat: +selectedMarker.LATITUDE, lng: +selectedMarker.LONGITUDE }}
              onCloseClick={handleInfoWindowClose}
              options={{
                pixelOffset: new window.google.maps.Size(0, -45),
              }}
            >
              <S.CustomInfoWindow>
                <div dangerouslySetInnerHTML={{ __html: selectedMarker.POPUP }} />
              </S.CustomInfoWindow>
            </InfoWindow>
          )}
        </GoogleMap>
      )}

      <ControlHeader
        setIsFullScreenEnabled={setIsFullScreenEnabled}
        isFullScreenEnabled={isFullScreenEnabled}
        getVisitControlFullData={getVisitControlFullData}
      />

      <ControlSideBar dataStatus={dataStatus} values={values} setValue={setValue} control={control} />
    </S.MapMainBox>
  );
}
