import styled from 'styled-components';

interface IMapHeaderProps {
  mapGridShow?: boolean;
}

export const SelectDayMainBox = styled.div`
  width: 100%;
  padding: 10px 0px;
`;

export const DaysBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
`;

export const DaysBoxTable = styled.table`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

export const DaysBoxTableRow = styled.tr``;

export const DaysBoxTableHeaderColumn = styled.th`
  border: 1px solid #cccccc90;
  font-size: 0.9rem;
  padding: 2.5px 0 0 2.5px;
`;

export const DaysBoxTableHead = styled.thead`
  background-color: #cccccc20;
`;

export const DaysBoxTableBody = styled.tbody``;

export const DaysBoxTableBodyCell = styled.td`
  border: 1px solid #cccccc90;
  padding: 2.5px 0 0 0px;
  text-align: center;
`;

export const DaysBoxTableInput = styled.input``;
