import { Dialog } from 'primereact/dialog';
import * as S from './styles';
import { UseEditGroupClient } from 'hooks/useGroupClients/useEditGroupClient';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Select, Option } from 'components/Select/styles';
import { UseCompaniesSelect } from 'hooks/useCompanies/useCompaniesSelect';
import { useEffect, useState } from 'react';
import { useUser } from 'client/hooks';
import VmButton from 'components/VmButton/VmButton';
import { IGroupClient } from 'client/interfaces/groupClient';
import { useSelectActive } from 'hooks/useSelectActive/useSelectActice';
import { Dropdown } from 'primereact/dropdown';

interface IEditGroupClient {
  isOpenModalEdit: boolean;
  setIsOpenModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
  rowGroupClient: IGroupClient;
}

export const EditGroupClients = ({ rowGroupClient, isOpenModalEdit, setIsOpenModalEdit }: IEditGroupClient) => {
  const [isValid, setIsValid] = useState(false);
  const { data: user } = useUser();

  const {
    isDefaultFilial,
    isDefaultCompany,
    handleSubmit,
    control,
    values,
    setValue,
    optionsFiliais,
    errors,
    DefaultValues,
    handleEditGroupClient,
    optionsExibirFV
  } = UseEditGroupClient({ setIsOpenModalEdit, rowGroupClient });

  const { optionsActiveAndInactive } = useSelectActive();

  const { optionsCompanies, codEmpresaVMais, selectedOption } = UseCompaniesSelect();

  useEffect(() => {
    if (
      values.codempresa === DefaultValues.COMPANY ||
      values.codfilial === DefaultValues.FILIAL ||
      values.nomeGrupo === '' ||  values.ativo === 'T'
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [values]);


  const handleClearFilial = () => {
    setValue('codfilial', DefaultValues.FILIAL);
  };

  useEffect(() => {
    handleClearFilial();
  }, [selectedOption]);

  return (
    <Dialog
      header={`Editar Grupo ${rowGroupClient?.nomeGrupo}`}
      visible={isOpenModalEdit}
      onHide={() => {
        setIsOpenModalEdit(!isOpenModalEdit);
      }}
      style={{ width: '40vw' }}
      breakpoints={{ '960px': '75vw', '641px': '90vw' }}
    >
      <S.Form onSubmit={handleSubmit(handleEditGroupClient)}>
        <S.ItemRow>
          <S.TitleInput error={errors.nomeGrupo && true}>*Nome Grupo de Clientes</S.TitleInput>
          <Controller
            rules={{
              required: true,
            }}
            name="nomeGrupo"
            defaultValue={rowGroupClient?.nomeGrupo}
            control={control}
            render={({ field }) => <InputText {...field} />}
          />
          <S.DescriptionInput error={errors.nomeGrupo && true}>Digite um nome para o grupo.</S.DescriptionInput>
        </S.ItemRow>

        <S.ItemRow>
          {user?.codEmpresa === codEmpresaVMais ? (
            <>
              <S.TitleInput error={errors.codempresa && true}>*Empresa</S.TitleInput>
              <Controller
                name="codempresa"
                rules={{
                  required: true,
                  validate: isDefaultCompany,
                }}
                defaultValue={rowGroupClient?.codempresa}
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <Option defaultChecked>Selecione uma empresa.</Option>
                    {optionsCompanies?.map((company) => (
                      <Option key={company.value} value={company.value}>
                        {company.label}
                      </Option>
                    ))}
                  </Select>
                )}
              />
              <S.DescriptionInput error={errors.codempresa && true}>Selecione uma empresa.</S.DescriptionInput>
            </>
          ) : (
            <>
              <S.TitleInput error={errors.codempresa && true}>*Empresa</S.TitleInput>
              <Controller
                name="codempresa"
                rules={{
                  required: true,
                  validate: isDefaultCompany,
                }}
                defaultValue={user ? String(user.codEmpresa) : DefaultValues.COMPANY}
                control={control}
                render={({ field }) => <InputText disabled {...field} />}
              />
            </>
          )}
        </S.ItemRow>

        <S.ItemRow>
          <S.TitleInput error={errors.codfilial && true}>*Código Filial</S.TitleInput>
          <Controller
            name="codfilial"
            rules={{
              required: true,
              validate: isDefaultFilial,
            }}
            control={control}
            defaultValue={rowGroupClient?.codfilial}
            render={({ field }) => (
              <Select {...field}>
                <Option defaultChecked>Selecione uma filial.</Option>
                {optionsFiliais?.map((filial) => (
                  <Option key={filial.value} value={filial.value}>
                    {filial.label}
                  </Option>
                ))}
              </Select>
            )}
          />

          <S.DescriptionInput error={errors.codfilial && true}>Selecione uma filial.</S.DescriptionInput>
        </S.ItemRow>

        <S.ItemRow>
          <S.TitleInput error={errors.ativo && true}>*Ativo/Inativo</S.TitleInput>
          <Controller
            name="ativo"
            rules={{
              required: true,
            }}
            defaultValue={rowGroupClient?.ativo}
            control={control}
            render={({ field }) => (
              <Select {...field}>
                {optionsActiveAndInactive?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          />

          <S.DescriptionInput error={errors.ativo && true}>Selecione uma situação.</S.DescriptionInput>
        </S.ItemRow>

        <S.ItemRow>
          <S.TitleInput error={errors.exibirFv && true}>*Exibir FV</S.TitleInput>
          <Controller
            name="exibirFv"
            rules={{
              required: true,
            }}
            defaultValue={rowGroupClient?.exibirFv}
            control={control}
            render={({ field }) => (
              <Select {...field}>
                {optionsExibirFV?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          />

          <S.DescriptionInput error={errors.ativo && true}>Selecione uma situação.</S.DescriptionInput>
        </S.ItemRow>


        <S.ItemRow style={{ width: '100%' }}>
          <VmButton
            type="submit"
            options={{
              label: 'Editar',
              style: 'btn-success',
              width: '100px',
              heigth: '',
              iconPosition: 'left',
              size: 'btn-md',
              disabled: !isValid,
              loading: false,
            }}
          />
        </S.ItemRow>
      </S.Form>
    </Dialog>
  );
};
