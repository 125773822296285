import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { BsFillUnlockFill, BsHandIndex } from 'react-icons/bs';

import { saveMenuRestricao } from 'client/api/menuRestricao';
import { useEmpresas } from 'client/hooks';
import ToastBox from 'components/ToastBox';
import { userSelector } from 'modules/auth/reducer/auth';
import { useAppSelector } from 'store';
import { useModalAddFunctionalityToCompany } from 'storesZustand/modalAddFunctionalityToCompany';

import { IRowDataMenu } from '../../ItensMenu';
import * as S from './styles';
import ModalSeeRegisteredCompanies from '../ModalSeeRegisteredCompanies';
import { toast } from 'react-toastify';

interface IModal {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  data: IRowDataMenu;
}

const ModalAddFunctionalityToCompany = ({ setOpenModal, openModal, data }: IModal) => {
  const currentUser = useAppSelector(userSelector);
  const { setStatus, selectedCompany, setSelectedCompany } = useModalAddFunctionalityToCompany();
  const queryClient = useQueryClient();

  const { data: empresas } = useEmpresas();

  const newDateSP = moment().tz('America/Sao_Paulo').toDate();

  const onSubmit = () => {
    saveMenuRestricao({
      codMenu: data.codMenu,
      codEmpresa: selectedCompany.codEmpresa,
      CodFuncLibera: currentUser.codUsuario,
      dtLibera: newDateSP,
    })
      .then(() => {
        setSelectedCompany({ codEmpresa: 0, cnpj: '', razaosocial: '', fantasia: '', caminhoLogo: '' });

        toast.success(`Acesso concedido ao menu ${data.nomeMenu}, na empresa ${selectedCompany.fantasia}`);
        queryClient.invalidateQueries({
          queryKey: ['MenuRestrincao'],
        });
      })
      .catch((e) => {
        setSelectedCompany({ codEmpresa: 0, cnpj: '', razaosocial: '', fantasia: '', caminhoLogo: '' });

        toast.error(
          `Não foi possível conceder acesso ao menu ${data.nomeMenu}, na empresa ${selectedCompany.fantasia}`,
        );
      });
  };

  const Cancel = () => {
    setStatus('default');
    setSelectedCompany({
      codEmpresa: 0,
      cnpj: '',
      razaosocial: '',
      fantasia: '',
      caminhoLogo: '',
    });
  };

  return (
    <Dialog
      header={'Liberação de empresa'}
      visible={openModal}
      onHide={() => setOpenModal(false)}
      style={{ width: '80vw', height: '500px' }}
    >
      <h3>Conceder acesso ao menu '{data.nomeMenu}'</h3>
      <S.ContainerModal>
        <S.Container>
          <S.Title>
            <span>Selecione uma empresa para conceder acesso</span>
          </S.Title>
          <S.BoxInput>
            <Dropdown
              value={selectedCompany}
              options={empresas}
              onChange={(e) => setSelectedCompany(e.value)}
              optionLabel="fantasia"
              placeholder="Selecione uma empresa"
              filter
              filterBy="fantasia"
            />
          </S.BoxInput>

          {selectedCompany.fantasia === '' ? (
            <>
              <ToastBox
                variant="default"
                icon={<BsHandIndex />}
                title={'Selecione uma empresa !'}
                text={`Conceder acesso ao menu ${data.nomeMenu}`}
              />
            </>
          ) : (
            <>
              <ToastBox
                variant="warning"
                icon={<BsFillUnlockFill />}
                renderButton
                onClick={onSubmit}
                onClickCancel={Cancel}
                title={'Conceder acesso?'}
                text={`Menu ${data.nomeMenu}, na empresa ${selectedCompany.fantasia}`}
              />
            </>
          )}
        </S.Container>

        <S.Separator />

        <ModalSeeRegisteredCompanies data={data} />
      </S.ContainerModal>
    </Dialog>
  );
};

export default ModalAddFunctionalityToCompany;