import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getVisitControlPointsData, getVisitControlStatusData } from 'client/api';
import { IPointsVisitControl, IStatusVisitControl } from 'client/interfaces/roteirizador.interfaces';

export const useVisitControlPointsData = (codEmpresa: string, CodVendedor: string, CodSuperv: string, enabled = true) =>
  useQuery<IPointsVisitControl[], AxiosError>(
    ['getVisitControlPointsData', codEmpresa],
    async () => await getVisitControlPointsData(codEmpresa, CodVendedor, CodSuperv),
    {
      enabled,
    },
  );

export const useVisitControlStatusData = (codEmpresa: string, CodVendedor: string, CodSuperv: string, enabled = true) =>
  useQuery<IStatusVisitControl[], AxiosError>(
    ['getVisitControlStatusData', codEmpresa],
    async () => await getVisitControlStatusData(codEmpresa, CodVendedor, CodSuperv),
    {
      enabled,
    },
  );
