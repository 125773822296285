import { Dialog } from 'primereact/dialog';
import * as S from './styles';
import useMediaQuery from 'hooks/useMediaQuery';
import { useEffect, useState } from 'react';
import { useVendedores } from 'client/hooks/vendedores';
import VmButton from 'components/VmButton/VmButton';
import { postAlterRcaProxVisita } from 'client/api';
import { toast } from 'react-toastify';

type AlterDtProxVisitaProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  codSetor: string;
  codVendedor: string;
  codEmpresa: string;
  currentPositions: any[];
  page: string;
  handleGetCliData: () => void;
  setLoadCliMap: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AlterDtProxVisitaModal({
  showModal,
  setShowModal,
  codSetor,
  codVendedor,
  codEmpresa,
  currentPositions,
  page,
  handleGetCliData,
  setLoadCliMap,
}: AlterDtProxVisitaProps) {
  const { data: vendedores } = useVendedores(codEmpresa);

  const [actualVendedor, setActualVendedor] = useState(null);

  useEffect(() => {
    if (vendedores) {
      setActualVendedor(
        vendedores.filter((v: any) => v.codVendedor == +codVendedor)
          ? vendedores.filter((v: any) => v.codVendedor == +codVendedor)[0]
          : null,
      );
    }
  }, [vendedores]);

  const [formParams, setFormParams] = useState({
    CodEmpresa: +codEmpresa,
    CodVendedor: +codVendedor,
    CodSetor: +codSetor,
    DataVisita: new Date().toLocaleDateString().split('/').reverse().join('-'),
    DataProximaVisita: new Date().toLocaleDateString().split('/').reverse().join('-'),
    DiaSemana: '',
  });

  useEffect(() => {
    if (currentPositions.length == 1 && showModal) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      // const filteredPositions = currentPositions?.filter((rout: any) => {
      //   const [year, month, day] = rout.dtProxVisita.split('T')[0].split('-').map(Number);
      //   const visitDate = new Date(year, month - 1, day);

      //   return page === rout.semana + '/' + rout.mes && visitDate > today;
      // });

      if (new Date(currentPositions[0].dtProxVisita) < today) {
        toast.error('O roteiro selecionado não pode ser alterado pois é referente a uma data anterior a data atual.');
        setShowModal(false);
      } else if (new Date(currentPositions[0].dtProxVisita).getTime() == today.getTime()) {
        toast.error('O roteiro selecionado não pode ser alterado pois é referente a data atual.');
        setShowModal(false);
      }

      setFormParams({
        CodEmpresa: +codEmpresa,
        CodVendedor: +codVendedor,
        CodSetor: +codSetor,
        DataVisita: currentPositions[0]
          ? new Date(currentPositions[0].dtProxVisita).toLocaleDateString().split('/').reverse().join('-')
          : new Date().toLocaleDateString().split('/').reverse().join('-'),
        DataProximaVisita: new Date().toLocaleDateString().split('/').reverse().join('-'),
        DiaSemana: currentPositions[0] ? currentPositions[0].diaSemana : '',
      });
    } else if (showModal) {
      toast.dismiss();
      toast.error('Nenhum roteiro selecionado para alteração.');
      setShowModal(false);
    }
  }, [currentPositions, showModal]);

  const handleAlterRcaProxViista = () => {
    // return;
    // const backupData = formParams.DataProximaVisita;

    // formParams.DataProximaVisita = formParams.DataVisita;
    // formParams.DataVisita = backupData;

    formParams.DiaSemana = getDayName(formParams.DataProximaVisita);

    postAlterRcaProxVisita(formParams)
      .then((res) => {
        if (res.succeeded) {
          toast.success('Data do roteiro atualizada');
          setShowModal(false);
          handleGetCliData();
          // setLoadCliMap(true);
        } else {
          toast.error('Falha ao atualizar data do roteiro.' + (res.message ? res.message : ``));
        }
      })
      .catch((err: any) => {
        console.log('err :', err);
      });
  };

  const getDayName = (dateString?: string): string => {
    const date = new Date();
    const options = { weekday: 'long' } as const;
    return date.toLocaleDateString('pt-BR', options).toUpperCase().replace('-FEIRA', '');
  };

  return (
    <Dialog
      header={`Alterar data do roteiro:`}
      visible={showModal}
      onHide={() => {
        setShowModal(!showModal);
      }}
    >
      <S.AlterDataModalBox>
        <S.AlterDataModalTitleBox>
          Vendedor: {actualVendedor ? `[${actualVendedor.codVendedor}] ${actualVendedor.nome}` : ''}
        </S.AlterDataModalTitleBox>

        <S.AlterDataModalTitleBox>
          Alterar roteiro do dia{' '}
          <b>
            {formParams.DataVisita.split('-').reverse().join('/')} {`[${formParams.DiaSemana}]`}
          </b>
        </S.AlterDataModalTitleBox>
        <S.AlterDataModalFormBox>
          <label>
            para o dia{' '}
            <b>
              {formParams.DataProximaVisita.split('-').reverse().join('/')}{' '}
              {`[${getDayName(formParams.DataProximaVisita)}]`}
            </b>
          </label>
          {/* <S.DateInputBox>
            <input
              type={'date'}
              id={'dateStart'}
              value={formParams.DataProximaVisita}
              defaultValue={formParams.DataProximaVisita}
              onChange={(e) => {
                setFormParams((prev: any) => {
                  return {
                    ...prev,
                    DataProximaVisita: e.target.value,
                  };
                });
              }}
            />
          </S.DateInputBox> */}
          <VmButton
            options={{
              label: 'Confirmar',
              style: 'btn-success',
              size: 'btn-md',
            }}
            onClick={() => {
              handleAlterRcaProxViista();
            }}
          />
        </S.AlterDataModalFormBox>
      </S.AlterDataModalBox>
    </Dialog>
  );
}
