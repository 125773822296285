import { useUserByCompany } from 'client/hooks';
import { useState } from 'react';

export const UseUsersByCompany = (codCompany: number | string) => {
  const { data: usuarios } = useUserByCompany(codCompany);

  const optionsUsersByCompany = usuarios?.map((item) => ({
    value: item.codUsuario,
    label: `${item.nome} - [${item.codUsuario}]`,
  }));

  const [selectedUserByCompany, setSelectedUserByCompany] = useState('');

  const handleSelectUserByCompany = (event) => {
    setSelectedUserByCompany(event.target.value);
  };

  return {
    optionsUsersByCompany,
    setSelectedUserByCompany,
    selectedUserByCompany,
    handleSelectUserByCompany
  };
};
