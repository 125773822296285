import styled from 'styled-components';

export const RestricoesTableMainBox = styled.section`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0px;
`;

export const RestricoesTableTitle = styled.div`
  width: 100%;
  font-size: 1.2rem;
  padding: 5px 10px;
  font-weight: 400;
`;

export const RestricoesBodyBox = styled.section`
  width: 100%;
  border-radius: 7.5px;
  overflow: hidden;
  border: 1px solid #cccccc70;
  background-color: #fff;
  overflow-x: hidden;

  table {
    font-size: 0.9rem;
  }

  .p-datatable {
    overflow: hidden !important;
    scrollbar-width: none !important;
  }
`;

export const RestricoesBodyFooter = styled.section`
  width: 100%;
  border-radius: 7.5px;
  overflow: hidden;
  background-color: #fff;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const AccordionHeader = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 40dvw;
`;

export const CodRestricaoBodyButton = styled.div`
  opacity: 0;
  transition: all 0.4s ease;
`;
export const CodRestricaoBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  &:hover ${CodRestricaoBodyButton} {
    opacity: 1;
  }
`;

export const AccordionHeaderTemplateBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  .reactivus-btn-success {
    background-color: #3f7d5420 !important;
  }

  .reactivus-btn-success:hover {
    background-color: #3f7d5440 !important;
  }

  .reactivus-btn-success svg {
    font-size: 0.9rem;
  }
`;
