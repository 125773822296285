import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { runSqlCommand } from 'client/api';
import { EmpresaBasico } from 'client/interfaces';
import type { RootState } from 'store/rootReducer';
import { handleAxiosError } from 'utils/handleError';

interface VmaisSqlState {
  empresa: EmpresaBasico | null;
  error: unknown;
  returnedValue: string | null;
}

const initialState: VmaisSqlState = {
  empresa: null,
  error: null,
  returnedValue: null,
};

export const runSqlCommandAsync = createAsyncThunk(
  'vmais/runSqlCommand',
  async ({ sqlText, empresaId }: { sqlText: string; empresaId: number }, thunkAPI) => {
    try {
      return await runSqlCommand(empresaId, sqlText);
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  },
);

const vmaisSqlSlice = createSlice({
  name: 'vmais',
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
    },
    setEmpresa: (state, action: PayloadAction<EmpresaBasico | null>) => {
      state.empresa = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runSqlCommandAsync.fulfilled, (state, action) => {
      state.returnedValue = action.payload;
      state.error = null;
    });
    builder.addCase(runSqlCommandAsync.rejected, (state, action) => {
      state.error = action.payload;
      state.returnedValue = null;
    });
  },
});

export const { clearState, setEmpresa } = vmaisSqlSlice.actions;

export const empresaSelector = (state: RootState): EmpresaBasico | null => state.vmaisSql.empresa;
export const returnedValueSelector = (state: RootState): string | null => state.vmaisSql.returnedValue;
export const errorSelector = (state: RootState): unknown => state.vmaisSql.error;

export default vmaisSqlSlice.reducer;
