import clsx from 'clsx';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { UserValidateStatus } from 'client/interfaces';
import Toggle from 'components/Toggle';
import { useAppDispatch, useAppSelector } from 'store';
import { formatCPF, revertFormatCPF } from 'utils/formatCpf';

import { empresaCNPJSelector, setEmpresaCNPJ, setUserCpf, validateUserInfo } from '../reducer/auth';
import styles from './styles.module.scss';

interface FormState {
  user: string;
}

function UserForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [cpf, setCpf] = useState('');
  const [matriculaERP, setMatriculaERP] = useState('');

  const empresaCNPJ = useAppSelector(empresaCNPJSelector);

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    setError,
  } = useForm<FormState>();
  const dispatch = useAppDispatch();
  const [isLoginCPF, setIsLoginCPF] = useState(true);

  const onSubmit: SubmitHandler<FormState> = async ({ user }) => {
    try {
      setIsLoading(true);
      const response = await dispatch(
        validateUserInfo({ user: isLoginCPF ? revertFormatCPF(cpf) : matriculaERP, isCpf: isLoginCPF }),
      ).unwrap();
      switch (response.status) {
        case UserValidateStatus.Invalid:
          setError('user', {
            message: 'cpf ou matrícula ERP inválido',
          });
          break;
        case UserValidateStatus.Inactive:
          setError('user', {
            message: 'Usuário inativo! Entre em contato com o administrador',
          });
          break;
        case UserValidateStatus.Registered:
          dispatch(setUserCpf(user));
          break;
        case UserValidateStatus.PendingRegistration:
          dispatch(setUserCpf(user));
          break;
        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e === 404) {
        setError('user', {
          message: 'usuário inválido',
        });
      } else {
        toast.error(
          <>
            <p>Erro ao validar usuário</p>
            <p>{`${e as string}`}</p>
          </>,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeCompany = () => {
    dispatch(setEmpresaCNPJ(null));
    dispatch(setUserCpf(null));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form w-100" noValidate>
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Código Empresa ou CNPJ</label>
        <div className="input-group mb-5">
          <input
            value={empresaCNPJ ?? ''}
            className="form-control form-control-lg form-control-solid"
            readOnly
            disabled
          />
          <a onClick={handleChangeCompany} className="btn btn-light">
            Alterar
          </a>
        </div>
      </div>

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Logar com</label>

        <div className="mb-4 mt-1">
          <Toggle
            isActive={isLoginCPF}
            setIsActive={setIsLoginCPF}
            text1={'CPF'}
            text2={'Matrícula ERP'}
            setValue1={setCpf}
            setValue2={setMatriculaERP}
          />
        </div>

        {isLoginCPF ? (
          <input
            {...register('user', { required: true })}
            placeholder="XXX.XXX.XXX-XX"
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': (dirtyFields.user ?? false) && errors.user,
            })}
            onChange={(e) => setCpf(e.target.value)}
            value={formatCPF(cpf)}
            autoFocus
            type="user"
            name="user"
          />
        ) : (
          <input
            {...register('user', { required: true })}
            placeholder="matrícula ERP"
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': (dirtyFields.user ?? false) && errors.user,
            })}
            onChange={(e) => setMatriculaERP(e.target.value)}
            value={matriculaERP}
            autoFocus
            type="user"
            name="user"
          />
        )}

        {errors.user != null && (
          <div className="fv-plugins-message-container invalid-feedback">
            <span role="alert">{errors.user.message}</span>
          </div>
        )}
      </div>

      <div className="text-center">
        <button type="submit" className={`${styles.button} btn btn-lg w-100 mb-5`} disabled={isLoading}>
          {!isLoading && <span className="indicator-label">Continuar</span>}
          {isLoading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Validando...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}

export default UserForm;
