import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmpresaBasico, UserData } from 'client/interfaces';
import type { RootState } from 'store/rootReducer';

interface MenuAcessoState {
  selectedUsuario: UserData | null;
  selectedEmpresa: EmpresaBasico | null;
}

const initialState: MenuAcessoState = {
  selectedUsuario: null,
  selectedEmpresa: null,
};

const menuAcessoSlice = createSlice({
  name: 'menuAcesso',
  initialState,
  reducers: {
    setSelectedUsuario: (state, action: PayloadAction<UserData | null>) => {
      state.selectedUsuario = action.payload;
    },
    setSelectedEmpresa: (state, action: PayloadAction<EmpresaBasico>) => {
      state.selectedEmpresa = action.payload;
    },
  },
});

export const { setSelectedUsuario, setSelectedEmpresa } = menuAcessoSlice.actions;

export const selectedUsuarioSelector = (state: RootState): UserData | null => state.menuAcesso.selectedUsuario;
export const selectedEmpresaSelector = (state: RootState): EmpresaBasico | null => state.menuAcesso.selectedEmpresa;

export default menuAcessoSlice.reducer;
