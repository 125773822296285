import styled from 'styled-components';

export const PaginatorBox = styled.section`
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`;

export const PaginatorDayCard = styled.div`
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 2.5px;
  transition: all 0.4s ease;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.46) 0px 1px 4px;
  }
`;

export const PaginatorDayCardIcon = styled.div`
  padding: 2px 2px;
  border-radius: 2.5px;
  cursor: pointer;
  position: relative;
  svg {
    font-size: calc(2rem + 15px);
    color: #495057;
    transition: all 0.4s ease;
  }
  svg:hover {
    color: #000;
  }
`;

type DayCardProps = {
  active?: boolean;
};

export const PaginatorDayCardText = styled.div<DayCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  & > span:first-child {
    color: #fff;
    background-color: ${(props) => (props.active ? '#000000' : '#adb5bd')};
    font-weight: 500;
    transition: all 0.4s ease;
  }
  &:hover > span:first-child {
    background-color: #000;
  }
  span {
    padding: 2px 10px;
  }
`;

export const PaginatorDayCardBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  border-radius: 5px;
  color: transparent;
  transition: all 0.2s ease;
`;

interface ButtonProps {
  active?: boolean;
}

export const PaginatorButtonPrev = styled.div<ButtonProps>`
  font-size: 2rem !important;
  color: #80808280;
  cursor: pointer;
  transform: rotateY(180deg);
  color: ${(props) => (props.active ? '#000' : '#80808280')};
`;

export const PaginatorButtonNext = styled.div<ButtonProps>`
  font-size: 2rem !important;
  color: #80808280;
  cursor: pointer;
  color: ${(props) => (props.active ? '#000' : '#80808280')};
`;

export const PaginatorWeekBox = styled.div`
  color: #9497b8;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position: absolute;
  top: 100%;
`;
