'use client';

// IMPORTA FOLHA DE ESTILOS DO COMPONENTE
import styles from './button.module.css';

// DEFINE TIPAGEM DE DADOS DO COMPONENTE
interface IButton {
  options: {
    label?: string;
    style?: 'btn-warning' | 'btn-danger' | 'btn-success' | 'btn-info' | 'btn-dark' | 'btn-light' | 'btn-white' | 'btn-black' | 'btn-none' | 'btn-gray';
    width?: string;
    heigth?: string;
    icon?: any;
    iconPosition?: 'left' | 'right';
    size?: 'btn-sm' | 'btn-md' | 'btn-lg';
    rounded?: true | false;
    disabled?: true | false;
    tooltip?: {
      show: true | false;
      text: string;
      position: 'top' | 'right' | 'bottom' | 'left';
    };
    loading?: boolean;
  };
}

// EXPORTA COMPONENTE POR PADRÃO
export default function VmButton({ options, ...rest }: IButton & Record<any, unknown>) {
  // DEFINE VALOR PARA STYLE OPTIONS
  let styleOp: any = rest.style ?? {};
  styleOp.width = options.width ?? 'auto';

  return (
    <button
      className={
        styles.buttonMainBox +
        ' ' +
        styles[options.style ?? 'btn-light'] +
        ' ' +
        styles[options.size ?? 'btn-md'] +
        ' ' +
        styles[options.disabled ? 'btn-disabled' : ''] +
        ' ' +
        styles[options.rounded ? 'btn-rounded' : '']
      }
      style={styleOp}
      {...rest}
    >
      {options.tooltip && options.tooltip.show && (
        <span className={styles.tooltip + ' ' + styles['tooltip-' + options.tooltip.position]}>
          {options.tooltip?.text}
        </span>
      )}

      <span className={styles.labelBox}>
        {options.iconPosition === 'left' && <>{options.loading ? <div className={styles.loading} /> : options.icon}</>}
        {options.label}
        {options.iconPosition === 'right' && <>{options.loading ? <div className={styles.loading} /> : options.icon}</>}
      </span>
    </button>
  );
}
