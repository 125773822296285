import { Outlet } from 'react-router-dom';

import logoVendaMais from 'assets/images/logovendamais.png';
import { UserValidateStatus } from 'client/interfaces';
import { useAppSelector } from 'store';

import { loginStatusSelector } from '../reducer/auth';
import * as S from './styles';
import styles from './styles.module.scss';

const LoginLayout = () => {
  const status = useAppSelector(loginStatusSelector);
  // const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <div className="w-lg-500px w-340px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <S.Header>
            <img alt="Logo" src={logoVendaMais} />
            <h1 className="text-dark fs-4 fs-lg-1">
              {status === UserValidateStatus.PendingRegistration ? 'Novo Cadastro' : 'Seja bem-vindo'}
            </h1>
          </S.Header>
          <Outlet />
          <div className={styles.versionContainer}>
            <span className={styles.version}>v.{process.env.REACT_APP_VERSION}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
