import { FC, useEffect } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { isLoggedInSelector, setUserData } from 'modules/auth/reducer/auth';
import { useAppDispatch, useAppSelector } from 'store';
import { useUser } from 'client/hooks';

import { LogoutPage } from 'modules/auth/pages';
import App from 'App';
import { PrivateRoutes } from './PrivateRoutes';
import { AuthRoutes } from 'modules/auth/routes';

import { CheckoutPage } from 'modules/checkout/pages';

const AppRoutes: FC = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { data: user } = useUser(isLoggedIn);

  useEffect(() => {
    if (user != null) {
      dispatch(setUserData(user));
    }
  }, [user]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="logout" element={<LogoutPage />} />
          {isLoggedIn ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthRoutes />} />
              <Route path="*" element={<Navigate to="/auth/login" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
