import { Parametro, ReplicateParametro } from 'client/interfaces';
import api from 'services/api';

const _controller = 'parametros';

export const getParametros = async (codEmpresa: number): Promise<Parametro[]> => {
  const { data } = await api.get(`Parametros?CodEmpresa=${codEmpresa}`);
  return data;
};

export const updateParams = async (parametro: Parametro): Promise<void> => {
  const { data } = await api.put(`${_controller}/${parametro.codEmpresa}/${parametro.codUsur}`, parametro);
  return data;
};

export const replicateParams = async (parametro: ReplicateParametro): Promise<void> => {
  const { data } = await api.put(`${_controller}/replicate`, parametro);
  return data;
};