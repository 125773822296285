import { ReportProps, ReturnReportPlayProps, ReturnReportProps, ReturnReportsProps } from 'client/interfaces';
import api from 'services/api';

export const getRelatoriosList = async (): Promise<ReturnReportsProps> => {
  const { data } = await api.get(`Relatorio`);
  return data;
};

export const getRelatorio = async (codrel: string): Promise<ReturnReportProps> => {
  const { data } = await api.get(`Relatorio/${codrel}`);
  return data;
};

export const postRelatorio = async (relatorio: ReportProps): Promise<ReturnReportPlayProps> => {
  const { data } = await api.post(`Relatorio/IncluirRelatorio`, relatorio);
  return data;
};

export const putRelatorio = async (relatorio: ReportProps): Promise<ReturnReportPlayProps> => {
  const { data } = await api.post(`Relatorio/EditarRelatorio`, relatorio);
  return data;
};

export const postPlayRelatorio = async (codrel: string, parametros: any[]): Promise<ReturnReportPlayProps> => {
  const { data } = await api.post(`Relatorio/ExecutarRelatorio/${codrel}`, { parametros: parametros });
  return data;
};
