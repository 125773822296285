import { useTitlePageStore } from 'storesZustand/titlePageStore';
import * as S from './styles';
import { useEffect } from 'react';

export default function AgendaFeriados() {
  const { setTitlePage } = useTitlePageStore();
  useEffect(() => {
    setTitlePage('Agenda de Feriados');
  }, []);

  return <S.AgendaMainBox></S.AgendaMainBox>;
}
