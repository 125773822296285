import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useState } from 'react';

export const useImportClients = () => {

  const [selectedClients, setSelectedClients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleDownloadSpreadsheet = () => {
    const fileName: string = `ARQUIVO_MODELO_IMPORTAR_CLIENTES_VMAIS`;
    let data: any = [{ CODCLI: '', CNPJ: '' }];

    const ws = XLSX.utils.json_to_sheet(data, { header: Object.keys(data[0]) });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  };

  const convertKeysToLowerCase = (arr: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> => {
    return arr.map((obj) => {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        newObj[key.toLowerCase()] = obj[key];
      }
      return newObj;
    });
  };

  return {
    handleDownloadSpreadsheet,
    // toggleSelectAll,
    setSelectAll,
    selectedClients,
    setSelectedClients,
    convertKeysToLowerCase
  };
};
