import { ReactNode } from 'react';

export enum Grupos {
  PADRAO = 'Padrão',
  ACESSOS = 'Acessos',
  PROCESSOS = 'Processos',
  CADASTRO = 'Roteiro',
  DEFAULT = 'DEFAULT',
  ADMIN = 'Admin',
  PESQUISA = 'Pesquisa',
  PARAMETROS = 'Parâmetros',
  CADASTROS = 'Cadastros',
  PEDIDO = 'Digitação Pedido',
  RELATORIOS = 'Relatórios',
}

export interface Module {
  cod: number;
  grupo?: Grupos;
  nome: string;
  codigo?: string;
  icon: string;
  element?: ReactNode;
  to: string;
}

export const modules: Module[] = [
  {
    cod: 1,
    grupo: Grupos.PADRAO,
    nome: 'VMaisSQL',
    to: '/sql',
    icon: 'pi pi-fw pi-database',
    // element: <SqlPage />
  },
  {
    cod: 2,
    grupo: Grupos.PADRAO,
    nome: 'Foto Produto',
    to: '/foto-produto',
    icon: 'pi pi-fw pi-camera',
    // element: <PhotoMasterPage />,
  },
  {
    cod: 6,
    grupo: Grupos.PESQUISA,
    nome: 'Parametros Vendedor',
    to: '/parametros',
    icon: 'pi pi-fw pi-id-card',
    // element: <ParametrosPage />,
  },
  {
    cod: 7,
    grupo: Grupos.PADRAO,
    nome: 'FV Formulário',
    to: '/formulario',
    icon: 'pi pi-fw pi-book',
    // element: <FormularioPage />,
  },
  {
    cod: 8,
    grupo: Grupos.PADRAO,
    nome: 'Respostas Formulário',
    to: '/respostas',
    icon: 'pi pi-fw pi-book',
    // element: <FormularioRespostaPage />,
  },
  {
    cod: 4,
    grupo: Grupos.ACESSOS,
    icon: 'pi pi-fw pi-bars',
    to: '/itens-menu',
    nome: 'Cadastro Menu',
    // element: <ItensMenu />,
  },
  {
    cod: 9,
    grupo: Grupos.CADASTRO,
    icon: 'pi pi-fw pi-map',
    to: '/mapa',
    nome: 'Roteiro',
    // element: <CadastroMapa />,
  },
  {
    cod: 10,
    grupo: Grupos.CADASTRO,
    icon: 'pi pi-fw pi-map',
    to: '/controle-visitas',
    nome: 'Painel GPS',
    // element: <ControleVisitas />,
  },
  {
    cod: 11,
    grupo: Grupos.CADASTRO,
    icon: 'pi pi-fw pi-map',
    to: '/analise-visitas',
    nome: 'Análise de Visitas',
    // element: <AnaliseVisitas />,
  },
  {
    cod: 5,
    grupo: Grupos.ACESSOS,
    icon: 'pi pi-fw pi-lock',
    to: '/acesso-usuarios',
    nome: 'Acesso de Usuários',
    // element: <UsuarioItensMenu />,
  },
  {
    cod: 12,
    grupo: Grupos.CADASTROS,
    icon: 'pi pi-fw pi-users',
    to: '/grupo-clientes',
    nome: 'Grupo de Clientes',
    // element: <ListGroupClients />,
  },
  {
    cod: 13,
    grupo: Grupos.PEDIDO,
    icon: 'pi pi-fw pi-users',
    to: '/pedido',
    nome: 'Digitação Pedido',
    // element: <ListGroupClients />,
  },
  {
    cod: 14,
    grupo: Grupos.ADMIN,
    icon: 'pi pi-fw pi-users',
    to: '/acesso-usuarios/redefinir-acesso',
    nome: 'Redefinir Acessos',
    // element: <ResetUserAccess />,
  },
  {
    cod: 15,
    grupo: Grupos.CADASTRO,
    icon: 'pi pi-fw pi-lock',
    to: '/mapa/acompanhamento-diario',
    nome: 'Acompanhamento',
    // element: <AcompanhamentoDiario />,
  },
  {
    cod: 32,
    grupo: Grupos.RELATORIOS,
    icon: 'pi pi-fw pi-lock',
    to: '/relatorios',
    nome: 'Relatorios',
    // element: <Relatorios7000List />,
  },
  {
    cod: 34,
    grupo: Grupos['RELATORIOS'],
    icon: 'pi pi-fw pi-lock',
    to: '/relatorios/7010',
    nome: 'Folha de Ponto',
    // element: <Relatorios7000List />,
  },
  {
    cod: 30,
    grupo: Grupos.ADMIN,
    icon: 'pi pi-fw pi-users',
    to: '/empresas',
    nome: 'Cadastro Empresas',
    // element: <Companies />,
  },
];
