import { Control, Controller } from 'react-hook-form';
import * as S from './styles';
import { FormState } from 'hooks/useSchedule/useModalSchedule';
import { InputText } from 'primereact/inputtext';
import { IPonto } from 'client/interfaces/ponto.interface';

interface IListClients {
  item: any;
  index: number;
  isWebScreen: boolean;
  selectedCard: any;
  handleCardSelect: any;
  control: Control<FormState, any>;
  values: FormState;
}

export const ListClients = ({
  values,
  control,
  item,
  index,
  isWebScreen,
  selectedCard,
  handleCardSelect,
}: IListClients) => {
  return (
    <S.Box key={index}>
      <input type="radio" name="card" checked={selectedCard === item} onChange={() => handleCardSelect(item)} />
      <div style={{ width: isWebScreen && '300px' }}>
        <h1>Cliente</h1>
        <span>{item.razaosocial ? item.razaosocial : '-'}</span>
      </div>
      <S.Separator />
      <div style={{ width: isWebScreen && '50px' }}>
        <h1>CodCli</h1>
        <span>{item.codCli ? item.codCli : '-'}</span>
      </div>
      <S.Separator />
      <div style={{ width: isWebScreen && '100px' }}>
        <h1>Cód. empresa</h1>
        <span>{item.codEmpresa ? item.codEmpresa : '-'}</span>
      </div>

      <S.Separator />
      <div style={{ width: isWebScreen && '130px' }}>
        <h1>Cód. vendedor</h1>
        <span>{item.codVendedor ? item.codVendedor : '-'}</span>
      </div>

      <S.Separator />
      {selectedCard === item && (
        <Controller
          name="sequencia"
          rules={{
            required: true,
            validate: (value: any) => parseInt(value, 10) > 0 || 'A sequência não pode ser negativa.',
          }}
          control={control}
          render={({ field }) => (
            <div style={{ width: isWebScreen && '130px' }}>
              <h1>Sequêcia</h1>
              <InputText
                style={{
                  borderColor: values.sequencia === 0 ? '#FFDD35' : '',
                }}
                required
                type="number"
                {...field}
                placeholder="000000"
              />
            </div>
          )}
        />
      )}
    </S.Box>
  );
};
