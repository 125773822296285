import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';

import * as S from './styles';

export default function RouterCliClist({ cliList, searchText }: any) {
  const [list, setList] = useState<any>([]);

  function validateClientes(clientes) {
    let results = [];
    clientes.forEach((cliente) => {
      Object.entries(cliente).forEach(([key, value]) => {
        if (value && value.toString().toUpperCase().includes(searchText.toUpperCase())) {
          results.push(cliente);
          return;
        }
      });
    });
    return results;
  }

  useEffect(() => {
    let clientes: any = [];
    cliList?.map((cli: any) => {
      cli.clientesDaRota?.map((cliente: any) => {
        clientes.push(cliente);
      });
    });
    setList(validateClientes(clientes));
  }, [cliList, searchText]);

  const cliTemplate = (rowData: any) => {
    return (
      <div>
        {rowData.nomeCli} [{rowData.codCli}]
      </div>
    );
  };

  const dataTemplate = (rowData: any) => {
    return <div>{new Date(rowData.dtProxVisita).toLocaleDateString('pt-BR')}</div>;
  };

  return (
    <>
      <S.BoxTable>
        <DataTable
          value={list}
          tableStyle={{ width: '100%', marginTop: '90px' }}
          paginator
          rows={10}
          style={{ fontSize: '11px' }}
        >
          <Column field="dtProxVisita" header="DT. PRÓX VISITA" sortable body={dataTemplate} />
          <Column field="sequencia" header="SEQUENCIA" sortable />
          <Column field="diaSemana" header="DIA SEMANA" sortable />
          <Column field="codCli" header="CLIENTE" sortable body={cliTemplate} />
          <Column field="lat" header="LATITUDE" sortable />
          <Column field="lng" header="LONGITUDE" sortable />
          <Column field="descPrioridade" header="DESC. PRIORIDADE" sortable />
        </DataTable>
      </S.BoxTable>
    </>
  );
}
