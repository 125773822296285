import styled from 'styled-components';

export const FormRespBox = styled.section`
`;

export const FormRespImageBox = styled.section`
  img {
    max-height: 50px;
    width: auto;
    max-width: 50px;
  }
`;

export const FormRespHeader = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 15px 5px;
`;

export const FormRespCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  color: #606060;
  height: clamp(110px, 115px, 120px);
  width: clamp(300px, 310px, 100%);
  @media (max-width: 1024px) {
    width: 100% !important;
    width: clamp(50px, 100%, 100%);
  }
`;

export const FormRespCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

type FormRespCardRowTitleProps = {
  color?: string;
};

export const FormRespCardRowTitle = styled.div<FormRespCardRowTitleProps>`
  display: flex;
  gap: 5px;
  color: ${(props) => props.color ?? '#606060'};
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: ${(props) => props.color ?? '#606060'};
    padding: 5px;
    border-radius: 4px;
    font-size: 0.8rem;
  }
`;

export const FormRespCardRowContent = styled.div`
  display: flex;
`;

export const FormRespCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormRespGridSearchBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  gap: 10px;

  span {
    width: clamp(20rem, 25rem, 100%);
    input {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    span {
      width: 100%;
    }
  }
`;
