/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Dialog } from 'primereact/dialog';
import { useContext } from 'react';
import { BsFillPencilFill, BsFillPeopleFill, BsFillTrash3Fill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import ValidaCliPoly from 'modules/mapa/pages/regioes/components/validaCliPoly/validaCliPoly';
import { MapContext } from 'providers/map';

import * as S from './styles';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { removePoly } from 'client/api/areaMapa';

interface IPolyData {
  clickPoly: any;
  setClickPoly: any;
  setToEditPoly?: any;
}

const PolyData = ({ clickPoly, setClickPoly, setToEditPoly }: IPolyData) => {
  const { setMapGridShow, clientes, vendedores, supervisores, setCurrentSetor, reloadAllData } =
    useContext(MapContext);

  async function handleRemoveArea() {
    let cliInPoly = await ValidaCliPoly(clientes, clickPoly.latlngs);
    setClickPoly(null);

    Swal.fire({
      title: 'Tem certeza?',
      html: `Deseja remover esta área?`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não, voltar',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    })
      .then((result: any) => {
        if (result.isConfirmed) {
          const setor = clickPoly;
          setor.codArea = setor.codSetor;
          setor.clientes = cliInPoly;
          setor.latlngs = JSON.stringify(setor.latlngs);
          removePoly(setor)
            .then((res) => {
              toast.success('Área removida com sucesso!');
              reloadAllData();
            })
            .catch((err) => {
              toast.error('Falha ao remover área!');
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const returnRcaName = (cod: any) => {
    const rca = [];
    for (let i = 0; i < vendedores.length; i++) {
      if (vendedores[i].codVendedor === cod) {
        rca.push(vendedores[i].nome);
      }
    }
    return rca[0] || '#';
  };

  const returnGaName = (cod: any) => {
    const ga = [];
    for (let i = 0; i < supervisores.length; i++) {
      if (supervisores[i].codSupervisor === cod) {
        ga.push(supervisores[i].nomesup);
      }
    }
    return ga[0] || '#';
  };

  const returnCliCount = (cod: any) => {
    const clis = clientes.filter((cli: any) => cli.codSetor === cod);

    return clis.length;
  };

  return (
    <Dialog
      visible={clickPoly != null}
      style={{ width: '50rem' }}
      breakpoints={{ '960': '75vw', '641px': '90vw' }}
      header={clickPoly != null && clickPoly.codSetor + ' - "' + clickPoly.setor + '"'}
      modal
      className="p-fluid"
      onHide={() => setClickPoly(null)}
    >
      <p>
        Supervisor:{' '}
        {clickPoly != null && clickPoly.codGa + ' - ' + returnGaName(clickPoly != null ? clickPoly.codGa : 0)}
      </p>
      <p>
        Vendedor:{' '}
        {clickPoly != null && clickPoly.codRca + ' - ' + returnRcaName(clickPoly != null ? clickPoly.codRca : 0)}{' '}
      </p>
      <p>Clientes: {returnCliCount(clickPoly != null ? clickPoly.codSetor : 0)}</p>

      <S.BoxButtons>
        <S.Button
          style={{
            borderColor: 'var(--green-300)',
          }}
          onClick={() => {
            setMapGridShow(false);
            setCurrentSetor(clickPoly);
            setClickPoly(null);
          }}
        >
          Visualizar clientes
          <BsFillPeopleFill color="var(--green-300)" size="20px" />
        </S.Button>
        <S.Button
          style={{
            borderColor: 'var(--orange-300)',
          }}
          onClick={() => {
            setToEditPoly(clickPoly);
            setClickPoly(null);
          }}
        >
          Editar área <BsFillPencilFill color="var(--orange-300)" size="20px" />
        </S.Button>
        <S.Button
          style={{
            borderColor: 'var(--danger-500)',
          }}
          onClick={() => {
            handleRemoveArea();
          }}
        >
          Remover área <BsFillTrash3Fill color={'var(--danger-500)'} size="20px" />
        </S.Button>
      </S.BoxButtons>
    </Dialog>
  );
};

export default PolyData;
