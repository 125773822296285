import styled from 'styled-components';

export const ContainerTable = styled.div`
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 40px;
  }
`;

export const Header = styled.div`
  width: 50%;
  margin-bottom: 15px;
  display: flex;
  padding-left: 50px;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 10px;
    width: 100%;
    display: flex;
  }
`;

export const BoxSelect = styled.div`
  padding-left: 10px;
`;

interface IBoxTab {
  isActive: boolean;
}

export const BoxTab = styled.div`
  width: 300px;
  height: 30px;
  background-color: ${(props: IBoxTab) => (props.isActive ? 'var(--primary-green)' : '#e4e7f1')};

  /* border-radius: 10px; */
  display: flex;
  align-items: center;

  cursor: pointer;

  p {
    margin-left: 10px;
    color: ${(props: IBoxTab) => (props.isActive ? 'white' : '#646670')};
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  margin-left: 50px;
  margin-right: 50px;

  border-bottom: 1px solid var(--dark-200);
`;
