import { UseSupervisorByRole } from 'hooks/useSupervisor/useSupervisorByRole';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

interface IFilterBySupervisorCompanyParams {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  codCompany: number | string;
  codUser: number | string;
}

export const FilterBySupervisorCompany = ({
  control,
  setValue,
  codCompany,
  codUser,
}: IFilterBySupervisorCompanyParams) => {
  const { optionsSupervisorByRole, refetchSupervisor } = UseSupervisorByRole({
    codCompany: codCompany,
    codUser: codUser,
  });

  useEffect(() => {
    refetchSupervisor();
  }, [codUser, codCompany]);

  return (
    <>
      <Controller
        name="codSupervisor"
        rules={{
          required: true,
        }}
        control={control}
        render={({ field }) => (
          <Dropdown
            placeholder="Selecione um Supervisor"
            value={field.value}
            options={optionsSupervisorByRole}
            onChange={(e) => setValue('codSupervisor', e.value)}
            emptyMessage="Nenhuma Supervisor Encontrado :/"
            filter
            showClear
          />
        )}
      />
    </>
  );
};
