import styled, { css } from 'styled-components';

export const ContainerPage = styled.div`
  padding: 40px;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form``;

interface InputProps {
  error?: boolean;
}

export const DescriptionInput = styled.small<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const TitleInput = styled.label<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;
