import styled from 'styled-components';

export const SuccesBox = styled.section`
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  padding-top: 50px;
  overflow-x: hidden;
  background-image: url('/assets/images/modern-texture-bg.png');
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1024px) {
    padding-bottom: 20px;
    gap: 20px;
    justify-content: center;
  }
`;

export const SuccessContent = styled.section`
  width: clamp(400px, 50dvw, 100dvw);
  height: clamp(400px, 50dvh, 100dvh);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;

  @media (max-width: 1024px) {
  padding: 10px;
  }
`;

export const SuccessTitle = styled.section`
  font-size: 1.75rem;
`;

export const SuccessSubTitle = styled.section`
  font-size: 1.5rem;
`;

export const SuccessIcon = styled.section`
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  .reactivus-path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
  .reactivus-path.reactivus-circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }

  .reactivus-path.reactivus-check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
`;
