import styled from 'styled-components';

export const AlterDataModalBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 0px;
  gap: 10px;
`;

export const AlterDataModalTitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-top: 15px;
`;

export const AlterDataModalFormBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
`;

export const DateInputBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  border: 1px solid #ced4da;
  border-radius: 2.5px;
  padding: 0px 5px;
  position: relative;
  color: #606060;
  height: 3rem;
  width: clamp(120px, 120px, 140px);

  input {
    color: #606060;
    border: none;
    outline: none;
    background-color: #fff !important;
    width: 100%;
  }

  label {
    position: absolute;
    bottom: 100%;
    font-size: 1rem;
    color: #000;
  }

  @media (max-width: 992px) {
    input {
      max-width: 100px;
    }
  }
`;
