import { useEffect, useState } from 'react';

import style from './prodList.module.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';

import { MdOutlineCheck } from 'react-icons/md';

import { BASE_URL } from 'services/api';

import * as S from './styles';

export default function ProdList({ cliente, prodList }: any) {
  // useEffect(() => {
  //   returnAllProds(true).then((res) => {
  //     getLocalProdCartData().then((cartProdsList) => {
  //       for (let i = 0; i < res.length; i++) {
  //         res[i].PVENDA = +res[i].PVENDA.toFixed(2);
  //         res[i].DESC = 0;

  //         res[i].EMB = '1X12';
  //         res[i].UN = 'UN';

  //         res[i].QTD =
  //           cartProdsList.find((el) => el.CODPROD == res[i].CODPROD) != undefined
  //             ? cartProdsList.find((el) => el.CODPROD == res[i].CODPROD).QTD
  //             : 0;

  //         res[i].SUBTOTAL = +res[i].PVENDA.toFixed(2) * res[i].QTD;

  //         res[i].PUNIT = 'R$' + +(res[i].PVENDA / res[i].QTUNITCX).toFixed(2);

  //         res[i].ESTOQUE = res[i].CODPROD.toString().includes('6') ? 0 : 1;
  //         res[i].ESTOQUE_CRITICO =
  //           res[i].CODPROD.toString().includes('2') && res[i].CODPROD.toString().includes('4') ? 'S' : 'N';
  //       }

  //       setProdutos(res);
  //       buildDatatableProd(res);
  //     });
  //   });
  // }, []);

  // const onCellEditComplete = (e) => {
  //   let { rowData, newValue, field, originalEvent: event } = e;

  //   rowData.SUBTOTAL = +(+newValue * +rowData.PVENDA).toFixed(3);
  //   switch (field) {
  //     case 'quantity':
  //     case 'price':
  //       if (isPositiveInteger(newValue)) rowData[field] = newValue;
  //       else event.preventDefault();
  //       break;

  //     default:
  //       if (newValue.toString().replaceAll(' ', '').length > 0) rowData[field] = newValue;
  //       else event.preventDefault();
  //       break;
  //   }
  // };

  const subTotFieldTemplate = (rowData) => {
    let styling = '';

    if (rowData.SUBTOTAL > 0 && rowData.QTD > 0) {
      styling = style.priceWithQtd;
    }

    return (
      <div className={styling}>
        <span>{rowData.SUBTOTAL}</span>
      </div>
    );
  };

  const nomeFieldTemplate = (rowData) => {
    return (
      <div>
        <span
          style={{
            color: rowData.ESTOQUE_CRITICO == 'S' ? 'purple' : rowData.ESTOQUE > 0 ? '#00000085' : 'red',
            fontWeight: '450',
          }}
        >
          {rowData.NOME}
        </span>{' '}
        <br />
        <div className={style.prodTags}>
          <span className={style.descTag}>{rowData.DESCONTO}%</span>
          <span className={style.multTag}>Múltiplo: {rowData.QTUNIT}</span>
          <span className={style.comboTag}>Combo</span>
        </div>
      </div>
    );
  };

  const imageFieldTemplate = (rowData) => {
    return (
      <S.prodImage>
        <Image
          src={`${BASE_URL}/foto_produtos/${rowData.FOTO_CAPA}`}
          zoomSrc={`${BASE_URL}/foto_produtos/${rowData.FOTO_CAPA}`}
          preview
          onError={(e: any) => (e.target.src = `${BASE_URL}/foto_produtos/sem_foto.png`)}
        ></Image>
      </S.prodImage>
    );
  };

  const embalagemTemplate = (rowData) => {
    return (
      <div>
        <span>{rowData.EMB}</span> <br />
        <span>{rowData.UN}</span>
      </div>
    );
  };

  const codprodFieldTemplate = (rowData) => {
    return (
      <span className={style.codprodCheck}>
        {rowData.CODPROD}
        <MdOutlineCheck style={{ color: 'green', fontSize: '1rem', fontWeight: 'bold' }} />
      </span>
    );
  };

  const qtdFieldTemplate = (rowData) => {
    let styling = '';

    if (rowData.DESC > 0) {
      styling = style.hasQtd;
    } else {
      styling = style.hasNoQtd;
    }

    return (
      <div className={styling}>
        <input
          className={'qtdInputProd'}
          type={'number'}
          defaultValue={rowData.QTD}
          onChange={(e) => {
            handleProdUpdate(rowData, +e.target.value, rowData);
          }}
        />
      </div>
    );
  };

  const handleProdUpdate = (prod, qtd, rowData) => {
    // updateCartProd(prod, qtd);

    rowData.QTD = qtd;
    rowData.SUBTOTAL = +(+qtd * +rowData.PVENDA).toFixed(3);
  };

  const descFieldTemplate = (rowData) => {
    return (
      <div className={style.descInput}>
        <input
          className={'qtdInputProd'}
          type={'number'}
          defaultValue={rowData.DESC}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              handleProdDescUpdate(e, rowData);
            }
          }}
        />
      </div>
    );
  };

  const handleProdDescUpdate = (e, rowData) => {
    let desc = e.target.value;

    if (+desc < 0) {
      rowData.SUBTOTAL = (rowData.SUBTOTAL * (1 - Math.abs(+desc) / 100)).toFixed(2);
    } else {
      rowData.SUBTOTAL = (rowData.SUBTOTAL * (1 + Math.abs(+desc) / 100)).toFixed(2);
    }
  };

  const priceFieldTemplate = (rowData) => {
    return (
      <div className={style.descInput}>
        <input
          className={'qtdInputProd'}
          type={'number'}
          defaultValue={rowData.PVENDA}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              handleProdPriceUpdate(e, rowData);
            }
          }}
        />
      </div>
    );
  };

  const handleProdPriceUpdate = (e, rowData) => {
    let price = e.target.value;
    rowData.PVENDA = price;
    rowData.SUBTOTAL = +(+rowData.QTD * +price).toFixed(3);
    return;
  };

  // const [produtos, setProdutos] = React.useState([]);

  const [prodTable, setProdTable] = useState([
    <Column key={'PROD'} field="PROD" header=" " body={imageFieldTemplate} style={{ width: '5vw' }}></Column>,

    <Column
      key={'CODPROD'}
      field="CODPROD"
      header="CODPROD"
      sortable
      style={{ width: '5vw' }}
      alignHeader="center"
      body={codprodFieldTemplate}
    ></Column>,

    <Column
      key={'NOME'}
      field="NOME"
      header="PRODUTO"
      sortable
      body={nomeFieldTemplate}
      style={{ width: '20vw' }}
    ></Column>,

    <Column key={'ESTOQUE'} field="ESTOQUE" header="EST" style={{ width: '3vw' }}></Column>,

    <Column
      key={'QTD'}
      field="QTD"
      header="QTD"
      body={qtdFieldTemplate}
      style={{ width: '5vw' }}
      alignHeader="left"
    ></Column>,

    <Column key={'DESC'} field="DESC" header="%DESC" style={{ width: '5vw' }} body={descFieldTemplate}></Column>,

    <Column
      key={'PVENDA'}
      field="PVENDA"
      header="PREÇO (R$)"
      style={{ textAlign: 'right', width: '3vw' }}
      body={priceFieldTemplate}
      alignHeader="right"
    ></Column>,

    <Column
      key={'PVENDAUNIT'}
      field="PUNIT"
      header="PR UNIT (R$)"
      style={{ textAlign: 'right', paddingRight: '10px', width: '3vw' }}
      alignHeader="right"
    ></Column>,

    <Column
      key={'SUBTOTAL'}
      field="SUBTOTAL"
      header="SUBTOTAL (R$)"
      style={{ textAlign: 'right', width: '3vw' }}
      body={subTotFieldTemplate}
      alignHeader="right"
    ></Column>,

    <Column key={'CODAUXILIAR'} field="CODAUXILIAR" header="COD BARRAS" style={{ width: '5vw' }}></Column>,

    <Column key={'EMB'} field="EMB" header="EMBALAGEM" style={{ width: '3vw' }} body={embalagemTemplate}></Column>,
  ]);

  // async function handleGridProdSearch() {
  //   let produtosList = [];

  //   Object.keys(produtos).forEach((key1) => {
  //     let itera = false;
  //     Object.keys(produtos[key1]).forEach((key2) => {
  //       if (produtos[key1][key2] != null && produtos[key1][key2].toString().includes(searchProd.toUpperCase())) {
  //         itera = true;
  //       }
  //     });
  //     if (itera) {
  //       produtosList.push(produtos[key1]);
  //     }
  //   });

  //   if (produtosList.length == 0) {
  //     for (let i = 0; i < produtos.length; i++) {
  //       await useSoundex(produtos[i].NOME, searchProd.toUpperCase()).then((res) => {
  //         if (res) {
  //           produtosList.push(produtos[i]);
  //         }
  //       });
  //     }
  //   }

  //   setProdList(produtosList);
  // }

  return (
    <S.prodListMainBox>
      <S.ProdListTableBox>
        <DataTable
          value={prodList}
          // tableStyle={{
          //   fontSize: '.7rem !important',
          //   minWidth: '90vw',
          //   width: '100% !important',
          //   minHeight: '10vh !important',
          //   maxHeight: '60vh !important',
          //   backgroundColor: '#FFF',
          // }}
          editMode="cell"
          size={'small'}
          scrollable
          scrollDirection="both"
          scrollHeight={'70vh'}
          emptyMessage="Ooops, não foi possível carregar os produtos :/"
          paginator
          rows={100}
          resizableColumns
        >
          <Column key={'PROD'} field="PROD" header=" " body={imageFieldTemplate} style={{ width: '5vw' }}></Column>
          <Column
            key={'CODPROD'}
            field="CODPROD"
            header="CODPROD"
            sortable
            style={{ width: '5vw' }}
            alignHeader="center"
            body={codprodFieldTemplate}
          ></Column>
          <Column
            key={'NOME'}
            field="NOME"
            header="PRODUTO"
            sortable
            body={nomeFieldTemplate}
            style={{ width: '25vw' }}
          ></Column>
          <Column key={'ESTOQUE'} field="ESTOQUE" header="EST" style={{ width: '3vw' }}></Column>
          <Column
            key={'QTD'}
            field="QTD"
            header="QTD"
            body={qtdFieldTemplate}
            style={{ width: '5vw' }}
            alignHeader="left"
          ></Column>
          <Column key={'DESC'} field="DESC" header="%DESC" style={{ width: '5vw' }} body={descFieldTemplate}></Column>
          <Column
            key={'PVENDA'}
            field="PVENDA"
            header="PREÇO (R$)"
            style={{ textAlign: 'right', width: '5vw' }}
            body={priceFieldTemplate}
            alignHeader="right"
          ></Column>
          <Column
            key={'PVENDAUNIT'}
            field="PUNIT"
            header="PR UNIT (R$)"
            style={{ textAlign: 'right', paddingRight: '10px', width: '4vw' }}
            alignHeader="right"
          ></Column>
          <Column
            key={'SUBTOTAL'}
            field="SUBTOTAL"
            header="SUBTOTAL (R$)"
            style={{ textAlign: 'right', width: '3vw' }}
            body={subTotFieldTemplate}
            alignHeader="right"
          ></Column>
          <Column key={'CODAUXILIAR'} field="CODAUXILIAR" header="COD BARRAS" style={{ width: '5vw' }}></Column>
          <Column key={'EMB'} field="EMB" header="EMBALAGEM" style={{ width: '3vw' }} body={embalagemTemplate}></Column>
        </DataTable>
      </S.ProdListTableBox>
    </S.prodListMainBox>
  );
}
