import { ComandoSql, NewTabSql } from 'client/interfaces';
import api from 'services/api';

const _controller = 'sql';

export const getSqlCommands = async (): Promise<ComandoSql[]> => {
  const { data } = await api.get(`${_controller}`);
  return data;
};

export const saveNewSqlCommand = async (sqlCommand: NewTabSql): Promise<NewTabSql> => {
  const { data } = await api.post(`${_controller}`, sqlCommand);
  return data;
};

export const updateSqlCommand = async (sqlCommand: NewTabSql): Promise<void> => {
  await api.put(`${_controller}/${sqlCommand.codSql}`, sqlCommand);
};
