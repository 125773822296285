import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default async function handleExportJsonToSheet(jsonData: any, fileName: string) {
  try {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
    return true;
  } catch (err) {
    console.log(err);
    return err;
  }
}
