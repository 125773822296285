import styled from 'styled-components';

export const ControlBox = styled.div`
    display: flex;
`;


export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  font-size: .8rem;
  margin-left: 20px;

  img{
    max-height: 2.5rem;
  }
`;