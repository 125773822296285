import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useAppDispatch } from 'store';
import { toast } from 'react-toastify';
import { setEmpresaCNPJ } from '../reducer/auth';
import { validateCompany } from 'client/api';

import styles from './styles.module.scss';

interface FormState {
  company: string;
}

function CompanyForm() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, dirtyFields },
  } = useForm<FormState>();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FormState> = async ({ company }) => {
    try {
      setIsLoading(true);
      const isValid = await validateCompany(company);
      if (isValid) {
        dispatch(setEmpresaCNPJ(company));
      } else {
        setError('company', {
          message: 'código ou cnpj inválido',
        });
      }
    } catch (e) {
      toast.error('Erro ao validar empresa');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form w-100" noValidate>
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Código Empresa ou CNPJ</label>
        <input
          {...register('company', { required: true })}
          placeholder="Código da empresa ou CNPJ"
          className={clsx('form-control form-control-lg form-control-solid', {
            'is-invalid': (dirtyFields.company ?? false) && errors.company,
          })}
          autoFocus
          type="company"
          name="company"
        />
        {errors.company != null && (
          <div className="fv-plugins-message-container invalid-feedback">
            <span role="alert">{errors.company.message}</span>
          </div>
        )}
      </div>

      <div className="text-center">
        <button type="submit" className={`${styles.button} btn btn-lg w-100 mb-5`} disabled={isLoading}>
          {!isLoading && <span className="indicator-label">Continuar</span>}
          {isLoading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Validando...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}

export default CompanyForm;
