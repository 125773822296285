import * as S from './styles';

import CliSearch from '../cliSearch/cliSearch';
import VmButton from 'components/VmButton/VmButton';

import { useNavigate } from 'react-router-dom';

import { Buffer } from 'buffer';

type PedHeaderProps = {
  cliente: any;
};

export default function PedidoHeader({ cliente }: PedHeaderProps) {
  
  const navigate = useNavigate();

  const handleRoteToNewPedScreen = () => {
    const vmDataClientPedidoEncoded = Buffer.from(JSON.stringify(cliente), 'utf-8').toString('base64');
    window.sessionStorage.setItem('vmDCP', vmDataClientPedidoEncoded)
    navigate(`/pedido/novo`)
  }

  return (
    <S.PedidoHeaderMainBox>
      <CliSearch />
      <VmButton 
      options={{
        label: '+ Novo Pedido',
        style: 'btn-success',
        disabled: !cliente
      }}
      onClick={() => handleRoteToNewPedScreen()}
      />
    </S.PedidoHeaderMainBox>
  );
}
