import styled from "styled-components";

export const Body = styled.div`
  width: 100%;
  padding-left: 50px; 
  padding-right: 50px;
  margin-top: 15px;
  background-color: aliceblue;

  max-height: 20rem;

  h3 {
    margin-top: 10px;
  }

  p {
    font-weight: bold;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10px;
`;

export const Container = styled.div`
  display: flex;
  border: 1px solid #cccccc70;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 7.5px;
  padding: 20px;
`;

export const Separator = styled.div`
  border-bottom: 1px solid #cccccc70;
  width: 100%;
  margin: 10px;
`;