import axios, { AxiosRequestConfig } from 'axios';
import {
  GetMultipleRegrasReturnProps,
  GetSingleRegraReturnProps,
  PostItensReturnProps,
  PostRegraReturnProps,
  PostRestricaoReturnProps,
  RegraItemProps,
  RegraProps,
  RegraRestricaoProps,
} from 'client/interfaces/regraComercial.interface';

import api, { BASE_API_URL, ORIGIN } from 'services/api';
import storage from 'services/storage';

const _controller = 'RegraComercial';

export const getAllRegrasComerciais = async (codEmpresa: number): Promise<GetMultipleRegrasReturnProps> => {
  const { data } = await api.get(`${_controller}/GetAllRegraComercialWeb/${codEmpresa}`);
  return data;
};

export const getAllRegrasComerciaisByDatePeriod = async (
  codEmpresa: number,
  dtIni: string,
  dtFim: string,
): Promise<GetMultipleRegrasReturnProps> => {
  const { data } = await api.get(`${_controller}/GetRegraComercial/${codEmpresa}/${dtIni}/${dtFim}`);
  return data;
};

export const getSingleRegraComercial = async (
  codEmpresa: number,
  codRegra: number,
): Promise<GetSingleRegraReturnProps> => {
  const { data } = await api.get(`${_controller}/GetRegraComercial/${codEmpresa}/${codRegra}`);
  return data;
};

export const postNovaRegra = async (regra: RegraProps): Promise<PostRegraReturnProps> => {
  const { data } = await api.post(`${_controller}/PostRegra`, regra);
  return data;
};

export const postRegraImages = async (data: any): Promise<any> => {
  var formData = new FormData();

  const filesArray = [data.img1, data.img2, data.img3];

  filesArray.forEach((file, index) => {
    formData.append(`files`, file);
  });
  formData.append(`file1`, data.img1 ?? null);
  formData.append(`file2`, data.img2 ?? null);
  formData.append(`file3`, data.img3 ?? null);

  const token = storage.getItem<string>('token');

  const config: AxiosRequestConfig = {
    baseURL: BASE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': ORIGIN,
      Authorization: `Bearer ${token ?? ''}`,
    },
  };

  const response = await axios.post(
    `${_controller}/PostFotos?codEmpresa=${data.codEmpresa.toString()}&codRegra=${data.codRegra.toString()}`,
    formData,
    config,
  );
  return response.data;
};

export const postDeleteRegraImage = async (
  imageName: string,
  codEmpresa: number,
  codRegra: number,
  index: number,
): Promise<any> => {
  const { data } = await api.post(
    `${_controller}/DeleteFotos?imageName=${imageName}&codEmpresa=${codEmpresa}&codRegra=${codRegra}&index=${index}`,
  );
  return data;
};

export const postEditRegra = async (regra: RegraProps): Promise<PostRegraReturnProps> => {
  const { data } = await api.post(`${_controller}/PutRegra`, regra);
  return data;
};

export const postNovaRegraRestricao = async (restricoes: RegraRestricaoProps[]): Promise<PostRestricaoReturnProps> => {
  const { data } = await api.post(`${_controller}/PostRegraRestricao`, restricoes);
  return data;
};

export const postDeleteRegraRestricao = async (
  restricoes: RegraRestricaoProps[],
): Promise<PostRestricaoReturnProps> => {
  const { data } = await api.post(`${_controller}/DeleteRegraRestricao`, restricoes);
  return data;
};

export const postEditRegraRestricao = async (restricao: RegraRestricaoProps): Promise<PostRestricaoReturnProps> => {
  const { data } = await api.post(`${_controller}/PutRegraRestricao`, restricao);
  return data;
};

export const postNovoRegraItem = async (restricoes: RegraItemProps[]): Promise<PostItensReturnProps> => {
  const { data } = await api.post(`${_controller}/PostRegraItems`, restricoes);
  return data;
};

export const postDeleteRegraItem = async (restricoes: RegraItemProps[]): Promise<PostItensReturnProps> => {
  const { data } = await api.post(`${_controller}/DeleteRegraItems`, restricoes);
  return data;
};

export const postEditRegraItem = async (restricao: RegraItemProps): Promise<PostItensReturnProps> => {
  const { data } = await api.post(`${_controller}/PutRegraItem`, restricao);
  return data;
};
