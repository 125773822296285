import { ISubMenusRestricao, SaveSubMenuRestricaoDTO } from 'client/interfaces/submenu.interface';
import api from 'services/api';

const _controller = 'MenuSubRestricao';

export const saveSubMenuRestricao = async (data: SaveSubMenuRestricaoDTO): Promise<void> => {
  await api.post(_controller, data);
};

export const getSubMenuRestricao = async (CodMenu: number): Promise<ISubMenusRestricao[]> => {
  const { data } = await api.get(_controller, { params: { CodMenu } });
  return data;
};

export const deleteSubMenuRestricao = async (
  codEmpresa: number,
  codMenu: number,
  codMenuSub: number,
): Promise<void> => {
  await api.delete(`${_controller}?codEmpresa=${codEmpresa}&codMenu=${codMenu}&CodMenuSub=${codMenuSub}`);
};
