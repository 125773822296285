import { UserData, UserValidateResponse } from 'client/interfaces';
import api from 'services/api';

const _controller = 'usuarios';

export const getUserData = async (): Promise<UserData> => {
  const { data } = await api.get(`${_controller}/current-user`);
  return data;
};

export const validateUser = async ({
  user,
  empresa,
  isCpf,
}: {
  user: string;
  empresa: string;
  isCpf: boolean;
}): Promise<UserValidateResponse> => {
  const { data } = await api.get(`${_controller}/${user}/validate`, { params: { empresa, isCpf } });
  return data;
};

export const getUsers = async (empresa: number | string): Promise<UserData[]> => {
  const { data } = await api.get(_controller, { params: { empresa } });
  return data;
};

export const postUserResetPassword = async (
  CodUsuario: number,
  codEmpresa: number,
  CodUsuarioLogado: number,
): Promise<void> => {
  const params = {
    CodUsuario,
    codEmpresa,
    CodUsuarioLogado,
  };

  await api.post(`Usuarios/resetarSenha`, null, {
    params,
  });
};

export const postUserEditInfo = async (userDataToEdit: any): Promise<void> => {
  const { data } = await api.post(`Usuarios/UpdateUser`, userDataToEdit);
  return data;
};
