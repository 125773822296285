// IMPORTA LBI DE VALIDAÇÃO DE INTERPOLAÇÃO DE POLYGONOS
import * as turf from '@turf/turf';

// EXPORTA FUNÇÃO QUE VALIDA INTERPOLAÇÃO DE POLYGONO POR PADRÃO
export default async function polyInterpol(coordinates: any, polygonos: any) {
  // DEFINE VLAOR LOCAL PARA AS COORDENADAS DO NOVO POLYGONO
  const newPoly = coordinates;

  // DEFINE VALOR LOCAL PARA A LISTA COM TODOS OS POLYGONOS DO MAPA
  const polyList = [];
  for (let i = 0; i < polygonos.length; i++) {
    polyList.push(polygonos[i].latlngs);
  }

  // TRATA POLYGONOS DO MAPA PARA O FORMATO NECESSÁRIO
  for (let w = 0; w < polyList.length; w++) {
    const pol = polyList[w];
    if (pol[0].lat !== undefined) {
      polyList[w] = polyList[w].map((poly: any) => [poly.lat, poly.lng]);
    }
  }
  // DEFINE VALOR DE RETORNO DA FUNÇÃO
  let returnValue = false;

  // MAPEIA TODOS OS POLYGONOS VALIDANDO SE HÁ INTERPOLAÇÃO COM O NOVO POLYGONO CRIADO
  for (let j = 0; j < polyList.length; j++) {
    const arr1 = polyList[j];

    const arr2 = newPoly.map((poly: any) => [poly.lat, poly.lng]);

    const line1 = turf.lineString(arr1);

    const line2 = turf.lineString(arr2);

    const intersects = turf.lineIntersect(line1, line2);

    const isInterpolating = intersects.features.length > 0;

    if (isInterpolating) {
      returnValue = true;
    }
  }
  // RETORNA VALOR APÓS PROCESSAMENTO
  return returnValue;
}
