import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface FormFilter {
  company: string;
  filial: string;
  city: string;
  manager: string;
  supervisor: string;
  seller: string;
  initDate: string;
  endDate: string;
  client: string;
  ask1: string;
  ask2: string;
}

//Regra: Ao Selecionar uma nova empresa, deve se atentar se o antigo valor de filial foi limpo

export const useResearchDataAnalysis = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormFilter>({});

  const values = watch();

  const handleFilter = (value: any) => {};

  return {
    handleFilter,
    handleSubmit,
    control,
    values,
    setValue,
    errors,
  };
};
