const chartConfig = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    display: false,
    x: {
      display: false,
      ticks: {
        font: {
          weight: 500,
          size: '9rem',
        },
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};

export default chartConfig;
