import styled from 'styled-components';

interface colorProp {
  color?: string;
}

export const PointsListMainBox = styled.div`
  background-color: #fff;
  width: 100%;
  height: 81%;
  padding: 2px;
  overflow-y: scroll;
`;

type PointMainCardProps = {
  expanded?: boolean;
};

export const PointMainCard = styled.div<PointMainCardProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: all 0.2s ease;
  max-height: ${(props) => (props.expanded ? 'calc(50 * 90px)' : '4rem')};
  overflow: ${(props) => (props.expanded ? 'auto' : 'hidden')};
  overflow: hidden;
`;

export const PointMainCardTitle = styled.div`
  width: 100%;
  padding: 5px;
  background-color: #e5e9f090;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  gap: 10px;
  margin: 2px 0px;
  position: relative;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    background-color: #e5e9f0;
  }
  & *::selection {
    background-color: transparent;
  }
`;

export const PointMainCardTitleDayString = styled.div<colorProp>`
  height: 100%;
  color: ${(props) => (props.color ? props.color : '#000')};
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PointsCliTotal = styled.div`
  color: #49505790;
  font-size: 0.8rem;
  font-weight: 500;
`;

export const PointMainCardTitleDay = styled.div`
  color: #000000;
  font-size: 0.9rem;
  padding-top: 2px;
  color: #495057;
`;

export const PointMainCardTitleKm = styled.div<colorProp>`
  color: ${(props) => (props.color ? props.color : '#000')};
  font-size: 1rem;
  position: relative;
  right: 5px;
  font-weight: 500;
  padding-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const CloseCardIcon = styled.div`
  svg {
    color: #495057;
    font-size: 1.2rem;
  }
`;

type PointCardProps = {
  active?: boolean;
};

export const PointCard = styled.div<PointCardProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.4s ease;
  background-color: ${(props) => (props.active ? '#00000010' : '#FFF')};
  &:hover {
    background-color: #00000010;
  }
`;

export const PointCardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type PointCardCliNameProps = {
  color?: string;
};
export const PointCardCliName = styled.div<PointCardCliNameProps>`
  width: 100%;
  color: ${(props) => (props.color ? props.color : '#000000')};
`;

export const PointCardCliData = styled.div<PointCardCliNameProps>`
  width: 100%;
  color: #49505799;
  color: ${(props) => (props.color ? props.color : '#000000')};
`;

type PointCardIconProps = {
  color?: string;
  dots?: string;
};

export const PointCardIcon = styled.div<PointCardIconProps>`
  background-color: ${(props) => (props.color ? props.color : '#495057')};
  color: #fff;
  box-shadow: rgba(50, 50, 93, 0.45) 0px 6px 12px -2px, rgba(0, 0, 0, 0.6) 0px 3px 7px -3px;

  min-width: 32px;
  min-height: 32px;
  font-size: 1rem;
  border-radius: 2rem;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3 !important;

  span {
    font-weight: 500 !important;
  }

  &::before {
    content: ${(props) => (props.dots ? "'" + props.dots + "'" : "''")};
    position: absolute;
    top: 100%;
    left: 60%;
    transform: translateX(-40%);
    display: flex;
    flex-direction: column;
    writing-mode: vertical-rl;
    letter-spacing: 4px;
    font-size: 2rem !important;
    color: #00000050;
    z-index: -2 !important;
  }
`;

export const PointCardIconMarker = styled.div<PointCardIconProps>`
  /* background-color: ${(props) => (props.color ? props.color : '#495057')}; */
  color: #fff;
  /* box-shadow: rgba(50, 50, 93, 0.45) 0px 6px 12px -2px, rgba(0, 0, 0, 0.6) 0px 3px 7px -3px; */

  min-width: 32px;
  min-height: 32px;
  font-size: 1rem;
  border-radius: 2rem;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3 !important;

  span {
    font-weight: 500 !important;
  }

  &::before {
    content: ${(props) => (props.dots ? "'" + props.dots + "'" : "''")};
    position: absolute;
    top: 100%;
    left: 60%;
    transform: translateX(-40%);
    display: flex;
    flex-direction: column;
    writing-mode: vertical-rl;
    letter-spacing: 4px;
    font-size: 2rem !important;
    color: #00000050;
    z-index: -2 !important;
  }
`;

export const BlockedVisitIcon = styled.div<colorProp>`
  position: absolute;
  top: -0.5rem;
  right: -2px;
  svg {
    color: #495057;
  }
`;

export const PointCardDateTag = styled.div<colorProp>`
  background-color: ${(props) => (props.color ? props.color : '#495057')};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  border-radius: 50px;
  font-size: 0.8rem;
  padding: 2px 5px;
`;

type PointsListLabelProps = {
  marginTop?: string;
};
export const PointsListLabel = styled.div<PointsListLabelProps>`
  padding: 5px 0 5px 5px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`;
