import { IGroupClient } from 'client/interfaces/groupClient';
import { useMemo, useState } from 'react';

export const useListGroupClients = () => {
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [rowGroupClient, setRowGroupClient] = useState<IGroupClient>();
  const [listGroups, setListGroups] = useState([]);

  return {
    isOpenModalEdit,
    setIsOpenModalEdit,
    isOpenModalDelete,
    setIsOpenModalDelete,
    rowGroupClient,
    setRowGroupClient,
    listGroups
  };
};
