import api from 'services/api';
import { EmpresaBasico, ICompanyDTO } from 'client/interfaces';

const _controller = 'empresas';

export const validateCompany = async (empresaCNPJ: string): Promise<boolean> => {
  const { data } = await api.get(`${_controller}/${empresaCNPJ}/validate`);
  return data;
};

export const getEmpresas = async (): Promise<EmpresaBasico[]> => {
  const { data } = await api.get(_controller);
  return data;
};

export const getEmpresasList = async (): Promise<ICompanyDTO[]> => {
  const { data } = await api.get(`${_controller}/list`);
  return data;
};

export const runSqlCommand = async (id: number, sqlCommand: string): Promise<string> => {
  const { data } = await api.post(`${_controller}/${id}/sql`, {
    sqlCommand,
  });
  return data;
};

export const getEmpresa = async (codEmpresa): Promise<EmpresaBasico> => {
  const { data } = await api.get(`Empresas/${codEmpresa}/consulta`);
  return data;
};

export const postCompany = async (company: ICompanyDTO): Promise<void> => {
  await api.post(`${_controller}?CodEmpresa=${company.codEmpresa}&Cnpj=${company.cnpj}&Razaosocial=${company.razaosocial}&Fantasia=${company.fantasia}&CodSisErp=${company.codSisErp}&UrlServFv=${company.urlServFv}&UrlServGerencial=${company.urlServGerencial}&UrlServApicliente=${company.urlServApicliente}&BdNome=${company.bdNome}&BdUsu=${company.bdUsu}&BdHost=${company.bdHost}&BdPsw=${company.bdPsw}&Ativo=${company.ativo}&Obsinativo=${company.obsinativo}&UrlServRelatorio=${company.urlServRelatorio}&CaminhoLogo=${company.caminhoLogo}&BdSgbd=${company.bdSgbd}
  `);
};

export const putCompany = async (company: ICompanyDTO): Promise<void> => {
  await api.put(`${_controller}?CodEmpresa=${company.codEmpresa}&Cnpj=${company.cnpj}&Razaosocial=${company.razaosocial}&Fantasia=${company.fantasia}&CodSisErp=${company.codSisErp}&UrlServFv=${company.urlServFv}&UrlServGerencial=${company.urlServGerencial}&UrlServApicliente=${company.urlServApicliente}&BdNome=${company.bdNome}&BdUsu=${company.bdUsu}&BdHost=${company.bdHost}&BdPsw=${company.bdPsw}&Ativo=${company.ativo}&Obsinativo=${company.obsinativo}&UrlServRelatorio=${company.urlServRelatorio}&CaminhoLogo=${company.caminhoLogo}&BdSgbd=${company.bdSgbd}
  `);
};
