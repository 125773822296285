import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getSqlGroups } from 'client/api';
import { SqlGroup } from 'client/interfaces';

export const useSqlGroup = (enabled = true) =>
  useQuery<SqlGroup[], AxiosError>(['sqlGroups'], getSqlGroups, {
    staleTime: 1000 * 60 * 5,
    enabled,
  });
