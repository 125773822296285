import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Aside from '../Aside/Aside';
import { PageDataProvider } from '_metronic/layout/core';
import { MenuComponent } from '_metronic/assets/ts/components';

import HeaderWrapper from '../Header';
import VmContainer from 'modules/main/components/Container/index';
import VmContent from 'modules/main/components/Content/index';

const MainLayout = () => {
  const [useVmHeader, setUseVmHeader] = useState<boolean>(true);

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
    if (location.pathname == '/pedido' || location.pathname == '/pedido/novo') {
      setUseVmHeader(false);
    } else if (!useVmHeader) {
      setUseVmHeader(true);
    }
  }, [location.key]);

  return (
    <PageDataProvider>
      <VmContainer>
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
          style={{
            paddingTop: useVmHeader ? '65px' : '0px',
          }}
        >
          <Aside />
          {useVmHeader && <HeaderWrapper />}

          <VmContent>
            <Outlet />
          </VmContent>
        </div>
      </VmContainer>
    </PageDataProvider>
  );
};

export default MainLayout;
