import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Controller } from 'react-hook-form';

import VmButton from 'components/VmButton/VmButton';
import { UseEditFrequenceVisit } from 'hooks/useMarkerMapClientSector/UseEditFrequenceVisit';

import * as S from './styles';
import { InputSwitch } from 'primereact/inputswitch';
import UseMapRouterDaySelector from 'modules/mapa/components/mapRouterDaySelector/mapRouterDaySelector';
import { useContext, useEffect, useState } from 'react';
import { updateFrequenceVisit } from 'client/api/mapaCli';
import { toast } from 'react-toastify';

import { MapContext } from 'providers/map';

interface IEditFrequenceVisit {
  selectedMarker: any;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  codCompany: string;
  codMap: string;
}
export const EditFrequenceVisit = ({
  showModal,
  setShowModal,
  selectedMarker,
  codCompany,
  codMap,
}: IEditFrequenceVisit) => {
  const { handleSubmit, control, errors, isValid, setValue, reset } = UseEditFrequenceVisit({
    setShowModal,
    selectedMarker,
    codCompany,
    codMap,
  });
  const { Selectdays, daySelection, handleDataStrucuteAdjust } = UseMapRouterDaySelector();

  useEffect(() => {
    if (selectedMarker && showModal) handleDataStrucuteAdjust(selectedMarker, 'fowards');
    if (selectedMarker) setAllowEditDailyVisit(selectedMarker.agendamentoDoCliente == 'S');
  }, [selectedMarker, showModal]);

  const { reloadAllData } = useContext(MapContext);

  const [allowEditDailyVisit, setAllowEditDailyVisit] = useState(false);

  const handleSubmitForm = async (data) => {
    data.bloqueioRoteiro = data.bloqueioRoteiro && data.bloqueioRoteiro == true ? 'S' : 'N';

    try {
      await updateFrequenceVisit({
        codEmpresa: +codCompany,
        codMapa: +codMap,
        codCli: selectedMarker.codCli,
        freqVisita: +data.freqVisita,
        tempoMinVisita: +data.tempoMinVisita,
        dtProxVisita: data.dtProxVisita ?? '',
        sequencia: +data.sequenciaVisita,
        bloqRoteiro: data.bloqRoteiro,
        agendamentoDoCliente: data.agendamentoDoCliente,
        domingoManha: daySelection.domingo.manha,
        domingoTarde: daySelection.domingo.tarde,
        segundaManha: daySelection.segunda.manha,
        segundaTarde: daySelection.segunda.tarde,
        tercaManha: daySelection.terca.manha,
        tercaTarde: daySelection.terca.tarde,
        quartaManha: daySelection.quarta.manha,
        quartaTarde: daySelection.quarta.tarde,
        quintaManha: daySelection.quinta.manha,
        quintaTarde: daySelection.quinta.tarde,
        sextaManha: daySelection.sexta.manha,
        sextaTarde: daySelection.sexta.tarde,
        sabadoManha: daySelection.sabado.manha,
        sabadoTarde: daySelection.sabado.tarde,
      });

      toast.success('Editado com sucesso!');
      reloadAllData();
      reset();
      setShowModal(false);
    } catch {
      toast.error('Não foi possível Editar.');
    }
  };

  return (
    <Dialog
      visible={showModal}
      style={{ width: '32rem' }}
      breakpoints={{ '960px': '75vw', '641px': '90vw' }}
      header={() => {
        return (
          <span>
            Editar Visita do Cliente: <br />
            <S.ModalCliName>
              [{selectedMarker.codCli}] {selectedMarker.razaosocial}.
            </S.ModalCliName>
          </span>
        );
      }}
      modal
      className="p-fluid"
      onHide={() => setShowModal(!showModal)}
    >
      <S.Form onSubmit={handleSubmit(handleSubmitForm)}>
        <S.ItemRow>
          <S.ItemRowContent>
            <S.TitleInput error={errors.freqVisita && true}>*Frequência Visita</S.TitleInput>
            <Controller
              rules={{
                required: true,
              }}
              name="freqVisita"
              control={control}
              render={({ field }) => <InputText {...field} type="number" />}
            />
            <S.DescriptionInput error={errors.freqVisita && true}>Digite a frequência visita.</S.DescriptionInput>
          </S.ItemRowContent>
          <S.ItemRowContent>
            <S.TitleInput error={errors.tempoMinVisita && true}>*Tempo Mínimo Visita</S.TitleInput>
            <Controller
              rules={{
                required: true,
              }}
              name="tempoMinVisita"
              control={control}
              render={({ field }) => <InputText {...field} type="number" />}
            />
            <S.DescriptionInput error={errors.tempoMinVisita && true}>Digite o tempo mínimo visita.</S.DescriptionInput>
          </S.ItemRowContent>
        </S.ItemRow>

        <S.ItemRow>
          <S.ItemRowContent>
            <S.TitleInput error={errors.tempoMinVisita && true}>*Sequência Visita</S.TitleInput>
            <Controller
              rules={{
                required: true,
              }}
              name="sequenciaVisita"
              control={control}
              render={({ field }) => <InputText {...field} type="number" />}
            />
            <S.DescriptionInput error={errors.tempoMinVisita && true}>Digite a sequencia da visita.</S.DescriptionInput>
          </S.ItemRowContent>
          <S.ItemRowContent>
            <S.TitleInput error={errors.tempoMinVisita && true}>*Data Próxima Visita</S.TitleInput>
            <Controller
              rules={{
                required: true,
              }}
              name="dtProxVisita"
              control={control}
              render={({ field }) => <InputText {...field} type="date" />}
            />
            <S.DescriptionInput error={errors.tempoMinVisita && true}>
              Digite a data da próxima visita.
            </S.DescriptionInput>
          </S.ItemRowContent>
        </S.ItemRow>

        <S.ItemRow>
          <S.ItemRowContent>
            <S.TitleInput error={errors.tempoMinVisita && true}>
              <Controller
                rules={{
                  required: true,
                }}
                name="bloqRoteiro"
                control={control}
                render={({ field }) => (
                  <InputSwitch
                    checked={field.value === 'S'}
                    onChange={(e) => {
                      setValue('bloqRoteiro', e.value ? 'S' : 'N');
                      field.onChange(e.value ? 'S' : 'N');
                    }}
                  />
                )}
              />
              Bloqueio Roteirização
            </S.TitleInput>
            <S.DescriptionInput error={errors.tempoMinVisita && true} style={{ color: 'blue', textAlign: 'right' }}>
              Marque se o cliente não pode ser roteirizado.
            </S.DescriptionInput>
          </S.ItemRowContent>

          <S.ItemRowContent>
            <S.TitleInput error={errors.tempoMinVisita && true}>
              <Controller
                rules={{
                  required: true,
                }}
                name="agendamentoDoCliente"
                control={control}
                render={({ field }) => (
                  <InputSwitch
                    checked={field.value === 'S'}
                    onChange={(e) => {
                      setValue('agendamentoDoCliente', e.value ? 'S' : 'N');
                      field.onChange(e.value ? 'S' : 'N');
                      setAllowEditDailyVisit(e.value);
                    }}
                  />
                )}
              />
              Agendamento Manual
            </S.TitleInput>
            <S.DescriptionInput error={errors.tempoMinVisita && true} style={{ color: 'blue', textAlign: 'right' }}>
              Marque se o cliente deve acatar agendamento manual.
            </S.DescriptionInput>
          </S.ItemRowContent>
        </S.ItemRow>

        {allowEditDailyVisit && (
          <S.ItemRow>
            <S.ItemRowContent width="100%">
              <S.TitleInput error={errors.tempoMinVisita && true}>Dia/Turno da Roteirização</S.TitleInput>
              <Selectdays />
              <S.DescriptionInput error={errors.tempoMinVisita && true}>
                Informe os dias/turnos de atendimento do cliente.
              </S.DescriptionInput>
            </S.ItemRowContent>
          </S.ItemRow>
        )}

        <S.ModalButtonsBox>
          <VmButton
            type="submit"
            options={{
              label: 'Editar',
              style: 'btn-success',
              width: '100px',
              heigth: '',
              iconPosition: 'left',
              size: 'btn-md',
              loading: false,
            }}
          />
        </S.ModalButtonsBox>
      </S.Form>
    </Dialog>
  );
};
