import styled from 'styled-components';

export const BoxButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const Button = styled.button`
  width: 15rem;
  padding: 10px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 8px;
  font-size: 14px;

  :hover {
    transition: all 0.4s ease;
  }

  svg {
    margin-left: 10px;
  }
`;
