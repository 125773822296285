import { useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { useErps, useSqlGroup } from 'client/hooks';
import { ComandoSql } from 'client/interfaces';
import { saveNewSqlCommand, updateSqlCommand } from 'client/api';
import { toast } from 'react-toastify';

interface NewSqlCommandModalProps {
  isOpen: boolean;
  txtSql: string;
  onClose: (command: ComandoSql | null) => void;
  command: ComandoSql | null;
}

const NewSqlCommandModal = ({ isOpen, onClose, txtSql, command }: NewSqlCommandModalProps) => {
  const { data: erps } = useErps();
  const { data: sqlGroups } = useSqlGroup();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, dirtyFields },
  } = useForm<ComandoSql>({ defaultValues: command ?? undefined });

  const onSubmit: SubmitHandler<ComandoSql> = async (data) => {
    try {
      setIsLoading(true);
      if (data.codSql > 0) {
        const newCommand = {
          codSql: data.codSql,
          txtSql,
          codGrupoSql: data.grupoSql.codGrupoSql,
          codSisErp: data.sistemaERP?.codSisErp ?? null,
          nomeSql: data.nomeSql,
          localExec: data.localExec,
          dtAltera: null,
          intervalo: data.intervalo,
          comandoDelete: command?.comandoDelete,
          comandoInsert: command?.comandoInsert,
        };
        await updateSqlCommand(newCommand);
        if (command) {
          onClose({
            ...command,
            dtAltera: null,
            codSql: data.codSql,
            grupoSql: data.grupoSql,
            sistemaERP: data.sistemaERP,
            nomeSql: data.nomeSql,
            localExec: data.localExec,
            intervalo: data.intervalo,
          });
        }
      } else {
        const newCommand = await saveNewSqlCommand({
          codSql: 0,
          txtSql,
          codGrupoSql: data.grupoSql.codGrupoSql,
          codSisErp: data.sistemaERP?.codSisErp ?? null,
          nomeSql: data.nomeSql,
          localExec: data.localExec,
          dtAltera: null,
          intervalo: data.intervalo,
          comandoDelete: command?.comandoDelete,
          comandoInsert: command?.comandoInsert,
        });
        if (command) {
          onClose({
            ...newCommand,
            grupoSql: data.grupoSql,
            sistemaERP: data.sistemaERP,
          });
        }
      }
      toast.success('Comando salvo com sucesso!');
    } catch (e) {
      toast.error('Falha ao salvar comando');
    } finally {
      setIsLoading(false);
    }
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Cancelar" icon="pi pi-times" onClick={() => onClose(command)} className="p-button-text" />
        <Button label="Salvar" loading={isLoading} icon="pi pi-check" onClick={handleSubmit(onSubmit)} autoFocus />
      </div>
    );
  };

  return (
    <Dialog
      header="Novo Comando SQL"
      visible={isOpen}
      style={{ width: '50vw' }}
      footer={renderFooter}
      onHide={() => onClose(command)}
    >
      <form className="form w-100" noValidate>
        <div className="fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark">Nome</label>
          <input
            {...register('nomeSql', { required: 'Obrigatório' })}
            placeholder="Nome do comando"
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': (dirtyFields.nomeSql ?? false) && errors.nomeSql,
            })}
            autoFocus
            maxLength={100}
            type="nomeSql"
            name="nomeSql"
          />
          {errors.nomeSql != null && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{errors.nomeSql.message}</span>
            </div>
          )}
        </div>

        <div className="fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark">ERP</label>
          <Controller
            control={control}
            name="sistemaERP"
            rules={{ required: 'Obrigatório' }}
            render={({ field }) => (
              <Dropdown
                {...field}
                className={clsx('form-control form-control-lg form-control-solid', {
                  'is-invalid': dirtyFields.sistemaERP != null && errors.sistemaERP,
                })}
                options={erps}
                dataKey="codSisErp"
                optionLabel="nomeErp"
                placeholder="Selecione o sistema ERP"
              />
            )}
          />

          {errors.sistemaERP != null && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{errors.sistemaERP.message}</span>
            </div>
          )}
        </div>

        <div className="fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark">Grupo SQL</label>
          <Controller
            control={control}
            name="grupoSql"
            rules={{ required: 'Obrigatório' }}
            render={({ field }) => (
              <Dropdown
                {...field}
                className={clsx('form-control form-control-lg form-control-solid', {
                  'is-invalid': dirtyFields.grupoSql != null && errors.grupoSql,
                })}
                options={sqlGroups}
                dataKey="codGrupoSql"
                optionLabel="nomeGrupoSql"
                placeholder="Selecione o Grupo SQL"
              />
            )}
          />

          {errors.grupoSql != null && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{errors.grupoSql.message}</span>
            </div>
          )}
        </div>

        <div className="fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark">Local Execução</label>
          <Controller
            control={control}
            name="localExec"
            rules={{ required: true }}
            defaultValue="L"
            render={({ field }) => (
              <SelectButton
                {...field}
                unselectable
                options={[
                  {
                    label: 'Local',
                    value: 'L',
                  },
                  {
                    label: 'Client',
                    value: 'C',
                  },
                ]}
              />
            )}
          />
        </div>

        <div className="fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark">Intervalo de Execução (minutos)</label>
          <input
            {...register('intervalo', { valueAsNumber: true })}
            placeholder="Intervalo de Execução"
            className="form-control form-control-lg form-control-solid"
            type="number"
            name="intervalo"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default NewSqlCommandModal;
