import { UseUsersByCompany } from 'hooks/useCompanies/useUserByCompany';
import { Dropdown } from 'primereact/dropdown';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

interface IFilterByUsersCompanyParams {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  codCompany: number | string;
}

export const FilterByUsersCompany = ({ control, setValue, codCompany }: IFilterByUsersCompanyParams) => {
  const { optionsUsersByCompany } = UseUsersByCompany(codCompany);

  return (
    <>
      <Controller
        name="codUserByCompany"
        rules={{
          required: true,
        }}
        control={control}
        render={({ field }) => (
          <Dropdown
            placeholder="Selecione um Usuário"
            value={field.value}
            options={optionsUsersByCompany}
            onChange={(e) => setValue('codUserByCompany', e.value)}
            filter
          />
        )}
      />
    </>
  );
};