import { useEffect, useState } from 'react';
import * as S from './regraComercial.styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import RegrasHeader from './components/regrasHeader/regrasHeader.index';
import RegrasTable from './components/regrasTable/regrasTable.index';
import RegrasConfig from './components/regrasConfig/regrasConfig.index';

import { getAllRegrasComerciais, getAllRegrasComerciaisByDatePeriod } from 'client/api/regraComercial';
import { toast } from 'react-toastify';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { RegraProps, RegrasListProps, SearchRegrasParams } from 'client/interfaces';
import { useParams } from 'react-router-dom';
import { regraTypeOptions } from './regraComercial.types';

export default function RegraComercialPage() {
  const { setTitlePage } = useTitlePageStore();
  const { selectedEmpresa } = UseSelectEmpresa();

  const [regrasList, setRegrasList] = useState<RegrasListProps>({
    originalList: [],
    filteredList: [],
  });
  const [selectedRegra, setSelectedRegra] = useState<RegraProps | null>(null);
  const [selectedEmpr, setSelectedEmpr] = useState(selectedEmpresa);

  const { id: regraType } = useParams();

  useEffect(() => {
    setTitlePage(regraTypeOptions[regraType] ? regraTypeOptions[regraType].title : 'Regras Comerciais');
  }, [regraType]);

  const [searchRegrasParams, setSearchRegrasParams] = useState<SearchRegrasParams>({
    text: '',
    dtIni: '',
    dtFim: '',
    // dtIni:
    //   new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleDateString('en-CA', {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    //   }) ?? '',
    // dtFim: new Date().toLocaleDateString('en-CA') ?? '',
    status: 'T',
  });

  useEffect(() => {
    if (selectedEmpr.codEmpresa) {
      handleGetRegrasComerciais();
    }
  }, [searchRegrasParams, selectedEmpr, regraType]);

  const handleGetRegrasComerciais = () => {
    if (searchRegrasParams.dtIni && searchRegrasParams.dtFim) {
      getAllRegrasComerciaisByDatePeriod(selectedEmpr.codEmpresa, searchRegrasParams.dtIni, searchRegrasParams.dtFim)
        .then((res) => {
          if (res.succeeded) {
            const newRegrasList = res.data.filter(
              (regra: RegraProps) =>
                regra.tipo == (regraTypeOptions[regraType] ? regraTypeOptions[regraType].type : ''),
            );
            if (selectedRegra) {
              const newSelection = newRegrasList.filter(
                (regra: RegraProps) => regra.codregra == selectedRegra.codregra,
              );
              newSelection && setSelectedRegra(newSelection[0]);
            }
            setRegrasList({
              originalList: newRegrasList,
              filteredList: newRegrasList,
            });
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao listar regras');
          console.log('err :', err);
        });
    } else {
      getAllRegrasComerciais(selectedEmpr.codEmpresa)
        .then((res) => {
          if (res.succeeded) {
            const newRegrasList = res.data.filter(
              (regra: RegraProps) =>
                regra.tipo == (regraTypeOptions[regraType] ? regraTypeOptions[regraType].type : ''),
            );
            if (selectedRegra) {
              const newSelection = newRegrasList.filter(
                (regra: RegraProps) => regra.codregra == selectedRegra.codregra,
              );
              newSelection && setSelectedRegra(newSelection[0]);
            }
            setRegrasList({
              originalList: newRegrasList,
              filteredList: newRegrasList,
            });
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao listar regras');
          console.log('err :', err);
        });
    }
  };

  useEffect(() => {
    handleSearchRegras();
  }, [searchRegrasParams, regrasList.originalList]);

  const handleSearchRegras = () => {
    const today = new Date();

    const parseDate = (dateStr: string) => {
      const [day, month, year] = dateStr.split('/').map(Number);
      return new Date(year, month - 1, day);
    };

    let regrasToFilter = [];

    if (searchRegrasParams.status == 'N') {
      regrasToFilter = regrasList.originalList.filter(
        (regra: RegraProps) => regra.ativa == 'N' || parseDate(regra.dataFim) < today,
      );
    } else if (searchRegrasParams.status == 'S') {
      regrasToFilter = regrasList.originalList.filter(
        (regra: RegraProps) => regra.ativa == 'S' && parseDate(regra.dataFim) >= today,
      );
    } else {
      regrasToFilter = regrasList.originalList;
    }

    if (searchRegrasParams.text == '') {
      setRegrasList((prevLists: RegrasListProps) => {
        return {
          ...prevLists,
          filteredList: regrasToFilter,
        };
      });
    } else {
      const filteredListByText = regrasToFilter?.filter(
        (regra: RegraProps) =>
          regra.codregra.toString().includes(searchRegrasParams.text) ||
          regra.nomeregra.toString().toUpperCase().includes(searchRegrasParams.text),
      );
      setRegrasList((prevLists: RegrasListProps) => {
        return {
          ...prevLists,
          filteredList: filteredListByText,
        };
      });
    }
  };

  return (
    <S.RegraComercialMainBox>
      <RegrasHeader
        searchRegrasParams={searchRegrasParams}
        setSearchRegrasParams={setSearchRegrasParams}
        setSelectedEmpr={setSelectedEmpr}
        handleGetRegrasComerciais={handleGetRegrasComerciais}
      />
      <RegrasTable
        regrasList={regrasList.filteredList}
        selectedRegra={selectedRegra}
        setSelectedRegra={setSelectedRegra}
        codEmpr={selectedEmpr.codEmpresa}
        handleGetRegrasComerciais={handleGetRegrasComerciais}
      />
      <RegrasConfig
        selectedRegra={selectedRegra}
        itensList={selectedRegra && selectedRegra.regraItems ? selectedRegra.regraItems : []}
        restricoesList={selectedRegra && selectedRegra.regraRestricao ? selectedRegra.regraRestricao : []}
        codEmpr={selectedEmpr.codEmpresa}
        handleGetRegrasComerciais={handleGetRegrasComerciais}
      />
    </S.RegraComercialMainBox>
  );
}
