import styled from 'styled-components';

interface IToggleProps {
  isActive: boolean;
}

export const Toggle = styled.div<IToggleProps>`
  justify-content: space-around;
  display: flex;
  height: 40px;
  background-color: var(--gray-300);
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
`;

export const Button = styled.button<IToggleProps>`
  width: 48%;
  height: 30px;
  border: none;
  border-radius: 5px;

  background-color: ${(props: IToggleProps) => (props.isActive ? 'var(--primary-green)' : 'transparent')};
  color: ${(props: IToggleProps) => (props.isActive ? 'var(--white)' : 'var(--dark-300)')};
  font-weight: 500;
  font-size: 16px;
`;
