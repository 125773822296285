import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { deleteGroupClient } from 'client/api/groupClients';
import { IGroupClient } from 'client/interfaces/groupClient';
import { toast } from 'react-toastify';

interface IUseDeleteGroupClient {
  rowGroupClient: IGroupClient;
  setIsOpenModalDelete: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<IGroupClient[], AxiosError<unknown, any>>>;
}

export const UseDeleteGroupClient = ({ rowGroupClient, setIsOpenModalDelete, refetch }: IUseDeleteGroupClient) => {
  const queryClient = useQueryClient();

  const handleRemoveGroup = async () => {
    deleteGroupClient({
      codgrupo: rowGroupClient?.codgrupo,
      codempresa: rowGroupClient?.codempresa,
      codfilial: rowGroupClient?.codfilial,
      nomeGrupo: rowGroupClient?.nomeGrupo,
    })
      .then(() => {
        refetch();
        toast.success('Grupo removido com sucesso!');
        setIsOpenModalDelete(false);
      })
      .catch(() => {
        toast.error('Não foi possível remover Grupo.');
      });
  };

  return {
    handleRemoveGroup,
  };
};
