import styled from 'styled-components';

export const ActionBarMainBox = styled.section`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-100%);
  z-index: 9 !important;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 7.5px;
  border-radius: 5px;
  margin: 5px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    bottom: 5vh;
    left: 0;
    transform: translate(0%);
  }
`;
