import { useFindByRoleManagers } from 'client/hooks/gerentes';

interface IFindByRoleManagersParams {
  codCompany: number;
  codUser: number;
}

export const UseFindByRoleManagers = ({ codCompany, codUser }: IFindByRoleManagersParams) => {
  const { data: managers, refetch: refetchManagersByRole } = useFindByRoleManagers(codCompany, codUser);

  const managersByRoleOptions = managers?.map((item) => ({
    value: item.codGerente,
    label: `${item.nomeGerente} - [${item.codGerente}]`,
  }));

  return {
    managersByRoleOptions,
    refetchManagersByRole
  }
};
