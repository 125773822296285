import styled from 'styled-components';

export const AnaliseModalMainBox = styled.section`
  border-radius: 5px;
  margin: 5px;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    bottom: 5vh;
    left: 0;
    transform: translate(0%);
  }
`;

export const AnaliseModalButtonsBox = styled.section`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const ObsInput = styled.section`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;

`;
