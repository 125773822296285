import { EditPoint, IRouterStartPointsData, RegisterPoint } from 'client/interfaces';
import { IPonto } from 'client/interfaces/ponto.interface';
import api from 'services/api';

const _controller = 'rotaPonto';

export const getRotaPontoVendedor = async (CodEmpresa: number, CodVendedor: number): Promise<IPonto[]> => {
  const { data } = await api.get(`${_controller}/RotaPontosVendedor`, { params: { CodEmpresa, CodVendedor } });
  return data;
};

export const registerClientPoint = async (toRegisterPoint: RegisterPoint): Promise<void> => {
  await api.post(`/RotaPonto`, toRegisterPoint);
};

export const editClientPoint = async (toEditPoint: EditPoint): Promise<void> => {
  await api.put(`/RotaPonto`, toEditPoint);
};

export const getRouterStartPointsData = async (codEmpresa: string): Promise<IRouterStartPointsData[]> => {
  const { data } = await api.get(`/RotaPonto`, { params: { codEmpresa } });
  return data;
};

interface IRotaPonto {
  codPonto: number;
  descricao: string;
  codVendedor: number;
  latitude: string;
  longitude: string;
  casa: string;
  codEmpresa: string;
}

export const deleteRotaPonto = async (rotaPonto: IRotaPonto): Promise<void> => {
  await api.delete(`RotaPonto`, { data: rotaPonto });
};
