import { useByUserFiliais, useFiliais } from 'client/hooks/filiais';
import { useState } from 'react';

interface IUseParams {
  codEmpresa: number;
  codUser: number;
}

export const UseByUserFiliais = ({ codEmpresa, codUser }: IUseParams) => {
  const { data: filiais, refetch: refetchFiliais } = useByUserFiliais(codEmpresa, codUser);

  const [selectedOption, setSelectedOption] = useState('');
  const codEmpresaVMais = 2072021;

  const handleSelectChange = (event) => {
  console.log('event.target.value :', event.target.value);
    setSelectedOption(event.target.value);
  };

  const optionsFiliais = filiais?.map((item) => ({
    value: item.codFilial,
    label: `${item.nomeFilial} - [${item.codFilial}]`,
  }));

  return {
    optionsFiliais,
    selectedOption,
    setSelectedOption,
    handleSelectChange,
    codEmpresaVMais,
    refetchFiliais
  };
};
