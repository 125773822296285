import { useClients } from 'client/hooks/groupClients';
import { Dropdown } from 'primereact/dropdown';

interface IFilterByClients {
  codEmpresa: number;
  selectedClient: number;
  setSelectedClient: React.Dispatch<React.SetStateAction<number>>;
}

export const FilterByClients = ({ codEmpresa, selectedClient, setSelectedClient }: IFilterByClients) => {
  const { data: dataClients } = useClients(codEmpresa);

  const handleSelectClient = (event) => {
    setSelectedClient(event.target.value);
  };

  return (
    <>
      <Dropdown
        value={selectedClient}
        onChange={handleSelectClient}
        options={dataClients}
        filter
        filterBy="razaosocial"
        filterPlaceholder="Filtrar por Razão Social"
        placeholder="Selecione um Cliente"
        optionLabel="razaosocial"
        optionValue="codCli"
        className="w-full md:w-14rem"
        showClear
        style={{ marginRight: '10px' }}
        itemTemplate={(item) => {
          return  '[' + item.codCli + '] - ' + item.razaosocial;
        }}
      />
    </>
  );
};
