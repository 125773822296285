import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import * as S from './styles';
import { BeatLoader } from 'react-spinners';

type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement>;

export type IButton = {
  icon?: string | any;
  text: string;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
  loading?: boolean;
  onClick: any;
} & ButtonTypes;

const ButtonComponent: React.FC<IButton> = ({ onClick, icon, text, disabled, loading, variant = 'primary' }) => {
  return (
    <S.Button onClick={() => onClick()} disabled={disabled} variant={variant}>
      {loading ? (
        <S.LoaderBox>
          <BeatLoader size="7" color={variant === 'primary' ? 'var(--white)' : 'var(--primary-green)'} />
        </S.LoaderBox>
      ) : typeof icon === 'string' ? (
        <img src={icon} />
      ) : (
        <div>{icon}</div>
      )}
      {text}
    </S.Button>
  );
};

export default ButtonComponent;
