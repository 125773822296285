import { IClientGroup } from 'client/interfaces/clientGroup';
import api from 'services/api';

const _controller = 'ClienteGrupo';

export const postClientGroup = async (data: IClientGroup): Promise<void> => {
  await api.post(_controller, data);
};

export const postClientGroupImport = async (data: IClientGroup[], codEmpresa: number | string): Promise<void> => {
  await api.post(`${_controller}/Importa`, {
    codEmpresa: codEmpresa,
    clientes: data
  });
};

export const getClientGroup = async (codEmpresa: string, codGrupo: number): Promise<IClientGroup[]> => {
  const { data } = await api.get(`${_controller}/List/${codEmpresa}/${codGrupo}`);
  return data;
};

export const deleteClientGroup = async (codClienteGrupo: string, codGrupo: string)  => {
  const { data } = await api.delete(`${_controller}/${codClienteGrupo}/${codGrupo}`);
  return data;
};

export const deleteAllClientsGroup = async (codGrupo: string, codEmpresa: string)  => {
  const { data } = await api.delete(`${_controller}/Todos/${codGrupo}/${codEmpresa}`);
  return data;
};