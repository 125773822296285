import styled from 'styled-components';

export const MainBox = styled.main`
  min-height: 100dvh;
  height: 100dvh;
  max-width: 100dvw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: top;
  padding-top: 50px;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    padding-bottom: 4rem;
    gap: 20px;
    justify-content: center;
  }
`;

export const HeaderBox = styled.header`
  width: 100dvw;
  padding: 10px;
  text-align: center;
  background-color: #228f6b90;
  background-color: #c4e1c5;
  position: absolute;
  top: 0px;
  left: 0;
  color: #176148;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  z-index: 9;
  svg {
    font-size: 1.3rem;
  }
`;

export const ColBox = styled.div`
  min-width: 500px;
  max-width: 50dvw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const PProccessButtonBox = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 10px;
  background-color: #FFF;
`;

export const PProccessButton = styled.span`
  /* background-color: #228f6b; */
  background-color: #000;
  color: #fff;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  padding: 15px 10px;
`;
