import { UseByUserFiliais } from 'hooks/useFiliais/useByRoleFiliais';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

interface IFilterByFilialCompanyParams {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  codCompany: number;
  codUser: number;
}

export const FilterByFilialCompany = ({ control, setValue, codCompany, codUser }: IFilterByFilialCompanyParams) => {
  const { optionsFiliais, refetchFiliais } = UseByUserFiliais({ codEmpresa: codCompany, codUser: codUser });

  useEffect(() => {
    refetchFiliais();
  }, [codUser, codCompany]);

  return (
    <>
      <Controller
        name="codFilial"
        rules={{
          required: true,
        }}
        control={control}
        render={({ field }) => (
          <Dropdown
            placeholder="Selecione uma Filial"
            value={field.value}
            options={optionsFiliais}
            onChange={(e) => setValue('codFilial', e.value)}
            emptyMessage="Nenhuma Filial Encontrada :/"
            filter
          />
        )}
      />
    </>
  );
};
