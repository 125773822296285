import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  z-index: 80;
  height: 100px;
  background-color: #f5f8fa;

  @media (max-width: 768px) {
    padding-right: 20px;
  }
`;

export const Container = styled.div`
  @media (max-width: 768px) {
    padding: 10px 0px 40px;
  }
`;

export const ContainerSQL = styled.div`
  margin-top: 100px;
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const BoxButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: end;
  justify-content: end;
`;
