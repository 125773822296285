import styled, { css } from 'styled-components';

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

interface ItemRowContentProps {
  width?: string;
}

export const ItemRowContent = styled.div<ItemRowContentProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '47.5%')};
  padding: 10px 0px;
`;

export const Form = styled.form``;

interface InputProps {
  error?: boolean;
}

export const DescriptionInput = styled.small<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const TitleInput = styled.label<InputProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const ModalButtonsBox = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

export const ModalCliName = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;
