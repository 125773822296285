import { IPonto } from 'client/interfaces/ponto.interface';
import * as S from './styles';

interface IListClients {
  item: IPonto;
  index: number;
  isWebScreen: boolean;
  selectedCard: any;
  handleCardSelect: (card: any) => void;
}

export const ListPoints = ({ item, index, isWebScreen, selectedCard, handleCardSelect }: IListClients) => {
  return (
    <S.Box key={index}>
      <input type="radio" name="card" checked={selectedCard === item} onChange={() => handleCardSelect(item)} />
      <div style={{ width: isWebScreen && '180px' }}>
        <h1>Descrição</h1>
        <span>{item.descricao ? item.descricao : '-'}</span>
      </div>
      <S.Separator />
      <div style={{ width: isWebScreen && '50px' }}>
        <h1>Casa</h1>
        <span>{item.casa ? item.casa : '-'}</span>
      </div>
      <S.Separator />
      <div style={{ width: isWebScreen && '100px' }}>
        <h1>Cód. empresa</h1>
        <span>{item.codEmpresa ? item.codEmpresa : '-'}</span>
      </div>

      <S.Separator />
      <div style={{ width: isWebScreen && '130px' }}>
        <h1>Cód. vendedor</h1>
        <span>{item.codVendedor ? item.codVendedor : '-'}</span>
      </div>

      <S.Separator />
      <div style={{ width: isWebScreen && '130px' }}>
        <h1>Cód. ponto</h1>
        <span>{item.codPonto ? item.codPonto : '-'}</span>
      </div>
    </S.Box>
  );
};
