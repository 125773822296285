import styled from 'styled-components';

export const AnaliseTableMainBox = styled.section`
  width: 100%;
  height: 90%;
  border: 1px solid #60606020;
  background-color: #fff;
  padding: 5px;
`;

export const DataCardsList = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
`;

type IStatus = {
  status: 'L' | 'A' | 'R';
  selected?: boolean;
};

export const DataCard = styled.div<IStatus>`
  position: relative;
  width: 100%;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.27) 0px 2px 8px 0px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  border-left: 5px solid
    ${(props) => (props.status === 'L' ? 'rgb(35 159 88 / 84%)' : props.status === 'A' ? '#FFA500' : 'rgb(231 76 60)')};
  background-color: ${(props) =>
    props.status === 'L'
      ? 'rgba(35, 159, 88, .15) !important'
      : props.status === 'A'
      ? '#FFA50015 !important'
      : 'rgba(231, 76, 60, .15) !important'};

  background-color: ${(props) =>
    props.status === 'L' && props.selected
      ? 'rgba(35, 159, 88, .25) !important'
      : props.status === 'A' && props.selected
      ? '#FFA50025 !important'
      : props.selected
      ? 'rgba(231, 76, 60, .25) !important'
      : ''};

  &:hover {
    background-color: ${(props) =>
      props.status === 'L'
        ? 'rgba(35, 159, 88, .25) !important'
        : props.status === 'A'
        ? '#FFA50025 !important'
        : 'rgba(231, 76, 60, .25) !important'};
  }
`;

export const CardTitle = styled.span`
  width: 100%;
  font-size: 0.9rem;
`;
export const CardVendedor = styled.span`
  width: 100%;
  white-space: nowrap;
  font-size: 0.9rem;
`;

export const CardDistancia = styled.span`
  width: 100%;
  white-space: nowrap;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const CliCardContent = styled.span<IStatus>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  font-size: 0.8rem;

  span {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.8rem;
  }
  svg {
    border-radius: 50%;
    padding: 5px;
    font-size: 1.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color: ${(props) =>
      props.status === 'L' ? 'rgb(35 159 88 / 84%)' : props.status === 'A' ? 'rgb(255 120 0)' : 'rgb(231 76 60)'};
  }
`;

export const CardContent = styled.span<IStatus>`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.8rem;
  svg {
    border-radius: 50%;
    padding: 2.5px;
    font-size: 1.2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color: ${(props) =>
      props.status === 'L' ? 'rgb(35 159 88 / 84%)' : props.status === 'A' ? 'rgb(255 120 0)' : 'rgb(231 76 60)'};
  }
`;

export const CardSubTitle = styled.span<IStatus>`
  position: absolute;
  right: 15px;
  bottom: 5px;
  font-size: 1rem;
  font-weight: bolder;
  color: ${(props) =>
    props.status === 'L' ? 'rgb(35 159 88 / 84%)' : props.status === 'A' ? '#FFA500' : 'rgb(231 76 60)'};
`;
