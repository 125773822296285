import { InputText } from 'primereact/inputtext';
import * as S from './regrasHeader.styles';
import { Dropdown } from 'primereact/dropdown';
import VmButton from 'components/VmButton/VmButton';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { useEffect, useState } from 'react';
import { RegrasHeaderParams, SearchRegrasParams, StatusFilterOptionsProps } from 'client/interfaces';
import NovaRegraModal from '../novaRegraModal/novaRegraModal.index';

export default function RegrasHeader({
  searchRegrasParams,
  setSearchRegrasParams,
  setSelectedEmpr,
  handleGetRegrasComerciais
}: RegrasHeaderParams) {
  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();

  useEffect(() => {
    setSelectedEmpr(selectedEmpresa);
  }, [selectedEmpresa]);

  const statusFilterOptions: StatusFilterOptionsProps[] = [
    { name: 'Todas', code: 'T' },
    { name: 'Ativas', code: 'S' },
    { name: 'Inativas', code: 'N' },
  ];

  const [showNovaRegraModal, setShowNovaRegraModal] = useState<boolean>(false);

  return (
    <S.RegrasHeaderMainBox>

      <S.RegrasHeaderRow>
        <SelectEmpresa />
        <S.RegraInputBox>
          <label htmlFor="report">Regra</label>
          <InputText
            id="rule"
            placeholder="Buscar pelo código ou nome da regra"
            style={{
              width: 'clamp(200px, 250px, 300px)',
            }}
            value={searchRegrasParams.text}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase();
              setSearchRegrasParams((prevState: SearchRegrasParams) => {
                return {
                  ...prevState,
                  text: e.target.value,
                };
              });
            }}
          />
        </S.RegraInputBox>
        <S.RegraDateInputBox>
          <label>Data Inicial</label>

          <input
            type={'date'}
            id={'dateStart'}
            value={searchRegrasParams.dtIni}
            onChange={(e) => {
              setSearchRegrasParams((prevState: SearchRegrasParams) => {
                return {
                  ...prevState,
                  dtIni: e.target.value,
                };
              });
            }}
          />
        </S.RegraDateInputBox>
        <S.RegraDateInputBox>
          <label>Data Final</label>
          <input
            type={'date'}
            id={'dateEnd'}
            value={searchRegrasParams.dtFim}
            onChange={(e) => {
              setSearchRegrasParams((prevState: SearchRegrasParams) => {
                return {
                  ...prevState,
                  dtFim: e.target.value,
                };
              });
            }}
          />
        </S.RegraDateInputBox>
        <S.RegraInputBox>
          <label htmlFor="report">Listar Regras</label>
          <Dropdown
            value={
              statusFilterOptions.filter(
                (options: StatusFilterOptionsProps) => searchRegrasParams.status == options.code,
              )[0]
            }
            options={statusFilterOptions}
            emptyMessage="Nenhum Status :/"
            optionLabel={`name`}
            onChange={(e) => {
              setSearchRegrasParams((prevState: SearchRegrasParams) => {
                return {
                  ...prevState,
                  status: e.value.code,
                };
              });
            }}
          />
        </S.RegraInputBox>
        <VmButton
          options={{
            label: '+ Regra',
            style: 'btn-success',
            size: 'btn-md',
          }}
          onClick={() => setShowNovaRegraModal(true)}
        />
      </S.RegrasHeaderRow>
      <NovaRegraModal
        showNovaRegraModal={showNovaRegraModal}
        setShowNovaRegraModal={setShowNovaRegraModal}
        codEmpresa={selectedEmpresa.codEmpresa}
        handleGetRegrasComerciais={handleGetRegrasComerciais}
      />
    </S.RegrasHeaderMainBox>
  );
}
