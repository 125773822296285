import styled from 'styled-components';

export const ProdListMainBox = styled.section`
  width: 100%;
  max-width: 95dvw;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 2px 0px;
  border-radius: 5px;
  max-height: 62dvh;
  overflow: scroll;

  @media (max-width: 1024px) {
    max-height: none;
    margin-bottom: 30px;
  }
`;

export const ProdListTitle = styled.div`
  color: #228f6b;
  font-weight: thin;
  font-size: 1.1rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9;
  width: 100%;
  padding: 10px;
`;

export const ProdListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 0 10px;
`;

export const ProdCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 5px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px 0px;
  }
`;

export const ProdImage = styled.div`
  width: 50px;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 50px;
  }
`;

export const ProdDesc = styled.span`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  width: 80%;
  height: 100%;
`;

export const ProdPedDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  width: 50px;
`;

export const ProdPedQtd = styled.span`
  background-color: #dff8fd;
  color: #154d57;
  padding: 0px 5px;
  border-radius: 20px;
  white-space: nowrap;
`;
