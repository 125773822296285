import ContainerPage from 'components/ContainerPage';
import { ParametrosTable } from 'modules/parametros/components';

const ParametrosPage = () => (
  <ContainerPage>
    <ParametrosTable />
  </ContainerPage>
);

export default ParametrosPage;
