import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getFotos, getProdutos } from 'client/api/produto';
import { FotoProduto, ProdutoProps } from 'client/interfaces';

export const useFotos = (enabled = true) =>
  useQuery<FotoProduto[], AxiosError>(['foto-produtos'], getFotos, {
    staleTime: 1000 * 60 * 1,
    enabled,
  });

export const useProdutos = (codEmpresa: number, enabled = true) =>
  useQuery<ProdutoProps[], AxiosError>(['produtos', codEmpresa], async () => await getProdutos(codEmpresa), {
    enabled,
  });
