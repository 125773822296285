import styled from 'styled-components';

export const ContainerPage = styled.div`
  padding: 40px;
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
