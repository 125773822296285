import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { useState } from 'react';
import { BsArrowClockwise, BsPlusLg, BsSearch } from 'react-icons/bs';

import imageNotFound from 'assets/images/image-not-found.png';
import { useFotos } from 'client/hooks';
import { FotoProduto, NewFotoProduto } from 'client/interfaces';
import ButtonComponent from 'components/Button';
import ClickIcon from 'components/ClickIcon';
import Input from 'components/Input';
import InputData from 'components/InputData';
import { NewPhotoDialog } from 'modules/controleFotoProduto/components';
import { BASE_URL } from 'services/api';
import { toLocalizedDatetime } from 'utils/datetime';

import * as S from './styles';

const actionBodyTemplate = (handleEdit: () => void) => {
  return <Button icon="pi pi-pencil" tooltip="Editar" onClick={handleEdit} className="p-button-text p-button-plain" />;
};

const imageBodyTemplate = (rowData: FotoProduto) => (
  <S.BoxImage>
    <img
      key={rowData.arquivo}
      src={`${BASE_URL}/foto_produtos/${rowData.fotoType === 0 ? '' : `${rowData.codEmpresa}%23`}${rowData.arquivo}`}
      onError={(e) => (e.currentTarget.src = imageNotFound)}
      alt={rowData.nome}
    />
  </S.BoxImage>
);

const PhotoMasterPage = () => {
  const { data: fotos, isLoading, refetch } = useFotos();
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    codProd: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    nome: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    descricao: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    dtAlteracao: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    dtCadastro: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    possuiFoto: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [photo, setPhoto] = useState<NewFotoProduto | null>(null);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = () => (
    <S.HeaderTable>
      <h5 className="m-0">Produtos</h5>
      <S.ContainerInput mobile>
        <Input
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Pesquisar..."
          iconSvg={<BsSearch />}
        />

        <ClickIcon
          icon={<BsArrowClockwise />}
          variant="secondary"
          onClick={async () => await refetch()}
          tooltip="Recarregar"
          style={{ marginLeft: '5px' }}
        />
      </S.ContainerInput>
    </S.HeaderTable>
  );

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => (
    <InputData
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );

  const openNew = () => {
    setPhoto({
      codProd: '',
      descricao: '',
      dtAlteracao: null,
      dtCadastro: null,
      nome: '',
      foto: '',
      arquivo: '',
      fotoType: 0,
      codEmpresa: 0,
    });
    setIsDialogOpen(true);
  };

  const handleEdit = (rowData: FotoProduto) => {
    setPhoto({ ...rowData, foto: '' });
    setIsDialogOpen(true);
  };

  const leftToolbarTemplate = () => <ButtonComponent icon={<BsPlusLg />} text="Adicionar" onClick={openNew} />;

  return (
    <div>
      <Toolbar className="mb-4 mt-4" left={leftToolbarTemplate} />

      <S.ContainerTable mobile tablet>
        <DataTable
          value={fotos}
          header={header}
          dataKey="codProd"
          paginator
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          rowHover
          filters={filters}
          filterDisplay="menu"
          loading={isLoading}
          globalFilterFields={['nome', 'descricao', 'codProd', 'possuiFoto', 'dtAlteracao', 'dtCadastro']}
          emptyMessage="Nenhuma foto cadastrada."
          currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
        >
          <Column field="codProd" header="Foto" body={imageBodyTemplate} />
          <Column field="codProd" header="Código" sortable filter filterPlaceholder="Pesquisar por código" />
          <Column field="nome" header="Nome" sortable filter filterPlaceholder="Pesquisar por nome" />
          <Column
            field="dtAlteracao"
            header="Data Alteração"
            sortable
            filterField="date"
            dataType="date"
            filter
            body={(rowData: FotoProduto) => rowData.dtAlteracao != null && toLocalizedDatetime(rowData.dtAlteracao)}
            filterElement={dateFilterTemplate}
          />
          <Column
            field="dtCadastro"
            header="Data Cadastro"
            sortable
            filterField="date"
            dataType="date"
            filter
            body={(rowData: FotoProduto) => toLocalizedDatetime(rowData.dtCadastro)}
            filterElement={dateFilterTemplate}
          />
          <Column
            field="fotoType"
            header="Tipo"
            sortable
            filterField="fotoType"
            dataType="numeric"
            filter
            body={(rowData: FotoProduto) => (rowData.fotoType === 0 ? 'EAN' : 'Específico')}
          />
          <Column body={(rowData: FotoProduto) => actionBodyTemplate(() => handleEdit(rowData))} />
        </DataTable>
      </S.ContainerTable>

      {isDialogOpen && photo && (
        <NewPhotoDialog photo={photo} isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
      )}
    </div>
  );
};

export default PhotoMasterPage;
