import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { BlockUI } from 'primereact/blockui';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BsCheckLg } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { saveMapa } from 'client/api';
import { getGroupClient } from 'client/api/groupClients';
import { useCidades, useEstados } from 'client/hooks/mapa';
import { Mapa } from 'client/interfaces';
import { Option, Select } from 'components/Select/styles';
import VmButton from 'components/VmButton/VmButton';
import { UseFiliaisSelect } from 'hooks/useFiliais/useFiliaisSelect';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

import UseMapRouterDaySelector from '../../components/mapRouterDaySelector/mapRouterDaySelector';

import * as S from './style';

const CadastroMapaForm = () => {
  const { codEmpresa: codEmpresaParam } = useParams<{ codEmpresa: string }>();
  const [isSaving, setIsSaving] = useState(false);

  const { Selectdays, daySelection, handleDataStrucuteAdjust } = UseMapRouterDaySelector();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, dirtyFields },
    getFieldState,
  } = useForm<Mapa>();

  const { setTitlePage } = useTitlePageStore();

  const { data: estados, isFetching: isLoadingEstados } = useEstados();
  const { optionsFiliais, handleSelectChange, selectedOption } = UseFiliaisSelect({
    codEmpresa: Number(codEmpresaParam),
  });

  const { data: cidades, isFetching: isLoadingCidades } = useCidades(
    watch().estados?.map((e) => e.coduf) ?? [],
    !!watch().estados,
  );

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [gruposCli, setGruposCli] = useState<GruposMapa[]>([]);

  const onSubmit = async (data: Mapa) => {
    try {
      data.grupos &&
        data.grupos.map((group: any) => {
          const stateGroup = gruposCli.filter((g: any) => g.codgrupo == group.codgrupo)[0];
          group.tempoMinimoVisita = +stateGroup.tempoMinimoVisita ?? 0;
          return group;
        });

      data = handleDataStrucuteAdjust(data, 'backwords');

      const bodySaveMap = {
        ...data,
        codFilial: Number(selectedOption),
        codEmpresa: Number(codEmpresaParam),
      };

      setIsSaving(true);

      await saveMapa(bodySaveMap).then(() => {
        toast.success('Mapa criado com sucesso!');
      });

      await queryClient.invalidateQueries(['mapas']);
      navigate('/mapa');
    } catch (e) {
      toast.error(e.message ? e.message : 'Falha ao criar Mapa!');
    } finally {
      setIsSaving(false);
    }
  };

  interface GruposMapa {
    codmapa: number;
    codempresa: number;
    codgrupo: number;
    codfilial: number;
    nomeGrupo: string;
    tempoMinimoVisita: string;
    label: string;
  }

  useEffect(() => {
    setTitlePage('Novo Mapa');
    setValue('ativo', true);
    getGroupClient(codEmpresaParam).then((res: any) => {
      let groupList: GruposMapa[] = [];
      for (let i = 0; i < res.length; i++) {
        let group = res[i];
        group.tempoMinimoVisita = 0;
        group.codmapa = 0;
        group.label = `${group.codgrupo} - ${group.nomeGrupo}`;
        groupList.push(group);
      }
      setGruposCli(groupList);
    });
  }, []);

  const grupoTemplate = (options: GruposMapa) => {
    return (
      <S.GroupTemplate>
        <S.GrupoTitle>{`${options.codgrupo} ${options.nomeGrupo}`}</S.GrupoTitle>
        <S.GrupoTimeInputBox>
          <InputText
            placeholder="Tempo Mínimo (min)"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputSelectionChange(e, options);
              options.tempoMinimoVisita = e.target.value;
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
            defaultValue={options.tempoMinimoVisita}
            onClick={(e) => e.stopPropagation()}
          />
          <label>Tempo Mínimo Visita</label>
        </S.GrupoTimeInputBox>
      </S.GroupTemplate>
    );
  };

  const handleInputSelectionChange = (e: React.ChangeEvent<HTMLInputElement>, option: GruposMapa) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    option.tempoMinimoVisita = e.target.value;
    const gruposList = gruposCli;
    gruposList.map((group: any) => {
      if (group.codgrupo == option.codgrupo) {
        group.tempoMinimoVisita = option.tempoMinimoVisita;
      }
    });
    setGruposCli(gruposList);
  };

  return (
    <div className="m-10 ">
      <BlockUI containerStyle={{ zIndex: 0}} blocked={isLoadingEstados || isLoadingCidades} template={<ProgressSpinner />}>
        <form className="form w-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="fv-row mb-5 w-100 d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">* Nome</label>
              <input
                {...register('nome', { required: 'Obrigatório' })}
                placeholder="Nome do Mapa"
                className={clsx('form-control form-control-lg', {
                  'is-invalid': (dirtyFields.nome ?? false) && errors.nome,
                })}
                autoFocus
                maxLength={300}
                type="text"
                name="nome"
              />
              {errors.nome != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.nome.message}</span>
                </div>
              )}
            </div>

            <div style={{ width: '45%' }}>
              {
                <>
                  <label className="form-label fs-6 fw-bolder text-dark">Cód Empresa</label>

                  <input
                    {...register('codEmpresa')}
                    placeholder="Cód. Empresa"
                    className={clsx('form-control form-control-lg', {
                      'is-invalid': (dirtyFields.codEmpresa ?? false) && errors.codEmpresa,
                    })}
                    maxLength={8}
                    type="text"
                    name="codEmpresa"
                    disabled={true}
                    value={codEmpresaParam}
                  />
                </>
              }
              {errors.codEmpresa != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.codEmpresa.message}</span>
                </div>
              )}
            </div>
          </div>

          <div className="fv-row mb-5 d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ width: '45%', flexDirection: 'column', display: 'flex' }}>
              <label className="form-label fs-6 fw-bolder text-dark">*Filial</label>

              <Select
                {...register('codFilial', { required: 'Obrigatório' })}
                onChange={handleSelectChange}
                value={selectedOption}
                placeholder="teste"
              >
                <Option defaultChecked>Selecione uma filial</Option>
                {optionsFiliais?.map((filial, index) => (
                  <Option key={index} value={filial.value}>
                    {filial.label + ' [' + filial.value + ']'}
                  </Option>
                ))}
              </Select>

              {errors.codFilial != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.codFilial.message}</span>
                </div>
              )}
            </div>
            <div style={{ width: '45%', textAlign: 'left', justifyContent: 'start' }}>
              <div>
                <label className="form-label fs-6 fw-bolder text-dark">Ativo</label>
              </div>
              <Controller
                control={control}
                name="ativo"
                render={({ field: { onChange, value } }) => <InputSwitch checked={value} onChange={onChange} />}
              />
            </div>
          </div>

          <div className="fv-row mb-5 d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">*Tempo Mínimo Visita</label>
              <input
                {...register('tempoMinimo', { required: 'Obrigatório' })}
                placeholder="Tempo Mínimo Visita"
                className="form-control form-control-lg"
                type="number"
                name="tempoMinimo"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // handleGroupMinimalTimeValue(e.target.value);
                }}
              />
              {errors.tempoMinimo != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.tempoMinimo.message}</span>
                </div>
              )}
            </div>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">*Frequência Visita</label>
              <input
                {...register('frequencia', { required: 'Obrigatório' })}
                placeholder="Frequência Visita"
                className="form-control form-control-lg"
                type="number"
                name="frequencia"
              />
              {errors.frequencia != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.frequencia.message}</span>
                </div>
              )}
            </div>
          </div>

          <div className="fv-row mb-5 d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">Clientes a Excluir</label>
              <input
                {...register('notInCli')}
                placeholder="Excluir clientes específicos do mapa"
                className="form-control form-control-lg"
                type="text"
                name="notInCli"
              />
            </div>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">Clientes a Incluir</label>
              <input
                {...register('inCli')}
                placeholder="Incluir clientes específicos no mapa"
                className="form-control form-control-lg"
                type="text"
                name="inCli"
              />
            </div>
          </div>

          <S.MapFormRow>
            <div style={{ width: '45%' }}>
              <div>
                <label className="form-label fs-6 fw-bolder text-dark">Estados</label>
              </div>
              <Controller
                control={control}
                name="estados"
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    className="form-control form-control-lg"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      setValue('cidades', []);
                    }}
                    required
                    options={estados ?? []}
                    optionLabel="nomeUf"
                    placeholder="Estados"
                    display="chip"
                    filter
                    filterBy="nomeUf"
                  />
                )}
              />
            </div>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">Dia/Turno da Roteirização</label>
              <Selectdays />
            </div>
          </S.MapFormRow>

          <div className="fv-row mb-5">
            <div>
              <label className="form-label fs-6 fw-bolder text-dark">Cidades</label>
            </div>
            <Controller
              control={control}
              name="cidades"
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  className="form-control form-control-lg"
                  value={value}
                  onChange={onChange}
                  options={cidades ?? []}
                  optionLabel="cidade"
                  placeholder="Cidades"
                  display="chip"
                  filter
                  filterBy="cidade"
                  dataKey="codcidade"
                />
              )}
            />
          </div>

          <div className="fv-row mb-5">
            <div>
              <label className="form-label fs-6 fw-bolder text-dark">Grupos</label>
            </div>
            <Controller
              control={control}
              name="grupos"
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  className="form-control form-control-lg"
                  value={value}
                  onChange={onChange}
                  options={gruposCli ?? []}
                  optionLabel="label"
                  placeholder="Grupos"
                  display="chip"
                  itemTemplate={grupoTemplate}
                  filter
                  filterBy="nome"
                  dataKey="codgrupo"
                />
              )}
            />
          </div>

          <VmButton
            options={{
              label: 'Salvar',
              icon: <BsCheckLg />,
              iconPosition: 'left',
              style: 'btn-success',
              loading: isSaving,
            }}
            style={{ marginTop: '10px' }}
            type="submit"
          />
        </form>
      </BlockUI>
    </div>
  );
};

export default CadastroMapaForm;
