import ContainerPage from 'components/ContainerPage';

import { FormularioRespostaTable } from '../components';

const FormularioRespostaPage = () => {
  return (
    <ContainerPage>
      <FormularioRespostaTable />
    </ContainerPage>
  );
};

export default FormularioRespostaPage;
