import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { TabPanel, TabView } from 'primereact/tabview';

import { SqlCommandResult } from 'client/interfaces';
import { useAppSelector } from 'store';

import { errorSelector, returnedValueSelector } from '../reducer/vmaissql';

const SqlResultContainer = () => {
  const returnedValue = useAppSelector(returnedValueSelector);
  const error = useAppSelector(errorSelector);
  const parsedValue: SqlCommandResult[] | null = returnedValue != null ? JSON.parse(returnedValue) : null;

  const dynamicColumns =
    parsedValue != null && parsedValue.length > 0
      ? Object.keys(parsedValue[0]).map((col) => {
          return <Column key={col} field={col} header={col} sortable />;
        })
      : null;

  const handleClickSaveJson = () => {
    if (returnedValue == null) return;
    const file = new Blob([returnedValue], { type: 'text/plain;charset=utf-8' });
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = 'retorno.json';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };

  return (
    <div style={{ minHeight: '400px' }}>
      <TabView>
        <TabPanel header="Grid">
          {error != null ? (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{`${error as string}`}</span>
            </div>
          ) : (
            <DataTable
              value={parsedValue ?? []}
              stripedRows
              emptyMessage="Nenhum valor"
              responsiveLayout="scroll"
              {...(returnedValue != null && {
                paginator: true,
                paginatorTemplate:
                  'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
                currentPageReportTemplate: 'Exibindo {first} até {last} de {totalRecords}',
                rows: 10,
                rowsPerPageOptions: [10, 20, 50],
              })}
            >
              {dynamicColumns}
            </DataTable>
          )}
        </TabPanel>
        <TabPanel header="Json">
          <>
            <textarea
              value={returnedValue?.toString() ?? `${error as string}`}
              readOnly
              className="form-control-plaintext border border-2 mb-2"
              style={{ minHeight: '300px' }}
            />
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Button
                disabled={returnedValue == null}
                label="Salvar Json"
                icon="pi pi-file"
                onClick={handleClickSaveJson}
                style={{
                  backgroundColor: '#228F6B',
                  width: '150px',
                  marginLeft: '10px',
                  border: 'none'
                }}
              />
            </div>
          </>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default SqlResultContainer;
