import * as S from './styles';

import { Dialog } from 'primereact/dialog';

import useMediaQuery from 'hooks/useMediaQuery';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { Column } from 'primereact/column';
import { FaHistory } from 'react-icons/fa';

export default function RouterHistModal({ showModal, setShowModal, compPositions, cli }: any) {
  const isWebScreen = useMediaQuery('(min-width: 1024px)');

  const visitList = compPositions.filter((visit: any) => visit.codCli == cli.codCli && visit.estaEmRota == 'S');
  visitList.sort((a: any, b: any) => {
    const dateA = new Date(a.dtProxVisita);
    const dateB = new Date(b.dtProxVisita);
    return dateA.getTime() - dateB.getTime();
  });

  return (
    <Dialog
      visible={showModal}
      onHide={() => setShowModal(false)}
      style={{ width: isWebScreen ? '30vw' : '40vw', height: isWebScreen ? '590px' : '400px' }}
      closable
      header={() => {
        return (
          <S.ModalHeader>
            <FaHistory />
            <h1>Histórico de Visitas</h1>
          </S.ModalHeader>
        );
      }}
    >
      <S.ModalBody>
        <S.ModalTitleBox>
          <b>CLIENTE: </b>
          {visitList[0] ? `[${visitList[0].codCli}] ${visitList[0].nomeCli}` : ''}
        </S.ModalTitleBox>
        <S.ModalTableBox>
          <DataTable
            value={visitList}
            tableStyle={{ width: '100%' }}
            rowHover
            scrollable
            scrollHeight={isWebScreen ? '450px' : '400px'}
            emptyMessage="Nenhum dado encontrado..."
          >
            <Column
              field="dtProxVisita"
              header="DT. ROTA"
              body={(row) => {
                return <span>{`${new Date(row.dtProxVisita).toLocaleDateString('pt-BR')} - ${row.diaSemana}`}</span>;
              }}
              sortable
            />
            <Column
              field="visitado"
              header="VISITADO"
              body={(row) => {
                return (
                  <S.IconTable>
                    <i
                      className={classNames('pi', {
                        'true-icon pi-check-circle text-success':
                          new Date(row.dtProxVisita) <= new Date() && row.visitado && row.visitado == 'S',
                        'false-icon pi-times-circle text-danger':
                          new Date(row.dtProxVisita) <= new Date() && row.visitado && row.visitado == 'N',
                      })}
                    />
                  </S.IconTable>
                );
              }}
            />
          </DataTable>
        </S.ModalTableBox>
      </S.ModalBody>
    </Dialog>
  );
}
