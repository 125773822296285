import { useUsers } from 'client/hooks';

export const UseUsers = (codCompany: number) => {
  const { data: usersCompany, refetch: refetchUsersCompany } = useUsers(codCompany);

  const optionsUsersCompany = usersCompany?.map((item) => ({
    value: item.codUsuario,
    label: `${item.nome} - [${item.codUsuario}]`,
  }));

  return { optionsUsersCompany, refetchUsersCompany };
};
