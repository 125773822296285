import {
  ICreatePoly,
  IEditPendPolyData,
  IEditPolyData,
  IRemovePoly,
  IToAdjustCliCoords,
  IToCreateCliSetorVinculo,
  IToRemoveCliSetorVinculo,
} from 'client/interfaces';
import api from 'services/api';

const _controller = 'AreaMapa';

export const removePoly = async (currentSetor: IRemovePoly): Promise<void> => {
  await api.delete(`/${_controller}`, { data: currentSetor });
};

export const editPoly = async (toEditPoly: IEditPolyData | IEditPendPolyData[] | [string]): Promise<void> => {
  await api.put(`/${_controller}`, toEditPoly);
};

export const createPoly = async (toCreatePoly: ICreatePoly): Promise<void> => {
  await api.post(`/${_controller}`, toCreatePoly);
};

export const createCliSetorVinculo = async (toCreateCliSetorVinculo: IToCreateCliSetorVinculo): Promise<void> => {
  await api.post(`/${_controller}/adicionaClienteSetor`, toCreateCliSetorVinculo);
};

export const postAdjustCliCoords = async (toAdjustCliCoords: IToAdjustCliCoords): Promise<void> => {
  await api.post(`/Setores/ajustaCoordenadas`, toAdjustCliCoords);
};

export const removeCliSetorVinculo = async (toRemoveCliSetorVinculo: IToRemoveCliSetorVinculo): Promise<void> => {
  await api.delete(`/${_controller}/removeClienteSetor`, { data: toRemoveCliSetorVinculo });
};
