import { IbgeCidade, IbgeEstado, Mapa, RegisterPoint, EditPoint } from 'client/interfaces';
import qs from 'qs';
import api from 'services/api';

const _controller = 'mapa';

export const getMapas = async (): Promise<Mapa[]> => {
  const { data } = await api.get(_controller);
  return data;
};

export const getMapa = async (id: number): Promise<Mapa> => {
  const { data } = await api.get(`${_controller}/${id}`);
  return data;
};

export const getEstados = async (codPais: number | undefined): Promise<IbgeEstado[]> => {
  const { data } = await api.get(`${_controller}/estados`, { params: { codPais } });
  return data;
};

export const getCidades = async (codUf: number[]): Promise<IbgeCidade[]> => {
  const { data } = await api.get(`${_controller}/cidades`, {
    params: { codUf },
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data;
};

export const saveMapa = async (mapa: Mapa): Promise<void> => {
  await api.post(_controller, mapa);
};

export const updateMapa = async (mapa: Mapa): Promise<void> => {
  await api.put(`${_controller}/${mapa.cod}`, mapa);
};

export const getMapasEmpresa = async (CodEmpresa: number): Promise<Mapa[]> => {
  const { data } = await api.get(`Mapa?CodEmpresa=${CodEmpresa.toString() == 'NaN' ? 0 : CodEmpresa}`);
  return data;
};

export const getMapsByUser = async (codCompany: number, codUser: number): Promise<Mapa[]> => {
  const { data } = await api.get(`Mapa/Setor/${codCompany}/${codUser}`);
  return data;
};

export const getVendedorAcompanhamento = async (
  codEmpresa: number,
  codGerente: string,
  codSupervisor: string,
  codVendedor: string,
  dtIni: string,
  dtFim: string,
): Promise<Array<any>> => {
  const url = `LocalizacaoVendedor/gps/${codEmpresa}/${codGerente}/${codSupervisor}/${codVendedor}/${dtIni}/${dtFim}`;
  const { data } = await api.get(url);
  return data;
};

export const getVendedorCheckinCheckout = async (
  codEmpresa: number,
  codVendedor: string,
  dtIni: string,
  dtFim: string,
): Promise<Array<any>> => {
  const url = `Setores/clientesVisita/${codEmpresa}/${codVendedor}/${dtIni}/${dtFim}`;
  const { data } = await api.get(url);
  return data;
};
