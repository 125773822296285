import { useEffect, useRef, useState } from 'react';

import style from './pedList.module.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { MdRefresh, MdSearch } from 'react-icons/md';

import { usePedido } from 'providers/pedidoProvider';
import axios from 'axios';
import VmButton from 'components/VmButton/VmButton';


//DEFINE COMPONETE COMO ARROW FUNCTION
export default function PedList({ cliente }) {
  const dtIniRef = useRef(null);
  const dtFimRef = useRef(null);

  const { numberFormat, pedTable, setPedTable, handlePedGridSearch }: any = usePedido();

  const [pedList, setPedList] = useState([]);

  const columnsList = [
    <Column key={'EMITENTE'} field="EMITENTE" header="EMITENTE" align="left" />,
    <Column key={'DATA'} field="DATA" header="DATA"/>,
    <Column key={'CODFILIAL'} field="CODFILIAL" header="FILIAL" align="left"/>,
    <Column key={'NUMPEDRCA'} field="NUMPEDRCA" header="NUMPED" align="left"/>,
    <Column key={'CODUSUR'} field="CODUSUR" header="RCA" align="left"/>,
    <Column key={'POSICAO'} field="POSICAO" header="POSIÇÃO" align="left"/>,
    <Column key={'VLTOTAL'} field="VLTOTAL" header="VALOR" align="left"/>,
    <Column key={'PRAZO'} field="PRAZO" header="PRAZO" align="left"/>,
    <Column key={'CODCOB'} field="CODCOB" header="CODCOB" align="left"/>,
    <Column key={'OBSERVACAO'} field="OBSERVACAO" header="OBSERVAÇÃO" align="left"/>,
  ];

  useEffect(() => {
    if (pedTable.length == 0) {
      setPedTable(columnsList);
    }
  }, []);

  useEffect(() => {
    if(cliente && cliente.CODCLI){
      handlePedSearch(undefined, undefined);
    }else if(pedList.length > 0){
      setPedList([])
    }
  }, [cliente]);

  const header = (
    <div className={style.tableHeader}>
      <span className={style.tableHeaderTitle}>Pedidos</span>
      <section className={style.pedSearchForm}>
        <div className={style.dtIniSearch}>
          <label htmlFor="nome"> Data Inicial </label>
          <input
            type="date"
            id="dtIni"
            name="dtIni"
            placeholder="Data inicial"
            ref={dtIniRef}
            onChange={(e) => {
              if (dtIniRef.current.value.length == 10 && dtFimRef.current.value.length == 10) {
                handlePedSearch(undefined, undefined);
              }
            }}
            defaultValue={new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              .toLocaleDateString()
              .split('/')
              .reverse()
              .join('-')}
          />
        </div>

        <div className={style.dtFimSearch}>
          <label htmlFor="nome"> Data Final </label>
          <input
            type="date"
            id="dtFim"
            name="dtFim"
            placeholder="Data final"
            ref={dtFimRef}
            onChange={(e) => {
              if (dtFimRef.current.value.length == 10) {
                handlePedSearch(undefined, undefined);
              }
            }}
            defaultValue={new Date().toLocaleDateString().split('/').reverse().join('-')}
          />
        </div>

        <div className={style.tablePedSearch}>
          <input
            type="text"
            id="numPed"
            name="numPed"
            placeholder="Pesquisar no grid..."
            onChange={(e) => handlePedGridSearch(e.target.value)}
          />
          <MdSearch />
        </div>

        <VmButton 
        options={{
          icon: <MdRefresh />,
          iconPosition: 'left',
        }}
        onClick={() => handlePedSearch(undefined, undefined)}
        />
      </section>
    </div>
  );


  const handlePedSearch = (dti: any, dtf: any) => {
    if (cliente == null) {
      return;
    }
    if ((dtIniRef.current == null || dtFimRef.current == null) && dti == undefined) {
      return;
    }
    if ((dtIniRef.current.value == '' || dtFimRef.current.value == '') && dtf == undefined) {
      return;
    }

    dti = dti == undefined ? dtIniRef.current.value.split('-').reverse().join('/') : dti;

    dtf = dtf == undefined ? dtFimRef.current.value.split('-').reverse().join('/') : dtf;

    if (dti != undefined && dtf != undefined) {
      dtIniRef.current.value = dti.split('/').reverse().join('-');
      dtFimRef.current.value = dtf.split('/').reverse().join('-');
    }

    handleCliPedSearch(cliente.CODCLI, dti, dtf);
  };


  
  async function handleCliPedSearch(codCli: any, dti: any, dtf: any) {

    setPedList([]);

    //BUSCA LISTA DE PEDIDOS DO CLIENTE NA API
    await axios({
      method: 'GET',
      url: 'http://10.122.65.57:29292/api/venda/pedidos',
      params: {
        codCli: codCli,
        dtIni: dti,
        dtFim: dtf,
      },
    })
      .then((res: any) => {

        if (res.data.length != 0) {
          let pedidos = [];
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].DATA = new Date(res.data[i].DATA).toLocaleDateString();
            res.data[i].VLTOTAL = 'R$' + numberFormat(res.data[i].VLTOTAL);
            pedidos.push(res.data[i]);
          }

          setPedList(pedidos);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const posicaoTemplate = (rowData: any) => {
    return rowData.POSICAO == 'C-CANCELADO'
      ? style.colorRed
      : rowData.POSICAO == 'L-LIBERADO'
      ? style.colorBlue
      : rowData.POSICAO == 'A-ANALISE' && style.colorYellow;
  };

  return (
    <div className={style.pedListMainBox}>
      <DataTable
        value={pedList}
        tableStyle={{ fontSize: '.8rem' }}
        size={'small'}
        paginator
        rows={10}
        header={header}
        scrollable
        emptyMessage="Nenhum pedido encontrado :/"
        height={'50vh'}
        rowClassName={posicaoTemplate}
      >
        {columnsList}
      </DataTable>
    </div>
  );
}
