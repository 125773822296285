import styled from 'styled-components';

export const RowItens = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FirstRow = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;


export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
