import { processRouterData } from 'client/api';

// EXPORTA FUNÇÃO POR PADRÃO
export async function postRouterData(codSetor: string, codVendedor: string, codEmpresa: string) {
  const toProcessData = {
    CodEmpresa: codEmpresa,
    codUsuario: codVendedor,
    CodSetor: codSetor,
    dataFimCalculo: new Date(),
    motivoExecutcao: 'Processado manualmente pelo roteirizador!',
  };
  return await processRouterData(toProcessData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}
