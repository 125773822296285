import styled from 'styled-components';

export const PcMainBox = styled.section`
width: 100%;
padding: 10px;
background-color: #fff;
box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 2px 0px;
border-radius: 5px;
`;

