import * as S from './styles';

import { Checkbox } from 'primereact/checkbox';

import { MapContext } from 'providers/map';
import { useContext, useMemo } from 'react';
import { TITLE_PAGE } from 'enums/titlePages';
import { useLocation } from 'react-router-dom';
import { modules } from 'modules/modules';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

export default function MarkersControl() {
  const { markersControler, setMarkersControler } = useContext(MapContext);
  const { pathname } = useLocation();
  const pageTitle = useMemo(() => modules.find((m) => m.to === pathname)?.nome ?? '', [pathname]);

  const { titlePage } = useTitlePageStore();

  if (!pageTitle) {
    switch (titlePage) {
      case TITLE_PAGE.SCREENWRITER:
        return (
          <S.ControlBox>
            <S.Option>
              <img src={`${process.env.REACT_APP_BASE_URL}/images/icon_maps/icon_cliente_agendado_router.png`} />
              <Checkbox
                onChange={(e) => {
                  setMarkersControler((prev: any) => {
                    return {
                      ...prev,
                      nextWeeks: !markersControler.nextWeeks,
                    };
                  });
                }}
                checked={markersControler.nextWeeks}
              />
              <label htmlFor="detailed">Agenda Prox Semanas</label>
            </S.Option>
            <S.Option>
              <img src={`${process.env.REACT_APP_BASE_URL}/images/icon_maps/icon_cliente_nao_router.png`} />
              <Checkbox
                onChange={() => {
                  setMarkersControler((prev: any) => {
                    return {
                      ...prev,
                      notInRoute: !markersControler.notInRoute,
                    };
                  });
                }}
                checked={markersControler.notInRoute}
              />
              <label htmlFor="checkinout">Não Roteirizado</label>
            </S.Option>
          </S.ControlBox>
        );
      default:
        return <></>;
    }
  }
}
