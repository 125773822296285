import { create } from 'zustand';

interface ISectorsClientsStore {
  selectedMap: any;
  setSelectedMap(selectedMap: any): void;
}

export const useSectorsClientStore = create<ISectorsClientsStore>()((set) => ({
  selectedMap: null,
  setSelectedMap: (selectedMap) => set(() => ({ selectedMap })),
}));
