// DEFINE SVG COMO STRING
const svg: string = `<svg xmlns="http://www.w3.org/2000/svg" height="50" width="50" viewBox="0 0 50 50" className="map-label-ini">
      <circle cx="18" cy="18" r="15" stroke="#bfcde0" stroke-width="3" fill="#000000" opacity="0.7" />
      <svg
        x="9"
        y="9"
        stroke="#FFF"
        fill="#FFF"
        stroke-width="0"
        viewBox="0 0 16 16"
        height="17px"
        width="17px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"></path>
      </svg>
    </svg>`;

export default svg;
