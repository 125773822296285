
import * as S from './styles';

import { useEffect, useRef } from 'react';

import axios from 'axios';

import useMask from 'providers/maskProvider';

import {usePedido} from 'providers/pedidoProvider';

import { BsFillFilePersonFill } from 'react-icons/bs';

export default function CliSearch() {

  const {cliData, setCliData}: any = usePedido();

  const clientInputRef = useRef(null);
  
  useEffect(() => {
    const cliInput = document.getElementById('cliSearchInput');
    cliInput && cliInput.focus();
  }, [])

  const getCliData = (codCli: string) => {

    axios({
      url: 'http://10.122.65.57:29292/api/venda/cliente',
      method: 'GET',
      params: {
        codCli: codCli.replace(/\D/g, '') ?? null,
      },
    })
      .then((res: any) => {
      if(res.data.length > 0){
        setCliData(res.data[0]);
      }
      })
      .catch((err) => {
        console.log('Error: ' + err.name + ' - ' + err.code + '');
      });

  }

  return (
    <S.CliSearchMainBox>
      <S.CliSearchInput>
        <label>Cliente:</label>
        <BsFillFilePersonFill />
        <input type={'text'} placeholder="COD / CPF / CNPJ" id={'cliSearchInput'}
        ref={clientInputRef}
      onKeyDown={(e: any) => {
        if(e.key == 'Enter'){
          getCliData(e.target.value)
        }
      }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.target.value = useMask.maskToCnpjOrCpf(e.target.value);
          if(cliData && (e.target.value != cliData.CODCLI && e.target.value != cliData.CGCENT)){
            setCliData(null);
          }
        }}/>
      </S.CliSearchInput>
    </S.CliSearchMainBox>
  );
}
