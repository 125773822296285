import styled from 'styled-components';

export const Box = styled.div`
  width: 100%;
  height: 60px;
  background-color: #f5f8fa;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 500px;
    justify-content: flex-start;
    align-items: start;
    padding: 0px;
    margin-bottom: 10px;
  }

  div {
    display: flex;
    height: 60px;
    flex-direction: column;
    margin-left: 1rem;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: left;

    @media (max-width: 768px) {
      height: 0px;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      margin-left: 0px;
      margin-top: 5px;
    }

    h1 {
      font-weight: bold;
      font-size: 14px;
    }

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export const Separator = styled.div`
  background-color: white;
  height: 60px;
  width: 1px;
  @media (max-width: 768px) {
    height: 0px;
  }
`;
