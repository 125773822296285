import styled from 'styled-components';

export const ImportCliModalBodyBox = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ImportCliModalButtonsBox = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const ImportCliModalFileBox = styled.section`
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  input {
    display: none;
  }
`;

export const ImportCliModalInstructionsBox = styled.section`
  width: 100%;
`;

export const modalHeader = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const ImportCliModalSampleFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  span {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    color: #606060;
    color: blue;
    border-bottom: 1px solid blue;
    text-align: center;
    cursor: pointer;
    font-size: 1.25rem;
  }
  span:hover {
    color: blue;
  }
`;
