import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getSetores, getSetoresByRole, getSetoresByUser, getSupervisores } from 'client/api';
import { Isetores, Isupervisores } from 'client/interfaces';

export const useSetores = (codMapa: number | undefined, enabled = true) =>
  useQuery<Isetores[], AxiosError>(['setores', codMapa], async () => await getSetores(codMapa), {
    enabled,
  });

export const useSetoresByRole = (codEmpresa: number, codUser: number, codMapa: number, enabled = true) =>
  useQuery<Isetores[], AxiosError>(
    ['setores-byRole', codUser, codMapa],
    async () => await getSetoresByRole(codEmpresa, codUser, codMapa),
    {
      enabled,
    },
  );

export const useSetoresByUser = (codEmpresa: number, codUser: number, codMapa: number, enabled = true) =>
  useQuery<number[], AxiosError>(
    ['setores-byUser', codUser, codMapa],
    async () => await getSetoresByUser(codEmpresa, codUser, codMapa),
    {
      enabled,
    },
  );

export const useSupervisores = (codEmpresa: number, enabled = true) =>
  useQuery<Isupervisores[], AxiosError>(['supervisores', codEmpresa], async () => await getSupervisores(codEmpresa), {
    enabled,
  });
