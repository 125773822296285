import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import { useComandosSql } from 'client/hooks';
import { ComandoSql } from 'client/interfaces';
import useMediaQuery from 'hooks/useMediaQuery';

import * as S from './styles';
import { Container } from './styles';

interface SqlCommandModalProps {
  isOpen: boolean;
  onClose: (value: ComandoSql | null) => void;
}

const SqlCommandModal = ({ isOpen, onClose }: SqlCommandModalProps) => {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  const [selectedCommand, setSelectedCommand] = useState<ComandoSql | null>(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const { data: commands, isLoading, refetch } = useComandosSql();

  const header = (
    // <div className="table-header">
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText
        type="search"
        onInput={(e) => setGlobalFilter((e.target as HTMLInputElement).value)}
        placeholder="Busca..."
      />
    </span>
    // </div>
  );

  const renderFooter = () => {
    return (
      <S.BoxButton style={{ justifyContent: 'start', marginTop: '10px' }}>
        <Button
          style={{
            backgroundColor: '#228F6B',
            width: '150px',
            marginLeft: '10px',
            border: 'none',
          }}
          label="Cancelar"
          icon="pi pi-times"
          onClick={() => onClose(null)}
          autoFocus
        />
        <Button
          icon="pi pi-check"
          label="Selecionar"
          disabled={selectedCommand == null}
          onClick={async () => {
            await refetch();
            onClose(selectedCommand);
          }}
          autoFocus
          style={{
            backgroundColor: '#228F6B',
            width: '150px',
            marginLeft: '10px',
            border: 'none',
          }}
        />
      </S.BoxButton>
    );
  };

  return (
      <Dialog
        header="Comandos SQL"
        visible={isOpen}
        footer={renderFooter}
        onHide={async () => {
          await refetch();
          onClose(null);
        }}
        contentStyle={{ padding: '0px', width: '90vw', height: '70vh' }}
      >
        <Container>
          <DataTable
            value={[...(commands ?? [])].sort((a, b) => (a.nomeSql > b.nomeSql ? 1 : -1))}
            loading={isLoading}
            globalFilter={globalFilter}
            header={header}
            selection={selectedCommand}
            onSelectionChange={(e) => setSelectedCommand(e.value)}
            dataKey="codSql"
            style={{ fontSize: !isWebScreen && '10px' }}
          >
            <Column selectionMode="single" headerStyle={{ width: '3em' }} />
            <Column field="sistemaERP.nomeErp" header="ERP" sortable />
            <Column field="grupoSql.nomeGrupoSql" header="Grupo SQL" sortable />
            <Column field="nomeSql" header="Nome" sortable />
            <Column field="txtSql" header="Comando" />
            <Column
              field="dtAltera"
              header="Data Alteração"
              sortable
              body={(c: ComandoSql) => c.dtAltera != null && new Date(c.dtAltera).toLocaleDateString()}
            />
          </DataTable>
        </Container>
      </Dialog>
  );
};

export default SqlCommandModal;
