import styled, { css } from 'styled-components';

import { IToastBox } from '.';

export type IBoxProps = {} & Pick<IToastBox, 'variant'>;

const colorToast = {
  success: () => css`
    background-color: var(--green-100);

    svg {
      stroke: var(--primary-green);
      fill: var(--primary-green);
    }
  `,

  default: () => css`
    background-color: var(--gray-100);
    border: none;

    svg {
      stroke: black;
      fill: black;
    }
  `,

  error: () => css`
    background-color: var(--red-100);

    svg {
      stroke: var(--red-500);
      fill: var(--red-500);
    }
  `,

  warning: () => css`
    background-color: var(--yellow-100);

    svg {
      stroke: black;
      fill: black;
    }
  `,
};

export const Box = styled.div<IBoxProps>`
  ${({ variant }) => css`
    display: flex;
    align-items: center;
    width: 80%;
    padding: 15px;
    border-radius: 16px;
    margin: 30px;

    svg {
      height: 20px;
      width: 20px;
    }

    ${!!variant && colorToast[variant]()}
  `}
`;

export const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 10px;
`;

export const Text = styled.span`
  font-weight: 500;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const ButtonCancel = styled.button`
  background-color: var(--danger-500);
  margin-top: 10px;
  border: none;
  color: white;
  border-radius: 10px;
  height: 25px;
`;

export const ButtonSave = styled.button`
  margin-top: 10px;
  background-color: var(--primary-green);
  border: none;
  color: white;
  border-radius: 10px;
  height: 25px;
  margin-right: 10px;
`;
