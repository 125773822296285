// IMPORTA COMPONENTE DE BOTÃO PERSONALIZADO
import VmButton from 'components/VmButton/VmButton';

// IMPORTA FOLHA DE ESTILOS DO COMPONENTE
import * as S from './styles';

// EXPORTA COMPONENTE POR PADRÃO
export default function PolyEditBox({ poly, handlePolygonEdit, cancelPolyEdit }: any) {
  if (poly) {
    return (
      <S.EditPolyMainBox>
        <div className={'descriptionPolyBox'}>
          <span>Poligono em edição:</span>
          <span>{poly.setor}</span>
        </div>
        <div className={'buttonPolyBox'}>
          <VmButton
            options={{
              icon: 'Salvar',
              iconPosition: 'left',
              rounded: false,
              style: 'btn-success',
              size: 'btn-sm',
            }}
            style={{ paddingRight: '10px' }}
            onClick={(e: any) => handlePolygonEdit()}
          />
          <VmButton
            options={{
              icon: 'Cancelar',
              iconPosition: 'left',
              rounded: false,
              style: 'btn-danger',
              size: 'btn-sm',
            }}
            style={{ paddingRight: '10px' }}
            onClick={(e: any) => cancelPolyEdit()}
          />
        </div>
      </S.EditPolyMainBox>
    );
  } else {
    return <></>;
  }
}
