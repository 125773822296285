import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #ffffffD1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  box-shadow: rgba(255, 255, 255, 1) 0px 54px 55px, rgba(255, 255, 255, 1) 0px -12px 30px,
    rgba(255, 255, 255 1) 0px 4px 6px, rgba(255, 255, 255 1) 0px 12px 13px, rgba(255, 255, 255 1) 0px -3px 5px;

  img {
    height: 50px;
    box-shadow: rgba(255, 255, 255, 1) 0px 54px 55px, rgba(255, 255, 255, 1) 0px -12px 30px,
      rgba(255, 255, 255 1) 0px 4px 6px, rgba(255, 255, 255 1) 0px 12px 13px, rgba(255, 255, 255 1) 0px -3px 5px;
    opacity: 0.7;
  }

  span {
    font-size: 2rem;
    color: #606060;
    pointer-events: none !important;
    text-align: center;
  }

  span::selection {
    background-color: transparent;
  }
`;
