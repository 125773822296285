import axios from 'axios';

const getAllProds = async (cliente: any) => {
  return await axios({
    url: 'http://10.122.65.57:29292/api/product',
    method: 'GET',
  })
    .then((res: any) => {
      return res.data ?? [];
    })
    .catch((err) => {
      console.log('Error: ' + err.name + ' - ' + err.code + '');
      return [];
    });
};

const useProds = {
  getAllProds: getAllProds,
};

export default useProds;
