import { IGroupClient } from 'client/interfaces/groupClient';
import VmButton from 'components/VmButton/VmButton';
import { UseDeleteGroupClient } from 'hooks/useGroupClients/useDeleteGroupClient';
import { Dialog } from 'primereact/dialog';
import { AiOutlineDelete } from 'react-icons/ai';
import * as S from './styles';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useMediaQuery from 'hooks/useMediaQuery';

interface IDeleteGroupClient {
  rowGroupClient: IGroupClient;
  setIsOpenModalDelete: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModalDelete: boolean;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>,
  ) => Promise<QueryObserverResult<IGroupClient[], AxiosError<unknown, any>>>;
}

export const DeleteGroupClient = ({
  rowGroupClient,
  isOpenModalDelete,
  setIsOpenModalDelete,
  refetch,
}: IDeleteGroupClient) => {
  const { handleRemoveGroup } = UseDeleteGroupClient({ rowGroupClient, setIsOpenModalDelete, refetch });
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  return (
    <Dialog
      header={`Deseja remover o ${rowGroupClient?.nomeGrupo}`}
      visible={isOpenModalDelete}
      onHide={() => {
        setIsOpenModalDelete(!isOpenModalDelete);
      }}
      style={{ width: isWebScreen ? '30vw' : '90vw' }}
    >
      <p>Código do Grupo {rowGroupClient?.codgrupo}</p>
      <p>Código da Empresa: {rowGroupClient?.codempresa}</p>
      <p>Código da Filial: {rowGroupClient?.codfilial}</p>

      <S.BoxButtons>
        <VmButton
          options={{
            icon: <AiOutlineDelete />,
            iconPosition: 'left',
            label: 'Remover',
            style: 'btn-danger',
          }}
          onClick={() => {
            handleRemoveGroup();
          }}
        />
        <VmButton
          options={{
            iconPosition: 'left',
            label: 'Cancelar',
            style: 'btn-success',
          }}
          style={{ marginLeft: '10px' }}
          onClick={() => {
            handleRemoveGroup();
          }}
        />
      </S.BoxButtons>
    </Dialog>
  );
};
