import * as S from './styles';

import chartConfig from './chartConfig';

import { MdAttachMoney, MdOutlineNewspaper, MdSearch } from 'react-icons/md';

import { usePedido } from 'providers/pedidoProvider';

import { Chart } from 'primereact/chart';

import { useState } from 'react';

import DebitList from '../debitList/debitList';

type ChartsProps = {
  cliente: any;
};

export default function DebitSalesCharts({ cliente }: ChartsProps) {
  const { numberFormat, handleCliPedSearch }: any = usePedido();

  const [showDebList, setShowDebList] = useState(false);

  //ARRAY COM NOME DOS MESES
  const month = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  const fullYear = new Date().getFullYear();

  //CALCULO DE PORCENTAGEM DE AUMENTO OU REDUÇÃO NAS VENDAS DO CLIENTE
  let perc1 = cliente != null ? ((cliente.TOTAL3 - cliente.TOTAL2) / cliente.TOTAL2) * 100 : 0;
  let perc2 = cliente != null ? ((cliente.TOTAL2 - cliente.TOTAL1) / cliente.TOTAL1) * 100 : 0;
  let perc3 = cliente != null ? ((cliente.TOTAL1 - cliente.TOTAL4) / cliente.TOTAL4) * 100 : 0;

  let labels = ['1ª sem', '2ª sem', '3ª sem', '4ª sem'];
  let data1 = {
    labels: labels,
    datasets: [
      {
        label: 'R$',
        borderColor: +perc3 > 0 ? '#00800070' : '#FF000070',
        backgroundColor: +perc3 > 0 ? '#00800070' : '#FF000070',
        pointBackgroundColor: +perc3 > 0 ? '#00800070' : '#FF000070',
        borderWidth: 2,
        pointStyle: false,
        data:
          cliente != null
            ? [cliente.T1SEM1 || 0, cliente.T1SEM2 || 0, cliente.T1SEM3 || 0, cliente.T1SEM4 || 0]
            : [0, 0, 0, 0],
      },
    ],
  };
  let data2 = {
    labels: labels,
    datasets: [
      {
        label: 'R$',
        borderColor: perc2 > 0 ? '#00800070' : '#FF000070',
        backgroundColor: perc2 > 0 ? '#00800070' : '#FF000070',
        pointBackgroundColor: perc2 > 0 ? '#00800070' : '#FF000070',
        borderWidth: 2,
        pointStyle: false,
        data:
          cliente != null
            ? [cliente.T2SEM1 || 0, cliente.T2SEM2 || 0, cliente.T2SEM3 || 0, cliente.T2SEM4 || 0]
            : [0, 0, 0, 0],
      },
    ],
  };
  let data3 = {
    labels: labels,
    datasets: [
      {
        label: 'R$',
        borderColor: perc1 > 0 ? '#00800070' : '#FF000070',
        backgroundColor: perc1 > 0 ? '#00800070' : '#FF000070',
        pointBackgroundColor: perc1 > 0 ? '#00800070' : '#FF000070',
        borderWidth: 2,
        pointStyle: false,
        data:
          cliente != null
            ? [cliente.T3SEM1 || 0, cliente.T3SEM2 || 0, cliente.T3SEM3 || 0, cliente.T3SEM4 || 0]
            : [0, 0, 0, 0],
      },
    ],
  };

  return (
    <>
      <S.FatAndCobDataBox>
        <S.FaturamentoField>
          <div>
            <span>
              <MdAttachMoney /> Vendas {month[new Date().getMonth()] + '/' + fullYear}:
            </span>
            <MdSearch
              className={'pedFatSearch'}
              onClick={(e: React.MouseEvent<Element>) => {
                if (cliente == null) {
                  return;
                }
                let date = new Date();
                handleCliPedSearch(
                  cliente.CODCLI,
                  new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString(),
                  new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString(),
                );
              }}
            />
          </div>

          <div>
            <b>R${cliente != null ? numberFormat(cliente.TOTAL3) : '0,00'}</b>
          </div>

          <span style={{ color: perc1 > 0 ? 'green' : perc1 == 0 ? 'orange' : 'red' }}>
            {isNaN(perc1) ? 0 : numberFormat(perc1)}%
          </span>

          <S.PedFatChart>
            {cliente != null && <Chart type="line" data={data3} options={chartConfig} className={'salesChart'} />}
          </S.PedFatChart>
        </S.FaturamentoField>

        <S.FaturamentoField>
          <div>
            <span>
              <MdAttachMoney /> Vendas {month[new Date().getMonth() - 1] + '/' + fullYear}:
            </span>
            <MdSearch
              className={'pedFatSearch'}
              onClick={(e) => {
                if (cliente == null) {
                  return;
                }
                let date = new Date();
                handleCliPedSearch(
                  cliente.CODCLI,
                  new Date(date.getFullYear(), date.getMonth() - 1, 1).toLocaleDateString(),
                  new Date(date.getFullYear(), date.getMonth(), 0).toLocaleDateString(),
                );
              }}
            />
          </div>

          <div>
            <b>R${cliente != null ? numberFormat(cliente.TOTAL2) : '0,00'}</b>
          </div>

          <span style={{ color: perc2 > 0 ? 'green' : perc1 == 0 ? 'orange' : 'red' }}>
            {isNaN(perc2) ? 0 : numberFormat(perc2)}%
          </span>

          <S.PedFatChart>
            {cliente != null && <Chart type="line" data={data2} options={chartConfig} className={'salesChart'} />}
          </S.PedFatChart>
        </S.FaturamentoField>

        <S.FaturamentoField>
          <div>
            <span>
              <MdAttachMoney /> Vendas {month[new Date().getMonth() - 2] + '/' + fullYear}:
            </span>
            <MdSearch
              className={'pedFatSearch'}
              onClick={(e) => {
                if (cliente == null) {
                  return;
                }
                let date = new Date();
                handleCliPedSearch(
                  cliente.CODCLI,
                  new Date(date.getFullYear(), date.getMonth() - 2, 1).toLocaleDateString(),
                  new Date(date.getFullYear(), date.getMonth() - 1, 0).toLocaleDateString(),
                );
              }}
            />
          </div>

          <div>
            <b>R${cliente != null ? numberFormat(cliente.TOTAL1) : '0,00'}</b>
          </div>

          <span style={{ color: perc3 > 0 ? 'green' : perc3 == 0 ? 'orange' : 'red' }}>
            {isNaN(perc3) ? 0 : numberFormat(perc3)}%
          </span>

          <S.PedFatChart>
            {cliente != null && <Chart type="line" data={data1} options={chartConfig} className={'salesChart'} />}
          </S.PedFatChart>
        </S.FaturamentoField>

        <S.CredCobField>
          <S.CredCodbValuesList>
            <span>
              <span>Lim. Crédito: </span>
              <S.CredCobValues style={{ color: cliente != null && cliente.LIMITE_CRED > 0 ? 'green' : 'red' }}>
                R${cliente != null ? numberFormat(cliente.LIMITE_CRED) : 0}
              </S.CredCobValues>
            </span>

            <span>
              <span>A Receber:</span>
              <S.CredCobValues style={{ color: cliente != null && cliente.VLABERTOCLI > 0 ? 'red' : 'green' }}>
                R${cliente != null ? numberFormat(cliente.VLABERTOCLI) : 0}
              </S.CredCobValues>
            </span>

            <span>
              <span>Disponível: </span>
              <S.CredCobValues
                style={{ color: cliente != null && cliente.LIMITE_CRED - cliente.VLABERTOCLI > 0 ? 'green' : 'red' }}
              >
                R${cliente != null ? numberFormat(cliente.LIMITE_CRED - cliente.VLABERTOCLI) : 0}
              </S.CredCobValues>
            </span>
          </S.CredCodbValuesList>

          <S.CredCobSearch>
            <MdOutlineNewspaper
              onClick={() => {
                if (cliente != null && cliente.VLABERTOCLI > 0) {
                  setShowDebList(true);
                }
              }}
            />
          </S.CredCobSearch>

          {cliente != null && cliente.LIMITE_CRED > 0 && (
            <S.CredCobChart>
              <svg viewBox="0 0 36 36">
                <path
                  d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                  fill="none"
                  stroke="#1dd32c"
                  strokeWidth="2"
                  strokeDasharray={
                    cliente != null
                      ? ((cliente.LIMITE_CRED - cliente.VLABERTOCLI) * 100) / cliente.LIMITE_CRED + ', 100'
                      : '0, 100'
                  }
                />
              </svg>
              <span>
                {cliente != null &&
                  (((cliente.LIMITE_CRED - cliente.VLABERTOCLI) * 100) / cliente.LIMITE_CRED).toFixed(0) + '%'}
              </span>
            </S.CredCobChart>
          )}
        </S.CredCobField>
      </S.FatAndCobDataBox>
      <DebitList cliente={cliente} showDebList={showDebList} setShowDebList={setShowDebList} />
    </>
  );
}
