import styled from 'styled-components';

export const ContainerInput = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-top: 10px;
    margin-left: 5px;
    height: 40px;
  }
`;

export const ContainerInputs = styled.div`
  display: flex;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    justify-content: space-between;
  }
`;

export const ContainerTable = styled.div`
  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;

    overflow: 'hidden';
  }
`;
