import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BsPlusLg } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import VmButton from 'components/VmButton/VmButton';
import { useClientsToAddGroup } from 'client/hooks/groupClients';
import { postClientGroupImport } from 'client/api/clientGroup';
import { useGroupClientMember } from 'storesZustand/groupClientMember';
import * as S from './styles';

interface IEditGroupClient {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  codEmpresa: string;
  codGrupo: number;
}

export const ModalAddMemberToGroupClient = ({ isOpenModal, setIsOpenModal, codEmpresa, codGrupo }: IEditGroupClient) => {
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');

  const groupInfo = useGroupClientMember((state) => state.groupInfo);
  const queryClient = useQueryClient();
  const { data } = useClientsToAddGroup(codEmpresa, codGrupo);

  const menbersGroup = selectedClients?.map((cli) => ({
    codCli: cli,
    codEmpresa: codEmpresa,
    codGrupo: groupInfo.codgrupo,
  }));

  const AddMembersToGroup = async () => {
    await postClientGroupImport(menbersGroup, codEmpresa)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['client-group'],
        });
        queryClient.invalidateQueries({
          queryKey: ['useClientsToAddGroup'],
        });
        toast.success('Clientes adicionados no Grupo com sucesso!');
        setIsOpenModal(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const toggleSelectAll = () => {
    if (!selectAll) {
      setSelectedClients(data?.map((client) => client.codCli) || []);
    } else {
      setSelectedClients([]);
    }
  };

  const handleClientSelection = (clientCodCli) => {
    if (selectedClients.includes(clientCodCli)) {
      setSelectedClients(selectedClients.filter((id) => id !== clientCodCli));
    } else {
      setSelectedClients([...selectedClients, clientCodCli]);
    }
  };

  const filteredData = data?.filter(
    (client) =>
      String(client.codCli).includes(globalFilter) ||
      client.razaosocial.toLowerCase().includes(globalFilter.toLowerCase()),
  );

  useEffect(() => {
    setSelectedClients([]);
    setSelectAll(false);
  }, [isOpenModal]);

  useEffect(() => {
    const areAllClientsSelected = data?.length > 0 && selectedClients.length === data.length;
    setSelectAll(areAllClientsSelected);
  }, [selectedClients, data]);

  return (
    <Dialog
      header={'Vincular Clientes ao Grupo'}
      visible={isOpenModal}
      onHide={() => setIsOpenModal(!isOpenModal)}
      style={{ width: 'clamp(30vw, 40vw, 100vw)' }}
      breakpoints={{ '960px': '75vw', '641px': '90vw' }}
      footer={
        <VmButton
          options={{
            style: 'btn-success',
            label: 'Vincular clientes',
            icon: <BsPlusLg />,
            iconPosition: 'right',
          }}
          onClick={() => AddMembersToGroup()}
        />
      }
    >
      <S.Container>
        <input
          type="text"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Filtrar por Nome Cliente ou CodCliente"
          style={{ marginBottom: '1rem', padding: '0.5rem' }}
        />
        <DataTable
          value={filteredData}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          emptyMessage="Nenhum Cliente encontrado."
          currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
        >
          <Column
            header={
              <div>
                <input type="checkbox" checked={selectAll} onChange={toggleSelectAll} />
                <label style={{ marginLeft: '0.5rem' }}>Selecionar Todos Clientes</label>
              </div>
            }
            body={(rowData) => (
              <div>
                <input
                  type="checkbox"
                  checked={selectedClients.includes(rowData.codCli)}
                  onChange={() => handleClientSelection(rowData.codCli)}
                />
                <label style={{ marginLeft: '0.5rem' }}>{rowData.razaosocial}</label>
              </div>
            )}
          />
          <Column header="CodCliente" field="codCli" />
        </DataTable>
      </S.Container>
    </Dialog>
  );
};
