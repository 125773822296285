import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { SelectButton } from 'primereact/selectbutton';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import { toast } from 'react-toastify';

import imageNotFound from 'assets/images/image-not-found.png';
import { saveFotos, updateFotos } from 'client/api';
import { useEmpresas, useUser } from 'client/hooks';
import { FotoProduto, NewFotoProduto } from 'client/interfaces';
import ButtonComponent from 'components/Button';
import { BASE_URL } from 'services/api';

import styles from './NewPhotoDialog.module.scss';
import * as S from './styles';
import useMediaQuery from 'hooks/useMediaQuery';

interface NewPhotoDialogProps {
  photo: NewFotoProduto | FotoProduto;
  isOpen: boolean;
  onClose: () => void;
}

interface Option {
  value: number;
  label: string;
}

const NewPhotoDialog = ({ photo, isOpen, onClose }: NewPhotoDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: empresas } = useEmpresas();
  const { data: user } = useUser();
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const isAdmin = useMemo(() => user?.admin ?? false, [user]);

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<NewFotoProduto>({ defaultValues: photo });
  const queryClient = useQueryClient();

  const renderFooter = () => (
    <S.RowItens>
      <ButtonComponent style={{ width: '120px' }} icon={<BsCheckLg />} text="Salvar" onClick={handleSubmit(onSubmit)} />
      <ButtonComponent
        style={{ width: '120px' }}
        icon={<BsXLg />}
        onClick={onClose}
        text="Cancelar"
        variant="secondary"
      />
    </S.RowItens>
  );

  const onSubmit: SubmitHandler<NewFotoProduto> = async (data) => {
    try {
      setIsLoading(true);
      if (data.dtCadastro != null) {
        await updateFotos(data);
      } else {
        await saveFotos(data);
      }
      await queryClient.invalidateQueries(['foto-produtos']);
      toast.success('Foto salva com sucesso!');
      onClose();
    } catch (e) {
      toast.error('Falha ao salvar foto');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      header={photo.dtCadastro != null ? 'Editar foto de produto' : 'Nova foto de produto'}
      visible={isOpen}
      style={{ width: isWebScreen ? '55vw' : '90%' }}
      footer={renderFooter}
      onHide={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="form w-100" noValidate>
        <S.FirstRow>
          <div className={styles.selectButton}>
            <Controller
              control={control}
              name="fotoType"
              defaultValue={0}
              render={({ field: { onChange, value } }) => (
                <SelectButton
                  unselectable
                  value={value}
                  options={
                    [
                      {
                        value: 0,
                        label: 'Cód. Barras (EAN)',
                      },
                      {
                        value: 1,
                        label: 'Cód. Interno (Específico)',
                      },
                    ] as Option[]
                  }
                  onChange={(e) => onChange(e.value)}
                />
              )}
            />
          </div>
          {watch().fotoType === 1 &&
            (isAdmin && photo.dtCadastro == null ? (
              <S.Info>
                <label className="form-label fs-6 fw-bolder text-dark">Cod Empresa</label>
                <Controller
                  control={control}
                  name="codEmpresa"
                  render={({ field: { value, onChange } }) => (
                    <Dropdown
                      value={empresas?.find((c) =>
                        value ? c.codEmpresa === value : c.codEmpresa === user?.codEmpresa,
                      )}
                      onChange={(e) => {
                        onChange(e.target.value.codEmpresa);
                      }}
                      filter
                      className="form-control form-control-lg form-control-solid"
                      options={empresas}
                      dataKey="codEmpresa"
                      optionLabel="fantasia"
                      filterBy="fantasia"
                    />
                  )}
                />
              </S.Info>
            ) : (
              <S.Info>
                <p>Cod Empresa</p>
                <p>{photo.codEmpresa ?? user?.codEmpresa}</p>
              </S.Info>
            ))}
        </S.FirstRow>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">
            * {watch().fotoType === 0 ? 'Cód. Barras' : 'Cód. Interno'}
          </label>
          <input
            {...register('codProd', { required: 'Obrigatório' })}
            placeholder={watch().fotoType === 0 ? 'Cód. Barras' : 'Cód. Interno'}
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': (dirtyFields.codProd ?? false) && errors.codProd,
            })}
            autoFocus
            readOnly={photo.dtCadastro != null}
            maxLength={14}
            type="codProd"
            name="codProd"
          />
          {errors.codProd != null && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{errors.codProd.message}</span>
            </div>
          )}
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">* Nome</label>
          <input
            {...register('nome', { required: 'Obrigatório' })}
            placeholder="Nome do produto"
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': (dirtyFields.nome ?? false) && errors.nome,
            })}
            autoFocus
            maxLength={100}
            type="nome"
            name="nome"
          />
          {errors.nome != null && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{errors.nome.message}</span>
            </div>
          )}
        </div>

        <div className="fv-row mb-10 d-flex">
          {photo.arquivo.length > 0 && (
            <div className="fv-row me-5">
              <label className="form-label fs-6 fw-bolder text-dark">Foto Anterior</label>
              <div className="fv-row">
                <img
                  src={`${BASE_URL}/foto_produtos/${photo.fotoType === 0 ? '' : `${photo.codEmpresa}%23`}${
                    photo.arquivo
                  }`}
                  onError={(e) => (e.currentTarget.src = imageNotFound)}
                  alt={photo.nome}
                  className={styles.productImage}
                />
              </div>
            </div>
          )}
          <div className="fv-row flex-fill">
            <label className="form-label fs-6 fw-bolder text-dark">
              {photo.dtCadastro == null ? '* Foto' : 'Nova Foto'}
            </label>
            <Controller
              control={control}
              name="foto"
              rules={{ required: photo.arquivo.length > 0 ? false : 'Obrigatório' }}
              render={({ field: { onChange } }) => (
                <FileUpload
                  name="demo[]"
                  customUpload
                  auto
                  uploadHandler={(e) => onChange(e.files[0])}
                  accept="image/*"
                  maxFileSize={1000000}
                  emptyTemplate={<p className="m-0">Arraste e solte os arquivos aqui para fazer upload.</p>}
                />
              )}
            />
            {errors.foto != null && (
              <div className="fv-plugins-message-container invalid-feedback">
                <span role="alert">{errors.foto.message}</span>
              </div>
            )}
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default NewPhotoDialog;
