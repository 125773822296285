import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 40px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
`;