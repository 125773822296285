import styled from 'styled-components';

export const IconTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ContainerTable = styled.div`
  width: 100%;
  padding-left: 55px;
  padding-right: 55px;
`;

export const GroupTemplate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  gap: 20px;
`;

export const GrupoTitle = styled.span``;

export const GrupoTimeInput = styled.input`
  border: 1px solid #60606090;
  border-radius: 2.5px;
  padding: 2px 5px 2px 10px;
`;

export const GrupoTimeInputBox = styled.span`
  position: relative;
  padding: 10px 0;
  label {
    position: absolute;
    bottom: 80%;
    left: 0;
    font-size: 0.8rem;
    padding: 0 5px;
  }
`;
export const MapFormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;
