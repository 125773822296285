import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getAcessoMenus, getAcessoSubMenus, getMenusWithSub, getUserMenus } from 'client/api';
import { BaseMenuDto, MenuWithSub } from 'client/interfaces';

export const useMenuWithSub = (codEmpresa: number, enabled = true) =>
  useQuery<MenuWithSub[], AxiosError>(['menu-with-sub', codEmpresa], async () => await getMenusWithSub(codEmpresa), {
    staleTime: Infinity,
    enabled,
  });

export const useMenuAcesso = (codUsuario: number, enabled = true) =>
  useQuery<number[], AxiosError>(['menu-acesso', codUsuario], async () => await getAcessoMenus(codUsuario), {
    staleTime: 1000 * 60 * 1,
    enabled,
  });

export const useSubMenuAcesso = (codUsuario: number, enabled = true) =>
  useQuery<number[], AxiosError>(['sub-menu-acesso', codUsuario], async () => await getAcessoSubMenus(codUsuario), {
    staleTime: 1000 * 60 * 1,
    enabled,
  });

export const useMenuUsuario = () =>
  useQuery<BaseMenuDto[], AxiosError>(['sub-menu-acesso'], getUserMenus, {
    staleTime: 1000 * 60 * 15,
  });
