import * as S from './prodList.styles';

import pedido from '../../pages/checkout/mockdata';

import { BsCardList } from 'react-icons/bs';
import { BASE_URL } from 'services/api';

import { Image } from 'primereact/image';

type PedProdProps = {
  codprod: string;
  ean: number;
  descricao: string;
  qt: string;
  ptabela: string;
  pvenda: string;
  foto_capa: string;
  ptotal: string;
};

export default function ProdList({ itens }: any) {
  return (
    <S.ProdListMainBox>
      <S.ProdListTitle>Itens Do Pedido</S.ProdListTitle>

      <S.ProdListBox>
        {itens.map((prod: PedProdProps) => {
          return (
            <S.ProdCard key={Math.random()}>
              <S.ProdImage>
                <Image src={`${BASE_URL}/foto_produtos/${prod.foto_capa ?? 'sem_foto.png'}`} alt="Image" preview />
              </S.ProdImage>

              <S.ProdDesc>
                <span>{prod.descricao}</span>
                <span>EAN: {prod.ean ?? '0'}</span>
              </S.ProdDesc>

              <S.ProdPedDetails>
                <span>{prod.pvenda ?? '0'}</span>
                <S.ProdPedQtd>x {prod.qt ?? '0'} un</S.ProdPedQtd>
                <span>{prod.ptotal ?? '0'}</span>
              </S.ProdPedDetails>
            </S.ProdCard>
          );
        })}
      </S.ProdListBox>
    </S.ProdListMainBox>
  );
}
