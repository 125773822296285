import * as S from './styles';

import { BiPlus, BiMinus } from 'react-icons/bi';

type IMapZoomData = {
  zoom: number;
  maxZoom: number;
};

type IMapZoomProps = {
  mapZoom: IMapZoomData;
  setMapZoom: React.Dispatch<React.SetStateAction<IMapZoomData>>;
};

export default function MapZoomControl({ mapZoom, setMapZoom }: IMapZoomProps) {
  const maxZoomAlowed = 22;
  const minMaxZoomAlowed = 16;
  const minZoomAlowed = 4;

  const handleMapZoomAction = (action: 'decrement' | 'increment') => {
    if (action === 'decrement') {
      setMapZoom((prevState) => ({
        ...prevState,
        zoom: mapZoom.zoom - 1 >= minZoomAlowed ? mapZoom.zoom - 1 : minZoomAlowed,
        maxZoom: mapZoom.maxZoom - 1 >= minMaxZoomAlowed ? mapZoom.maxZoom - 1 : minMaxZoomAlowed,
      }));
    } else if (action === 'increment') {
      setMapZoom({
        zoom: mapZoom.zoom + 1 <= maxZoomAlowed ? mapZoom.zoom + 1 : maxZoomAlowed,
        maxZoom: mapZoom.maxZoom + 1 <= maxZoomAlowed ? mapZoom.maxZoom + 1 : maxZoomAlowed,
      });
    }
  };

  return (
    <S.MapZoomMainBox>
      <S.ZoomButton onClick={() => handleMapZoomAction('increment')} disabled={mapZoom.zoom === maxZoomAlowed}>
        <BiPlus />
      </S.ZoomButton>
      <S.HLine></S.HLine>
      <S.ZoomButton onClick={() => handleMapZoomAction('decrement')} disabled={mapZoom.zoom === minZoomAlowed}>
        <BiMinus />
      </S.ZoomButton>
    </S.MapZoomMainBox>
  );
}
