import * as S from './styles';
import { BlockUI } from 'primereact/blockui';
import { UserData } from 'client/interfaces';
import { BlockTableTemplate } from 'components/datatable';
import ContainerFilial from './ContainerFilial';
import ContainerSupervisor from './ContainerSupervisor';
import ContainerManager from './ContainerManager';
import { useUser } from 'client/hooks';
import { IGetAccessData } from 'client/interfaces/acessoDados.interface';
import Swal from 'sweetalert2';
import { deleteAccessData } from 'client/api/acessoDados';
import { toast } from 'react-toastify';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { useQueryClient } from '@tanstack/react-query';

interface IAccessData {
  selectedUsuario: UserData;
}

export enum COD_NOME {
  codFilial = 'TODAS FILIAIS',
  codSupervisor = 'TODOS SUPERVISORES',
  codGerente = 'TODOS GERENTES',
}

const AccessData = ({ selectedUsuario }: IAccessData) => {
  const queryClient = useQueryClient();

  const { data: user } = useUser();

  const handleDelete = async (rowData: IGetAccessData) => {
    await Swal.fire({
      title: 'Tem certeza ?',
      html: `Retirar acesso do codUser[${rowData.codUsuario}] - ${rowData.nome}[${rowData.codEmpresa}]`,
      icon: 'question',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Não, voltar',
      showCancelButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          const data = {
            CodEmpresa: rowData.codEmpresa,
            Pk: rowData.pk,
          };

          deleteAccessData(data.CodEmpresa, data.Pk)
            .then(() => {
              queryClient.invalidateQueries(['acesso-dados']);

              toast.success('Acesso retirado com sucesso!');
            })
            .catch(() => {
              toast.error('Falha ao retirar acesso!');
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const itemListTemplate = ({ data, cod }) => {
    return (
      <S.BoxRow>
        <div>
          <p className="font-bold">{data.codAcesso === 999 ? COD_NOME[cod] : `${data?.nome}`}</p>
          <p className="font-bold">{data.codAcesso === 999 ? '' : `[${data.codAcesso}]`}</p>
        </div>
        <BsFillTrash3Fill color="red" onClick={() => handleDelete(data)} />
      </S.BoxRow>
    );
  };

  return (
    <BlockUI containerStyle={{ zIndex: 0}} blocked={selectedUsuario == null} template={BlockTableTemplate('Selecione uma empresa e usuário')}>
      <S.Container>
        <S.ContainerBox>
          {selectedUsuario == null ? (
            <S.ContainerDefault />
          ) : (
            <>
              <ContainerFilial itemListTemplate={itemListTemplate} selectedUsuario={selectedUsuario} user={user} />
              <ContainerManager itemListTemplate={itemListTemplate} selectedUsuario={selectedUsuario} user={user} />
              <ContainerSupervisor itemListTemplate={itemListTemplate} selectedUsuario={selectedUsuario} user={user} />
            </>
          )}
        </S.ContainerBox>
      </S.Container>
    </BlockUI>
  );
};

export default AccessData;
