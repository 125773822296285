import styled, { css } from 'styled-components';

interface IBox {
  remove: boolean;
}

export const Box = styled.div<IBox>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid var(--dark-200);
  justify-content: space-between;

  ${({ remove }) => css`
    background-color: ${remove && 'var(--red-100)'};
  `}
`;

export const Button = styled.button`
  width: 50%;
  justify-content: center;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: white;
`;

export const BoxConfirm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1px;
  justify-content: center;
  align-items: center;
  text-align: center;
  div {
    display: flex;
    flex-direction: row;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
  width: 50%;
`;

export const ContainerScroll = styled.div`
  display: flex;
  height: 400px;
  display: flex;
  overflow: auto;
  flex-direction: column;
`;

export const InputFilter = styled.input`
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  width: 100%;
  margin: 10px 0;
  border: 1px solid #ced4da;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 13px;
`;

export const Void = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  h3 {
    color: var(--primary-green);
    cursor: pointer;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  /* width: 70%; */

  span {
    color: var(--dark-500);
    font-size: 14px;
  }

  p {
    color: var(--dark-400);
    font-weight: 300;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;
