import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px;
`;

export const Separator = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ContainerImages = styled.div`
  width: 100%;
  display: flex;
`;

export const BoxImage = styled.div`
  display: flex;
  height: 310px;
  width: 600px;
  margin-right: 10px;
  /* background-color: red; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: solid 1px #ced4da;
`;

export const Image = styled.div`
  display: flex;
  height: 200px;
  width: 250px;
  background-color: blue;
`;

export const BoxLeggend = styled.div`
  width: 250px;
  margin-top: 10px;
`;

export const BoxRowLeggend = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.span`
  font-weight: bold;
`;

export const RowImages = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;
