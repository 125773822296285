import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { deleteSubMenuRestricao } from 'client/api/subMenuRestricao';
import { useSubMenuRestricao } from 'client/hooks/subMenuRestricao';
import { ISubMenusRestricao } from 'client/interfaces/submenu.interface';
import ClickIcon from 'components/ClickIcon';
import useMediaQuery from 'hooks/useMediaQuery';

import { IRowDataSubMenu } from '../ModalAddFunctionalityToCompanySubMenu';
import * as S from './styles';

interface IModal {
  data: IRowDataSubMenu;
}

const ModalSeeRegisteredCompaniesSubMenu = ({ data }: IModal) => {
  const queryClient = useQueryClient();

  const { data: subMenus } = useSubMenuRestricao(data.codMenu);

  const [filter, setFilter] = useState('');

  const filteredSubMenus = subMenus?.filter((item) => item.nomeEmpresa.toLowerCase().includes(filter.toLowerCase()));
  const [removeCompany, setRemoveCompany] = useState(0);

  const deletePermissionToCompany = (submenu: ISubMenusRestricao) => {
    deleteSubMenuRestricao(submenu.codEmpresa, submenu.codMenu, submenu.codMenuSub)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['SubMenuRestrincao'],
        });
      })
      .catch(() => {
        toast.error('Não foi possível deletar acesso.');
      });
  };



  return (
    <S.Container>
      <S.Title>
        Empresas com Acesso
      </S.Title>

      {subMenus?.length ? (
        <>
          <S.InputFilter
            type="text"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Filtrar por empresa"
          />
          <S.ContainerScroll>
            {!filteredSubMenus.length ? (
              <>Não encontramos empresas com esse nome :/</>
            ) : (
              <>
                {filteredSubMenus?.map((menu) => (
                  <S.Box remove={removeCompany === menu.codEmpresa} key={menu.codFuncLibera}>
                    <S.Description>
                      <h6> {menu.nomeEmpresa}</h6>

                      <S.ContainerBox>
                        <S.Description>
                          <p>Data</p>
                          <span> {moment(menu.dtLibera).format('DD/MM/YYYY')}</span>
                        </S.Description>

                        <S.Description style={{ paddingLeft: '10px' }}>
                          <p>Liberador</p>
                          <span>
                            {menu.codFuncLibera} [{menu.codFuncLibera}]
                          </span>
                        </S.Description>
                      </S.ContainerBox>
                    </S.Description>

                    {removeCompany === menu.codEmpresa ? (
                      <S.BoxConfirm>
                        <p>Deseja remover o acesso dessa empresa </p>
                        <div>
                          <S.Button
                            style={{ background: 'var(--primary-green' }}
                            onClick={() => deletePermissionToCompany(menu)}
                          >
                            Sim
                          </S.Button>
                          <S.Button style={{ background: 'var(--danger-500)' }} onClick={() => setRemoveCompany(0)}>
                            Não
                          </S.Button>
                        </div>
                      </S.BoxConfirm>
                    ) : (
                      <ClickIcon
                        variant="danger"
                        onClick={() => setRemoveCompany(menu.codEmpresa)}
                        icon={<BsFillTrash3Fill />}
                      />
                    )}
                  </S.Box>
                ))}
              </>
            )}
          </S.ContainerScroll>
        </>
      ) : (
        <S.Void>
          <h2>Você não possui empresas vinculadas neste menu :/</h2>
        </S.Void>
      )}
    </S.Container>
  );
};

export default ModalSeeRegisteredCompaniesSubMenu;
