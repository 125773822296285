import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

import { useCabecalhoItems, useRespostaWithItems } from 'client/hooks';
import { booleanBodyTemplate, filterByGlobalFilter } from 'components/datatable';
import { formatDateTime } from 'utils/datetime';
import { RespostaItem } from 'client/interfaces';

import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { BASE_URL } from 'services/api';

import * as S from './styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import useMediaQuery from 'hooks/useMediaQuery';

const fotoTemplate = (rowData: RespostaItem) => {
  return rowData.formularioItem?.tipo === 'FOTO' ? (
    <S.FormRespImageBox>
      <Image src={`${BASE_URL}/fotos_formulario/${rowData.resposta}`} alt="Image" width="100" preview />
    </S.FormRespImageBox>
  ) : (
    rowData.resposta
  );
};

const FormularioRespostaItemsPage = () => {
  const { id } = useParams();
  const { selectedEmpresa } = UseSelectEmpresa();
  const {
    data: resposta,
    isLoading,
    isFetching,
    refetch,
  } = useRespostaWithItems(id ?? '', selectedEmpresa.codEmpresa, !!id);
  const { data: items } = useCabecalhoItems(resposta?.nroform ?? 0, selectedEmpresa.codEmpresa, !!resposta);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const isWebScreen = useMediaQuery('(min-width: 1024px)');

  const { titlePage, setTitlePage } = useTitlePageStore();

  useEffect(() => {
    setTitlePage(`Respostas Formulário ${resposta?.nroform ? `Nº ${resposta?.nroform}` : ''}`);
  }, [titlePage, resposta]);

  const header = useMemo(
    () => (
      <S.FormRespGridSearchBox>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onChange={(e) => setGlobalFilter(e.target.value ?? undefined)}
            placeholder="Pesquisar..."
          />
        </span>
        <Button
          icon="pi pi-refresh"
          className="p-button-square"
          aria-label="refresh"
          onClick={async () => await refetch()}
        />
      </S.FormRespGridSearchBox>
    ),
    [],
  );

  const filteredItems = useMemo(
    () => filterByGlobalFilter(resposta?.items?.map((p, index) => ({ ...p, id: index })) ?? [], globalFilter),
    [resposta?.items, globalFilter],
  );

  return (
    <S.FormRespBox>
      <S.FormRespHeader>
        <S.FormRespCard>
          <S.FormRespCardRow>
            <S.FormRespCardRowTitle color={'#228F6B'}>
              <i className="pi pi-dollar" /> Vendedor:
            </S.FormRespCardRowTitle>
            <S.FormRespCardRowContent>{resposta?.vendedor}</S.FormRespCardRowContent>
          </S.FormRespCardRow>

          <S.FormRespCardRow>
            <S.FormRespCardRowTitle color={'#375E97'}>
              <i className="pi pi-shopping-bag" /> Cliente:
            </S.FormRespCardRowTitle>
            <S.FormRespCardRowContent>{resposta?.codcli}</S.FormRespCardRowContent>
          </S.FormRespCardRow>

          <S.FormRespCardRow>
            <S.FormRespCardRowTitle color={'#606062'}>
              <i className="pi pi-calendar" /> Data da Resposta:
            </S.FormRespCardRowTitle>
            <S.FormRespCardRowContent>
              {resposta?.dtresposta ? formatDateTime('dd/MM/yyyy hh:mm:ss', new Date(resposta.dtresposta)) : ''}
            </S.FormRespCardRowContent>
          </S.FormRespCardRow>
        </S.FormRespCard>

        <S.FormRespCard>
          <S.FormRespCardRow>
            <S.FormRespCardRowTitle color={'#228F6B'}>
              <i className="pi pi-book" /> {resposta?.form ?? ''}
            </S.FormRespCardRowTitle>
          </S.FormRespCardRow>

          <S.FormRespCardRow>
            <S.FormRespCardRowContent>
              <S.FormRespCardColumn>
                <div>{items?.length ?? 0}</div>
                <div>Perguntas</div>
              </S.FormRespCardColumn>
            </S.FormRespCardRowContent>
            <S.FormRespCardRowContent>
              <S.FormRespCardColumn>
                <div>{resposta?.items.length ?? 0}</div>
                <div>Respostas</div>
              </S.FormRespCardColumn>
            </S.FormRespCardRowContent>
          </S.FormRespCardRow>
        </S.FormRespCard>
      </S.FormRespHeader>

      <DataTable
        value={filteredItems}
        header={header}
        dataKey="id"
        loading={isLoading || isFetching}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 25, 50, 100, 200, 300, 500]}
        rows={50}
        emptyMessage="Nenhum dado encontrado"
        currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
      >
        <Column header="Ordem" field="nroCampo" sortable />
        <Column header="Pergunta" field="formularioItem.nomeCampo" sortable align={isWebScreen ? 'left' : 'right'} />
        <Column
          header="Obrigatório*"
          field="formularioItem.limiteMinimo"
          body={(rowData: RespostaItem) => booleanBodyTemplate((rowData.formularioItem.limiteMinimo ?? 0) > 0)}
          sortable
        />
        <Column header="Resposta" field="resposta" body={(rowData: RespostaItem) => fotoTemplate(rowData)} sortable />
      </DataTable>
    </S.FormRespBox>
  );
};

export default FormularioRespostaItemsPage;
