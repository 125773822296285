import { saveAs } from 'file-saver';
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useRef, useState } from 'react';
import { AiFillFileExcel, AiOutlineCloseCircle } from 'react-icons/ai';
import { BsCheck, BsFillTrashFill, BsPlusLg, BsX } from 'react-icons/bs';
import { MdClose, MdOutlineMap, MdSearch, MdViewList } from 'react-icons/md';
import { toast } from 'react-toastify';
import localStorage from 'redux-persist/es/storage/session';
import * as XLSX from 'xlsx';

import { deleteAllCliFromSetor, registerSetor } from 'client/api';
import VmButton from 'components/VmButton/VmButton';
import useMediaQuery from 'hooks/useMediaQuery';
import { MapContext } from 'providers/map';

import UpdateMapData from '../updateMapData/updateMapData';
import * as S from './styles';
import ImportCliFile from 'modules/mapa/pages/regioes/components/importCliFile/importCliFile';
import Swal from 'sweetalert2';

const MapHeader = ({ startPoly, drawingMode }: any) => {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const {
    mapGridShow,
    setMapGridShow,
    clientes,
    setInitialZoom,
    setCurrentPin,
    setClientesList,
    clientesList,
    viewSetorCad,
    setViewSetorCad,
    currentCli,
    setCurrentCli,
    vendedores,
    supervisores,
    reloadAllData,
    setCurrentSetor,
    currentSetor,
    codEmpresa,
    codmapa,
    colorIconesMapList,
  } = useContext(MapContext);

  const setorRef: any = useRef(null);

  const [cor, setCor] = useState<any>({ nome: '', cor: '' });
  const [supervisorSelecionado, setSupervisorSelecionado] = useState<any>('');
  const [vendedorSelecionado, setVendedorSelecionado] = useState<any>('');
  const [autSelecionado, setAutSelecionado] = useState<string>('S');

  const [showImportCliModal, setShowImportCliModal] = useState<boolean>(false);

  useEffect(() => {
    setFiltCli([]);

    let markersCli: any = clientes;
    if (currentSetor && currentSetor.setores) {
      if (currentSetor && currentSetor.setores && currentSetor.setores[0].codSetor == -1) {
        markersCli = markersCli.filter((cli: any) => cli.codSetor === null || cli.codSetor === 0);
      } else {
        let markersCliFilter = [];
        for (let i = 0; i < currentSetor.setores.length; i++) {
          clientes.map((cli: any) => {
            if (currentSetor.setores[i].codSetor == -1 && !cli.codSetor) {
              markersCliFilter.push(cli);
            } else if (cli.codSetor === currentSetor.setores[i].codSetor) {
              markersCliFilter.push(cli);
            }
          });
        }
        markersCli = markersCliFilter;
      }
      setClientesList(markersCli);
    } else {
      setClientesList(clientes);
    }
  }, [currentSetor]);

  const corTemplate = (rowData: any) => {
    return (
      <S.CorOption cor={rowData.cor}>
        <S.Cor cor={rowData.cor} />
        {rowData.nome}
      </S.CorOption>
    );
  };

  const vendedorOptionTemplate = (vendedor: any) => {
    return (
      <S.SelectOption>
        [{vendedor.codVendedor}] {vendedor.nome}
        <S.SelectOptionCloseButton
          show={vendedorSelecionado && vendedorSelecionado.codVendedor == vendedor.codVendedor}
          onClick={() => setVendedorSelecionado([])}
        >
          <AiOutlineCloseCircle />
        </S.SelectOptionCloseButton>
      </S.SelectOption>
    );
  };

  const supervisorOptionTemplate = (supervisor: any) => {
    return (
      <S.SelectOption>
        [{supervisor.codSupervisor}] {supervisor.nomesup}
        <S.SelectOptionCloseButton
          show={supervisorSelecionado && supervisorSelecionado.codSupervisor == supervisor.codSupervisor}
          onClick={() => setSupervisorSelecionado([])}
        >
          <AiOutlineCloseCircle />
        </S.SelectOptionCloseButton>
      </S.SelectOption>
    );
  };

  const PopCadastrarSetor = () => {
    return (
      <Dialog
        visible={viewSetorCad}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={'Cadastro de Setor'}
        modal
        className="p-fluid"
        onHide={() => setViewSetorCad(false)}
        footer={DialogFooterCadastrarSetor}
        draggable={false}
      >
        <S.NovoSetorForm>
          <S.Box className="field">
            Nome do Setor*
            <InputText id="name" ref={setorRef} placeholder="Setor" />
          </S.Box>

          <S.Box className="field">
            Supervisor
            <Dropdown
              options={supervisores}
              optionLabel="nomesup"
              placeholder={supervisorSelecionado.nomesup || 'Selecione um supervisor'}
              value={supervisorSelecionado}
              filter
              itemTemplate={supervisorOptionTemplate}
              className="w-full md:w-14rem"
              onChange={(e) => setSupervisorSelecionado(e.target.value)}
            />
          </S.Box>

          <S.Box className="field">
            Vendedor
            <Dropdown
              options={
                supervisorSelecionado.codSupervisor
                  ? vendedores.filter((vend: any) => vend.codSupervisor == supervisorSelecionado.codSupervisor)
                  : vendedores
              }
              optionLabel="nome"
              placeholder={vendedorSelecionado.nome || 'Selecione um vendedor'}
              value={vendedorSelecionado}
              filter
              itemTemplate={vendedorOptionTemplate}
              className="w-full md:w-14rem"
              onChange={(e) => setVendedorSelecionado(e.target.value)}
            />
          </S.Box>

          <S.Box className="field">
            Roteiriza Automático
            <Dropdown
              options={[
                { option: 'S', checked: true, label: 'SIM' },
                { option: 'N', label: 'NÃO' },
              ]}
              optionLabel="label"
              defaultChecked={true}
              placeholder={autSelecionado == 'S' ? 'SIM' : 'NÃO'}
              filter
              className="w-full md:w-14rem"
              onChange={(e) => {
                setAutSelecionado(e.target.value.option);
              }}
            />
          </S.Box>

          <S.Box className="field">
            Cor
            <Dropdown
              options={colorIconesMapList}
              optionLabel="nome"
              defaultValue={'preto'}
              defaultChecked={true}
              placeholder={cor.nome}
              itemTemplate={corTemplate}
              className="w-full md:w-14rem"
              onChange={(e) => {
                setCor(e.target.value);
              }}
            />
          </S.Box>
        </S.NovoSetorForm>
      </Dialog>
    );
  };

  const DialogFooterCadastrarSetor = (
    <S.BoxButtons>
      <VmButton
        options={{
          icon: <BsCheck />,
          label: 'Salvar',
          iconPosition: 'left',
          style: 'btn-success',
        }}
        style={{ paddingRight: '10px' }}
        onClick={() => handleSaveSetor()}
      />
      <VmButton
        options={{
          icon: <BsX />,
          label: 'Fechar',
          iconPosition: 'left',
          style: 'btn-danger',
        }}
        style={{ paddingRight: '10px' }}
        onClick={() => setViewSetorCad(false)}
      />
    </S.BoxButtons>
  );

  const handleSaveSetor = () => {
    if (setorRef.current == null || setorRef.current.value === '') {
      toast.error('Nome do setor não informado!');
      return;
    } else if (cor.nome == '') {
      toast.error('Cor não informada!');
      return;
    }

    const toRegisterSetor = {
      codSetor: 0,
      setor: setorRef.current.value,
      codRca: vendedorSelecionado.codVendedor !== undefined ? +vendedorSelecionado.codVendedor : 0,
      ativo: true,
      codFilial: 1,
      codGerente: supervisorSelecionado.codSupervisor !== undefined ? +supervisorSelecionado.codSupervisor : 0,
      codMapa: codmapa,
      cor: cor.cor,
      CodEmpresa: codEmpresa,
      atualizaAutomatico: autSelecionado,
    };
    registerSetor(toRegisterSetor)
      .then((res) => {
        toast.success('Setor criado com sucesso!');
        reloadAllData();
      })
      .catch((err) => {
        toast.error('Não foi possível criar setor!');
      });

    setVendedorSelecionado('');
    setSupervisorSelecionado('');
    setCor({});
    setViewSetorCad(false);
  };

  const [filtCli, setFiltCli] = useState([]);

  const searchRef: any = useRef(null);

  const hadleCliSearch = () => {
    const currentSetores = currentSetor ? currentSetor.setores : [];
    setTimeout(() => {
      if (searchRef.current != null && searchRef.current.value === '') {
        let fullCliListToSet: any = [];
        if (currentSetores.length > 0) {
          for (let i = 0; i < currentSetores.length; i++) {
            let clisFiltered = clientes.filter((cli: any) => cli.codSetor == currentSetores[i].codSetor);
            fullCliListToSet = [...fullCliListToSet, ...clisFiltered];
          }
        } else {
          fullCliListToSet = clientes;
        }

        if (mapGridShow) {
          setFiltCli([]);
        }
        if (!mapGridShow) {
          setClientesList(fullCliListToSet);
        }
        return;
      }

      // let cliList: any = clientes.filter(
      //   (cli: any) =>
      //     cli.razaosocial.includes(searchRef.current.value.toUpperCase()) ||
      //     cli.codCli == +searchRef.current.value ||
      //     cli.codCli.toString().includes(searchRef.current.value),
      // );

      let cliList: any = [];

      const searchRefArray = searchRef.current.value.toUpperCase().split(',');

      for (let i = 0; i < clientes.length; i++) {
        let actualCli: any = clientes[i];
        for (let j = 0; j < searchRefArray.length; j++) {
          let actualSearchText: string = searchRefArray[j];
          if (
            actualCli.razaosocial.includes(actualSearchText) ||
            actualCli.codCli == +actualSearchText ||
            actualCli.codCli.toString().includes(actualSearchText)
          ) {
            cliList.push(actualCli);
          }
        }
      }

      let newCliList: any = [];

      if (currentSetores.length > 0) {
        for (let i = 0; i < currentSetores.length; i++) {
          let clisFiltered = cliList.filter((cli: any) => cli.codSetor == currentSetores[i].codSetor);
          newCliList = [...newCliList, ...clisFiltered];
        }
      } else {
        newCliList = cliList;
      }

      if (mapGridShow) {
        setFiltCli(newCliList);
      }
      if (!mapGridShow) {
        setClientesList(newCliList);
      }
    }, 500);
  };

  function exportCliToExcel() {
    const fileName: string = `CLIENTES`;

    let cliList: any = [];
    for (let i = 0; i < clientesList.length; i++) {
      let cli: any = clientesList[i];
      let newCli: any = {
        CODCLI: '',
        CLIENTE: '',
        SETOR: '',
        CIDADE: '',
        LOGRADOURO: '',

        PROXIMA_VISITA: '',
        SEQUENCIA: '',
        FREQ_VISITA: '',
        TEMPO_MINIMO_VISITA: '',
        POTENCIAL: '',
        VENDA_ULT_TRI: '',
        VENDA_MES: '',

        LATITUDE: '',
        LONGITUDE: '',
      };

      newCli['CODCLI'] = `${cli.codCli ?? ''}`;
      newCli['CLIENTE'] = `${cli.razaosocial ?? ''}`;
      newCli['SETOR'] = `[${cli.codSetor ?? ''}] ${cli.nomeSetor ?? ''}`;
      newCli['CIDADE'] = `${cli.endCidade ?? ''} [${cli.endUf ?? ''}]`;
      newCli['LOGRADOURO'] = `${cli.endLogradouro ?? ''}`;

      newCli['PROXIMA_VISITA'] = `${cli.dtProxVisita ?? ''}`;
      newCli['SEQUENCIA'] = `${cli.sequencia ?? ''}`;
      newCli['FREQ_VISITA'] = `${cli.freqVisita ?? ''}`;
      newCli['TEMPO_MINIMO_VISITA'] = `${cli.tempoMinVisita ?? ''}`;
      newCli['POTENCIAL'] = `${cli.vlPotencial ?? ''}`;
      newCli['VENDA_ULT_TRI'] = `${cli.vlMedUltTrim ?? ''}`;
      newCli['VENDA_MES'] = `${cli.vlMesAtual ?? ''}`;

      newCli['LATITUDE'] = `${cli.latitude ?? ''}`;
      newCli['LONGITUDE'] = `${cli.longitude ?? ''}`;

      cliList.push(newCli);
    }

    const ws = XLSX.utils.json_to_sheet(cliList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  }

  const handleRemoveAllCliFromSetor = () => {
    const paramsToRemoveAllCliFromSetor = {
      codmepresa: currentSetor.setores[0].codEmpresa,
      codsetor: currentSetor.setores[0].codSetor,
    };

    const clientesDoSetor = clientesList.filter((cli: any) => cli.codSetor == currentSetor.setores[0].codSetor);

    Swal.fire({
      icon: 'question',
      title: 'Deseja remover todos os clientes do setor?',
      showCancelButton: true,
      cancelButtonText: 'Não, voltar',
      confirmButtonText: 'Sim, remover',
    })
      .then((res) => {
        if (res.isConfirmed) {
          Swal.fire({
            html: `Serão removidos <b>${clientesDoSetor.length}</b> clientes do 
            setor <b>[${currentSetor.setores[0].codSetor}] ${currentSetor.setores[0].setor}</b>. <br/> Confirma?`,
            showCancelButton: true,
            cancelButtonText: 'Não, voltar',
            confirmButtonText: 'Sim, remover',
          })
            .then((res) => {
              if (res.isConfirmed) {
                deleteAllCliFromSetor(paramsToRemoveAllCliFromSetor)
                  .then((res) => {
                    toast.success('Todos os clientes removidos do setor!');
                    reloadAllData();
                  })
                  .catch((err: any) => {
                    toast.error('Falha ao remover clientes do setor!');
                    console.log('err :', err);
                  });
              }
            })
            .catch((err: Error) => {
              toast.error('Erro inesperado ao remover clientes do setor!');
              console.log('err :', err);
            });
        }
      })
      .catch((err: Error) => {
        toast.error('Erro inesperado ao remover clientes do setor!');
        console.log('err :', err);
      });
  };

  return (
    <S.MapHeaderMainBox>
      <S.SearchClientMainBoxInput>
        <S.SearchInput
          type="text"
          placeholder={currentCli != null ? currentCli.codCli + ' - ' + currentCli.razaosocial : 'Buscar...'}
          onChange={(e) => hadleCliSearch()}
          ref={searchRef}
        />
        {currentCli != null ? (
          <MdClose
            onClick={(e) => {
              setCurrentCli(null);
              setInitialZoom(14);
              searchRef.current.value = '';
              window.localStorage.setItem('cor', '000000');
            }}
          />
        ) : (
          <MdSearch />
        )}

        {mapGridShow && (
          <S.ClieSuggestionBox
            style={{ height: filtCli.length > 0 ? '40vh' : '0vh', opacity: filtCli.length > 0 ? '1' : '0' }}
          >
            {filtCli
              .sort((a, b) => a.razaosocial.localeCompare(b.razaosocial))
              ?.map((cli: any, index: any) => {
                return (
                  <S.ClieSuggestionCard
                    key={Math.random()}
                    onClick={(e) => {
                      setCurrentCli(cli);
                      setCurrentPin({ lat: +cli.latitude, lng: +cli.longitude });
                      setInitialZoom(18);
                      setFiltCli([]);
                      searchRef.current.value = '';
                    }}
                  >
                    {cli.codCli} - {cli.razaosocial} <br />{' '}
                    <S.OptionSetorCode cor={cli.cor ?? '000000'}>
                      {cli.codSetor != 0 && cli.codSetor ? `[${cli.codSetor}] ${cli.nomeSetor}` : 'Sem Setor'}
                    </S.OptionSetorCode>
                  </S.ClieSuggestionCard>
                );
              })}
          </S.ClieSuggestionBox>
        )}
      </S.SearchClientMainBoxInput>

      {mapGridShow && <UpdateMapData />}

      <S.MapGridButton>
        <S.Icon mapGridShow={mapGridShow} onClick={() => setMapGridShow(!mapGridShow)}>
          <MdOutlineMap />
        </S.Icon>
        <S.Icon mapGridShow={!mapGridShow} onClick={() => setMapGridShow(!mapGridShow)}>
          <MdViewList />
        </S.Icon>
      </S.MapGridButton>

      {!mapGridShow && (
        <>
          <VmButton
            options={{
              icon: <AiFillFileExcel />,
              label: 'Exportar',
              iconPosition: 'left',
              style: 'btn-success',
            }}
            onClick={() => exportCliToExcel()}
          />
          <VmButton
            options={{
              icon: <AiFillFileExcel />,
              label: 'Importar Clientes',
              iconPosition: 'left',
              style: 'btn-success',
              disabled:
                !currentSetor || (currentSetor && currentSetor.setores && currentSetor.setores[0].codSetor == -1),
            }}
            onClick={() => setShowImportCliModal(true)}
          />
          <VmButton
            options={{
              icon: <BsFillTrashFill />,
              label: 'Remover Todos Clientes',
              iconPosition: 'left',
              style: 'btn-danger',
              disabled:
                !currentSetor || (currentSetor && currentSetor.setores && currentSetor.setores[0].codSetor == -1),
            }}
            onClick={() => {
              handleRemoveAllCliFromSetor();
            }}
          />
        </>
      )}

      {mapGridShow && (
        <div style={{ display: 'flex', gap: '10px' }}>
          <VmButton
            options={{
              icon: <BsPlusLg />,
              label: currentSetor ? 'Setor' : !currentSetor && isWebScreen ? 'Novo Setor' : 'Setor',
              iconPosition: 'left',
              style: 'btn-success',
              tooltip: {
                show: true,
                text: 'Novo Setor',
                position: 'bottom',
              },
            }}
            onClick={() => setViewSetorCad(!viewSetorCad)}
          />
          <VmButton
            options={{
              icon: <BsPlusLg />,
              label: currentSetor ? 'Área' : !currentSetor && isWebScreen ? 'Nova Área' : 'Área',
              iconPosition: 'left',
              style: drawingMode ? 'btn-danger' : 'btn-success',
              tooltip: {
                show: true,
                text: 'Nova Área',
                position: 'bottom',
              },
            }}
            onClick={() => startPoly()}
          />
        </div>
      )}

      {currentSetor != null && currentSetor.setores != undefined && currentSetor.setores[0] != undefined && (
        <S.CurrentSetorCard style={{ borderColor: '#' + currentSetor.setores[0].cor + 'f5' }}>
          {currentSetor.setores[0].codSetor} - "{currentSetor.setores[0].setor.slice(0, 10)}..."
          <span
            onClick={() => {
              setCurrentSetor(null);
              localStorage.removeItem('cor');
            }}
          >
            &#xd7;
          </span>
        </S.CurrentSetorCard>
      )}

      {PopCadastrarSetor()}

      <ImportCliFile
        showModal={showImportCliModal}
        setShowModal={setShowImportCliModal}
        setorToImportCli={currentSetor && currentSetor.setores ? currentSetor.setores[0] : null}
      />
    </S.MapHeaderMainBox>
  );
};

export default MapHeader;
