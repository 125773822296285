export const formatCellphone = (str: string) => {
  if (str) {
    return str.toString().replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2$3-$4');
  }
  return;
};

export const revertCellphoneFormat = (formattedStr: string) => {
  return formattedStr.replace(/\D/g, '');
};
