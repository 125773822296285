import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './novoItemModal';
import { Dialog } from 'primereact/dialog';
import { NovoItemModalProps } from 'client/interfaces';
import { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import VmButton from 'components/VmButton/VmButton';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { postDeleteRegraItem, postEditRegraItem, postNovoRegraItem } from 'client/api/regraComercial';
import { getCidadesBySearchText, getClienteBySearchText, getProdutosBySearchParam } from 'client/api';
import { useProdutos } from 'client/hooks';

export default function NovoItemModal({
  showNovoItemModal,
  setShowNovoItemModal,
  codEmpresa,
  handleGetRegrasComerciais,
  selectedRegra,
}: NovoItemModalProps) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const tipoRestricoes = [
    {
      code: 'P',
      name: 'Produto',
      data: [],
    },
    {
      code: 'D',
      name: 'Departamento',
      data: [],
    },
    { code: 'S', name: 'Seção', data: [] },
    {
      code: 'F',
      name: 'Fornecedor',
      data: [],
    },
  ];

  const [selectedTipo, setSelectedTipo] = useState(tipoRestricoes[0]);
  const [selectedItems, setSelectedItems] = useState<any[]>(selectedRegra ? selectedRegra.regraItems : []);
  const [tableData, setTableData] = useState([]);
  const searchRef = useRef(null);
  const typingTimer = useRef(null);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);

  useEffect(() => {
    handleCheckIfTheresAnyAction();
  }, [selectedItems]);

  useEffect(() => {
    if (showNovoItemModal) {
      setSelectedTipo(tipoRestricoes[0]);
      const itensToBeSelected = [];
      for (let i = 0; i < selectedRegra.regraItems.length; i++) {
        const newItem = selectedRegra.regraItems[i];
        newItem.dtaltera = new Date().toLocaleDateString().split('/').reverse().join('-');
        itensToBeSelected.push(newItem);
      }
      setSelectedItems(itensToBeSelected);
    }
  }, [showNovoItemModal]);

  useEffect(() => {
    if (selectedTipo.data) {
      handleTableDataFilter();
    }
  }, [selectedTipo, showNovoItemModal]);

  const handleTableDataFilter = () => {
    if (selectedTipo.code && selectedTipo.code != 'P') {
      const searchText = searchRef.current ? searchRef.current.value.toString().toUpperCase() : '';
      let newTableData = selectedTipo.data ?? [];
      if (searchText)
        newTableData = newTableData?.filter(
          (newData: any) =>
            newData.coditem.toString().includes(searchText) ||
            newData.descricao.toString().toUpperCase().includes(searchText),
        );
      setTableData(newTableData);
    } else {
      setTableData(selectedTipo.data);
    }
  };

  function adjustRestricaoListIndexes(arr: any[], txt1: string, txt2: string, tipoRestricaoString: string): any[] {
    const newTxt1 = 'coditem';
    const newTxt2 = 'descricao';

    const keyMap: { [key: string]: string } = {
      [txt1]: newTxt1,
      [txt2]: newTxt2,
    };

    return arr.map((obj) => {
      const renamedObject: any = {};

      for (const key in obj) {
        if (obj.hasOwnProperty(key) && keyMap[key]) {
          const newKey = keyMap[key];
          renamedObject[newKey] = obj[key];
        }
      }

      const filteredObject: any = {};
      if (renamedObject[newTxt1] !== undefined) filteredObject[newTxt1] = renamedObject[newTxt1];
      if (renamedObject[newTxt2] !== undefined) filteredObject[newTxt2] = renamedObject[newTxt2];
      filteredObject['tiporestricao'] = tipoRestricaoString;
      filteredObject['dtaltera'] = new Date().toLocaleDateString().split('/').reverse().join('-');
      filteredObject['codEmpresa'] = codEmpresa;
      filteredObject['codregra'] = selectedRegra ? selectedRegra.codregra : 0;
      filteredObject['codigorestricao'] = filteredObject.coditem;

      filteredObject['qdemin'] = 0;
      filteredObject['valormin'] = 0;
      filteredObject['tiponeg'] = 'Z';
      filteredObject['numgrupo'] = 0;
      filteredObject['obrigatorio'] = 'S';
      filteredObject['descgrupo'] = null;
      filteredObject['qdesugerida'] = 0;
      filteredObject['fatorMult'] = 0;
      filteredObject['fatorDiv'] = 0;

      return filteredObject;
    });
  }

  const validateFormFields = () => {
    handleInsertOrEditRestricao();
  };

  const handleInsertOrEditRestricao = () => {
    const restricoesToInsert = selectedItems.filter((item: any) => !(selectedRegra.regraItems ?? []).includes(item));
    const restricoesToEdit = selectedRegra.regraItems.filter((item: any) => (selectedItems ?? []).includes(item));
    const restricoesToDelete = selectedRegra.regraItems.filter((item: any) => !(selectedItems ?? []).includes(item));
    if (restricoesToInsert.length > 0) {
      postNovoRegraItem(restricoesToInsert)
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
            toast.success('Nova restrição inserida com sucesso');
            setShowNovoItemModal(false);
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao inserir nova restrição');
          console.log('err :', err);
        });
    }

    if (restricoesToDelete.length > 0) {
      postDeleteRegraItem(restricoesToDelete)
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
          }
        })
        .catch((err) => {
          toast.error('Falha inesperada ao remover restrição');
          console.log('err :', err);
        });
    }

    for (let i = 0; i < restricoesToEdit.length; i++) {
      postEditRegraItem(restricoesToEdit[i])
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
            setShowNovoItemModal(false);
          }
        })
        .catch((err) => {
          toast.error(
            `Falha inesperada ao editar restrição [${restricoesToEdit[i].coditem}] ${
              restricoesToEdit[i].descricao ?? ''
            }`,
          );
          console.log('err :', err);
        });
    }
  };

  const onSelectionChange = (e: { value: any[] }) => {
    setSelectedItems(e.value);
  };

  const rowSelectionTemplate = (rowData: any) => {
    return (
      <S.NovaRegraModalChecboxTemplate>
        <Checkbox
          checked={selectedItems.some((item) => item.id === rowData.id)}
          onChange={(e) => {
            let _selectedItems = [...selectedItems];
            if (e.checked) {
              _selectedItems.push(rowData);
            } else {
              _selectedItems = _selectedItems.filter((item) => item.id !== rowData.id);
            }
            setSelectedItems(_selectedItems);
          }}
          style={{
            marginLeft: '10px',
          }}
        />
      </S.NovaRegraModalChecboxTemplate>
    );
  };

  const handleGetProdutos = () => {
    const searchText = searchRef.current ? searchRef.current.value.toString().toUpperCase() : '';
    if (searchText != '') {
      getProdutosBySearchParam(codEmpresa, selectedRegra.filial, searchText)
        .then((res) => {
          if (res.length > 0) {
            const produtosToHandle = adjustRestricaoListIndexes(res ?? [], 'codprod', 'produto', 'P');
            setSelectedTipo((prev: any) => {
              return {
                ...prev,
                data: produtosToHandle,
              };
            });
          }
        })
        .catch((err) => {
          console.log('err :', err);
        });
    } else {
      setSelectedTipo((prev: any) => {
        return {
          ...prev,
          data: [],
        };
      });
    }
  };

  const handleCheckIfTheresAnyAction = () => {
    if (selectedRegra) {
      const restricoesToInsert = selectedItems.filter((item: any) => !(selectedRegra.regraItems ?? []).includes(item));
      const restricoesToEdit = selectedRegra.regraItems.filter((item: any) => (selectedItems ?? []).includes(item));
      const restricoesToDelete = selectedRegra.regraItems.filter((item: any) => !(selectedItems ?? []).includes(item));

      if (
        restricoesToInsert.length > 0 ||
        restricoesToDelete.length > 0 ||
        (JSON.stringify(selectedRegra.regraItems) != JSON.stringify(restricoesToEdit) && restricoesToEdit.length > 0)
      ) {
        setIsSaveButtonActive(true);
      } else if (isSaveButtonActive) {
        setIsSaveButtonActive(false);
      }
    }
  };

  return (
    <Dialog
      header={
        selectedRegra
          ? `Gerenciar itens da regra - [${selectedRegra.codregra}] ${selectedRegra.nomeregra}`
          : 'Novo item'
      }
      visible={showNovoItemModal}
      style={{ width: isWebScreen ? '50vw' : '90%' }}
      onHide={() => {
        setShowNovoItemModal(false);
      }}
    >
      <S.NovaRegraModalFormBox>
        <S.NovaRegraModalFormRow>
          <S.NovaRegraModalFormInputs>
            <S.RegraInputBox>
              <label>Tipo</label>
              <Dropdown
                value={tipoRestricoes?.filter((tp: any) => tp.code == selectedTipo.code)[0] ?? []}
                options={tipoRestricoes}
                onChange={(e) => {
                  if (e.value.code == 'P') {
                    setSelectedTipo({
                      code: e.value.code,
                      name: e.value.name,
                      data: e.value.data,
                    });
                  } else {
                    setSelectedTipo(e.value);
                  }
                }}
                optionLabel="code"
                placeholder="Tipo da Restrição"
                className="p-column-filter"
                itemTemplate={(tipo: any) => {
                  return `[${tipo.code}] ${tipo.name}`;
                }}
              />
            </S.RegraInputBox>
            <S.RegraInputBox>
              <label>Buscar</label>
              <InputText
                placeholder="Buscar pelo cód. ou desc."
                ref={searchRef}
                onChange={() => {
                  if (selectedTipo.code != 'P') {
                    handleTableDataFilter();
                  }
                }}
                onKeyDown={(e) => {
                  // if (e.key == 'Enter') {
                  if (selectedTipo.code === 'P') {
                    if (typingTimer.current) {
                      clearTimeout(typingTimer.current);
                    }
                    typingTimer.current = setTimeout(() => {
                      handleGetProdutos();
                    }, 500);
                  } else {
                    handleTableDataFilter();
                  }
                  // }
                }}
              />
            </S.RegraInputBox>
          </S.NovaRegraModalFormInputs>
          <VmButton
            options={{
              label: 'Salvar',
              style: isSaveButtonActive ? 'btn-success' : 'btn-dark',
              size: 'btn-md',
              disabled: !isSaveButtonActive,
            }}
            onClick={() => {
              isSaveButtonActive && validateFormFields();
            }}
          />
        </S.NovaRegraModalFormRow>

        <S.NovaRegraModalTable>
          <DataTable
            value={tableData ?? []}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={10}
            rowHover
            stripedRows
            scrollable
            scrollHeight="55dvh"
            style={{ minWidth: '100%', minHeight: '51dvh' }}
            emptyMessage={'Nenhum item desse tipo disponível'}
            selection={selectedItems}
            onSelectionChange={onSelectionChange}
            selectionMode="checkbox"
          >
            <Column
              selectionMode="multiple"
              body={rowSelectionTemplate}
              sortable
              align="left"
              style={{
                maxWidth: '100px',
              }}
            />
            <Column field="coditem" header="Código" sortable />
            <Column field="descricao" header="Descrição" sortable />
          </DataTable>
        </S.NovaRegraModalTable>
      </S.NovaRegraModalFormBox>
    </Dialog>
  );
}
