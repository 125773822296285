import { useTitlePageStore } from 'storesZustand/titlePageStore';
import * as S from './styles';
import { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import VmButton from 'components/VmButton/VmButton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IoMdCheckmark } from 'react-icons/io';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { getRelatorio, putRelatorio } from 'client/api/relatorios';

type ReportParamProps = {
  codrel: number;
  ordem: number;
  obrigatorio: string;
  nome_param: string;
  legenda: string;
  componente: string;
  valor_padrao: string;
  itens: string;
  consulta: string;
  busca: string;
};

type ReportProps = {
  codrel: number;
  descricao: string;
  script: string;
  layout: string;
  layout_p: string;
  ativo: string;
  tipo: string;
  exibir_fv: string;
  parametros: ReportParamProps[];
};

export default function Relatorios7000Edit() {
  const navigate = useNavigate();

  const { setTitlePage } = useTitlePageStore();

  const { id } = useParams();

  const reportTypes = [
    {
      name: 'Relatório',
      code: 'R',
    },
    {
      name: 'Ação',
      code: 'A',
    },
    {
      name: 'Mapa',
      code: 'M',
    },
  ];

  const [reportData, setReportData] = useState<ReportProps>({
    codrel: 0,
    descricao: '',
    script: '',
    layout: '',
    layout_p: '',
    ativo: 'S',
    exibir_fv: 'S',
    tipo: 'R',
    parametros: [],
  });

  useEffect(() => {
    if (id) {
      handleGetReportInfo();
    }
  }, []);

  async function handleGetReportInfo() {
    const relatorio = await getRelatorio(id);
    setReportData(relatorio.data);
  }

  useEffect(() => {
    if (reportData) {
      setTitlePage(`Editar Relatório: ${reportData.codrel} - ${reportData.descricao}`);
    }
  }, [reportData]);

  const handleScriptStringAdjust = (script: string): string => {
    return script
      .replaceAll('\n', ' ')
      .replaceAll('(', '( ')
      .replaceAll(')', ' )')
      .replaceAll(',', ' , ')
      .replaceAll('=', ' = ')
      .replaceAll('\n', ' ');
  };

  const handleScriptParams = (script: string) => {
    const params = handleScriptStringAdjust(script)
      .split(' ')
      ?.filter((param: string) => param.slice(0, 1) == ':');
    const paramsList = [];
    for (let i = 0; i < params.length; i++) {
      if (!paramsList.some((param) => param.nome_param === params[i].replace(':', '')))
        paramsList.push({
          codrel: 0,
          ordem: i + 1,
          obrigatorio: 'S',
          nome_param: params[i].replace(':', ''),
          legenda: '',
          componente: '',
          valor_padrao: '',
          itens: '',
          consulta: '',
          busca: '',
        });
    }
    setReportData((prev: ReportProps) => {
      for (let i = 0; i < paramsList.length; i++) {
        paramsList[i].ordem = i + 1;
        for (let j = 0; j < prev.parametros.length; j++) {
          if (prev.parametros[j].nome_param == paramsList[i].nome_param) {
            paramsList[i] = prev.parametros[j];
          }
        }
      }
      return {
        ...prev,
        parametros: paramsList ?? [],
      };
    });
  };

  const textEditor = (options: any) => {
    return (
      <S.EditReportParamsInputBox>
        <InputText
          type="text"
          value={options.value}
          onChange={(e) => options.editorCallback(e.target.value)}
          style={{
            width: 'clamp(100px, 150px, 200px)',
          }}
        />
      </S.EditReportParamsInputBox>
    );
  };

  const activeEditor = (options: any) => {
    return (
      <S.EditReportParamsInputBox>
        <InputSwitch
          checked={options.value == 'S' || options.obrigatorio == 'S'}
          onChange={(e) => options.editorCallback && options.editorCallback(e.value ? 'S' : 'N')}
        />
      </S.EditReportParamsInputBox>
    );
  };

  const onCellEditComplete = (e: any) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    setReportData((prev: ReportProps) => {
      const newParams = prev.parametros;
      for (let i = 0; i < newParams.length; i++) {
        newParams[i].ordem = i + 1;
        if (newParams[i].nome_param == field) {
          newParams[i] = rowData;
        }
      }
      return {
        ...prev,
        parametros: newParams,
      };
    });
  };

  const handleSaveNewReport = () => {
    if (reportData.descricao == '') {
      toast.error('Descrição não informada');
      return;
    } else if (reportData.script == '') {
      Swal.fire({
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Não, voltar',
        confirmButtonText: 'Sim, salvar',
        icon: 'question',
        title: 'Nenhum script?',
        text: 'Nenhum script foi informado, deseja prosseguir?',
      }).then((res) => {
        if (res.isConfirmed) {
          postSaveNewReport();
        } else {
          return;
        }
      });
    } else {
      postSaveNewReport();
    }
  };

  async function postSaveNewReport() {
    const reportDataToEdit = { ...reportData };
    if (!reportDataToEdit.script.toUpperCase().includes('--{CODEMPRESA}')) {
      reportDataToEdit.script = reportDataToEdit.script + ' --{codempresa}';
    }
    const reportEdited = await putRelatorio(reportDataToEdit);
    if (reportEdited.succeeded) {
      toast.success('Sucesso ao editar relatório');
      navigate(`/relatorios`);
    }
  }

  return (
    <S.EditReportMainBox>
      <S.EditReportContentBox>
        <S.EditReportRowBox1>
          <S.EditReportInputBox>
            <label htmlFor="report">Descrição</label>
            <InputText
              id="report"
              placeholder="Descrição do relatório"
              style={{
                width: 'clamp(200px, 250px, 300px)',
              }}
              value={reportData.descricao}
              onChange={(e) => {
                setReportData((prev: ReportProps) => {
                  return {
                    ...prev,
                    descricao: e.target.value.toUpperCase() ?? '',
                  };
                });
              }}
            />
          </S.EditReportInputBox>

          <S.EditReportInputBox>
            <label htmlFor="report">Tipo</label>
            <Dropdown
              value={reportData.tipo}
              onChange={(e) => {
                setReportData((prev: ReportProps) => {
                  return {
                    ...prev,
                    tipo: e.value ?? 'R',
                  };
                });
              }}
              options={reportTypes}
              optionLabel="name"
              optionValue="code"
              className="w-full md:w-14rem"
              style={{ marginRight: '10px' }}
              itemTemplate={(item) => {
                return '[' + item.code + '] - ' + item.name;
              }}
            />
          </S.EditReportInputBox>

          <S.EditReportInputBox>
            <label htmlFor="report">Ativo</label>
            <InputSwitch
              checked={reportData.ativo == 'S'}
              onChange={(e) => {
                setReportData((prev: ReportProps) => {
                  return {
                    ...prev,
                    ativo: e.value ? 'S' : 'N',
                  };
                });
              }}
            />
          </S.EditReportInputBox>

          <S.EditReportInputBox>
            <label htmlFor="report">Exibir FV</label>
            <InputSwitch
              checked={reportData.exibir_fv == 'S'}
              onChange={(e) => {
                setReportData((prev: ReportProps) => {
                  return {
                    ...prev,
                    exibir_fv: e.value ? 'S' : 'N',
                  };
                });
              }}
            />
          </S.EditReportInputBox>

          <VmButton
            options={{
              icon: <IoMdCheckmark />,
              label: 'Salvar',
              iconPosition: 'left',
              style: 'btn-success',
            }}
            onClick={() => handleSaveNewReport()}
          />
        </S.EditReportRowBox1>

        <S.EditReportRowBox2>
          <S.EditReportScriptInputBox>
            <label htmlFor="report">Script</label>
            <InputTextarea
              defaultValue={reportData.script}
              onChange={(e) => {
                handleScriptParams(e.target.value.toUpperCase() ?? '');
                setReportData((prev: ReportProps) => {
                  return {
                    ...prev,
                    script: e.target.value.toUpperCase() ?? '',
                  };
                });
              }}
              autoResize
              rows={24}
              cols={30}
            />
          </S.EditReportScriptInputBox>

          <S.EditReportInputBox>
            <label htmlFor="report">Parâmetros</label>
            <S.EditReportParamsBox>
              <DataTable
                value={reportData.parametros}
                stripedRows
                scrollable
                scrollHeight="66dvh"
                reorderableRows
                rowHover
                onRowReorder={(e) => {
                  for (let i = 0; i < e.value.length; i++) {
                    e.value[i].ordem = i + 1;
                  }
                  setReportData((prev: ReportProps) => {
                    return {
                      ...prev,
                      parametros: e.value,
                    };
                  });
                }}
              >
                <Column
                  field="reorder"
                  header=""
                  align="left"
                  rowReorder
                  style={{
                    maxWidth: '2rem',
                  }}
                />
                <Column
                  field="ordem"
                  header=""
                  align="center"
                  style={{
                    maxWidth: '2rem',
                  }}
                />
                <Column field="nome_param" header="Nome" align="left" />
                <Column
                  field="legenda"
                  header="Legenda"
                  align="left"
                  editor={(options) => textEditor(options)}
                  onCellEditComplete={onCellEditComplete}
                />
                <Column
                  field="obrigatorio"
                  header="Obrigatório"
                  align="center"
                  editor={(options) => activeEditor(options)}
                  onCellEditComplete={onCellEditComplete}
                  body={(options) => activeEditor(options)}
                />
                <Column
                  field="valor_padrao"
                  header="Vl. Padrão"
                  align="left"
                  editor={(options) => textEditor(options)}
                  onCellEditComplete={onCellEditComplete}
                />
              </DataTable>
            </S.EditReportParamsBox>
          </S.EditReportInputBox>
        </S.EditReportRowBox2>
      </S.EditReportContentBox>
    </S.EditReportMainBox>
  );
}
