import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import { getEmpresas } from 'client/api';
import styled from 'styled-components';

type SelectedEmpresaProps = {
  codEmpresa: number;
  fantasia?: string;
};

const UseSelectEmpresa = () => {
  const { data: user } = useUser();

  const [empresas, setEmpresas] = useState<any>([]);

  const GetSelectedEmpresa = () => {
    let empr = JSON.parse(sessionStorage.getItem('vmEmpData'));
    empr = empr
      ? empr
      : {
          codEmpresa: user
            ? user.codEmpresa
            : empresas.length > 0
            ? empresas.find((empr: any) => empr.codEmpresa === 2072021)
            : 2072021,
          fantasia: '',
        };

    return empr;
  };

  const [selectedEmpresa, setSelectedEmpresa] = useState<SelectedEmpresaProps>(GetSelectedEmpresa());

  useEffect(() => {
    if (user && empresas.length == 0) {
      if (user.codEmpresa == 2072021) {
        getEmpresas().then((emps: any) => {
          for (let i = 0; i < emps.length; i++) {
            emps[i].label = `[${emps[i].codEmpresa}] - ${emps[i].fantasia}`;
          }
          setEmpresas(emps);
          if (selectedEmpresa.codEmpresa == 2072021) {
            setSelectedEmpresa(emps.find((empr: any) => empr.codEmpresa === 2072021));
          }
        });
      } else {
        setSelectedEmpresa({
          codEmpresa: user.codEmpresa,
          fantasia: '',
        });
        sessionStorage.clear();
        sessionStorage.setItem(
          'vmEmpData',
          JSON.stringify({
            codEmpresa: user.codEmpresa,
            fantasia: '',
          }),
        );
      }
    }
  }, [user]);

  const handleEmpChange = (emp: any) => {
    setSelectedEmpresa(emp);
    sessionStorage.setItem('vmEmpData', JSON.stringify(emp));
  };

  const SelectEmpresa = () => {
    const width = +(`[${selectedEmpresa.codEmpresa}] - ${selectedEmpresa?.fantasia}`.length * 9) + 20;
    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: clamp(100px, ${width}px, 375px);

      input::focus {
        outline: none;
        border: none;
      }
    `;

    if (user && user.codEmpresa === 2072021) {
      return (
        <InputBox>
          <label>Empresa</label>
          <Dropdown
            value={selectedEmpresa}
            onChange={(e) => handleEmpChange(e.value)}
            options={empresas}
            optionLabel="label"
            filter
            itemTemplate={(emp) => `[${emp.codEmpresa}] - ${emp.fantasia}`}
          />
        </InputBox>
      );
    }
  };

  const selectEmpresaHook = {
    SelectEmpresa: SelectEmpresa,
    GetSelectedEmpresa: GetSelectedEmpresa,
    selectedEmpresa: selectedEmpresa,
    setSelectedEmpresa: setSelectedEmpresa,
  };
  return selectEmpresaHook;
};

export default UseSelectEmpresa;
