import { UserData } from 'client/interfaces';
import { create } from 'zustand';

export enum TABS {
  ACCESS_MENU = 'AccessMenu',
  ACCESS_DATA = 'AccessData',
  ALTER_PASSWORD = 'AlterPassword',
}

interface IAccessData {
  tabActive: string;
  setTabActive(tabActive: string): void;
}

export const useAccessDataStore = create<IAccessData>()((set) => ({
  tabActive: TABS.ACCESS_MENU,
  setTabActive: (tabActive) => set(() => ({ tabActive })),
}));
