import { FC } from 'react';
import userAvatar from 'assets/images/user-avatar.jpg';
import { useAppDispatch } from 'store';
import { logout } from 'modules/auth/reducer/auth';
import { useUser } from 'client/hooks';
import { useNavigate } from 'react-router-dom';
import { BoxUser } from 'modules/main/components/Header/styles';
const HeaderUserMenuMobile: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: user } = useUser();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-200px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-30px me-5 mt-10 px-5">
            <img alt="Logo" src={userAvatar} />
          </div>

          <BoxUser>
            <p className="fw-bolder fs-4">{user?.nome}</p>
            <span
              onClick={() => {
                navigate('/usuario');
              }}
            >
              {'> MEUS DADOS'}
            </span>
          </BoxUser>
        </div>
      </div>

      <div className="separator my-2" />

      <div className="menu-item px-5">
        <a onClick={handleLogout} className="menu-link px-5">
          Sair 
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenuMobile };
