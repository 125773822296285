import { IManagers } from 'client/interfaces/gerentes.interface';
import api from 'services/api';

const _controller = 'Gerente';

export const getManagersCompany = async (CodEmpresa: number): Promise<IManagers[]> => {
  const { data } = await api.get(`${_controller}?empresa=${CodEmpresa}`);
  return data;
};

export const getByRoleManagersCompany = async (codCompany: number, codUser: number): Promise<IManagers[]> => {
  const { data } = await api.get(`${_controller}/Allowed/ByUser/${codCompany}/${codUser}`);
  return data;
};
