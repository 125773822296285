import { create } from 'zustand';

import { IPontoAgenda } from 'client/interfaces/pontoAgenda.interface';
import { EmpresaBasico } from 'client/interfaces';

interface IModalAddFunctionalityToCompany {
  points: IPontoAgenda[];
  setPoints(points: IPontoAgenda[]): void;
  status: string;
  setStatus(status: string): void;
  selectedCompany: EmpresaBasico;
  setSelectedCompany(selectedCompany: EmpresaBasico): void;
}

export const useModalAddFunctionalityToCompany = create<IModalAddFunctionalityToCompany>()((set) => ({
  points: [],
  setPoints: (points) => set(() => ({ points })),
  status: 'default',
  setStatus: (status) => set(() => ({ status })),
  selectedCompany: {
    codEmpresa: 0,
    cnpj: '',
    razaosocial: '',
    fantasia: '',
    caminhoLogo: '',
  },
  setSelectedCompany: (company) => set(() => ({ selectedCompany: company })),
}));
