import styled from 'styled-components';

interface IMapProps {
  showMenu?: boolean;
  codColorSetor?: string;
}

export const Content = styled.section<IMapProps>`
  background-color: #fafafa;
  height: 85%;
  width: 100%;
  overflow-y: scroll;
  opacity: ${(props) => (props.showMenu ? 'none' : '0')};
  transition: ${(props) => (!props.showMenu ? 'opacity 0.4s ease' : '')};

  @media (max-width: 992px) {
    border-radius: none;
    height: calc(80% - 10px);
  }
`;
export const ResetMainBox = styled.section`
  background-color: #fafafa;
  width: 100%;
  padding: 20px;
`;
export const ResetFormBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: flex-start;
  gap: 20px;
  border: 1px solid #cccccc70;
  border-radius: 7.5px;
  padding: 20px;
  background-color: #fff;
`;
export const ResetInputBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  width: clamp(120px, 175px, 175px);

  label {
    text-align: left;
  }
`;
