import styled from 'styled-components';

export const CcMainBox = styled.section`
  width: 100%;
  max-width: 95dvw;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 2px 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  height: 40dvh;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }

  @keyframes rotation1 {
    from {
      transform: rotateY(0deg);
      opacity: 1;
    }
    to {
      transform: rotateY(180deg);
      opacity: 0;
    }
  }
  @keyframes rotation2 {
    from {
      transform: rotateY(180deg);
    }
    to {
      transform: rotateY(0deg);
    }
  }
`;

export const CardMainBox = styled.div`
  width: calc(341px);
  height: calc(183px + 2rem);
  position: relative;
  cursor: pointer;
  &:hover .cardBox {
    transform: rotateY(180deg);
    opacity: 0;
  }
  &:hover .cardBoxVerso {
    opacity: 1;
    transform: rotateY(0deg);
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: calc(200px);
  }
`;

export const CardBox = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: 10px;
  position: absolute;
  opacity: 1;
  transition: all 0.6s ease;
  background-color: #228f6b;
  padding: 10px;
  background-image: url('/assets/images/credit-card-green-bg.png');

  @media (max-width: 1024px) {
    padding: 5px;
    border-radius: 10px;
  }
`;

export const CardChip = styled.span`
  color: #fff;
  position: absolute;
  top: 10%;
  left: 20px;
  img {
    max-width: 50px;
  }

  @media (max-width: 1024px) {
    left: 15px;
    img {
      max-width: 40px;
    }
  }
`;

export const CardBrand = styled.span`
  color: #fff;
  position: absolute;
  top: 10%;
  right: 20px;
  img {
    max-width: 80px;
  }

  @media (max-width: 1024px) {
    right: 15px;
    img {
      max-width: 75px;
    }
  }
`;

export const CardNumber = styled.span`
  color: #fff;
  position: absolute;
  bottom: 40%;
  left: 20px;
  font-size: 2rem;

  @media (max-width: 1024px) {
    left: 15px;
    font-size: 1.5rem;
    bottom: 35%;
  }
`;

export const CardName = styled.span`
  color: #fff;
  position: absolute;
  bottom: 10%;
  left: 20px;

  @media (max-width: 1024px) {
    left: 15px;
    bottom: 15%;
  }
`;

export const CardDate = styled.span`
  color: #fff;
  position: absolute;
  bottom: 10%;
  right: 20px;

  @media (max-width: 1024px) {
    right: 15px;
    bottom: 15%;
  }
`;

export const CardCvv = styled.span`
  color: #fff;
  position: absolute;
  bottom: 10%;
  right: 20px;

  @media (max-width: 1024px) {
    right: 15px;
  }
`;

export const CardBoxVerso = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: 10px;
  position: absolute;
  transition: all 0.5s ease;
  opacity: 0;
  transform: rotateY(180deg);
  background-color: #228f6b;
  background-image: url('/assets/images/credit-card-green-bg-reversed.png');
`;
export const CardFormBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 0;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

type InputProps = {
  width?: string;
};

export const InputBox = styled.div<InputProps>`
  background-color: #fff;
  width: ${(props: InputProps) => (props.width ? props.width : '100%')};
  padding: 10px 5px;
  max-height: calc(2.5rem + 10px);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  margin-top: 20px;
  border-radius: 5px;
  position: relative;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
`;

export const Label = styled.label`
  position: absolute;
  bottom: 100%;
`;

export const CardTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 5px;
`;
export const CardTitleText = styled.div`
  color: #228f6b;
  font-weight: thin;
  font-size: 1.1rem;
`;
export const CardTitleEnviroment = styled.div`
  font-size: 0.75rem;
  span:first-child {
    color: #1dd699;
  }
`;
