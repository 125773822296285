import styled, { css } from 'styled-components';

export const ContainerPage = styled.div`
  padding: 40px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: red;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px 0px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form``;

interface InputProps {
  error?: boolean;
}

export const DescriptionInput = styled.small<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const TitleInput = styled.label<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableDefault = styled.div``;

export const HeaderFilters = styled.div`
  display: flex;
  align-items: center;
`;

export const BoxFilters = styled.div`
  display: flex;
  flex-direction: column;
`;
