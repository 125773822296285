import { useQueryClient } from '@tanstack/react-query';
import { postAccessData } from 'client/api/acessoDados';
import { useAccessData } from 'client/hooks/acessoDados';
import { UseFiliaisSelect } from 'hooks/useFiliais/useFiliaisSelect';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IFormFilial {
  filial: string[];
}

interface IParamsFilial {
  codEmpresa: number;
  codUsuario: number;
  codfuncLib: number;
}

export const useFilialAccessData = ({ codEmpresa, codUsuario, codfuncLib }: IParamsFilial) => {
  const queryClient = useQueryClient();

  const { optionsFiliais } = UseFiliaisSelect({
    codEmpresa: codEmpresa,
  });

  const { data: accessData } = useAccessData(codEmpresa);

  const accessDataFilial = useMemo(() => {
    if (accessData) {
      return accessData?.filter((objeto) => objeto.tipoAcesso === 'F' && objeto.codUsuario === codUsuario);
    }
    return [];
  }, [accessData, codUsuario]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<IFormFilial>({});

  const values = watch();

  const onSubmitFilial = async (data) => {
    const selectedAll = data.filial.length === optionsFiliais.length;

    const allFilial = {
      codEmpresa: codEmpresa,
      tipoAcesso: 'F',
      codUsuario: codUsuario,
      codAcesso: 999,
      codfuncLib: codfuncLib,
    };

    await postAccessData(
      selectedAll
        ? [allFilial]
        : data.filial.map((item) => {
            return {
              codEmpresa: codEmpresa,
              tipoAcesso: 'F',
              codUsuario: codUsuario,
              codAcesso: item,
              codfuncLib: codfuncLib,
            };
          }),
    )
      .then(() => {
        queryClient.invalidateQueries(['acesso-dados']);
        toast.success('Sucesso ao conceder Acesso a Filial');
      })
      .catch(() => {
        toast.error('Error ao conceder Acesso a Filial');
      })
      .finally(() => {
        reset();
      });
  };

  return {
    handleSubmit,
    onSubmitFilial,
    control,
    setValue,
    accessDataFilial,
    optionsFiliais,
    isDirty,
    values,
  };
};
