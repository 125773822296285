import styled from 'styled-components';

export const CliSearchMainBox = styled.div`
  display: flex;
  flex-direction: row;
  width: clamp(200px, 280px, 620px);
  height: 2rem;
  padding: 0px 5px 5px 0;
`;

export const CliSearchInput = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  padding: 20px 10px;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  position: relative;

  svg {
    font-size: 1.7rem;
    color: #60606090;
    cursor: pointer;
    transition: color 0.4s ease, font-size 0.2s ease;
  }
  svg:hover {
    color: #606060;
    font-size: 1.8rem;
  }

  input {
    outline: none;
    width: 90%;
    padding: 5px;
    border: none;
    border-left: 1px solid #60606010;
    color: #606060;
    font-size: 1.2rem;
  }

  label {
    color: #606060;
    position: absolute;
    bottom: 100%;
    font-weight: 300;
    font-size: 1.25rem;
  }
`;
