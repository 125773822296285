import styled from 'styled-components';

export const prodListMainBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px;
`;

export const ProdListTableBox = styled.div`
  table {
    font-size: 0.7rem;
    white-space: nowrap !important;
    overflow: scroll;
    width: 100%;
  }
`;

export const ProdColumnTemplate = styled.div`
  width: 25vw;
`;

export const prodImage = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  Image {
    margin: 0 auto !important;
  }

  img {
    max-width: 30px;
    max-height: 30px;
  }
`;
