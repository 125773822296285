import { useManagers } from 'client/hooks/gerentes';

interface IUseManagersParams {
  codEmpresa: number;
}

export const UseManagers = ({ codEmpresa }: IUseManagersParams) => {
  const { data: managers } = useManagers(codEmpresa);

  const optionsManagers = managers?.map((item) => ({
    value: item.codGerente,
    label: `${item.nomeGerente} - [${item.codGerente}]`,
  }));

  return {
    optionsManagers,
  };
};
