import { GetCidadesReturnProps, GetClienteReturnProps } from 'client/interfaces/clientes.interface';

import api from 'services/api';

const _controller = 'Cliente';

export const getClienteBySearchText = async (codEmpresa: number, search: string): Promise<GetClienteReturnProps> => {
  const { data } = await api.get(`${_controller}/GetClienteBySearch/${codEmpresa}/${search}`);
  return data;
};

export const getCidadesBySearchText = async (search: string): Promise<GetCidadesReturnProps> => {
  const { data } = await api.get(`${_controller}/GetCidadeBySearch/${search}`);
  return data;
};
