import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: start;
`;

export const Container = styled.div`
  display: flex;
  height: 370px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  margin: 10px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;

  h3 {
    margin-right: 10px;
  }

  span {
    margin: 0px 0px 10px 10px;
    display: flex;
    align-items: center;

    label {
      margin-left: 10px;
    }
  }
`;

export const Body = styled.body`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
`;
