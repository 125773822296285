import styled from 'styled-components';

export const ItensTableMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const ItensTableTitle = styled.div`
  width: 100%;
  font-size: 1.2rem;
  padding: 5px 10px;
  font-weight: 400;
`;

export const ItensBodyBox = styled.section`
  width: 100%;
  background-color: #00000010;
  border-radius: 7.5px;
  overflow: hidden;
  border: 1px solid #cccccc70;
  background-color: #fff;
`;
export const AccordionHeaderTemplateBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  .reactivus-btn-success {
    background-color: #3f7d5420 !important;
  }

  .reactivus-btn-success:hover {
    background-color: #3f7d5440 !important;
  }

.reactivus-btn-success svg {
  font-size: .9rem;
}
`;
