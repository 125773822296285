import * as S from './styles';

import { AiOutlineMenu } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';

import logo from 'assets/images/logo/vmaisLogoCircled.png';

import { useNavigate } from 'react-router-dom';
import { HeaderUserMenuMobile } from './HeaderUserMenuMobile';

export default function FooterMenu() {
  const navigate = useNavigate();
  return (
    <S.FooterMenu>
      <AiOutlineMenu id={'kt_aside_mobile_toggle'} />
      <img src={logo} onClick={() => navigate(`/`)} />
      <div
        className="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="top-end"
        data-kt-menu-flip="top"
      >
        <BsFillPersonFill />
      </div>
      <HeaderUserMenuMobile />
    </S.FooterMenu>
  );
}
