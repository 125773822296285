import { useSupervisor } from 'client/hooks/supervisor';

interface IUseSupervisorParams {
  codEmpresa: number;
}

export const UseSupervisor = ({ codEmpresa }: IUseSupervisorParams) => {
  const { data: supervisors, isLoading } = useSupervisor(codEmpresa);

  const optionsSupervisor = supervisors?.map((item) => ({
    value: item.codSupervisor,
    label: `${item.nomesup} - [${item.codSupervisor}]`,
  }));

  return {
    optionsSupervisor,
    isLoading,
  };
};
