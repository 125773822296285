import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  getClients,
  getClientsToAddGroup,
  getGroupClient,
  getGroupClientFiliais,
  getGroupClientFilial,
} from 'client/api/groupClients';
import { IClient, IGroupClient } from 'client/interfaces/groupClient';

export const useGroupClients = (codEmpresa: number | string, enabled = true) =>
  useQuery<IGroupClient[], AxiosError>(['group-clients', codEmpresa], async () => await getGroupClient(codEmpresa), {
    enabled,
  });

export const useGroupClientsFilial = (codEmpresa: number | string, codFilial: number | string, enabled = true) =>
  useQuery<IGroupClient[], AxiosError>(
    ['group-clients', codEmpresa, codFilial],
    async () => await getGroupClientFilial(codEmpresa, codFilial),
    {
      enabled,
    },
  );

export const useGroupClientsFiliais = (
  codEmpresa: number | string,
  codFiliais: Array<number>,
  codCliente = 0,
  enabled = true,
) =>
  useQuery<IGroupClient[], AxiosError>(
    ['group-clients-filiais', codEmpresa, codFiliais],
    async () => await getGroupClientFiliais(codEmpresa, codFiliais, codCliente),
    {
      enabled,
    },
  );

export const useClients = (codEmpresa: number | string, enabled = true) =>
  useQuery<IClient[], AxiosError>(['clients', codEmpresa], async () => await getClients(codEmpresa), {
    enabled,
  });

export const useClientsToAddGroup = (codCompany: number | string, codGroup: number | string, enabled = true) =>
  useQuery<IClient[], AxiosError>(
    ['useClientsToAddGroup', codCompany],
    async () => await getClientsToAddGroup(codCompany, codGroup),
    {
      enabled,
    },
  );

