import styled from 'styled-components';

interface InputProps {
  height?: string;
}

export const Input = styled.input<InputProps>`
  height: ${(props) => (props.height ? props.height : '3rem')};
  padding: 10px 40px 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;
