import axios from 'axios';
import { createContext, useContext, useState } from 'react';

export const PedidoContext = createContext({});

export const PedidoProvider = ({ children }) => {

  const [cliData, setCliData] = useState(null);


  const [showLoader, setShowLoader] = useState(false);

  const [searchProd, setSearchProd] = useState(null);

  const [pedTable, setPedTable] = useState([]);
  const [pedList, setPedList] = useState([]);
  const [pedidosList, setPedidosList] = useState([]);

  const numberFormat = (number) => {
    if (number != null && number.toString() == 'Infinity') {
      number = 100.0;
    }
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(number).replace('$', '');
  };


  const handlePedGridSearch = (searchParam: string) => {
    let pedidos = [];

    Object.keys(pedidosList).forEach((key1) => {
      let itera = false;
      Object.keys(pedidosList[key1]).forEach((key2) => {
        if (pedidosList[key1][key2] != null && pedidosList[key1][key2].toString().includes(searchParam.toUpperCase())) {
          itera = true;
        }
      });
      if (itera) {
        pedidos.push(pedidosList[key1]);
      }
    });

    setPedList(pedidos);
  };

  //RETURN COM A EXPORTAÇÃO DOS ELEMENTOS PARA O PROVIDER
  return (
    <PedidoContext.Provider
      value={{
        numberFormat,
        handlePedGridSearch,
        showLoader,
        setShowLoader,
        searchProd,
        setSearchProd,
        pedTable,
        setPedTable,
        pedList,
        setPedList,
        cliData,
        setCliData
      }}
    >
      {children}
    </PedidoContext.Provider>
  );
};

export const usePedido = () => useContext(PedidoContext);
