import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import * as S from './styles';

import { useState, useEffect } from 'react';

import { Marker } from '@react-google-maps/api';
import clientSvg1 from '../../../../../../assets/svgs/clientSvg1';

import AnaliseHeader from '../analiseHeader';
import AnaliseSideBar from '../analiseSideBar/mapSidebar';
import MapSubtitle from './mapSubtitle';

import useMediaQuery from 'hooks/useMediaQuery';

interface ICoord {
  lat: number;
  lng: number;
}

export default function AnaliseMapa({ selectedCli, data, setSearchResult, setSelectedCli }: any) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [showMap, setShowMap] = useState<any>(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAUHxQUnO76uq2HBu2X6xzaLZPapIFv--0',
    libraries: ['drawing', 'places'],
  });

  const [mapCenter, setMapCenter] = useState<ICoord>({
    lat: -15.79630312741709,
    lng: -47.89262600823724,
  });

  const [mapZoom, setMapZoom] = useState<number>(5);

  const [renderedMarkers, setRenderedMarkers] = useState<any>([]);
  const [renderedLines, setRenderedLines] = useState<any>([]);

  useEffect(() => {
    setRenderedMarkers([]);
    setRenderedLines([]);
    setSelectedCli(null);
    if (!showMap) {
      setShowMap(isLoaded);
    }
    if (data && data[0]) {
      const lat1 = data[0].latitudeCliFv ? +data[0].latitudeCliFv.replace(',', '.') : 0;
      const lng1 = data[0].longitudeCliFv ? +data[0].longitudeCliFv.replace(',', '.') : 0;
      setMapCenter({ lat: lat1, lng: lng1 });
    }
  }, [isLoaded, data]);

  useEffect(() => {
    if (selectedCli) {
      handleCliMarkerRender(selectedCli);
    } else {
      setRenderedMarkers([]);
      setRenderedLines([]);
    }
  }, [selectedCli]);

  const customMarkerIcon = (color: string, code: number | string) => {
    let svgTemplate: any = '';
    svgTemplate = {
      url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(clientSvg1(code, color)),
    };
    return svgTemplate;
  };

  const handleCliMarkerRender = (data: any) => {
    function findMidpoint(coord1: ICoord, coord2: ICoord): ICoord {
      const midX = (coord1.lat + coord2.lat) / 2;
      const midY = (coord1.lng + coord2.lng) / 2;

      return { lat: midX, lng: midY };
    }

    const lat1 = selectedCli.latitudeCliFv ? +selectedCli.latitudeCliFv.replace(',', '.') : 0;
    const lng1 = selectedCli.longitudeCliFv ? +selectedCli.longitudeCliFv.replace(',', '.') : 0;

    const lat2 = data.latitude ? +data.latitude.replace(',', '.') : 0;
    const lng2 = data.longitude ? +data.longitude.replace(',', '.') : 0;

    const middlePoint = findMidpoint({ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 });

    setMapCenter(middlePoint);

    let distancia = data.distancia ? data.distancia.replace(',', '.') : '0';
    if (distancia.includes('km')) {
      distancia = distancia.replace('km', '');
    } else {
      distancia = distancia.replace('M', '');
      distancia = +distancia / 1000;
    }

    setMapZoom(
      +distancia < 0.55
        ? 16
        : +distancia < 1.55
        ? 15
        : +distancia < 4.55
        ? 13
        : +distancia > 6.55 && +distancia > 200
        ? 7
        : 12,
    );

    const markers: any = [];

    const mainColor =
      data.status == 'L' ? '#228F6B' : data.status == 'R' ? '#cb2a29' : data.status == 'A' ? '#FFA500' : '#cb2a29';

    markers.push(<Marker icon={customMarkerIcon('#007fff', 'C')} key={2} position={{ lat: lat1, lng: lng1 }}></Marker>);

    markers.push(<Marker icon={customMarkerIcon(mainColor, 'V')} key={1} position={{ lat: lat2, lng: lng2 }}></Marker>);

    setRenderedMarkers(markers);

    const lines: any = [];

    lines.push(
      <Polyline
        key={Math.random()}
        options={{ strokeColor: mainColor }}
        path={[
          { lat: lat1, lng: lng1 },
          { lat: lat2, lng: lng2 },
        ]}
      />,
    );
    setRenderedLines(lines);
  };

  return (
    <S.MapMainBox>
      {showMap && (
        <GoogleMap
          mapContainerClassName="map-container"
          center={mapCenter}
          zoom={mapZoom}
          options={{
            mapTypeControl: false,
            mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            streetViewControl: true,
            streetViewControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            fullscreenControl: false,
          }}
        >
          {renderedMarkers}
          {renderedLines}
          {isWebScreen && (
            <AnaliseHeader
              setSearchResult={setSearchResult}
              selectedCli={selectedCli}
              setSelectedCli={setSelectedCli}
            />
          )}
          <AnaliseSideBar
            data={data}
            selectedCli={selectedCli}
            setSearchResult={setSearchResult}
            setSelectedCli={setSelectedCli}
          />
          <MapSubtitle selectedCli={selectedCli} />
        </GoogleMap>
      )}
    </S.MapMainBox>
  );
}
