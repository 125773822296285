import * as S from './styles';
import { FaRuler } from 'react-icons/fa';
import { BsCalendar2WeekFill, BsFillPersonFill, BsPersonBadgeFill } from 'react-icons/bs';
import { IoMdPin } from 'react-icons/io';
import useMediaQuery from 'hooks/useMediaQuery';

export default function AnaliseCards({ data, setSelectedCli, selectedCli, setShowMenu }: any) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const handleRowClick = (rowData: any) => {
    if (selectedCli && selectedCli.codcli == rowData.codcli) {
      setSelectedCli(null);
    } else {
      !isWebScreen && setShowMenu(false);
      setSelectedCli(rowData ?? null);
    }
  };

  const DataCard = ({ data }: any) => {
    return (
      <S.DataCard
        status={data.status}
        selected={selectedCli && data.codcli === selectedCli.codcli}
        onClick={() => handleRowClick(data)}
      >
        <S.CardTitle>
          <S.CardContent status={data.status}>
            <BsFillPersonFill />[{data.codcli}] {data.nomeCliente}
          </S.CardContent>
        </S.CardTitle>
        <S.CardTitle>
          <S.CardContent status={data.status}>
            <IoMdPin /> {data.endLogradouro}, {data.endNumero} - {data.endBairro}. {data.endCidade} - {data.endUf}
          </S.CardContent>
        </S.CardTitle>

        <S.CardVendedor>
          <S.CardContent status={data.status}>
            <BsPersonBadgeFill />[{data.codusur}] {data.nomeVendedor}
          </S.CardContent>
        </S.CardVendedor>

        <S.CardDistancia>
          <S.CardContent status={data.status}>
            <FaRuler />
            {data.distancia}
          </S.CardContent>
        </S.CardDistancia>

        <S.CardDistancia>
          <S.CardContent status={data.status}>
            <BsCalendar2WeekFill    />
            {new Date(data.dtinclusao).toLocaleDateString('pt-BR')}
          </S.CardContent>
        </S.CardDistancia>

        <S.CardSubTitle status={data.status}>
          {data.status == 'L' ? 'LIBERADO' : data.status == 'R' ? 'REJEITADO' : 'ANÁLISE'}
        </S.CardSubTitle>
      </S.DataCard>
    );
  };

  return (
    <S.AnaliseTableMainBox>
      <S.DataCardsList>
        {data.length > 0
          ? data?.map((data: any) => <DataCard key={Math.random()} data={data} />)
          : 'Nenhum dado encontrado :/'}
      </S.DataCardsList>
    </S.AnaliseTableMainBox>
  );
}
