import styled from 'styled-components';

export const RegraComercialMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0px;
  /* background-color: #00000010; */
`;
