import { Calendar, CalendarChangeParams, CalendarValueType } from 'primereact/calendar';

interface InputDataProps {
  value?: CalendarValueType;
  placeholder?: string;
  dateFormat: string;
  mask?: string;
  onChange?(e: CalendarChangeParams): void;
}

const InputData = ({ value, placeholder, dateFormat, mask, onChange }: InputDataProps) => {
  return (
    <Calendar
      value={value}
      dateFormat={dateFormat}
      placeholder={placeholder}
      mask={mask}
      onChange={onChange}
    />
  );
};

export default InputData;
