import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  getCabecalho,
  getCabecalhoItems,
  getRestricoes,
  getRespostas,
  getRespostaWithItems,
  getTipos,
} from 'client/api';
import { FormTipo, FormularioCab, FormularioItem, RespostaCab, Restricao } from 'client/interfaces';

export const useCabecalhos = (codEmpresa: number | string, enabled = true) =>
  useQuery<FormularioCab[], AxiosError>(['forms/cabecalho', codEmpresa], async () => await getCabecalho(codEmpresa), {
    enabled,
  });

export const useRespostas = (start: Date, end: Date, codEmpresa: number, enabled = true) =>
  useQuery<RespostaCab[], AxiosError>(
    ['forms/respostas', start, end],
    async () => await getRespostas(start, end, codEmpresa),
    {
      staleTime: 1000 * 60 * 5,
      enabled,
    },
  );

export const useRespostaWithItems = (resposta: string, codEmpresa: number, enabled = true) =>
  useQuery<RespostaCab, AxiosError>(
    ['forms/respostas', resposta],
    async () => await getRespostaWithItems(resposta, codEmpresa),
    {
      staleTime: 1000 * 60 * 2,
      enabled,
    },
  );

export const useCabecalhoItems = (id: number, codEmpresa: number, enabled = true) =>
  useQuery<FormularioItem[], AxiosError>(
    ['forms/cabecalho/items', id],
    async () => await getCabecalhoItems(id, codEmpresa),
    {
      // staleTime: 1000 * 60 * 5,
      enabled,
    },
  );

export const useRestricoes = (id: number, codEmpresa: number, enabled = true) =>
  useQuery<Restricao[], AxiosError>(['forms/restricoes', id], async () => await getRestricoes(id, codEmpresa), {
    staleTime: 1000 * 60 * 5,
    enabled,
  });

export const useTipos = (enabled = true) =>
  useQuery<FormTipo[], AxiosError>(['forms/tipos'], getTipos, {
    staleTime: 1000 * 60 * 15,
    enabled,
  });
