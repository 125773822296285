import { useVendedores } from 'client/hooks/vendedores';

interface IUserSellers {
  codCompany: number | string;
}

export const UseSellersSelect = ({ codCompany }: IUserSellers) => {
  const { data } = useVendedores(codCompany);

  const optionsSellers = data?.map((item) => ({
    value: item.codVendedor,
    label: `${item.nome} - [${item.codVendedor}]`,
    codSupervisor: item.codSupervisor,
  }));

  return {
    optionsSellers,
  };
};
