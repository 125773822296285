import { InputText } from 'primereact/inputtext';
import * as S from './styles';
import { UseResetUserData } from 'hooks/useAccessData/useResetUserData';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useUser } from 'client/hooks';
import VmButton from 'components/VmButton/VmButton';
import editIcon from 'assets/svgs/edit.svg';

export const ResetUserData = () => {
  const {
    selectedUsuario,
    updateInputValues,
    handleSubmit,
    onSubmit,
    control,
    isEdit,
    setIsEdit,
    handleResetPassword,
  } = UseResetUserData();

  useEffect(() => {
    updateInputValues();
  }, [selectedUsuario]);

  return (
    <S.ResetMainBox>
      <S.BoxPassword>
        <p>Apagar senha do usuário {selectedUsuario?.nome}</p>
        <VmButton
          options={{
            label: 'Apagar Senha',
            style: 'btn-danger',
            size: 'btn-sm',
          }}
          onClick={() => handleResetPassword()}
        />
      </S.BoxPassword>

      <S.ResetFormBox onSubmit={handleSubmit(onSubmit)}>
        <S.Row>
          <Controller
            name="name"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <S.Box style={{ width: '30%' }}>
                <label>Nome</label>
                <InputText disabled={!isEdit} {...field} />
              </S.Box>
            )}
          />

          <Controller
            name="birth_date"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <S.Box style={{ width: '30%' }}>
                <label>Data de nascimento</label>
                <InputText type="date" disabled={!isEdit} {...field} />
              </S.Box>
            )}
          />

          <S.Box />
        </S.Row>

        <S.Row>
          <Controller
            name="email"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <S.Box>
                <label>Email</label>
                <InputText type="email" disabled={!isEdit} {...field} />
              </S.Box>
            )}
          />

          <Controller
            name="cellphone"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <S.Box>
                <label>Celular</label>
                <InputText {...field} maxLength={14} disabled={!isEdit} />
              </S.Box>
            )}
          />

          <S.Box style={{ justifyContent: 'end' }}>
            {isEdit ? (
              <>
                <VmButton
                  options={{
                    label: 'Salvar',
                    style: 'btn-success',
                    size: 'btn-sm',
                    // disabled: !values || !values?.manager?.length
                  }}
                  type="submit"
                />
              </>
            ) : (
              <img
                onClick={() => setIsEdit(!isEdit)}
                style={{ height: '2rem', width: '2rem' }}
                src={editIcon}
                alt="Editar"
              />
            )}
          </S.Box>
        </S.Row>
      </S.ResetFormBox>
    </S.ResetMainBox>
  );
};
