import { Controller } from 'react-hook-form';
import * as S from './styles';
import { InputText } from 'primereact/inputtext';
import VmButton from 'components/VmButton/VmButton';
import { BsChevronLeft, BsFillPeopleFill } from 'react-icons/bs';
import { UseFiliaisSelect } from 'hooks/useFiliais/useFiliaisSelect';
import { Option, Select } from 'components/Select/styles';
import { useUser } from 'client/hooks';
import { UseCompaniesSelect } from 'hooks/useCompanies/useCompaniesSelect';
import { useEffect, useState } from 'react';
import { useAddGroupClients } from 'hooks/useGroupClients/useAddGroupClients';
import { useNavigate } from 'react-router-dom';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

export const AddGroupClients = () => {
  const [isValid, setIsValid] = useState(false);

  const {
    isDefaultFilial,
    isDefaultCompany,
    handleSubmit,
    control,
    values,
    setValue,
    errors,
    DefaultValues,
    handleAddGroupClients,
    handleClearFilial,
  } = useAddGroupClients();

  const { data: user } = useUser();
  const navigate = useNavigate();

  const { optionsCompanies, codEmpresaVMais } = UseCompaniesSelect();

  const { optionsFiliais } = UseFiliaisSelect({
    codEmpresa: Number(values?.codEmpresa !== DefaultValues.COMPANY && values.codEmpresa),
  });

  const { titlePage, setTitlePage } = useTitlePageStore();

  useEffect(() => {
    setTitlePage('Adicionar Grupo Cliente')
  }, [titlePage]);

  useEffect(() => {
    if (
      values.codEmpresa === DefaultValues.COMPANY ||
      values.codFilial === DefaultValues.FILIAL ||
      values.nomeGrupo === ''
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [values]);

  useEffect(() => {
    handleClearFilial();
  }, [values.codEmpresa]);

  useEffect(() => {
    setValue('codEmpresa', String(user?.codEmpresa));
  }, [user]);

  return (
    <S.ContainerPage>
      <h1>
        <VmButton
          style={{
            marginRight: '10px',
          }}
          options={{
            style: 'btn-light',
            icon: <BsChevronLeft />,
            iconPosition: 'right',
            rounded: true,
            tooltip: {
              text: 'Voltar para Lista de Grupos',
              position: 'right',
              show: true,
            },
          }}
          onClick={() => {
            navigate('/grupo-clientes');
          }}
        />
        Cadastro Grupo de Clientes
      </h1>
      <S.Form onSubmit={handleSubmit(handleAddGroupClients)}>
        <S.ItemRow>
          <S.TitleInput error={errors.nomeGrupo && true}>*Nome Grupo de Clientes</S.TitleInput>
          <Controller
            rules={{
              required: true,
            }}
            name="nomeGrupo"
            defaultValue=""
            control={control}
            render={({ field }) => <InputText {...field} />}
          />
          <S.DescriptionInput error={errors.nomeGrupo && true}>Digite um nome para o grupo.</S.DescriptionInput>
        </S.ItemRow>

        <S.ItemRow>
          {user?.codEmpresa === codEmpresaVMais ? (
            <>
              <S.TitleInput error={errors.codEmpresa && true}>*Empresa</S.TitleInput>
              <Controller
                name="codEmpresa"
                rules={{
                  required: true,
                  validate: isDefaultCompany,
                }}
                defaultValue={user ? String(user.codEmpresa) : DefaultValues.COMPANY}
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <Option defaultChecked>Selecione uma empresa.</Option>
                    {optionsCompanies?.map((company) => (
                      <Option key={company.value} value={company.value}>
                        {company.label + ' [' + company.value + ']'}
                      </Option>
                    ))}
                  </Select>
                )}
              />
              <S.DescriptionInput error={errors.codEmpresa && true}>Selecione uma empresa.</S.DescriptionInput>
            </>
          ) : (
            <>
              <S.TitleInput error={errors.codEmpresa && true}>*Empresa</S.TitleInput>
              <Controller
                name="codEmpresa"
                rules={{
                  required: true,
                  validate: isDefaultCompany,
                }}
                defaultValue={user ? String(user.codEmpresa) : DefaultValues.COMPANY}
                control={control}
                render={({ field }) => <InputText disabled {...field} />}
              />
            </>
          )}
        </S.ItemRow>

        <S.ItemRow>
          <S.TitleInput error={errors.codFilial && true}>*Código Filial</S.TitleInput>
          <Controller
            name="codFilial"
            rules={{
              required: true,
              validate: isDefaultFilial,
            }}
            control={control}
            defaultValue={DefaultValues.FILIAL}
            render={({ field }) => (
              <Select {...field}>
                <Option defaultChecked>Selecione uma filial.</Option>
                {optionsFiliais?.map((filial) => (
                  <Option key={filial.value} value={filial.value}>
                    {filial.label + ' [' + filial.value + ']'}
                  </Option>
                ))}
              </Select>
            )}
          />

          <S.DescriptionInput error={errors.codFilial && true}>Selecione uma filial.</S.DescriptionInput>
        </S.ItemRow>

        <S.ItemRow style={{ width: '100%' }}>
          <VmButton
            type="submit"
            options={{
              label: 'Adicionar',
              style: 'btn-success',
              width: '100px',
              heigth: '',
              iconPosition: 'left',
              size: 'btn-md',
              disabled: !isValid,
              loading: false,
            }}
          />
        </S.ItemRow>
      </S.Form>
    </S.ContainerPage>
  );
};
