import styled from 'styled-components';

export interface IIModalProps {
  color: string;
}

export const Box = styled.div<IIModalProps>`
  background-color: ${(props) =>
    props.color === 'error' ? 'var(--red-100)' : props.color === 'success' ? 'var(--green-100)' : 'var(--gray-100)'};
  border: 1px solid
    ${(props) =>
      props.color === 'error' ? 'var(--red-600)' : props.color === 'success' ? 'var(--green-600)' : 'var(--dark-400)'};
  display: flex;
  align-items: center;
  text-align: center;
  width: 60%;
  padding: 10px;
  border-radius: 16px;
  margin: 30px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
`;

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;

  > button {
    width: 45%;
  }
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  margin: 10px 0;
`;

export const Title = styled.div`
  width: 100%;
  justify-content: flex-start;
  span {
    font-weight: bold;
    font-size: 13px;
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: var(--gray-100);
  margin-left: 10px;
  margin-right: 10px;

`;