import api from 'services/api';
import { RegisterRequest, LoginRequest, LoginResponse } from 'client/interfaces';

const _controller = 'auth';

export const registerAsync = async (request: RegisterRequest): Promise<void> => {
  await api.post(`${_controller}/register`, request);
};

export const loginAsync = async (request: LoginRequest): Promise<LoginResponse> => {
  const { data } = await api.post(`${_controller}/login`, request);
  return data;
};
