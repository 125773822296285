import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 40px;
  }
`;

export const Box = styled.div`
  width: 30%;
  height: 100%;
`;

export const BoxRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  span {
    color: gray;
  }
`;

export const ContainerBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
`;

export const ContainerDefault = styled.div`
  width: 100%;
  height: 50vh;
  margin-top: 20px;
  /* background-color: red; */
`;

export const ListPermissions = styled.div`
  width: 100%;
  margin-top: 30px;
  border-top: 1px solid var(--dark-200);

  .p-orderlist .p-orderlist-controls {
    display: none;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 40px;
  background-color: var(--dark-200);
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;

  span {
  }
`;

export const ButtonSave = styled.button`
  border: 0;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 10px;

  svg {
    margin-right: 5px;
  }
`;

export const ButtonDelete = styled.button`
  border: 0;
  background-color: transparent;
`;
