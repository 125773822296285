import * as S from '../styles';
import { OrderList } from 'primereact/orderlist';
import { UserData } from 'client/interfaces';
import { Controller } from 'react-hook-form';
import { useFilialAccessData } from 'hooks/useAccessData/useFilialAccessData';
import { MultiSelect } from 'primereact/multiselect';
import VmButton from 'components/VmButton/VmButton';
import { IGetAccessData } from 'client/interfaces/acessoDados.interface';

interface IAccessData {
  selectedUsuario: UserData;
  user: UserData;
  itemListTemplate: ({ data, cod }: { data: IGetAccessData; cod: string }) => JSX.Element;
}

const ContainerFilial = ({ selectedUsuario, user, itemListTemplate }: IAccessData) => {
  const { values, optionsFiliais, accessDataFilial, control, setValue, handleSubmit, onSubmitFilial } =
    useFilialAccessData({
      codEmpresa: selectedUsuario?.codEmpresa,
      codUsuario: selectedUsuario?.codUsuario,
      codfuncLib: user?.codUsuario,
    });

  const onShow = () => {
    let selectAllCheckbox = document.querySelector('.p-multiselect-header > .p-checkbox');
    selectAllCheckbox.after(' TODAS FILIAIS');
  };

  return (
    <S.Box>
      <form onSubmit={handleSubmit(onSubmitFilial)}>
        <p>Filial</p>
        <Controller
          name="filial"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => (
            <MultiSelect
              disabled={!optionsFiliais?.length}
              style={{ width: '100%', marginBottom: '10px' }}
              id="multiselect"
              placeholder="Selecione uma Filial"
              value={field.value}
              options={optionsFiliais ? optionsFiliais.sort((a, b) => a.label.localeCompare(b.label)) : []}
              onChange={(e) => setValue('filial', e.value)}
              onShow={onShow}
              filter
              filterBy="label"
            />
          )}
        />

        <VmButton
          options={{
            label: 'Salvar Filial',
            style: 'btn-success',
            size: 'btn-sm',
            disabled: !values || !values?.filial?.length,
          }}
          type="submit"
        />
      </form>

      <S.ListPermissions>
        <OrderList
          value={accessDataFilial}
          header="Acessos a Filiais"
          filter
          dataKey="pk"
          filterBy="nome"
          itemTemplate={(rowData: IGetAccessData) => itemListTemplate({ data: rowData, cod: 'codFilial' })}
        />
      </S.ListPermissions>
    </S.Box>
  );
};

export default ContainerFilial;
