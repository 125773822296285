import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getSqlCommands } from 'client/api';
import { ComandoSql } from 'client/interfaces';

export const useComandosSql = (enabled = true) =>
  useQuery<ComandoSql[], AxiosError>(['sql-commands'], getSqlCommands, {
    staleTime: 1000 * 60 * 5,
    enabled,
  });
