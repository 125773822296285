/* eslint-disable @typescript-eslint/restrict-plus-operands */

import pointInPolygon from 'point-in-polygon';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';

import { BsInfoCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

import VmButton from 'components/VmButton/VmButton';
import { MapContext } from 'providers/map';

import 'primeicons/primeicons.css';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { editPoly, postAdjustCliCoords } from 'client/api/areaMapa';
import useMediaQuery from 'hooks/useMediaQuery';

import { ProgressBar } from 'primereact/progressbar';

const UpdateMapData = () => {
  const { polygonos, reloadAllData, clientes, setores, codEmpresa, setLoadCliMap, setLoadCliMapMessage } =
    useContext(MapContext);
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [pendCliData, setPendCliData] = useState<any>([]);
  const [cliChangesList, setCliChanges] = useState<any>([]);
  const [pendCliCoordsData, setPendCliCoordsData] = useState<any>([]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    validaCliData()
      .then((res) => {
        console.log('Clients successfully validated!');
      })
      .catch((err) => {
        console.log(err);
      });
  }, [polygonos, clientes]);

  async function validaCliData() {
    const polys = polygonos;
    const clis = clientes;

    try {
      const polysToUpdate = [];

      const cliChanges = [];

      for (let i = 0; i < polys.length; i++) {
        let poly = JSON.parse(polys[i].polygono[0].latlong);

        if (poly[0].lat === undefined) {
          poly = poly.map((lats: any) => {
            return { lat: lats[0], lng: lats[1] };
          });
        }

        let clisInPoly = await validarClientesNoPoligono(clis, poly);

        clisInPoly = clisInPoly.filter((cli: any) => cli.inclusaoManual === 'N');

        const cliWithDiffSetor = clisInPoly?.filter((cli: any) => cli.codSetor !== polys[i].codSetor);

        if (cliWithDiffSetor.length > 0) {
          cliChanges.push({
            cliente: cliWithDiffSetor,
            setor: setores?.filter((set: any) => set.codSetor === polys[i].codSetor),
          });
        }

        if (cliWithDiffSetor.length > 0) {
          polysToUpdate.push({
            codArea: 0,
            codSetor: polys[i].codSetor,
            codFilial: 1,
            codEmpresa: codEmpresa,
            latlngs: JSON.stringify(polys[i].latlngs),
            clientes: clisInPoly,
          });
        }
      }

      const pendCliCoordsDataList = clis?.filter((cli: any) => cli.latitude == 0 || cli.longitude == 0);

      setPendCliData(polysToUpdate);
      setCliChanges(cliChanges);
      setPendCliCoordsData(pendCliCoordsDataList);
    } catch (err) {
      console.log(err);
    }
  }

  async function validarClientesNoPoligono(clientes: any, poligono: any) {
    const coordsPoligono = poligono.map((ponto: any) => [ponto.lng, ponto.lat]);

    const clientesComCoordenadas = clientes.filter((cliente: any) => cliente.latitude && cliente.longitude);

    const clientesDentroDoPoligono = clientesComCoordenadas.filter((cliente: any) => {
      const coordsCliente = [cliente.longitude, cliente.latitude];
      return pointInPolygon(coordsCliente, coordsPoligono);
    });

    return clientesDentroDoPoligono;
  }

  const saveCliPendData = async () => {
    if (pendCliData.length > 0) {
      editPoly(pendCliData)
        .then((res) => {
          if (pendCliCoordsData.length == 0) {
            reloadAllData();
            setShowModal(false);
            toast.success('Clientes ajustados com sucesso!');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro ao revalidar dados!');
        })
        .finally(() => {
          if (pendCliCoordsData.length == 0) {
            setLoadCliMap(false);
          }
        });
    }

    const arrayPieces = 20;

    if (pendCliCoordsData.length > 0) {
      setLoadCliMap(true);
      setLoadCliMapMessage(
        <span>
          Aguarde o processamento dos dados dos clientes.
          <br />
          Esse processo poderá levar alguns minutos.
          <br />
          <ProgressBar value={0}  color={'#228f6b'} />
        </span>,
      );
      setShowModal(false);
      let reqsToMake = +(pendCliCoordsData.length / arrayPieces).toFixed();
      let reqsMade = 0;
      for (let i = 0; i < pendCliCoordsData.length; i = i + arrayPieces) {
        let cliCoordsToAdjust = {
          codEmpresa: codEmpresa,
          clientes: pendCliCoordsData.slice(i, i + arrayPieces).map((cli: any) => {
            return { codCli: cli.codCli };
          }),
        };
        let cliReqsMadePerc: number = +((reqsMade * arrayPieces * 100) / pendCliCoordsData.length).toFixed();
        setLoadCliMapMessage(
          <span>
            Aguarde o processamento dos dados dos clientes.
            <br />
            Esse processo poderá levar alguns minutos.
            <br />
            <ProgressBar value={cliReqsMadePerc > 5 ? cliReqsMadePerc : 5} color={'#228f6b'} />
          </span>,
        );
        reqsMade = reqsMade + 1;
        await postAdjustCliCoords(cliCoordsToAdjust)
          .then((res) => {
            if (reqsMade >= reqsToMake) {
              reloadAllData();
              toast.success('Coordenadas dos clientes ajustadas com sucesso!');
            }
          })
          .catch((err) => {
            console.log(err);
            reloadAllData();
            toast.error('Falha ao revalidar os dados!');
          })
          .finally(() => {
            if (reqsMade >= reqsToMake) {
              reloadAllData();
              setLoadCliMap(false);
            }
          });
      }
    }
  };

  return pendCliData.length > 0 || pendCliCoordsData.length > 0 ? (
    <>
      <VmButton
        style={{ marginRight: '5px' }}
        options={{
          icon: <BsInfoCircleFill />,
          label:
            pendCliData.length + pendCliCoordsData.length > 1 && isWebScreen
              ? pendCliData.length + pendCliCoordsData.length + ' Pendências'
              : isWebScreen
              ? pendCliData.length + pendCliCoordsData.length + ' Pendência'
              : '',
          iconPosition: 'left',
          rounded: !isWebScreen,
          style: 'btn-danger',
          tooltip: {
            show: true,
            position: 'bottom',
            text:
              pendCliData.length + pendCliCoordsData.length
                ? pendCliData.length + pendCliCoordsData.length + ' Pendências'
                : pendCliData.length + pendCliCoordsData.length + ' Pendência',
          },
        }}
        onClick={() => setShowModal(true)}
      />

      <Dialog
        visible={showModal}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={
          pendCliData.length + pendCliCoordsData.length > 1
            ? pendCliData.length + pendCliCoordsData.length + ' Clientes Irregulares'
            : pendCliData.length + pendCliCoordsData.length + ' Cliente Irregular'
        }
        modal
        className="p-fluid"
        onHide={() => setShowModal(false)}
      >
        <div style={{ margin: '20px 0' }}>
          <h4>Lista de alterações:</h4>
          {cliChangesList?.map((change: any) => {
            return (
              <span key={change.cliente[0].codC}>
                <br />
                Cliente: {change.cliente[0].codCli} - {change.cliente[0].razaosocial} será vinculado ao setor:
                {' ' + change.setor[0].codSetor} - {change.setor[0].setor}
                <br />
                <hr></hr>
              </span>
            );
          })}
          {`${pendCliCoordsData.length} ${
            pendCliCoordsData.length > 1 ? 'clientes' : 'cliente'
          } com coordenadas inválidas ${pendCliCoordsData.length > 1 ? 'serão validados' : 'será validados'}.`}
        </div>

        <div style={{ display: 'flex', gap: '10px' }}>
          <VmButton
            options={{
              label: 'Reprocessar Dados',
              iconPosition: 'left',
              rounded: false,
              style: 'btn-success',
            }}
            onClick={() => saveCliPendData()}
          />
          <VmButton
            options={{
              label: 'Fechar',
              iconPosition: 'left',
              rounded: false,
              style: 'btn-danger',
            }}
            onClick={() => setShowModal(false)}
          />
        </div>
      </Dialog>
    </>
  ) : (
    <></>
  );
};

// EXPORTA FUNÇÃO POR PADRÃO
export default UpdateMapData;
