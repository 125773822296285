import * as S from './styles';

interface IToggle {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  text1: string;
  text2: string;
  setValue1: React.Dispatch<React.SetStateAction<string>>;
  setValue2: React.Dispatch<React.SetStateAction<string>>;
}

const Toggle = ({ isActive, setIsActive, text1, text2, setValue1, setValue2 }: IToggle) => {
  const button1 = () => {
    setIsActive(!isActive);
    setValue1('');
  };

  const button2 = () => {
    setIsActive(!isActive);
    setValue2('');
  };

  return (
    <S.Toggle isActive={isActive}>
      <S.Button type="button" className="fs-4 fs-lg-3" isActive={isActive} onClick={() => button1()}>
        {text1}
      </S.Button>
      <S.Button type="button" className="fs-4 fs-lg-3" isActive={!isActive} onClick={() => button2()}>
        {text2}
      </S.Button>
    </S.Toggle>
  );
};

export default Toggle;
