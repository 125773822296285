/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

export const handleAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.data != null) {
      const response = error.response?.data as any;
      return response.message as string;
    }
    return error.message;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return 'unknown error';
};

export const getAxiosStatusCode = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    return error.response?.status;
  }
  return 500;
};
