export function formatarCnpj(cnpj) {
  const cnpjLimpo = cnpj.replace(/[^\d]/g, '');

  return cnpjLimpo.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
}

export const formatCPFOrCNPJ = (CPF_CNPJ: string) => {
  const cleanedInput = CPF_CNPJ.replace(/\D/g, '');

  if (cleanedInput.length === 11) {
    return cleanedInput.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  else if (cleanedInput.length === 14) {
    return cleanedInput.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
  else {
    return CPF_CNPJ;
  }
};