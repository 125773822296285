import { useEffect, useState } from 'react';

import style from './debitList.module.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// import useApi from '@/providers/apiProvider';

import { usePedido } from 'providers/pedidoProvider';

export default function DebitList({ cliente, showDebList, setShowDebList }) {
  const { numberFormat }: any = usePedido();

  // useEffect(() => {
  //   if (showDebList && cliente != null) {
  //     useApi({
  //       method: 'GET',
  //       route: 'debitos',
  //       params: {
  //         codCli: cliente.CODCLI,
  //       },
  //     })
  //       .then((res) => {
  //         if (res.length != 0) {
  //           for (let i = 0; i < res.length; i++) {
  //             res[i].DTEMISSAO = new Date(res[i].DTEMISSAO).toLocaleDateString();
  //             res[i].DTVENC = new Date(res[i].DTVENC).toLocaleDateString();
  //             res[i].VALOR = numberFormat(res[i].VALOR);
  //           }

  //           setDebList(res);

  //           setShowLoader(false);
  //         } else {
  //           setShowLoader(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setShowLoader(false);
  //       });
  //   }
  // }, [showDebList]);

  // const [debTable, setDebTable] = useState([
  //   <Column key={'FILIAL'} field="CODFILIAL" header="FILIAL"></Column>,
  //   <Column key={'NF'} field="DUPLIC" header="NF"></Column>,
  //   <Column key={'DT. EMISSÃO'} field="DTEMISSAO" header="DT. EMISSÃO"></Column>,
  //   <Column key={'DT. VENCIMENTO'} field="DTVENC" header="DT. VENCIMENTO"></Column>,
  //   <Column key={'DIAS VENCIDOS'} field="DIAS_VENCIDO" header="DIAS VENCIDOS"></Column>,
  //   <Column key={'VALOR'} field="VALOR" header="VALOR"></Column>,
  //   <Column key={'CÓD'} field="CODCOB" header="CÓD"></Column>,
  //   <Column key={'COBRANÇA'} field="COBRANCA" header="COBRANÇA"></Column>,
  // ]);
  // const [debList, setDebList] = useState([]);

  // const header = (
  //   <div className={style.tableHeader}>
  //     <span className={style.tableHeaderTitle}>Contas a Receber</span>
  //     <div>
  //       <span className={style.debListCredCard}>
  //         Lim.Crédito: <br />{' '}
  //         <b style={{ color: '#375e97' }}>R${cliente != null ? numberFormat(cliente.LIMITE_CRED) : '0,00'}</b>
  //       </span>

  //       <span className={style.debListCredCard}>
  //         A Receber: <br />{' '}
  //         <b style={{ color: '#F64f3d' }}>R${cliente != null ? numberFormat(cliente.VLABERTOCLI) : '0,00'}</b>{' '}
  //       </span>

  //       <span className={style.debListCredCard}>
  //         Disponível: <br />{' '}
  //         <b style={{ color: '#228f6b' }}>
  //           R${cliente != null ? numberFormat(cliente.LIMITE_CRED - cliente.VLABERTOCLI) : '0,00'}
  //         </b>
  //       </span>
  //     </div>
  //   </div>
  // );

  return (
    <div
      className={style.debListMainBox}
      style={{ opacity: showDebList ? '1' : '0', zIndex: showDebList ? '998' : '-1' }}
    >
      <div className={style.debListBackBox} onClick={(e) => setShowDebList(false)}></div>
      <div className={style.debListTabBox} style={{ marginTop: showDebList ? '0' : '-100%' }}>
        {/* <DataTable
          value={debList}
          tableStyle={{ fontSize: '.8rem' }}
          size={'small'}
          className={'defaultBoxShadow'}
          header={header}
          emptyMessage="Nenhum débito para este cliente :)"
          paginator={debTable.length > 10}
          rows={10}
        >
          {debTable}
        </DataTable> */}
      </div>
    </div>
  );
}
