import styled from 'styled-components';

export const PmcMainBox = styled.section`
  width: 100%;
  max-width: 95dvw;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

export const PPaymentDataBox = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

export const PTitle = styled.span`
  color: #228f6b;
  font-weight: thin;
  font-size: 1.1rem;
`;

export const PPaymentData = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const PTotal = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #1dd699;
  font-size: 1.4rem;
  border-top: 1px solid #60606010;
  span{
  font-weight: 600;
  }
`;
