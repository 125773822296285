import api from 'services/api';

const _controller = 'MapaCli';

interface IDataUpdateFrequenceVisit {
  codEmpresa: number;
  codMapa: number;
  codCli: number;
  freqVisita: number;
  tempoMinVisita: number;
  dtProxVisita: string;
  sequencia: number;
  bloqRoteiro: string;
  agendamentoDoCliente: string;
  domingoManha?: string;
  domingoTarde?: string;
  segundaManha?: string;
  segundaTarde?: string;
  tercaManha?: string;
  tercaTarde?: string;
  quartaManha?: string;
  quartaTarde?: string;
  quintaManha?: string;
  quintaTarde?: string;
  sextaManha?: string;
  sextaTarde?: string;
  sabadoManha?: string;
  sabadoTarde?: string;
}

export const updateFrequenceVisit = async (data: IDataUpdateFrequenceVisit): Promise<void> => {
  await api.put(`${_controller}`, data);
};
