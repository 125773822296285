import * as S from './cliCard.styles';

export default function CliCard({ cliente }: any) {
  return (
    <S.ClicMainBox>
      <S.CTitle>Dados Do Cliente</S.CTitle>
      <S.CData>
        <span>CNPJ</span>
        <span>{cliente.cnpjcpf ?? ''}</span>
      </S.CData>
      <S.CData>
        <span>LOGRADOURO</span>
        <span>
          {cliente.endLogradouro ?? ''}
          {cliente.endNumero ? ` Nº${cliente.endNumero}` : ''}
        </span>
      </S.CData>
      <S.CData>
        <span>BAIRRO</span>
        <span>{cliente.endLogrendCidadeadouro ?? ''}</span>
      </S.CData>
      <S.CData>
        <span>CIDADE</span>
        <span>
          {cliente.endCidade ?? ''}
          {cliente.endUf ? '-' + cliente.endUf : ''}
        </span>
      </S.CData>
      <S.CData>
        <span>CEP</span>
        <span>{cliente.endCep ?? ''}</span>
      </S.CData>
    </S.ClicMainBox>
  );
}
