// IMPORTA LIBE DE VALIDAÇÃO DE PONTO EM POLY
import pointInPolygon from 'point-in-polygon';

// EXPORTA FUNÇÃO QUE IDENTIFICA CLIENTES NO POLYGONO POR PADRÃO
export default async function ValidaCliPoly(clientes: any, poligono: any) {
  const coordsPoligono = poligono.map((ponto: any) => [+ponto.lng, +ponto.lat]);

  const clientesComCoordenadas = clientes.filter((cliente: any) => +cliente.latitude && +cliente.longitude);

  const clientesDentroDoPoligono = clientesComCoordenadas.filter((cliente: any) => {
    const coordsCliente = [cliente.longitude, cliente.latitude];
    return pointInPolygon(coordsCliente, coordsPoligono);
  });

  return clientesDentroDoPoligono;
}
