import styled from 'styled-components';

export const ClicMainBox = styled.section`
  width: 100%;
  max-width: 95dvw;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 2px 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CTitle = styled.span`
  color: #228f6b;
  font-weight: thin;
  font-size: 1.1rem;
`;
export const CData = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CliTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  svg {
    font-size: 1.2rem;
  }
`;

export const CliAddress = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  svg {
    font-size: 1.2rem;
  }
`;
