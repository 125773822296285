import { ReactNode, useMemo, useRef, useState } from 'react';
import { Tree, TreeFilterOptions, TreeNodeTemplateOptions, TreeSelectionParams } from 'primereact/tree';
import { AsideMenuItem } from './AsideMenuItem';
import TreeNode from 'primereact/treenode';
import { classNames } from 'primereact/utils';
import { useMenuUsuario } from 'client/hooks';

import { checkIsActive } from '_metronic/helpers';

import * as S from './styles';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineAppstore } from 'react-icons/ai';

interface MenuNode extends TreeNode {
  to?: string;
  codigo?: string;
}

export function AsideMenuMain({ ktMenuMinimized }) {
  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>({ '0': true });
  const { data: menus } = useMenuUsuario();
  const [filterValue, setFilterValue] = useState('');
  const filterInputRef = useRef<HTMLInputElement | null>(null);
  const [showDashboardMenu, setShowDashBoardMenu] = useState(true);

  const modules = menus ? menus.map(item => ({
    cod: item.codMenu,
    grupo: item.grupo,
    nome: item.nomeMenu,
    codigo: item.codigo || '',
    icon: '',
    element: null,
    to: item.to
  })) : [];

  const uniqueGrupos: string[] = [];
  const grupoSet = new Set<string>();

  menus?.forEach((item) => {
    if (!grupoSet.has(item.grupo)) {
      grupoSet.add(item.grupo);
      uniqueGrupos.push(item.grupo);
    }
  });

  const generateEnum = (values: string[]): { [key: string]: string } => {
    return values.reduce((acc, value) => {
      acc[value] = value;
      return acc;
    }, {} as { [key: string]: string });
  };

  const DynamicGrupos = generateEnum(uniqueGrupos);

  type Grupos = keyof typeof DynamicGrupos;

  interface Module {
    cod: number;
    grupo?: Grupos;
    nome: string;
    codigo?: string;
    icon: string;
    element?: ReactNode;
    to: string;
  }

  const capitalizeWords = (input: string): string => {
    return input
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

  const Node = ({ node, options }: { node: MenuNode; options: TreeNodeTemplateOptions }) => {
    const { pathname } = useLocation();
    const isActive = checkIsActive(pathname, node.to);

    return (
      <S.MenuItemBox className={isActive ? 'active' : 'inactive'} ktMenuMinimized={ktMenuMinimized}>
        {node.children?.length ? (
          <span>{capitalizeWords(node.label)}</span>
        ) : (
          <AsideMenuItem
            ktMenuMinimized={ktMenuMinimized}
            to={node.to as string}
            title={capitalizeWords(node.label) as string}
            codigo={node.codigo as string}
          />
        )}
      </S.MenuItemBox>
    );
  };

  const nodeTemplate = (node: MenuNode, options: TreeNodeTemplateOptions) => {
    return <Node node={node} options={options} />;
  };

  const userModules = useMemo(
    () =>
      modules
        .filter((m) => menus?.some((menu) => menu.codMenu === m.cod))
        .reduce<{ [key in Grupos | 'default']: Module[] }>((a, b) => {
          const menu = menus?.find((menu) => menu.codMenu === b.cod);
          if (menu) {
            b.nome = menu.codigo ? `${menu.codigo} ${menu.nomeMenu}` : menu.nomeMenu;
            b.grupo = menu.grupo as any;
            b.codigo = menu.codigo ?? '';
          }
          a[b.grupo ?? 'default'] = a[b.grupo ?? 'default'] || [];
          a[b.grupo ?? 'default'].push(b);
          return a;
        }, {} as { [key in Grupos | 'default']: Module[] }),
    [menus],
  );

  const onNodeSelect = (e: TreeSelectionParams) => {
    const _expandedKeys = { ...expandedKeys };
    const key = e.value as string;
    if (_expandedKeys[key]) {
      delete _expandedKeys[key];
    } else {
      _expandedKeys[key] = true;
    }
    setExpandedKeys(_expandedKeys);
  };

  const nodes = useMemo(
    () => [
      ...Object.keys(userModules).flatMap((key) =>
        key === 'default'
          ? userModules.default.map((module) => ({
              key: module.cod.toString(),
              label: module.nome,
              to: module.to,
              codigo: module.codigo,
            }))
          : {
              key,
              label: key,
              to: '',
              codigo: '',
              children: userModules[key as Grupos].map((module) => ({
                key: module.cod.toString(),
                label: module.nome,
                to: module.to,
                codigo: module.codigo,
              })),
            },
      ),
    ],
    [userModules],
  );

  const myFilterFunction = (event: any, options: TreeFilterOptions) => {
    const newFilterValue = event.target?.value;
    setFilterValue(newFilterValue);
    options.filter?.(event);
  };

  const filterTemplate = (options: any) => {
    const { filterOptions } = options;

    return (
      <>
        <S.MenuSearchBox className="flex  menu-title mb-2" style={{ margin: '0 1rem' }}>
          <span style={{ width: '100%' }} className="p-input-icon-right">
            <input
              style={{ width: '100%' }}
              value={filterValue}
              ref={filterInputRef}
              onChange={(e) => {
                myFilterFunction(e, filterOptions);
                setShowDashBoardMenu(
                  e.target.value.toUpperCase().includes('DASHBOARD') ? true : e.target.value == '' ? true : false,
                );
              }}
              type="text"
              placeholder="Pesquisar"
            />
            <i className="pi pi-search" />
          </span>
        </S.MenuSearchBox>
        {showDashboardMenu && (
          <S.MenuItemDashBoard>
            <Link to={'/dashboard'}>
              <S.MenuItemDashBoardIcon>
                <AiOutlineAppstore />
              </S.MenuItemDashBoardIcon>

              <S.MenuItemDashBoardTitle ktMenuMinimized={ktMenuMinimized}>Dahsboard</S.MenuItemDashBoardTitle>
            </Link>
          </S.MenuItemDashBoard>
        )}
      </>
    );
  };

  const togglerTemplate = (node, options) => {
    if (node.to) {
      return <p className="p-ghost-content"></p>;
    }

    const expanded = options.expanded;
    const iconClassName = classNames('p-tree-toggler-icon pi pi-fw', {
      'pi-angle-right': !expanded,
      'pi-angle-down': expanded,
    });

    return (
      <S.MenuItemBoxIcon>
        <button type="button" className="p-tree-toggler p-link" tabIndex={-1} onClick={options.onClick}>
          <span className={iconClassName} aria-hidden="true"></span>
        </button>
      </S.MenuItemBoxIcon>
    );
  };


  return (
    <S.MenuTreeBox>
      <Tree
        style={{ border: 0, background: 'transparent', fontSize: '15px' }}
        value={nodes}
        expandedKeys={expandedKeys}
        selectionMode="single"
        onToggle={(e) => setExpandedKeys(e.value)}
        onSelectionChange={onNodeSelect}
        nodeTemplate={nodeTemplate}
        filter
        filterTemplate={filterTemplate}
        togglerTemplate={togglerTemplate}
      />
    </S.MenuTreeBox>
  );
}
