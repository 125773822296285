//IMPORTA REACT.JS
import { useEffect } from 'react';

import PedidoHeader from './components/pedidoHeader/pedidoHeader';
import ClientAccordeon from './components/clientAccordeon/clientAccordeon';

import { useTitlePageStore } from 'storesZustand/titlePageStore';
import PedList from './components/pedList/pedList';
import DebitSalesCharts from './components/debitSalesCharts/debitSalesCharts';

import * as S from './styles';
import { usePedido } from 'providers/pedidoProvider';

export default function Pedido() {
  const { setTitlePage } = useTitlePageStore();

  useEffect(() => {
    setTitlePage('Pedido');
  }, []);


  const {cliData}:any = usePedido();

  return (
      <S.PedidoMainBox>
      <PedidoHeader cliente={cliData} />

      <DebitSalesCharts cliente={cliData} />

      {/* <DebitList cliente={cliData} showDebList={showDebList} setShowDebList={setShowDebList} /> */}

      <ClientAccordeon cliente={cliData} />

      <PedList cliente={cliData} />

      {/* <LoaderEffect /> */}
      </S.PedidoMainBox>
  );
}
