import styled from 'styled-components';

export const EyeIcon = styled.i`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;

  img {
    height: 70%;
    width: 40%;
  }
`;

export const Container = styled.div`
  /* scrollbar-width: none; */
  padding: 0 10px;
`;
