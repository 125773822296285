import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getByRoleManagersCompany, getManagersCompany } from 'client/api/gerentes';
import { IManagers } from 'client/interfaces/gerentes.interface';

export const useManagers = (CodEmpresa: number, enabled = true) =>
  useQuery<IManagers[], AxiosError>(['gerentes', CodEmpresa], async () => await getManagersCompany(CodEmpresa), {
    enabled,
  });

export const useFindByRoleManagers = (codCompany: number, codUser: number, enabled = true) =>
  useQuery<IManagers[], AxiosError>(
    ['gerentes-byRole', codCompany],
    async () => await getByRoleManagersCompany(codCompany, codUser),
    {
      enabled,
    },
  );
