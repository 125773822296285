import styled from 'styled-components';

export const FooterMenu = styled.div`
  position: absolute;
  bottom: 0;
  height: 8vh;
  width: 110% !important;
  transform: translateX(-5%);
  background-color: #fff;
  z-index: 9999 !important;
  justify-content: space-between;
  padding: 0 20%;
  align-items: center;
  display: none;

  @media only screen and (max-width: 1024px) {
    display: flex;
  }

  img {
    position: absolute;
    bottom: 35%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 65px;
    cursor: pointer;
  }
  
  svg {
    font-size: 2rem;
    color: #606060;
    cursor: pointer;
  }
`;
