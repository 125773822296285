import { Toolbar } from 'primereact/toolbar';
import * as S from './styles';
import { HeaderCompanies } from './HeaderCompanies';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatarCnpj } from 'utils/formatCNPJ';
import { useEmpresasList } from 'client/hooks';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { AiFillEdit } from 'react-icons/ai';

export const Companies = () => {
  const { data: empresas } = useEmpresasList();
  
  const { control, watch, setValue } = useForm({});

  const navigate = useNavigate();

  const filterCompany = watch('filterCompany', '');

  const filteredCompanies = useMemo(() => {
    if (!filterCompany) return empresas;
    return empresas?.filter((company) => {
      const searchStr = filterCompany.toLowerCase();
      return (
        company.codEmpresa.toString().toLowerCase().includes(searchStr) ||
        company.cnpj.toLowerCase().includes(searchStr) ||
        company.razaosocial.toLowerCase().includes(searchStr) ||
        company.fantasia.toLowerCase().includes(searchStr) ||
        (company.ativo === 'S' ? 'ativo' : 'inativo').toLowerCase().includes(searchStr)
      );
    });
  }, [empresas, filterCompany]);

  return (
    <S.ContainerPage>
      <Toolbar className="mb-4" left={<HeaderCompanies setValue={setValue} control={control} />} />

      <DataTable
        value={filteredCompanies}
        header=""
        dataKey="id"
        paginator
        rows={10}
        editMode="cell"
        emptyMessage="Nenhum dado encontrado"
      >
        <Column header="Cod Empresa" field="codEmpresa" />
        <Column header="CNPJ" field="cnpj" dataType="text" body={(rowData) => formatarCnpj(rowData.cnpj)} />
        <Column header="Razão Social" field="razaosocial" />
        <Column header="Fantasia" field="fantasia" />
        <Column header="Fantasia" field="fantasia" />
        <Column
          header="Ativo"
          field="ativo"
          body={(rowData) => {
            return (
              <S.IconTable>
                <i
                  className={classNames('pi', {
                    'true-icon pi-check-circle text-success': rowData.ativo === 'S',
                    'false-icon pi-times-circle text-danger': rowData.ativo === 'N',
                  })}
                />
              </S.IconTable>
            );
          }}
        />
        <Column
          header=""
          field="ativo"
          body={(rowData) => {
            return (
              <S.IconTable onClick={() => navigate('/empresas/editar', { state: { company: rowData } })}>
                <AiFillEdit />
              </S.IconTable>
            );
          }}
        />
      </DataTable>
    </S.ContainerPage>
  );
};
