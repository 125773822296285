import styled from 'styled-components';

export const ModalButtonsBox = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Form = styled.form``;

export const SectorItemTemplateBox = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`;

export const SectorItemVendedor = styled.span`
  font-size: 0.8rem;
`;
