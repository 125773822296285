// IMPORTA FUNÇÃO QUE VALIDA CLIENTES NO POLYGONO
import { toast } from 'react-toastify';

// IMPORTA COMPONENTE DE ALERTA
import Swal from 'sweetalert2';

// IMPORTA COMPONENTE DE NOTIFICAÇÃO
import ValidaCliPoly from '../validaCliPoly/validaCliPoly';

// IMPORTA LBI DE VALIDAÇÃO DE INTERPOLAÇÃO DE POLYGONOS
import * as turf from '@turf/turf';
import { createPoly } from 'client/api/areaMapa';


// EXPORTA COMPONENTE POR PADRÃO
export default async function CreatePoly(
  coordinates: any,
  clientes: any[],
  id: number,
  setor: any,
  reloadAllData: any,
  polygonos: any,
  setShowVinculoSetor: any,
) {
  // MAPEIA CLIENTES DENTRO DO POLYGONO
  let cliInPoly = await ValidaCliPoly(clientes, coordinates);

  if (cliInPoly.length === 0) {
    toast.error('Não há clientes na área desenhada!');
    reloadAllData();
    Swal.fire({
      title: 'Oops :/',
      text: 'Polygono não possui clientes',
      icon: 'warning',
      confirmButtonText: 'Entendi',
    }).then((result) => {
      window.location.reload();
    });
    return;
  }

  // FORMATA DADOS DOS CLIENTES NO POLYGONO
  const clientList = [];
  for (let i = 0; i < cliInPoly.length; i++) {
    cliInPoly[i].id_poligono = id;
    clientList.push({ id_poligono: id, codCli: cliInPoly[i].codCli, inclusaoManual: cliInPoly[i].inclusaoManual });
  }

  // CONTRÓI OBJETO COM DADOS DA NOVA ÁREA
  const dados = {
    id: id,
    codsetor: setor.codSetor,
    codfilial: 1,
    codempresa: setor.codEmpresa,
    latlngs: JSON.stringify(coordinates),
    clientes: clientList.filter((cli: any) => cli.inclusaoManual === 'N'),
  };

  // DEFINA VLAOR LOCAL PARA AS COORDENADAS DO NOVO POLYGONO
  const newPoly = coordinates;

  // DEFINE VALOR LOCAL PARA A LISTA COM TODOS OS POLYGONOS DO MAPA
  const polyList = [];
  for (let i = 0; i < polygonos.length; i++) {
    polyList.push(polygonos[i].latlngs);
  }

  // TRATA POLYGONOS DO MAPA PARA O FORMATO NECESSÁRIO
  for (let w = 0; w < polyList.length; w++) {
    const pol = polyList[w];
    if (pol[0].lat !== undefined) {
      polyList[w] = polyList[w].map((poly: any) => [poly.lat, poly.lng]);
    }
  }

  // MAPEIA TODOS OS POLYGONOS VALIDANDO SE HÁ INTERPOLAÇÃO COM O NOVO POLYGONO CRIADO
  for (let j = 0; j < polyList.length; j++) {
    const arr1 = polyList[j];

    const arr2 = newPoly.map((poly: any) => [poly.lat, poly.lng]);

    const line1 = turf.lineString(arr1);

    const line2 = turf.lineString(arr2);

    const intersects = turf.lineIntersect(line1, line2);

    const isInterpolating = intersects.features.length > 0;

    if (isInterpolating) {
      console.log('Polygono com interpolação!');
      alert('Polygono com interpolação! Crie corretamente.');
      window.location.reload();
      return;
    }
  }

  createPoly(dados)
    .then(() => {
      // reloadAllData();
      window.location.reload();
      setShowVinculoSetor(false);
    })
    .catch((err: Error) => {
      console.error(err);
    });
}
