import styled from 'styled-components';

export const ReportMainBox = styled.section`
  background-color: #fafafa;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  position: sticky;
`;
export const ReportFormBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px;
  border-radius: 7.5px;
  border: 1px solid #cccccc70;
  background-color: #fff;
  width: auto;
  position: sticky;
  top: 20px;
  left: 0px;
  z-index: 9;
`;

export const ReportInputBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;

  label {
    text-align: left;
  }
`;
export const ReportDateInputBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  border: 1px solid #ced4da;
  border-radius: 2.5px;
  padding: 0px 5px;
  position: relative;
  color: #606060;
  height: 2.6rem;
  width: clamp(120px, 120px, 140px);

  input {
    color: #606060;
    border: none;
    outline: none;
    background-color: #fff !important;
    width: 100%;
  }

  label {
    position: absolute;
    bottom: 100%;
    font-size: 1rem;
    color: #000;
  }

  @media (max-width: 992px) {
    input {
      max-width: 100px;
    }
  }
`;

export const ReportViewModeBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 10px;
`;

export const ReportViewOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const ReportHeaderButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

