import InputData from 'components/InputData';
import { Calendar } from 'primereact/calendar';
import * as S from './styles';
import { Dropdown } from 'primereact/dropdown';
import { Option, Select } from 'components/Select/styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { useEffect } from 'react';
import VmButton from 'components/VmButton/VmButton';
import { BsPlusLg } from 'react-icons/bs';
import { Bar, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useResearchDataAnalysis } from 'hooks/useResearchDataAnalysis/useResearchDataAnalysis';
import { Controller } from 'react-hook-form';
import { UseCompaniesSelect } from 'hooks/useCompanies/useCompaniesSelect';
import { UseFiliaisSelect } from 'hooks/useFiliais/useFiliaisSelect';

export const ResearchDataAnalysis = () => {
  const { handleSubmit, handleFilter, control, values } = useResearchDataAnalysis();

  const { optionsCompanies, codEmpresaVMais } = UseCompaniesSelect();
  const { optionsFiliais } = UseFiliaisSelect({
    codEmpresa: Number(0),
  });
  const setTitlePage = useTitlePageStore((state) => state.setTitlePage);
  const titlePage = useTitlePageStore((state) => state.titlePage);

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
    },
  ];

  const optionsAsk = [
    {
      label: 'Gráfico',
      value: 'grafico',
    },
    {
      label: 'Foto',
      value: 'foto',
    },
  ];

  useEffect(() => {
    setTitlePage('Análise de Dados das Pesquisas');
  }, [titlePage]);

  console.log(values);

  return (
    <S.Container>
      <form onSubmit={handleSubmit(handleFilter)}>
        <S.Row>
          <S.InputContainer>
            <S.Title>Empresa</S.Title>
            <Controller
              name="company"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <Option defaultChecked>Selecione uma empresa.</Option>
                  {optionsCompanies?.map((company) => (
                    <Option key={company.value} value={company.value}>
                      {company.label + ' [' + company.value + ']'}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </S.InputContainer>

          <S.InputContainer>
            <S.Title>Filial</S.Title>
            <Controller
              name="filial"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <Option defaultChecked>Selecione uma filial.</Option>
                  {optionsFiliais?.map((filial) => (
                    <Option key={filial.value} value={filial.value}>
                      {filial.label + ' [' + filial.value + ']'}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </S.InputContainer>

          <S.InputContainer>
            <S.Title>Cidade</S.Title>
            <Select>
              <Option defaultChecked>Selecione uma Cidade.</Option>
              {/* {optionsCompanies?.map((company) => (
                <Option key={company.value} value={company.value}>
                  {company.S.Title + ' [' + company.value + ']'}
                </Option>
              ))} */}
            </Select>
          </S.InputContainer>
        </S.Row>
        <S.Separator />

        <S.Row>
          <S.InputContainer>
            <S.Title>Gerente</S.Title>
            <Select>
              <Option defaultChecked>Selecione um Gerente.</Option>
              {/* {optionsCompanies?.map((company) => (
                      <Option key={company.value} value={company.value}>
                        {company.S.Title + ' [' + company.value + ']'}
                      </Option>
                    ))} */}
            </Select>
          </S.InputContainer>
          <S.InputContainer>
            <S.Title>Supervisor</S.Title>
            <Select>
              <Option defaultChecked>Selecione um Supervisor.</Option>
              {/* {optionsCompanies?.map((company) => (
                      <Option key={company.value} value={company.value}>
                        {company.S.Title + ' [' + company.value + ']'}
                      </Option>
                    ))} */}
            </Select>
          </S.InputContainer>

          <S.InputContainer>
            <S.Title>Vendedor</S.Title>
            <Select>
              <Option defaultChecked>Selecione um Vendedor.</Option>
              {/* {optionsCompanies?.map((company) => (
                      <Option key={company.value} value={company.value}>
                        {company.S.Title + ' [' + company.value + ']'}
                      </Option>
                    ))} */}
            </Select>
          </S.InputContainer>
        </S.Row>

        <S.Separator />

        <S.Row>
          <S.InputContainer>
            <S.Title>Data Inicial*</S.Title>
            <Calendar
              style={{
                width: '100%',
                height: '40px',
                borderRadius: '6px',
              }}
              dateFormat="dd/mm/yy"
              placeholder="dd/mm/yyyy"
              mask="99/99/9999"
            />
          </S.InputContainer>
          <S.InputContainer>
            <S.Title>Data Final*</S.Title>
            <Calendar
              style={{
                width: '100%',
                height: '40px',
                borderRadius: '6px',
              }}
              dateFormat="dd/mm/yy"
              placeholder="dd/mm/yyyy"
              mask="99/99/9999"
            />
          </S.InputContainer>
          <S.InputContainer>
            <S.Title>Cliente</S.Title>
            <Select>
              <Option defaultChecked>Selecione um Cliente.</Option>
              {/* {optionsCompanies?.map((company) => (
                      <Option key={company.value} value={company.value}>
                        {company.S.Title + ' [' + company.value + ']'}
                      </Option>
                    ))} */}
            </Select>
          </S.InputContainer>
        </S.Row>

        <S.Separator />

        <S.Row>
          <S.InputContainer>
            <S.Title>Pergunta</S.Title>
            <Controller
              name="ask1"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <Option defaultChecked>Selecione um tipo</Option>
                  {optionsAsk?.map((company) => (
                    <Option key={company.value} value={company.value}>
                      {company.label}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </S.InputContainer>

          {/* Somente exibir quando a pergunta 1 for do tipo foto */}
          <S.InputContainer>
            {values.ask1 === 'foto' ? (
              <>
                <S.Title>Pergunta 2</S.Title>
                <Controller
                  name="ask2"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <Option defaultChecked>Selecione um tipo</Option>
                      {optionsAsk?.map((company) => (
                        <Option key={company.value} value={company.value}>
                          {company.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </>
            ) : (
              <VmButton
                style={{
                  width: '50%',
                }}
                options={{
                  style: 'btn-success',
                  label: 'Filtrar',
                  icon: <BsPlusLg />,
                  iconPosition: 'right',
                }}
                onClick={() => {}}
              />
            )}
          </S.InputContainer>

          <S.InputContainer>
            {values.ask1 === 'grafico' ? (
              <>
                {/* <VmButton
              style={{
                width: '50%',
                marginBottom: '10px',
              }}
              options={{
                style: 'btn-success',
                label: 'Filtrar',
                icon: <BsPlusLg />,
                iconPosition: 'right',
              }}
              onClick={() => {}}
            /> */}

                <VmButton
                  style={{
                    width: '50%',
                  }}
                  options={{
                    style: 'btn-info',
                    label: 'Limpar Filtros',
                    icon: <BsPlusLg />,
                    iconPosition: 'right',
                  }}
                  onClick={() => {}}
                />
              </>
            ) : (
              <>
                <VmButton
                  style={{
                    width: '50%',
                    marginBottom: '10px',
                  }}
                  options={{
                    style: 'btn-success',
                    label: 'Filtrar',
                    icon: <BsPlusLg />,
                    iconPosition: 'right',
                  }}
                  onClick={() => {}}
                />

                <VmButton
                  style={{
                    width: '50%',
                  }}
                  options={{
                    style: 'btn-info',
                    label: 'Limpar Filtros',
                    icon: <BsPlusLg />,
                    iconPosition: 'right',
                  }}
                  onClick={() => {}}
                />
              </>
            )}
          </S.InputContainer>
        </S.Row>
      </form>
      <S.Separator />

      <S.ContainerImages>
        {values.ask1 === 'grafico' ? (
          <BarChart width={730} height={250} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" fill="#8884d8" />
            <Bar dataKey="uv" fill="#82ca9d" />
          </BarChart>
        ) : values.ask1 === 'foto' ? (
          <S.RowImages>
            <S.BoxImage>
              {/* <h3>Pergunta 1</h3> */}
              <S.RowImages>
                <S.Image />
                <S.Image />
              </S.RowImages>

              <S.BoxLeggend>
                <S.BoxRowLeggend>
                  <S.Title>Data/Hora</S.Title>
                  <span>21/03/2021 15:30:22</span>
                </S.BoxRowLeggend>
                <S.BoxRowLeggend>
                  <S.Title>Cliente</S.Title>
                  <span>Jon Doe</span>
                </S.BoxRowLeggend>
                <S.BoxRowLeggend>
                  <S.Title>Remetente</S.Title>
                  <span>Jon Doe</span>
                </S.BoxRowLeggend>
              </S.BoxLeggend>
            </S.BoxImage>
          </S.RowImages>
        ) : (
          <></>
        )}
      </S.ContainerImages>
    </S.Container>
  );
};
