import styled from 'styled-components';

export const ReportParamsModalBody = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 5px;
`;

export const ReportPlayerFormInputsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

export const ReportPlayerFormButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const ReportPlayerInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
