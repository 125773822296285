import { logout } from 'modules/auth/reducer/auth';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'store';

const Logout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return (
    <>
      <Navigate replace to="/auth/login" />
    </>
  );
};

export default Logout;
