import styled from 'styled-components';

export const MapMainBox = styled.section`
  position: relative;
  overflow: hidden;

  .map-container {
    position: relative;
    height: 88vh;
    border: 3px solid #fff;
    border-radius: 5px;
  }
`;

export const CustomInfoWindow = styled.div`
  position: relative;
  top: 0px;
  white-space: nowrap;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const BoxMarker = styled.div`
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  span {
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    span {
      font-size: 10px;
    }
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Separator = styled.div`
  height: 60px;
  background: #e8e8e8;
  width: 1px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const ContainerIcons = styled.div`
  display: flex;
`;

export const Point = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-right: 10px;
`;

export const pontot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    span {
      font-size: 10px;
    }
  }
`;
