import React from 'react';

import style from './clientAccordeon.module.css';

import { IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5';

import { MdReceipt, MdMap } from 'react-icons/md';

export default function ClientAccordeon({ cliente }) {
  const [accordion1, setAccordion1] = React.useState(false);

  return (
    <div className={style.accordion}>
      <div className={style.accordionTitle} onClick={(e) => setAccordion1(!accordion1)}>
        {!accordion1 && cliente != null ? cliente.CLIENTE + ' - ' + cliente.CODCLI : 'Cliente'}
        {!accordion1 ? <IoChevronDownSharp /> : <IoChevronUpSharp />}{' '}
      </div>

      <div className={style.accordionContent + ' ' + (accordion1 && style.accordionContentOpen)}>
        <div className={style.clientInfoDados}>
          <div className={style.clientInfoTitleDados}>
            <MdReceipt color={'#F64f3d'} /> Dados
          </div>
          <span>
            {cliente != null && cliente.CLIENTE} <br />
            {cliente != null && cliente.CNPJCPF}
            <br />
            {cliente != null && cliente.TELENT} <br />
            {cliente != null && cliente.EMAIL == null && cliente.EMAILNFE}
          </span>
        </div>

        <div className={style.clientInfoEndereco} style={{ justifyContent: 'end' }}>
          <div className={style.clientInfoTitleEndereco}>
            <MdMap color={'#F64f3d'} /> Endereço
          </div>
          <span>
            {cliente != null && cliente.ENDERENT + ','} {cliente != null && ' Nº' + cliente.NUMEROENT} <br />{' '}
            {cliente != null && cliente.BAIRROENT} <br />
            {cliente != null && cliente.MUNICENT + ' - '} {cliente != null && cliente.ESTENT + ','}{' '}
            {cliente != null && cliente.CEPENT}
            <br />
            {cliente != null && '"' + cliente.COMPLEMENTOENT + '"'}
          </span>
        </div>
      </div>
    </div>
  );
}
