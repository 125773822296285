import { useUser } from 'client/hooks';
import moment from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatCellphone } from 'utils/formatCellphone';

interface IForm {
  name: string;
  birth_date: string;
  cellphone: string;
  email: string;
}

export const UseEditUserProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { data: user } = useUser();

  const { handleSubmit, control, watch, reset, setValue, getValues } = useForm<IForm>({
    defaultValues: {
      name: user?.nome,
      birth_date: user?.dtNascimento,
      cellphone: user?.celular,
      email: user?.email,
    },
  });

  const formatBirthDate = moment(user?.dtNascimento).format('YYYY-MM-DD');
  const updateInputValues = () => {
    setValue('name', user?.nome);
    setValue('birth_date', formatBirthDate);
    setValue('email', user?.email);
    setValue('cellphone', formatCellphone(user?.celular));
  };

  const onSubmit = async (data) => {
    // const params = {
    //   CodUsuario: selectedUsuario?.codUsuario,
    //   codEmpresa: selectedUsuario?.codEmpresa,
    //   CodUsuarioLogado: user?.codUsuario,
    // };
    // postUserResetPassword(params);
  };

  return {
    handleSubmit,
    control,
    watch,
    reset,
    onSubmit,
    isEdit,
    setIsEdit,
    updateInputValues,
    user,
    getValues
  };
};
