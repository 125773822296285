import VmButton from 'components/VmButton/VmButton';
import { Dialog } from 'primereact/dialog';
import { AiOutlineDelete } from 'react-icons/ai';

import useMediaQuery from 'hooks/useMediaQuery';

import * as S from './styles';

type ExecRouterModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handlePostRouter: () => void;
};

export const ExecRouterModal = ({ showModal, setShowModal, handlePostRouter }: ExecRouterModalProps) => {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  return (
    <Dialog
      header={``}
      visible={showModal}
      onHide={() => {
        setShowModal(!showModal);
      }}
      style={{ width: isWebScreen ? '30vw' : '90vw', height: '50vh' }}
    >
      <S.ExecRouterModalBox>
        <S.ExecRouterModalHeaderBox>
          <S.ExecRouterModalHeaderTitle>
            Nenhum roteiro disponível :/ <br />
            Deseja calcular um roteiro para o vendedor?
          </S.ExecRouterModalHeaderTitle>
        </S.ExecRouterModalHeaderBox>

        <S.ExecRouterModalButtonsBox>
          <VmButton
            options={{
              iconPosition: 'left',
              label: 'Sim, calcular',
              style: 'btn-success',
            }}
            onClick={() => {
              setShowModal(false);
              handlePostRouter();
            }}
          />
          <VmButton
            options={{
              iconPosition: 'left',
              label: 'Não, fechar',
              style: 'btn-danger',
            }}
            style={{ marginLeft: '10px' }}
            onClick={() => {
              setShowModal(false);
            }}
          />
        </S.ExecRouterModalButtonsBox>
      </S.ExecRouterModalBox>
    </Dialog>
  );
};
