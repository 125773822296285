const data = {
  codEmpresa: 2072021,
  supervisor: 'Silas Martins',
  codSupervisor: 1,
  mes: 'Dezembro',
  codMes: 12,
  vendedores: [
    {
      vendedor: 'Carlos Corgozinho',
      codVendedor: 1,
      pendencias: 0,
      dias: [
        {
          dia: '1',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '2',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '3',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '4',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '5',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '6',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '7',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '8',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '9',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '10',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '11',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '12',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '13',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '14',
          semana: 'SEMANA 14/19',
          status: 'S',
        },
        {
          dia: '15',
          semana: 'SEMANA 14/19',
          status: 'S',
        },
        {
          dia: '16',
          semana: 'SEMANA 14/19',
          status: 'S',
        },
        {
          dia: '17',
          semana: 'SEMANA 14/19',
          status: 'S',
        },
        {
          dia: '18',
          semana: 'SEMANA 14/19',
          status: 'S',
        },
        {
          dia: '19',
          semana: 'SEMANA 14/19',
          status: 'S',
        },
        {
          dia: '20',
          semana: 'SEMANA 20/22',
          status: 'S',
        },
        {
          dia: '21',
          semana: 'SEMANA 20/22',
          status: 'S',
        },
        {
          dia: '22',
          semana: 'SEMANA 20/22',
          status: 'S',
        },
      ],
    },
    {
      vendedor: 'Luana Campos',
      codVendedor: 2,
      pendencias: 14,
      dias: [
        {
          dia: '1',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '2',
          semana: 'SEMANA 01/07',
          status: 'N',
        },
        {
          dia: '3',
          semana: 'SEMANA 01/07',
          status: 'N',
        },
        {
          dia: '4',
          semana: 'SEMANA 01/07',
          status: 'N',
        },
        {
          dia: '5',
          semana: 'SEMANA 01/07',
          status: 'N',
        },
        {
          dia: '6',
          semana: 'SEMANA 01/07',
          status: 'N',
        },
        {
          dia: '7',
          semana: 'SEMANA 01/07',
          status: 'N',
        },
        {
          dia: '8',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '9',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '10',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '11',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '12',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '13',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '14',
          semana: 'SEMANA 14/19',
          status: 'S',
        },
        {
          dia: '15',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '16',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '17',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '18',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '19',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '20',
          semana: 'SEMANA 20/22',
          status: 'N',
        },
        {
          dia: '21',
          semana: 'SEMANA 20/22',
          status: 'N',
        },
        {
          dia: '22',
          semana: 'SEMANA 20/22',
          status: 'N',
        },
      ],
    },
    {
      vendedor: 'Silas Martins',
      codVendedor: 3,
      pendencias: 11,
      dias: [
        {
          dia: '1',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '2',
          semana: 'SEMANA 01/07',
          status: 'N',
        },
        {
          dia: '3',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '4',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '5',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '6',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '7',
          semana: 'SEMANA 01/07',
          status: 'S',
        },
        {
          dia: '8',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '9',
          semana: 'SEMANA 08/13',
          status: 'N',
        },
        {
          dia: '10',
          semana: 'SEMANA 08/13',
          status: 'N',
        },
        {
          dia: '11',
          semana: 'SEMANA 08/13',
          status: 'N',
        },
        {
          dia: '12',
          semana: 'SEMANA 08/13',
          status: 'S',
        },
        {
          dia: '13',
          semana: 'SEMANA 08/13',
          status: 'N',
        },
        {
          dia: '14',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '15',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '16',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '17',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '18',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '19',
          semana: 'SEMANA 14/19',
          status: 'N',
        },
        {
          dia: '20',
          semana: 'SEMANA 20/22',
          status: 'S',
        },
        {
          dia: '21',
          semana: 'SEMANA 20/22',
          status: 'S',
        },
        {
          dia: '22',
          semana: 'SEMANA 20/22',
          status: 'S',
        },
      ],
    },
  ],
};

const handleKmTotalValue = (data: any[]): number => {
  if (data) {
    let totalKmSum = 0;
    for (let i = 0; i < data.length; i++) {
      const info = data[i];
      totalKmSum = totalKmSum + +info.km_exec;
    }
    return +totalKmSum ?? 0;
  } else {
    return 0;
  }
};

const visitas = (listData: Array<any>) => {
  if (!listData || listData.length == 0) {
    return [];
  }
  const tableData = listData;

  // TRATA ARRAY DE VENDEDORES
  let vendedores = [];
  let vendedor = -1;
  for (let i = 0; i < tableData.length; i++) {
    if (vendedor != tableData[i].cod_vendedor) {
      vendedor = tableData[i].cod_vendedor;

      let newVendedor = {
        dtRota: tableData[i].dt_rota,
        codVendedor: tableData[i].cod_vendedor,
        vendedor: tableData[i].vendedor,
        cod_empresa: tableData[i].cod_empresa,
        codSupervisor: tableData[i].cod_supervisor,
        supervisor: tableData[i].nomesup,
        codGerente: tableData[i].cod_gerente,
        gerente: tableData[i].nomegerente,
        qde_cli: tableData[i].qde_cli,
        qde_visitado: tableData[i].qde_visitado,
        perc_visitado: tableData[i].rota_perc_exec,
        km_exec: tableData[i].km_exec,
        qt_positivado: tableData[i].qt_positivado,
        perc_positivado: tableData[i].perc_positivado,
        pendencias: tableData[i].rota_nao_exec,
        dias: [],
        prev_visita_total: 0,
        real_visita_total: 0,
        pendencia_visita_total: 0,
        perc_visita_total: 0,
        positivacao_total: 0,
        perc_positivacao_total: 0,
        km_total: 0,
      };
      vendedores.push(newVendedor);
    }
  }

  // TRATA ARRAY DE DIAS
  for (let j = 0; j < vendedores.length; j++) {
    let currentVendedor = vendedores[j];
    let dias = [];
    for (let k = 0; k < tableData.length; k++) {
      if (currentVendedor.codVendedor == tableData[k].cod_vendedor) {
        let newDia = {
          dia: tableData[k].dia,
          semana: tableData[k].semana,
          status: tableData[k].rota_completa,
          qde_cli_dia: tableData[k].qde_cli,
          qde_visitado_dia: tableData[k].qde_visitado,
          qde_nao_visitado: +tableData[k].qde_cli - +tableData[k].qde_visitado,
          perc_visitado: tableData[k].rota_perc_exec ?? 0,
          positivacoes: tableData[k].qt_positivado ?? 0,
          perc_positivado: tableData[k].perc_positivado ?? 0,
          km_exec: tableData[k].km_exec ?? 0,
          dtRota: tableData[k].dt_rota,
          codVendedor: tableData[k].cod_vendedor,
          vendedor: tableData[k].vendedor,
          codEmpresa: tableData[k].cod_empresa,
        };
        dias.push(newDia);
        // TOTALIZADORES
        vendedores[j].prev_visita_total = vendedores[j].prev_visita_total + +tableData[k].qde_cli ?? 0;
        vendedores[j].real_visita_total = vendedores[j].real_visita_total + +tableData[k].qde_visitado ?? 0;
        vendedores[j].pendencia_visita_total =
          vendedores[j].pendencia_visita_total + +(+tableData[k].qde_cli - +tableData[k].qde_visitado) ?? 0;
        vendedores[j].positivacao_total = vendedores[j].positivacao_total + +tableData[k].qt_positivado ?? 0;
        vendedores[j].km_total = (+vendedores[j].km_total ?? 0) + (+tableData[k].km_exec ?? 0);
      }
    }
    vendedores[j].pendencias = dias.filter((day: any) => +day.qde_visitado_dia < +day.qde_cli_dia).length;
    vendedores[j].dias = dias;

    vendedores[j].perc_visita_total = +(
      (vendedores[j].real_visita_total * 100) /
      vendedores[j].prev_visita_total
    ).toFixed(2);
    vendedores[j].perc_positivacao_total = +(
      (vendedores[j].positivacao_total * 100) /
      vendedores[j].prev_visita_total
    ).toFixed(2);
  }
  return vendedores;
};

export { visitas };

export default data;
