import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getEmpresa, getEmpresas, getEmpresasList } from 'client/api';
import { EmpresaBasico, ICompanyDTO } from 'client/interfaces';

export const useEmpresas = (enabled = true) =>
  useQuery<EmpresaBasico[], AxiosError>(['empresas'], getEmpresas, {
    staleTime: 1000 * 60 * 15,
    enabled,
  });

  
export const useEmpresasList = (enabled = true) =>
  useQuery<ICompanyDTO[], AxiosError>(['empresas-list'], getEmpresasList, {
    staleTime: 1000 * 60 * 15,
    enabled,
  });

export const useEmpresa = (codEmpresa: number, enabled = true) =>
  useQuery<EmpresaBasico, AxiosError>(['empresa', codEmpresa], async () => await getEmpresa(codEmpresa), {
    enabled,
    staleTime: 1000 * 60 * 15,
  });
