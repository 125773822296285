import { UseCompaniesSelect } from 'hooks/useCompanies/useCompaniesSelect';
import { Dropdown } from 'primereact/dropdown';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

interface IFilterByCompanyParams {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
}

export const FilterByCompany = ({
  control,
  setValue,
}: IFilterByCompanyParams) => {
  const { optionsCompanies } = UseCompaniesSelect();

  return (
    <>
      <Controller
        name="codCompany"
        rules={{
          required: true,
        }}
        control={control}
        render={({ field }) => (
          <Dropdown
            placeholder="Selecione uma Empresa"
            value={field.value}
            options={optionsCompanies}
            onChange={(e) => setValue('codCompany', e.value)}
            emptyMessage="Nenhuma Empresa Encontrada :/"
            filter
          />
        )}
      />
    </>
  );
};
