import VmButton from 'components/VmButton/VmButton';
import * as S from './paymentCard.styles';
import useMediaQuery from 'hooks/useMediaQuery';

export default function PaymentCard({ pedido, handleProccessPayment }: any) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const PaymentButton = () => {
    if (isWebScreen) {
      return (
        <VmButton
          options={{
            label: 'Finalizar Pagamento',
            style: 'btn-black',
            size: 'btn-md',
          }}
          onClick={() => handleProccessPayment()}
        />
      );
    }
  };

  return (
    <S.PmcMainBox>
      <S.PPaymentDataBox>
        <S.PPaymentData>
          <span>{pedido.cliente.razaosocial ?? ''}</span>
          <PaymentButton />
        </S.PPaymentData>
        <S.PTitle>Dados Do Pedido</S.PTitle>
        <S.PPaymentData>
          <span>Nº Pedido </span> <span># {pedido.cab.numpedrca ?? ''}</span>
        </S.PPaymentData>
        <S.PPaymentData>
          <span>Subtotal</span> <span>R${pedido.cab.vlsubtotal ?? ''}</span>
        </S.PPaymentData>
        <S.PPaymentData>
          <span>Entrega</span> <span>R${pedido.cab.entrega ?? ''}</span>
        </S.PPaymentData>
        <S.PPaymentData>
          <span>Parcelas</span>{' '}
          <span>
            {pedido.cab.parcelas ?? ''} x de R${pedido.cab.valorparcela ?? ''}
          </span>
        </S.PPaymentData>
        <S.PTotal>
          <span>Total</span> <span>R${pedido.cab.vltotal ?? ''}</span>
        </S.PTotal>
      </S.PPaymentDataBox>
    </S.PmcMainBox>
  );
}
