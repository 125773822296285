import styled, { css } from 'styled-components';
import { IClickIcon } from '.';

export type IButtonProps = {} & Pick<IClickIcon, 'variant'>;

const colorButton = {
  secondary: () => css`
    background-color: transparent;
    border: 1px solid var(--primary-green);

    svg {
      stroke: var(--primary-green);
      fill: var(--primary-green);
    }
  `,

  primary: () => css`
    background-color: var(--primary-green);
    border: none;

    svg {
      stroke: #ffffff;
      fill: #ffffff;
    }
  `,

  danger: () => css`
    background-color: var(--red-500);
    border: none;

    svg {
      stroke: #ffffff;
      fill: #ffffff;
    }
  `,
};

export const Button = styled.button<IButtonProps>`
  ${({ variant }) => css`
    width: 35px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;

    svg {
      height: 20px;
      width: 20px;
    }

    ${!!variant && colorButton[variant]()}
  `}
`;
