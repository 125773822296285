import * as S from './styles';

export default function LostPage() {
  return (
    <S.SuccesBox>
      <S.SuccessContent>
        <S.SuccessIcon>
        <svg version="1.1" viewBox="0 0 130.2 130.2">
      <circle
        className="reactivus-path reactivus-circle"
        fill="none"
        stroke="#60606070"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <text
        className="reactivus-path reactivus-question"
        x="65.1"
        y="75"
        fontSize="70"
        fontFamily="Arial, sans-serif"
        fill="#606060"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        ?
      </text>
    </svg>
        </S.SuccessIcon>
        <S.SuccessTitle>Ooops :/</S.SuccessTitle>
        <S.SuccessSubTitle>Parece que não tem nada aqui.</S.SuccessSubTitle>
      </S.SuccessContent>
    </S.SuccesBox>
  );
}
