import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getRotaPontoVendedor } from 'client/api/ponto';
import { IPonto } from 'client/interfaces/ponto.interface';

export const usePonto = (CodEmpresa: number, CodVendedor: number, enabled = true) =>
  useQuery<IPonto[], AxiosError>(
    ['rotaPonto', CodEmpresa],
    async () => await getRotaPontoVendedor(CodEmpresa, CodVendedor),
    {
      enabled,
    },
  );
