import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';

import { useUser } from 'client/hooks';
import { modules } from 'modules/modules';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

import { SwitchComponentHeader } from './components/SwitchComponentHeader';
import * as S from './styles';
import Topbar from './Topbar';
import { UseExpireTokenAndStopApi } from 'hooks/utils/UseExpireTokenAndStopApi';
import useMediaQuery from 'hooks/useMediaQuery';
import { BsArrow90DegLeft } from 'react-icons/bs';
import VmButton from 'components/VmButton/VmButton';
import MarkersControl from 'modules/mapa/pages/Roteirizador/components/markersControl/markersControl';
import { TITLE_PAGE } from 'enums/titlePages';
import { IoArrowBack } from 'react-icons/io5';
import { useMapSectorsStore } from 'storesZustand/mapSectorsStore';

const HeaderWrapper = () => {
  const { data: user, error: errorUseUser, status: statusUseUser } = useUser();
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  UseExpireTokenAndStopApi({ errorUseUser, statusUseUser });

  const { pathname } = useLocation();
  const pageTitle = useMemo(() => modules.find((m) => m.to === pathname)?.nome ?? '', [pathname]);

  const { titlePage } = useTitlePageStore();
  const codUser = useMapSectorsStore((state) => state.codUser);

  const navigate = useNavigate();
  const { codMapa: codMapaParam, codEmpresa: codEmpresaParam } = useParams<{
    codMapa: string;
    codEmpresa: string;
    codSetor: string;
    codVendedor: string;
  }>();

  const BackButtonDisplay = () => {
    if (!pageTitle) {
      if (pathname.includes('relatorios/')) {
        return (
          <S.ReportBackButton
            onClick={() => {
              navigate(`/relatorios`);
            }}
          >
            <IoArrowBack />
          </S.ReportBackButton>
        );
      } else {
        switch (titlePage) {
          case TITLE_PAGE.SCREENWRITER:
            return (
              <VmButton
                options={{
                  icon: <BsArrow90DegLeft />,
                  iconPosition: 'left',
                  label: 'Voltar',
                  style: 'btn-danger',
                }}
                style={{ marginRight: '20px' }}
                onClick={() => {
                  navigate(`/regioes/${codEmpresaParam}/${codUser}/${codMapaParam}`);
                }}
              />
            );
          default:
            return <></>;
        }
      }
    }
  };

  return (
    <div id="kt_header" className="header align-items-stretch">
      <S.BoxHeader>
        <S.Header>
          <div className="container-fluid d-flex align-items-stretch justify-content-between">
            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              <S.Box mobile tablet gap={titlePage == 'Roteirizador' ? '50px' : ''}>
                <BackButtonDisplay />
                <S.TitlePage tablet mobile>
                  {pageTitle ? pageTitle : titlePage}
                </S.TitlePage>
                <SwitchComponentHeader />
                <MarkersControl />
              </S.Box>

              {isWebScreen && (
                <div className="d-flex align-items-stretch flex-shrink-0">
                  <S.BoxInfo>
                    {user ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingRight: '10px',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '13px',
                          }}
                        >
                          {user.nome.split(' ')[0] + '...'} [{user.codUsuario}]
                        </p>
                        <p>
                          {user.codEmpresa} - {user.nomeEmpresa}
                        </p>
                      </div>
                    ) : (
                      <span>
                        <PropagateLoader size="4px" color="#228F6B" />
                      </span>
                    )}
                    <Topbar />
                  </S.BoxInfo>
                </div>
              )}
            </div>
          </div>
        </S.Header>
      </S.BoxHeader>
    </div>
  );
};

export default HeaderWrapper;
