import styled from 'styled-components';

export const Box = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  pointer-events: none !important;

  img {
    width: 10rem;
    height: 10rem;
    pointer-events: none !important;
  }
`;
