import { useEffect, useState } from 'react';
import { TbPlayerTrackNextFilled, TbPlayerTrackPrevFilled } from 'react-icons/tb';

import * as S from './styles';

type IRouterCliente = {
  codCli: number;
  diaSemana: string;
  distancia: number;
  dtProxVisita: string;
  freqVisita: number;
  lat: string;
  lng: string;
  nomeCli: string;
  sequencia: number;
};

type IRouter = {
  rota: {
    descricaoPonto: string;
    disSemana: string;
    dtProxVisita: string;
    latitudePonto: string;
    longitudePonto: string;
    mes: string;
    semana: string;
    clientesDaRota: Array<IRouterCliente>;
    codPonto: number;
  };
  handleUniqueRouteRender: any;
  routeTotalKm: number;
};

export default function NextRouterPagination({ rota, handleUniqueRouteRender, routeTotalKm }: IRouter) {
  const [paginationIndexes, setPaginationIndexes] = useState({
    actual: 0,
    max: 0,
  });

  const handleCliPagination = () => {
    setPaginationIndexes({
      actual: -1,
      max: rota.clientesDaRota.length - 1,
    });
  };

  const handleNextClientRout = () => {
    const indexToUpdate = paginationIndexes.actual + 1 <= paginationIndexes.max ? paginationIndexes.actual + 1 : 0;

    setPaginationIndexes((prevState: any) => {
      return {
        ...prevState,
        actual: indexToUpdate,
      };
    });
    handleClientRouterRender(indexToUpdate);
  };

  const handlePrevClientRout = () => {
    const indexToUpdate = paginationIndexes.actual - 1 >= 0 ? paginationIndexes.actual - 1 : paginationIndexes.max;

    setPaginationIndexes((prevState: any) => {
      return {
        ...prevState,
        actual: indexToUpdate,
      };
    });
    handleClientRouterRender(indexToUpdate);
  };

  const handleClientRouterRender = (clientIndex: number) => {
    const initialCoordinate =
      clientIndex === 0
        ? { lat: rota.latitudePonto, lng: rota.longitudePonto }
        : { lat: rota.clientesDaRota[clientIndex - 1].lat, lng: rota.clientesDaRota[clientIndex - 1].lng };
    const finalCoordinate = { lat: rota.clientesDaRota[clientIndex].lat, lng: rota.clientesDaRota[clientIndex].lng };

    const toRenderCoordinates = [initialCoordinate, finalCoordinate];
    handleUniqueRouteRender(toRenderCoordinates, rota.clientesDaRota[clientIndex].distancia);
  };

  useEffect(() => {
    handleCliPagination();
  }, [rota]);

  return (
    <S.RouterPaginationMainBox>
      {/* <S.TotalKmBox>
        <S.TotalKmBoxTitle>Tot. Rota:</S.TotalKmBoxTitle>
        <S.TotalKmBoxValue>{routeTotalKm}Km</S.TotalKmBoxValue>
      </S.TotalKmBox> */}
      <S.RouterPaginationBox>
        {paginationIndexes.actual >= 0 ? <div> Destino : </div> : <div> Origem: </div>}
        <S.PaginationSubBox>
          <S.Arrow>
            <TbPlayerTrackPrevFilled onClick={() => handlePrevClientRout()} />
          </S.Arrow>
          {rota.clientesDaRota[paginationIndexes.actual] && paginationIndexes.actual >= 0 ? (
            <p>
              {rota.clientesDaRota[paginationIndexes.actual].nomeCli ?? ''} [
              {rota.clientesDaRota[paginationIndexes.actual].codCli ?? ''}]
            </p>
          ) : (
            <p>{rota.descricaoPonto}</p>
          )}
          <S.Arrow>
            <TbPlayerTrackNextFilled onClick={() => handleNextClientRout()} />
          </S.Arrow>
        </S.PaginationSubBox>
      </S.RouterPaginationBox>
    </S.RouterPaginationMainBox>
  );
}
