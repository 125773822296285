import { useQueryClient } from '@tanstack/react-query';
import { postRotaPontoAgenda } from 'client/api/pontoAgenda';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export interface FormState {
  codPonto: number;
  codVendedor: number;
  codEmpresa: number;
  dtAgenda: string;
  typeScheduling: string;
  sequencia: number;
}

enum TYPES_SCHEDULING {
  POINT = 'point',
  CLIENT = 'client',
}

interface IUseModalSchedule {
  date: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useModalSchedule = ({ date, setOpenModal }: IUseModalSchedule) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const { handleSubmit, register, setValue, watch, control, formState } = useForm<FormState>();
  const queryClient = useQueryClient();

  const handleOnSubmit: SubmitHandler<FormState> = async (form) => {
    const { codPonto, codVendedor, codEmpresa, codCli } = selectedCard;

    await postRotaPontoAgenda({
      codPonto: TYPES_SCHEDULING.CLIENT === form.typeScheduling ? 0 : codPonto,
      codVendedor,
      codEmpresa: String(codEmpresa),
      dtAgenda: date,
      sequencia: TYPES_SCHEDULING.CLIENT === form.typeScheduling ? Number(form.sequencia) : 0,
      codcli: TYPES_SCHEDULING.CLIENT === form.typeScheduling ? codCli : 0,
    })
      .then(() => {
        toast.success('Salvo com sucesso!');
        setOpenModal(false);
        setSelectedCard(null);
        queryClient.invalidateQueries({
          queryKey: ['rotaPontoAgenda'],
        });
      })
      .catch((e) => {
        setOpenModal(false);
        toast.error('Não foi possivel salvar!');
        setSelectedCard(null);
      });
  };

  return {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    formState,
    handleOnSubmit,
    TYPES_SCHEDULING,
    setSelectedCard,
    selectedCard,
  };
};
