import * as S from './styles';


export interface IClickIcon extends React.BaseHTMLAttributes<HTMLButtonElement> {
  icon: string | any;
  variant?: 'primary' | 'secondary' | 'danger';
  tooltip?: string;
  colorButton?: string;
}
const ClickIcon = ({ icon, tooltip, colorButton, variant = 'primary', ...rest }: IClickIcon) => {
  return (
    <S.Button variant={variant}  {...rest}>
      {typeof icon === 'string' ? <img src={icon} /> : <div>{icon}</div>}
    </S.Button>
  );
};

export default ClickIcon;
