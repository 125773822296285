import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { useState } from 'react';

import { removeAcessoSubMenus, saveAcessoSubMenus } from 'client/api';
import { useSubMenuAcesso } from 'client/hooks';
import { MenuWithSub } from 'client/interfaces';
import { booleanEditor } from 'components/datatable';
import { useAppSelector } from 'store';

import { MenuSubAcesso } from '../interfaces';
import { selectedEmpresaSelector, selectedUsuarioSelector } from '../reducer/menuAcesso';

interface SubItemMenuProps {
  menu: MenuWithSub;
}

const acessoTemplate = (data: MenuSubAcesso) =>
  data.acesso ? <Tag value="Sim" severity="success" /> : <Tag value="Não" severity="danger" />;

const SubItemMenu = ({ menu }: SubItemMenuProps) => {
  const selectedEmpresa = useAppSelector(selectedEmpresaSelector);
  const selectedUsuario = useAppSelector(selectedUsuarioSelector);
  const {
    data: acessos,
    isLoading: isLoadingAcessos,
    refetch: refetchAcesso,
  } = useSubMenuAcesso(selectedUsuario?.codUsuario ?? 0, selectedUsuario != null);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCellEditComplete = async (e: any) => {
    if (!selectedEmpresa || !selectedUsuario) return;

    const { rowData, newValue }: { rowData: MenuSubAcesso; newValue: boolean } = e;
    if (rowData.acesso === newValue) return;

    try {
      if (newValue) {
        setIsLoadingSave(true);
        await saveAcessoSubMenus({
          codEmpresa: selectedEmpresa.codEmpresa,
          codUsuario: selectedUsuario.codUsuario,
          codMenu: rowData.codMenu,
          codSubMenu: rowData.codSubMenu,
        });
      } else {
        await removeAcessoSubMenus(rowData.codSubMenu);
      }
      await refetchAcesso();
    } finally {
      setIsLoadingSave(false);
    }
  };

  const userMenus = menu.menuSub?.filter((item) => acessos?.includes(item.codSubMenu));

  return (
    <DataTable
      value={selectedUsuario == null ? [] : userMenus}
      loading={isLoadingSave || isLoadingAcessos}
      sortMode="single"
      sortField="grupo"
      sortOrder={1}
      editMode="cell"
      dataKey="codMenuSub"
      emptyMessage="Nenhum submenu adicionado"
    >
      <Column field="codSubMenu" header="Código" headerStyle={{ width: '3em' }} />
      <Column field="nomePermissao" header="Permissão" />
      <Column
        field="acesso"
        header="Acesso"
        headerStyle={{ width: '3em' }}
        editor={booleanEditor}
        onCellEditComplete={onCellEditComplete}
        body={acessoTemplate}
        sortable
      />
    </DataTable>
  );
};

export default SubItemMenu;
