import VmButton from 'components/VmButton/VmButton';
import * as S from './styles';
import { BsPlusLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';

interface IHeaderCompanies {
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues, any>;
}

export const HeaderCompanies = ({ setValue, control }: IHeaderCompanies) => {
  const navigate = useNavigate();

  return (
    <S.HeaderBox>
      <VmButton
        style={{
          marginRight: '10px',
        }}
        options={{
          style: 'btn-success',
          label: 'Adicionar',
          icon: <BsPlusLg />,
          iconPosition: 'right',
        }}
        onClick={() => {
          navigate('/empresas/adicionar');
        }}
      />

      <Controller
        name="filterCompany"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <InputText
            {...field}
            value={field.value || ''}
            onChange={(e) => {
              setValue('filterCompany', (e.target as HTMLInputElement).value);
              field.onChange(e);
            }}
            placeholder="buscar..."
          />
        )}
      />

      <div></div>
    </S.HeaderBox>
  );
};
