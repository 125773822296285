import {  Route, Routes } from 'react-router-dom';
import { LoginLayout } from '../components';

import { LoginPage } from '../pages';

export const AuthRoutes = () => (
  <Routes>
    <Route path="/login" element={<LoginLayout />}>
      <Route index element={<LoginPage />} />
    </Route>
  </Routes>
);
