import { postCompany } from 'client/api';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IForm {
  codEmpresa: string;
  cnpj: string;
  razaosocial: string;
  fantasia: string;
  codSisERP: 1 | 2;
  urlServFV: string;
  urlServGerencial: string;
  urlServRelatorio: string;
  urlservApiClient: string;
  bdNome: string;
  bdUSU: string;
  bdPSW: string;
  ativo: string;
  dtInativo: string;
  obsInativo: string;
  caminhoLogo: string;
  bdSGBD: string;
  bdHost: string;
}

export const UseAddCompany = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IForm>({});

  const values = watch();

  const onSubmit = async (data: IForm) => {
    await postCompany({
      codEmpresa: Number(data.codEmpresa),
      cnpj: data.cnpj,
      razaosocial: data.razaosocial,
      fantasia: data.fantasia,
      codSisErp: data.codSisERP,
      urlServFv: data.urlServFV,
      urlServGerencial: data.urlServGerencial,
      urlServRelatorio: data.urlServRelatorio,
      urlServApicliente: data.urlservApiClient,
      bdNome: data.bdNome,
      bdUsu: data.bdUSU,
      bdPsw: data.bdPSW,
      ativo: data.ativo,
      dtinativo: data.dtInativo,
      obsinativo: data.obsInativo,
      caminhoLogo: data.caminhoLogo,
      bdSgbd: data.bdSGBD,
      bdHost: data.bdHost,
    })
      .then(() => {
        toast.success('Sucesso ao Criar Empresa.');
        reset();
      })
      .catch(() => {
        toast.error('Erro ao Criar Empresa!');
      });
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    setValue,
    values,
  };
};
