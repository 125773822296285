import { useEffect } from 'react';

import logoVendaMais from 'assets/images/logovendamais.png';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

import styles from './DashboardPage.module.scss';

const DashboardPage = () => {

  const { titlePage, setTitlePage } = useTitlePageStore();

  useEffect(() => {
    setTitlePage('')
  }, [titlePage]);

  return (
    <div className={styles.mainContainer}>
      <img src={logoVendaMais} alt="logo vendamais" />
    </div>
  );
};

export default DashboardPage;
