import { useSelectActive } from 'hooks/useSelectActive/useSelectActice';
import { Dropdown } from 'primereact/dropdown';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

interface IFilterByCompanyParams {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
}

export const FilterByStatus = ({ control, setValue }: IFilterByCompanyParams) => {
  const { optionsActiveAndInactive } = useSelectActive();

  return (
    <>
      <Controller
        name="codStatus"
        rules={{
          required: true,
        }}
        control={control}
        render={({ field }) => (
          <Dropdown
            placeholder="Ativo/Inativo"
            value={field.value}
            options={optionsActiveAndInactive}
            onChange={(e) => setValue('codStatus', e.value)}
            emptyMessage="Nenhum Status Encontrado :/"
            filter
          />
        )}
      />
    </>
  );
};
