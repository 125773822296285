import * as S from './styles';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BsPlusLg } from 'react-icons/bs';
import VmButton from 'components/VmButton/VmButton';
import { UseEditCompany } from 'hooks/useCompanies/useEditCompany';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICompanyDTO } from 'client/interfaces';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { TITLE_PAGE } from 'enums/titlePages';
import { OPTIONS_ACTIVE_INATIVE, OPTIONS_SISTEM_ERP } from 'enums/selectOptions';
import { formatDateTime } from 'utils/datetime';
import { error } from 'console';

interface LocationState {
  company: ICompanyDTO;
}

export const EditCompany = () => {
  const location = useLocation();
  const stateCompany = location.state as LocationState;

  const { onSubmit, handleSubmit, control, setValue, values, errors } = UseEditCompany();

  const setTitlePage = useTitlePageStore((state) => state.setTitlePage);

  const navigate = useNavigate();

  console.log(errors);

  useEffect(() => {
    setTitlePage(`${TITLE_PAGE.EDIT_COMPANY} ${stateCompany?.company?.fantasia}`);
  }, []);

  return (
    <S.Body>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Dados da Empresa</h3>
        <S.Container>
          <S.Row>
            <Controller
              name="codEmpresa"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={String(stateCompany?.company?.codEmpresa)}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Código Empresa</label>
                  <InputText {...field} placeholder="000000" disabled />
                </S.Box>
              )}
            />

            <Controller
              name="cnpj"
              control={control}
              defaultValue={String(stateCompany?.company?.cnpj)}
              rules={{
                required: true,
                validate: (value) => {
                  const cleanedValue = value.replace(/\D/g, '');
                  return cleanedValue.length === 14 || 'CNPJ deve ter 14 dígitos';
                },
              }}
              render={({ field, fieldState }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>CNPJ</label>
                  <InputMask
                    {...field}
                    placeholder="XX.XXX.XXX/0001-XX"
                    style={{
                      padding: '6.5px',
                      border: '1px solid #ced4da ',
                      borderRadius: '3px',
                    }}
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    inputRef={field.ref}
                  />
                  {fieldState.error && <label style={{ color: 'red' }}>{fieldState.error.message}</label>}
                </S.Box>
              )}
            />
            <S.Box />
          </S.Row>

          <S.Row>
            <Controller
              name="razaosocial"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={String(stateCompany?.company?.razaosocial)}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Razão Social</label>
                  <InputText {...field} placeholder="Digite Razão Social..." />
                </S.Box>
              )}
            />

            <Controller
              name="fantasia"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={String(stateCompany?.company?.fantasia)}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Nome Fantasia</label>
                  <InputText {...field} placeholder="Digite Nome Fantasia..." />
                </S.Box>
              )}
            />
            <S.Box />
          </S.Row>
          <S.Separator />
          <p>Situação da Empresa</p>
          <S.Row>
            <Controller
              name="ativo"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={String(stateCompany?.company?.ativo)}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Ativa/Inativa</label>
                  <Dropdown
                    placeholder="Ativa/Inativa "
                    value={field.value}
                    options={OPTIONS_ACTIVE_INATIVE}
                    onChange={(e) => setValue('ativo', e.value)}
                  />
                </S.Box>
              )}
            />

            {stateCompany?.company?.ativo === 'N' && (
              <Controller
                name="dtInativo"
                rules={{
                  required: true,
                }}
                control={control}
                defaultValue={formatDateTime('dd/MM/yyyy', new Date(stateCompany?.company?.dtinativo))}
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>Data Inatividade</label>
                    <InputText {...field} disabled />
                  </S.Box>
                )}
              />
            )}
            {values.ativo === 'N' && (
              <Controller
                name="obsInativo"
                rules={{
                  required: true,
                }}
                control={control}
                defaultValue={String(stateCompany?.company?.obsinativo)}
                render={({ field }) => (
                  <S.Box style={{ width: '30%' }}>
                    <label>Motivo da Inatividade</label>
                    <InputText type="text" {...field} placeholder="Motivo Inatividade" />
                  </S.Box>
                )}
              />
            )}
          </S.Row>
        </S.Container>
        <h3>Configurações</h3>
        <S.Container>
          <S.Row>
            <Controller
              name="codSisERP"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={String(stateCompany?.company?.codSisErp)}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Sistema ERP</label>
                  <Dropdown
                    placeholder="Selecione um Sistema ERP "
                    value={field.value}
                    options={OPTIONS_SISTEM_ERP}
                    onChange={(e) => setValue('codSisERP', e.value)}
                  />
                </S.Box>
              )}
            />
          </S.Row>
          <S.Separator />

          <p>URLS Servidor</p>
          <S.Row>
            <Controller
              name="urlServFV"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.urlServFv}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>URL Servidor FV</label>
                  <InputText {...field} placeholder="www.servidorFV.com.br" />
                </S.Box>
              )}
            />

            <Controller
              name="urlServGerencial"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.urlServGerencial}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>URL Servidor Gerencial</label>
                  <InputText {...field} placeholder="www.servidorGerencial.com.br" />
                </S.Box>
              )}
            />

            <Controller
              name="urlServRelatorio"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.urlServRelatorio}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>URL Servidor Relatório</label>
                  <InputText {...field} placeholder="www.servidorRelatório.com.br" />
                </S.Box>
              )}
            />
          </S.Row>
          <S.Row>
            <Controller
              name="urlservApiClient"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.urlServApicliente}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>URL Servidor API Cliente</label>
                  <InputText {...field} placeholder="www.servidorApiCliente.com.br" />
                </S.Box>
              )}
            />
          </S.Row>
          <S.Separator />

          <p>Banco de Dados</p>
          <S.Row>
            <Controller
              name="bdNome"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.bdNome}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD Nome</label>
                  <InputText {...field} placeholder="BD Nome" />
                </S.Box>
              )}
            />

            <Controller
              name="bdUSU"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.bdUsu}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD USU</label>
                  <InputText {...field} placeholder="BD USU" />
                </S.Box>
              )}
            />

            <Controller
              name="bdPSW"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.bdPsw}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD PSW</label>
                  <InputText {...field} placeholder="BD PSW" />
                </S.Box>
              )}
            />
          </S.Row>
          <S.Row>
            <Controller
              name="bdSGBD"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.bdSgbd}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD SGBD</label>
                  <InputText {...field} placeholder="BD SGBD" />
                </S.Box>
              )}
            />

            <Controller
              name="bdHost"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue={stateCompany?.company?.bdHost}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD HOST</label>
                  <InputText {...field} placeholder="BD HOST" />
                </S.Box>
              )}
            />

            <S.Box />
          </S.Row>
        </S.Container>

        <S.Row style={{ marginTop: '10px', marginBottom: '10px' }}>
          <VmButton
            style={{
              marginRight: '10px',
            }}
            options={{
              style: 'btn-success',
              label: 'Editar',
              icon: <BsPlusLg />,
              iconPosition: 'right',
            }}
          />

          <VmButton
            style={{
              marginRight: '10px',
            }}
            options={{
              style: 'btn-danger',
              label: 'Cancelar',
              icon: <BsPlusLg />,
              iconPosition: 'right',
            }}
            onClick={() => {
              navigate('/empresas');
            }}
          />
        </S.Row>
      </form>
    </S.Body>
  );
};
