import { useGroupClientMember } from 'storesZustand/groupClientMember';
import { Dialog } from 'primereact/dialog';
import * as S from './styles';
import { useEffect, useState } from 'react';
import VmButton from 'components/VmButton/VmButton';
import { AiFillFileExcel } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { postClientGroupImport } from 'client/api/clientGroup';
import { useQueryClient } from '@tanstack/react-query';
import { useImportClients } from 'hooks/useGroupClients/useImportClintes';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Steps } from 'primereact/steps';
import { ProgressBar } from 'primereact/progressbar';

interface IEditGroupClient {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  codEmpresa: string;
}

export const ModalImportMembersXLS = ({ isOpenModal, setIsOpenModal, codEmpresa }: IEditGroupClient) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [cliDataToImport, setCliDataToImport] = useState([]);
  const [cliFileIsEmpty, setCliFileIsEmpty] = useState(false);

  const [cliFile, setCliFile] = useState<File>(null);

  const queryClient = useQueryClient();
  const groupInfo = useGroupClientMember((state) => state.groupInfo);

  const { handleDownloadSpreadsheet, convertKeysToLowerCase } = useImportClients();

  const items = [
    { label: 'Selecionar Planilha' },
    { label: 'Confirmar Clientes' },
    { label: 'Importação em Andamento' },
  ];

  const handleNext = () => {
    setSelectedClients(cliDataToImport?.map((client) => client.codCli));

    if (activeStep < items.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const [cliProgressObject, setCliProgressObject] = useState(
    <span>
      Aguarde o processamento dos dados dos clientes.
      <br />
      Esse processo poderá levar alguns minutos.
      <br />
      <ProgressBar value={0} color={'#228f6b'} />
    </span>,
  );

  const AddMembersToGroup = async () => {
    try {
      setCliProgressObject(
        <span>
          Aguarde o processamento dos dados dos clientes.
          <br />
          Esse processo poderá levar alguns minutos.
          <br />
          <ProgressBar value={0} color={'#228f6b'} />
        </span>,
      );
      const membersGroup = selectedClients?.map((cli) => ({
        codCli: String(cli).length < 11 ? String(cli) : null,
        cnpj: String(cli).length >= 11 ? String(cli) : null,
        codEmpresa: codEmpresa,
        codGrupo: groupInfo.codgrupo,
      }));

      setActiveStep(2);

      const clientsPerTime = 100;
      const totalClientsToImport = +membersGroup.length;
      const totalApiCalls = +(totalClientsToImport / clientsPerTime + 1).toFixed();
      for (let i = 0; i < totalApiCalls; i++) {
        let cliListToImport = selectedClients
          .slice(i > 0 ? i * clientsPerTime : 0, (i + 1) * clientsPerTime)
          .map((cli) => ({
            codCli: String(cli).length < 11 ? String(cli) : null,
            cnpj: String(cli).length >= 11 ? String(cli) : null,
            codEmpresa: codEmpresa,
            codGrupo: groupInfo.codgrupo,
          }));

        const apiCallsMade = i + 1;
        const apiCallsPercDone = (apiCallsMade * 100) / totalApiCalls;

        await postClientGroupImport(cliListToImport, codEmpresa)
          .then(() => {
            setCliProgressObject(
              <span>
                Aguarde o processamento dos dados dos clientes.
                <br />
                Esse processo poderá levar alguns minutos.
                <br />
                <ProgressBar value={apiCallsPercDone.toFixed()} color={'#228f6b'} />
              </span>,
            );
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
      setCliProgressObject(
        <span>
          Importação concluída, aguarde...
          <br />
          <br />
          <ProgressBar value={100} color={'#228f6b'} />
        </span>,
      );
      setTimeout(() => {
        toast.success('Clientes adicionados no Grupo com sucesso!');
        setIsOpenModal(false);
        queryClient.invalidateQueries({
          queryKey: ['client-group'],
        });
      }, 1500);
    } catch (err) {
      toast.error('Falha inesperada ao importar clientes!');

      setActiveStep(1);
    }
  };

  const readFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setCliFileIsEmpty(false);

    setCliFile(e.target.files[0]);

    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const removeExtraSpaces = (str: string) => {
          return str.trim().replace(/\s+/g, ' ');
        };

        const convertKeysToLowerCaseAndRemoveSpaces = (obj: { [key: string]: any }) => {
          const newObj: { [key: string]: any } = {};
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              const newKey = removeExtraSpaces(key.toLowerCase());
              newObj[newKey] = obj[key];
            }
          }
          return newObj;
        };

        const clientes = convertKeysToLowerCase(XLSX.utils.sheet_to_json(worksheet));
        const clientesFormatted = clientes.map((cliente) => convertKeysToLowerCaseAndRemoveSpaces(cliente));

        const cliList = clientesFormatted
          ?.filter((cliente) =>
            Object.values(cliente).some((value) => value !== '' && value !== null && value !== undefined),
          )
          .map((cliente) => ({
            codCli: cliente?.codcli || cliente?.cnpj,
            cnpj: cliente?.cnpj,
          }));

        setCliDataToImport(cliList);
      };

      reader.readAsArrayBuffer(e.target.files[0]);
    } catch (Err) {
      toast.error('Erro ao ler arquivo!');
    }
  };

  const toggleSelectAll = () => {
    if (!selectAll) {
      setSelectedClients(cliDataToImport?.map((client) => client.codCli));
    } else {
      setSelectedClients([]);
    }
  };

  const handleClientSelection = (clientCodCli) => {
    if (selectedClients.includes(clientCodCli)) {
      setSelectedClients(selectedClients.filter((id) => id !== clientCodCli));
    } else {
      setSelectedClients([...selectedClients, clientCodCli]);
    }
  };

  useEffect(() => {
    setCliFile(null);
    setCliDataToImport([]);
    setSelectedClients([]);
    setSelectAll(false);

    setActiveStep(0);
    setCliFileIsEmpty(false);
  }, [isOpenModal]);

  useEffect(() => {
    const areAllClientsSelected = cliDataToImport?.length > 0 && selectedClients.length === cliDataToImport.length;
    setSelectAll(areAllClientsSelected);
  }, [selectedClients, cliDataToImport]);

  return (
    <Dialog
      header={'Importar Planilha de Clientes'}
      visible={isOpenModal}
      onHide={() => {
        setIsOpenModal(!isOpenModal);
      }}
      style={{ width: 'clamp(30vw, 40vw, 100vw)', minHeight: '25vw' }}
      breakpoints={{ '1300px': '800vw' }}
    >
      <div className="card">
        <Steps
          model={items}
          activeIndex={activeStep}
          style={{
            marginBottom: '10px',
          }}
        />
        {activeStep === 0 && (
          <S.BoxImport>
            <S.RowItens>
              <S.TitleSection>Baixar Planilha Modelo</S.TitleSection>
              <VmButton
                options={{
                  icon: <AiFillFileExcel />,
                  iconPosition: 'left',
                  style: 'btn-info',
                  label: 'Planilha Modelo',
                }}
                onClick={() => handleDownloadSpreadsheet()}
              />

              <S.ImportCliModalFileBox>
                <S.TitleSection>Importar Planilha Populada</S.TitleSection>

                <input
                  type="file"
                  id="fileInput"
                  accept=".xls, .xlsx"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.files && readFile(e);
                  }}
                />
                <VmButton
                  options={{
                    icon: <AiFillFileExcel />,
                    label: cliFile && cliFile.name ? cliFile.name : 'Nenhum arquivo selecionado...',
                    iconPosition: 'left',
                    style: 'btn-light',
                  }}
                  onClick={() => document.getElementById('fileInput').click()}
                />
                <S.ImportCliModalInstructionsBox>
                  <p>
                    ** O arquivo deve conter as colunas: <b> CODCLI </b>, <b>CNPJ</b>.
                  </p>
                  <p>
                    ** Extensões aceitas: <b>XLS</b>, <b>XLSX</b>.
                  </p>
                  <p style={{ color: cliFileIsEmpty ? 'red' : '' }}>
                    ** Atenção, a Planilha Importada não pode ser vazia.
                  </p>
                </S.ImportCliModalInstructionsBox>
              </S.ImportCliModalFileBox>
            </S.RowItens>

            <S.RowButton>
              <div />
              <VmButton
                options={{
                  disabled: !cliFile,
                  iconPosition: 'left',
                  style: 'btn-success',
                  label: 'Próximo',
                }}
                onClick={() => handleNext()}
              />
            </S.RowButton>
          </S.BoxImport>
        )}

        {activeStep === 1 && (
          <S.BoxTable>
            <DataTable
              value={cliDataToImport}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              emptyMessage="Nenhum Cliente encontrado."
              currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
            >
              <Column
                header={
                  <div>
                    <input type="checkbox" checked={selectAll} onChange={toggleSelectAll} />
                    <label style={{ marginLeft: '0.5rem' }}>Selecionar Todos Clientes</label>
                  </div>
                }
                body={(rowData) => (
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedClients.includes(rowData?.codCli)}
                      onChange={() => handleClientSelection(rowData.codCli)}
                    />
                  </div>
                )}
              />

              <Column header="Cliente" field="codCli" />
            </DataTable>

            <S.RowButton style={{ marginTop: '10px' }}>
              <VmButton
                options={{
                  iconPosition: 'left',
                  style: 'btn-info',
                  label: 'Voltar',
                  disabled: !cliFile,
                }}
                onClick={() => handleBack()}
              />

              <VmButton
                options={{
                  iconPosition: 'left',
                  style: 'btn-success',
                  label: 'Salvar Clientes',
                  disabled: !selectedClients.length,
                }}
                onClick={() => AddMembersToGroup()}
              />
            </S.RowButton>
          </S.BoxTable>
        )}

        {activeStep === 2 && <S.ProgressSection>{cliProgressObject}</S.ProgressSection>}
      </div>
    </Dialog>
  );
};
