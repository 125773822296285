import styled, { css } from 'styled-components';
import { IButton } from '.';

export type IButtonProps = {} & Pick<IButton, 'variant'>;

const colorButton = {
  primary: () => css`
    color: var(--white);
    background-color: var(--primary-green);
    border: transparent;

    svg {
      stroke: var(--white);
      fill: var(--white);
    }

    &:hover {
      background-color: var(--green-900);
    }
  `,

  secondary: () => css`
    color: var(--primary-green);
    background-color: transparent;
    border: 1px solid var(--primary-green);

    svg {
      stroke: var(--primary-green);
      fill: var(--primary-green);
    }

    &:hover {
      color: var(--green-900);
      border: 1px solid var(--green-900);

      svg {
        stroke: var(--green-900);
        fill: var(--green-900);
      }
    }
  `,
};

export const Button = styled.button<IButtonProps>`
  ${({ variant }) => css`
    width: fit-content;
    padding: 10px;
    font-weight: 600;
    height: 3rem;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;

    font-size: 14px;

    div {
      margin-right: 10px;
    }

    svg {
      height: 20px;
      width: 20px;
    }

    &:hover {
      cursor: pointer;
      transition: all 0.3s;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    ${!!variant && colorButton[variant]()}
  `}
`;

export const LoaderBox = styled.div`
  display: flex;
  align-items: center;
`;
