import { useAppSelector } from 'store';
import { selectedUsuarioSelector } from 'modules/acessoUsuario/reducer/menuAcesso';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatCellphone } from 'utils/formatCellphone';
import moment from 'moment';
import { useUser } from 'client/hooks';
import Swal from 'sweetalert2';
import { postUserResetPassword } from 'client/api';
import { toast } from 'react-toastify';

interface IForm {
  name: string;
  birth_date: string;
  cellphone: string;
  email: string;
}

export const UseResetUserData = () => {
  const { data: user } = useUser();

  const [isEdit, setIsEdit] = useState(false);
  const selectedUsuario = useAppSelector(selectedUsuarioSelector);

  const { handleSubmit, control, watch, reset, setValue } = useForm<IForm>({
    defaultValues: {
      name: selectedUsuario?.nome,
      birth_date: selectedUsuario?.dtNascimento,
      cellphone: selectedUsuario?.celular,
      email: selectedUsuario?.email,
    },
  });
  const formatBirthDate = moment(selectedUsuario?.dtNascimento).format('YYYY-MM-DD');
  const updateInputValues = () => {
    setValue('name', selectedUsuario?.nome);
    setValue('birth_date', formatBirthDate);
    setValue('email', selectedUsuario?.email);
    setValue('cellphone', formatCellphone(selectedUsuario?.celular));
  };

  const onSubmit = async (data) => {
    console.log(data);
    // const params = {
    //   CodUsuario: selectedUsuario?.codUsuario,
    //   codEmpresa: selectedUsuario?.codEmpresa,
    //   CodUsuarioLogado: user?.codUsuario,
    // };
    // postUserResetPassword(params);
  };

  const handleResetPassword = () => {
    Swal.fire({
      title: 'Tem certeza?',
      text: `O usuário "${selectedUsuario?.nome}" terá de cadastrar uma nova senha de acesso.`,
      icon: 'error',
      confirmButtonText: 'Apagar Senha',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        postUserResetPassword(
          selectedUsuario?.codUsuario,
          selectedUsuario?.codEmpresa,
          user?.codUsuario,
        )
          .then(() => {
            toast.success('Senha Redefinida com Sucesso!');
          })
          .catch(() => {
            toast.error('Falha ao Redefinir Senha!');
          });
      } else {
        toast.warning('Redefinição de acesso cancelada!');
      }
    });
  };

  return {
    handleSubmit,
    control,
    isEdit,
    watch,
    reset,
    onSubmit,
    setIsEdit,
    handleResetPassword,
    updateInputValues,
    selectedUsuario,
  };
};
