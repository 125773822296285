import api from 'services/api';

export const getPedidoCheckout = async (token: string): Promise<any> => {
  const { data } = await api.get(`Pedido/GetPedidoCheckOut`, { params: { token } });
  return data;
};

export const getBandeiraCartaoCheckout = async (numCard: string): Promise<any> => {
  const { data } = await api.get(`Pagamento/GetBandeiraCheckOut/${numCard}`);
  return data;
};

export const postPaymentCheckout = async (bodyData: any): Promise<void> => {
  const { data } = await api.post(`Pagamento/PostPagamento`, bodyData);
  return data;
};
