import styled from 'styled-components';

interface ToTop {
  toTop: boolean;
}

export const MapMainBox = styled.section<ToTop>`
  position: a;
  margin-top: ${(props) => (props.toTop ? '0px' : '0px')};
  transition: all 0.4s ease;
  overflow: hidden;

  .map-container {
    height: ${(props) => (props.toTop ? '98vh' : '90vh')};
    border: 3px solid #fff;
    border-radius: 5px;
  }
`;

export const CustomInfoWindow = styled.div`
  position: relative;
  top: 0px;
  white-space: nowrap;
  padding: 5px;
`;

