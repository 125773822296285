import { TITLE_PAGE } from 'enums/titlePages';
import { HeaderScreenwriter } from 'modules/mapa/pages/Roteirizador/components/HeaderScreenwriter';
import { HeaderClientSectors } from 'modules/mapa/pages/regioes/components/HeaderClientSectors';
import { modules } from 'modules/modules';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

export const SwitchComponentHeader = () => {
  const { titlePage } = useTitlePageStore();

  const { pathname } = useLocation();
  const pageTitle = useMemo(() => modules.find((m) => m.to === pathname)?.nome ?? '', [pathname]);
  
  if (pageTitle) {
    switch (pageTitle) {
      case '':
        return <></>;

      default:
        return <></>;
    }
  } else {
    switch (titlePage) {
      case TITLE_PAGE.CLIENT_SECTORS:
        return <HeaderClientSectors />;
      case TITLE_PAGE.SCREENWRITER: 
        return <HeaderScreenwriter />;
      default:
        return <></>;
    }
  }
};
