import * as S from './styles';
import StopListCard from './stopListCard';
import { useContext } from 'react';
import { MapContext } from 'providers/map';
import NextRoutedListCard from './nextRoutedListCard';
import NotRoutedListCard from './notRoutedListCard';

export default function RouterStopsList({
  currentPositions,
  cores,
  setMapCenter,
  searchText,
  selectedMarker,
  setSelectedMarker,
  positions,
  setCurrentPositions,
  compPositions,
}: {
  currentPositions: any;
  cores: any[];
  setMapCenter: any;
  searchText: string;
  selectedMarker: any;
  setSelectedMarker: any;
  positions: any[];
  setCurrentPositions: any;
  compPositions: any[];
}) {
  const { markersControler } = useContext(MapContext);

  const higherDate =
    currentPositions[currentPositions.length - 1] &&
    new Date(currentPositions[currentPositions.length - 1].dtProxVisita)
      ? new Date(currentPositions[currentPositions.length - 1].dtProxVisita)
      : '';

  const routedPoints = markersControler.nextWeeks
    ? compPositions.filter((p: any) => p.estaEmRota == 'S' && p.dtProxVisita && new Date(p.dtProxVisita) > higherDate)
    : [];
  routedPoints.sort((a, b) => {
    const dateA = new Date(a.dtProxVisita);
    const dateB = new Date(b.dtProxVisita);
    return dateA.getTime() - dateB.getTime();
  });
  const notRoutedPoints = markersControler.notInRoute ? compPositions.filter((p: any) => p.estaEmRota == 'N') : [];

  let allClients = [];

  for (let i = 0; i < currentPositions.length; i++) {
    if (currentPositions[i].clientesDaRota) {
      allClients = allClients.concat(currentPositions[i].clientesDaRota);
    }
  }
  let filteredRoutedPoints = routedPoints.filter(
    (point) => !allClients.some((position) => position.codCli === point.codCli),
  );

  const newFilteredRoutedPoints = [];
  for (let i = 0; i < filteredRoutedPoints.length; i++) {
    let isCliInArray = newFilteredRoutedPoints.findIndex((cli: any) => cli.codCli === filteredRoutedPoints[i].codCli);

    if (isCliInArray == -1) newFilteredRoutedPoints.push(filteredRoutedPoints[i]);
  }

  return (
    <S.PointsListMainBox>
      <S.PointsListLabel>Rotas da semana:</S.PointsListLabel>
      {currentPositions.map((point: any) => {
        return (
          <StopListCard
            point={point}
            cores={cores}
            setMapCenter={setMapCenter}
            searchText={searchText}
            selectedMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
            currentPositions={currentPositions}
            positions={positions}
            key={new Date(point.dtProxVisita).toLocaleDateString('pt-BR')}
            setCurrentPositions={setCurrentPositions}
          />
        );
      })}
      {markersControler.nextWeeks && (
        <NextRoutedListCard
          points={newFilteredRoutedPoints}
          setMapCenter={setMapCenter}
          searchText={searchText}
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
          currentPositions={currentPositions}
          positions={positions}
          setCurrentPositions={setCurrentPositions}
        />
      )}
      {markersControler.notInRoute && (
        <NotRoutedListCard
          points={notRoutedPoints}
          setMapCenter={setMapCenter}
          searchText={searchText}
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
          currentPositions={currentPositions}
          positions={positions}
          setCurrentPositions={setCurrentPositions}
        />
      )}
    </S.PointsListMainBox>
  );
}
