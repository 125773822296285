import styled from 'styled-components';

export const RegrasImagesModalMainBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  padding: 0 20px;
`;

export const FloatIcons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;

  svg {
    font-size: 1.5rem;
  }
`;

export const FloatCenterAddImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 10px;
  width: 7rem;
  height: 4rem;
  z-index: 100 !important;
`;

interface ImageExistsProps {
  image?: true;
}

export const ImagemCard = styled.div<ImageExistsProps>`
  border-radius: 5px;
  /* overflow: hidden; */
  background-color: #fff;
  min-width: calc(150px + 10px);
  padding: 5px;
  max-height: 30dvh;
  min-height: 200px;
  width: 100%;
  max-width: 50dvw;
  transition: all 0.4s ease, border 0.1s ease;
  border: 2px dashed #228f6b;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;

  &:hover {
    border: 2px dashed #228f6b;
    cursor: pointer;
  }

  &::after {
    content: '+ Imagem';
    border: 2px dashed #228f6b;
    background-color: #ffffff99;
    color: #228f6b;
    padding: 10px 10px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99 !important;
    opacity: ${(props) => (props.image ? `0` : '1')};
    transition: all 0.4s ease;
  }

  &:hover::after {
    opacity: 1;
    border: 2px dashed #228f6b;
  }

  input {
    display: none;
  }

  img {
    max-width: 350px;
    max-height: 200px;
  }

  &:hover ${FloatIcons} {
    opacity: 1;
  }
`;

export const ImagemCardImg = styled.img`
  transition: all 0.4s ease;
  max-height: 240px;
  max-width: 580px;
  margin: auto auto;

  ${ImagemCard}:target & {
    scale: 1.1;
    cursor: grab;
    cursor: move;
  }
`;

export const RegrasImagesModalFooterBox = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const RegrasImagesLabel = styled.label`
  font-size: 0.8rem;
  color: #cb2a29;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  /* transform: translateX(-50%); */
`;
