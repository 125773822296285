import styled, { css } from 'styled-components';

interface IResponsiveProps {
  mobile?: boolean;
  tablet?: boolean;
}

export const ContainerInput = styled.div<IResponsiveProps>`
  display: flex;
  flex-direction: row;
  width: 300px;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 768px) {
        width: 200px;
      }
    `}
`;

export const BoxImage = styled.div`
  min-height: '100px';

  img {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerTable = styled.div<IResponsiveProps>`
  width: 100%;
  padding-left: 55px;
  padding-right: 55px;

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 768px) {
        padding: 10px;
        padding-bottom: 30px;
      }
    `}

  ${(props) =>
    props.tablet &&
    css`
      @media (max-width: 992px) {
        padding: 10px;
        padding-bottom: 30px;
      }
    `}
`;


