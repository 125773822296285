export function formatCPF(toValue: string) {
  let value = toValue;

  value = value.replace(/\D/g, '');

  if (value.length > 11) {
    value = value.slice(0, 11);
  }

  value = value.replace(/^(\d{9})(\d)/, '$1-$2');
  value = value.replace(/^(\d{6})(\d)/, '$1.$2');
  value = value.replace(/^(\d{3})(\d)/, '$1.$2');

  return value;
}

export const revertFormatCPF = (cpf) => {
  // Substitui todos os caracteres não numéricos por uma string vazia
  return cpf.replace(/\D/g, '');
};