import * as S from './styles';

import clientSvg1 from 'assets/svgs/clientSvg1';

export default function MapSubtitle({ selectedCli }: { selectedCli: any }) {
  if (selectedCli) {
    const color =
      selectedCli.status == 'L'
        ? '#228F6B'
        : selectedCli.status == 'R'
        ? '#cb2a29'
        : selectedCli.status == 'A'
        ? '#FFA500'
        : '#cb2a29';
    return (
      <S.MarkersSubtitle>
        <S.Subtitle>
          <img src={'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(clientSvg1('V', color))}></img>
          <span>VENDEDOR</span>
        </S.Subtitle>
        <S.Subtitle>
          <img src={'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(clientSvg1('C', '#007fff'))}></img>
          <span>CLIENTE</span>
        </S.Subtitle>
      </S.MarkersSubtitle>
    );
  }
}
