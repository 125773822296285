/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useState } from 'react';

import {
  getClientes,
  getMapaIcones,
  getSetores,
  getSetoresByRole,
  getSetoresByUser,
  getSupervisores,
  getVendedores,
} from 'client/api';
import { IRoteirizaData } from 'client/interfaces/roteirizador.interface';
import { postRouterData } from 'modules/mapa/pages/Roteirizador/components/executaRoteiro/executaRoteiro';
import { useMapSectorsStore } from 'storesZustand/mapSectorsStore';

export interface MapProviderProps {
  children: ReactNode;
}

type ToValidadeCoordinates = {
  lat: number | string;
  lng: number | string;
};

type ToValidadeCoordinatesReturn = {
  isValid: boolean;
  coord: ToValidadeCoordinates;
};

type MarkersControlerProps = {
  currentWeek: boolean;
  nextWeeks: boolean;
  notInRoute: boolean;
};

type ReProcessRouterProps = {
  codSetor?: string | number;
  codVendedor?: string | number;
  codMapa?: string | number;
  codEmpresa: string | number;
};

export interface MapContextProps {
  loadCliMap: boolean;
  setLoadCliMap: React.Dispatch<React.SetStateAction<boolean>>;
  loadCliMapMessage: string | ReactNode;
  setLoadCliMapMessage: React.Dispatch<React.SetStateAction<string | ReactNode>>;
  polygonos: any;
  setPolygonos: React.Dispatch<React.SetStateAction<any>>;
  mapGridShow: boolean;
  setMapGridShow: React.Dispatch<React.SetStateAction<boolean>>;
  clientes: never[];
  setClientes: React.Dispatch<React.SetStateAction<never[]>>;
  clientesList: never[];
  setClientesList: React.Dispatch<React.SetStateAction<never[]>>;
  initialZoom: number;
  setInitialZoom: React.Dispatch<React.SetStateAction<number>>;
  currentPin: {
    lat: any;
    lng: any;
  };
  setCurrentPin: React.Dispatch<
    React.SetStateAction<{
      lat: any;
      lng: any;
    }>
  >;
  colorIconesMapList: any;
  setColorIconesMapList: any;
  viewSetorCad: boolean;
  setViewSetorCad: React.Dispatch<React.SetStateAction<boolean>>;
  setorToEdit: any;
  setSetorToEdit: React.Dispatch<React.SetStateAction<any>>;
  setores: any;
  setSetores: React.Dispatch<any>;
  showVinculoSetor: boolean;
  setShowVinculoSetor: React.Dispatch<React.SetStateAction<boolean>>;
  showEditSetor: boolean;
  setShowEditSetor: React.Dispatch<React.SetStateAction<boolean>>;
  currentSetor: any;
  setCurrentSetor: React.Dispatch<React.SetStateAction<any>>;
  currentCli: any;
  setCurrentCli: React.Dispatch<React.SetStateAction<any>>;
  vendedores: any;
  setVendedores: React.Dispatch<React.SetStateAction<any>>;
  supervisores: any;
  setSupervisores: React.Dispatch<React.SetStateAction<any>>;
  reloadAllData: () => void;
  codEmpresa: number;
  setCodEmpresa: React.Dispatch<React.SetStateAction<number>>;
  codmapa: number;
  SetCodMapa: React.Dispatch<React.SetStateAction<number>>;
  roteirizaSetor: any;
  setRoteirizaSetor: React.Dispatch<React.SetStateAction<any>>;
  getRoteirizaData: () => Promise<any>;
  validateCoordinates: (ToValidadeCoordinates) => ToValidadeCoordinatesReturn;
  markersControler: MarkersControlerProps;
  setMarkersControler: React.Dispatch<React.SetStateAction<MarkersControlerProps>>;
  ReProcessRouterData: (ReProcessRouterProps) => void;
}

export const MapContext = createContext({} as MapContextProps);

export const MapProvider = ({ children }: MapProviderProps) => {
  const { codCompanyUser, codUser } = useMapSectorsStore();

  const sectors = localStorage.getItem('setores');

  const [loadCliMap, setLoadCliMap] = useState(true);
  const [loadCliMapMessage, setLoadCliMapMessage] = useState('');
  const [mapGridShow, setMapGridShow] = useState(true);

  const [polygonos, setPolygonos] = useState<any>([]);
  const [clientes, setClientes] = useState<any>([]);
  const [initialZoom, setInitialZoom] = useState(14);
  const [currentPin, setCurrentPin] = useState({ lat: '-1.7360918', lng: '-48.8766365' });
  const [clientesList, setClientesList] = useState<any>([]);
  const [viewSetorCad, setViewSetorCad] = useState<boolean>(false);
  const [setorToEdit, setSetorToEdit] = useState<any>('');

  const [setores, setSetores] = useState(sectors ? JSON.parse(sectors) : []);
  const [showVinculoSetor, setShowVinculoSetor] = useState(false);
  const [showEditSetor, setShowEditSetor] = useState(false);
  const [currentSetor, setCurrentSetor] = useState<any>(null);
  const [currentCli, setCurrentCli] = useState<any>(null);
  const [vendedores, setVendedores] = useState<any>([]);
  const [supervisores, setSupervisores] = useState<any>([]);

  const [codEmpresa, setCodEmpresa] = useState(0);
  const [codmapa, SetCodMapa] = useState(0);

  const [roteirizaSetor, setRoteirizaSetor] = useState<IRoteirizaData>(null);

  const [colorIconesMapList, setColorIconesMapList] = useState<any>(null);

  const [markersControler, setMarkersControler] = useState<MarkersControlerProps>({
    currentWeek: true,
    nextWeeks: false,
    notInRoute: false,
  });

  async function reloadAllData() {
    if (codmapa == 0 || codEmpresa == 0) {
      return;
    }

    // BUSCA LISTA DE CORES/ICONES DO MAPA DE SETORES
    getMapaIcones()
      .then((res: any) => {
        setColorIconesMapList(res ?? []);
      })
      .catch((err: Error) => {
        console.log('Erro ao buscar dados de cores e ícones :', err);
      });

    // BUSCA LISTA DE SETORES
    getSetoresByRole(codCompanyUser, codUser, codmapa)
      .then((res) => {
        let setoresListAll = res;
        const customSort = (a, b) => {
          const aPolygonLength = a.polygono.length;
          const bPolygonLength = b.polygono.length;
          if (aPolygonLength > 0 && bPolygonLength === 0) {
            return -1;
          }
          if (aPolygonLength === 0 && bPolygonLength > 0) {
            return 1;
          }

          return a.codSetor - b.codSetor;
        };

        setoresListAll = setoresListAll.sort(customSort);

        const noCliSetor = [
          {
            setor: ' Clientes sem Setor',
            cor: '606060',
            codSetor: -1,
            codRca: -1,
            codMapa: -1,
            codGa: -1,
            codEmpresa: -1,
            atualizaAutomatico: 'N',
            polygono: [],
            ativo: 'S',
          },
        ];

        setoresListAll = noCliSetor.concat(setoresListAll);

        setSetores(setoresListAll);

        const polys = setoresListAll.filter((setor: any) => setor.polygono.length > 0);
        for (let i = 0; i < polys.length; i++) {
          polys[i].latlngs = JSON.parse(polys[i].polygono[0].latlong);
        }
        setoresListAll = polys;
        setPolygonos([]);
        setPolygonos(setoresListAll);

        let dataSetoresUser = [];

        getSetoresByUser(codCompanyUser, codUser, codmapa)
          .then((res) => {
            dataSetoresUser = res;
          })
          .catch(() => {});

        getClientes(codCompanyUser, codUser, codmapa)
          .then((res) => {
            const objetosFiltrados = res?.filter(
              (objeto) => dataSetoresUser?.includes(+objeto.codSetor) || +objeto.codSetor == 0,
            );
            setCurrentPin({ lat: '-1.7360918', lng: '-48.8766365' });

            setTimeout(() => {
              setClientes(objetosFiltrados);
              setClientesList(objetosFiltrados);
              setLoadCliMap(false);
            }, 750);
          })
          .catch((err) => {
            setLoadCliMap(false);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // BUSCA LISTA DE VENDEDORES
    const vendedores = await getVendedores(codEmpresa);
    setVendedores(vendedores.sort((a, b) => a['nome'].localeCompare(b['nome'])));

    // BUSCA LISTA DE SUPERVISORES
    const supervisores = await getSupervisores(codEmpresa);
    setSupervisores(supervisores.sort((a, b) => a['nomesup'].localeCompare(b['nomesup'])));
  }

  // FUNÇÃO QUE TRATA E RETORNA DADOS DO SETOR / VENDEDOR A SE ROTEIRIZAR
  async function getRoteirizaData() {
    if (roteirizaSetor !== null) {
      return roteirizaSetor;
    } else {
      return JSON.parse(localStorage.getItem('roteirizaSetor'));
    }
  }

  const validateCoordinates = (coords: ToValidadeCoordinates): ToValidadeCoordinatesReturn => {
    if (!coords.lat || !coords.lng) {
      return {
        isValid: false,
        coord: {
          lat: +coords.lat,
          lng: +coords.lng,
        },
      };
    }

    coords.lat = coords.lat.toString();
    coords.lng = coords.lng.toString();

    if (!coords.lat.includes('.') && coords.lat.length >= 2) {
      coords.lat = +`${coords.lat.slice(0, 2)}.${coords.lat.slice(2)}`;
    }
    if (!coords.lng.includes('.') && coords.lng.length >= 2) {
      coords.lng = +`${coords.lng.slice(0, 2)}.${coords.lng.slice(2)}`;
    }

    return {
      isValid: true,
      coord: {
        lat: +coords.lat,
        lng: +coords.lng,
      },
    };
  };

  async function ReProcessRouterData({ codSetor, codVendedor, codMapa, codEmpresa }: ReProcessRouterProps) {
    try {
      if (codMapa) {
        const setoresList = await getSetores(+codMapa);
        for (let i = 0; i < setoresList.length; i++) {
          const setor = setoresList[i];
          if (setor.codRca && setor.atualizaAutomatico == 'S') {
            await postRouterData(setor.codSetor.toString(), setor.codRca.toString(), setor.codEmpresa.toString())
              .then(() => {
                console.log(`Setor ${setor.codSetor.toString()} reprocessado!`);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      } else if (codVendedor && codSetor && codEmpresa) {
        postRouterData(codSetor.toString(), codVendedor.toString(), codEmpresa.toString())
          .then(() => {
            console.log(`Setor ${codSetor.toString()} reprocessado!`);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
      console.log('Erro ao reprocessar dados do roteiro!');
    }
  }

  return (
    <MapContext.Provider
      value={{
        colorIconesMapList,
        setColorIconesMapList,
        loadCliMap,
        setLoadCliMap,
        loadCliMapMessage,
        setLoadCliMapMessage,
        setPolygonos,
        polygonos,
        mapGridShow,
        setMapGridShow,
        clientes,
        setClientes,
        initialZoom,
        setInitialZoom,
        currentPin,
        setCurrentPin,
        clientesList,
        setClientesList,
        viewSetorCad,
        setViewSetorCad,
        setorToEdit,
        setSetorToEdit,
        setores,
        setSetores,
        showVinculoSetor,
        setShowVinculoSetor,
        showEditSetor,
        setShowEditSetor,
        currentSetor,
        setCurrentSetor,
        currentCli,
        setCurrentCli,
        vendedores,
        setVendedores,
        supervisores,
        setSupervisores,
        reloadAllData,
        codEmpresa,
        setCodEmpresa,
        codmapa,
        SetCodMapa,
        setRoteirizaSetor,
        roteirizaSetor,
        getRoteirizaData,
        validateCoordinates,
        markersControler,
        setMarkersControler,
        ReProcessRouterData,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
