import styled from 'styled-components';

export const Box = styled.label`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const HeaderButtons = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 15px 15px;
  gap: 20px;
`;

export const SelectOption = styled.span``;

type ICloseOption = {
  show: boolean;
};

export const SelectOptionCloseButton = styled.span<ICloseOption>`
  position: absolute;
  display: ${(props) => (props.show ? 'block' : 'none')};
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: all 0.4s ease;

  &:hover {
    font-size: 1.45rem;
  }
`;

export const ContainerTable = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 40px;
  }
  @media (max-width: 992px) {
    padding: 10px;
    padding-bottom: 40px;
  }
`;
