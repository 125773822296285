import { useTitlePageStore } from 'storesZustand/titlePageStore';
import * as S from './styles';
import { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import VmButton from 'components/VmButton/VmButton';
import { FiEdit2, FiPlus } from 'react-icons/fi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IoClose, IoPlayOutline } from 'react-icons/io5';
import { IoMdCheckmark } from 'react-icons/io';
import { RiFileEditLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { getRelatoriosList } from 'client/api/relatorios';
import { Buffer } from 'buffer';
import { Dialog } from 'primereact/dialog';
import ReportParamsModal from 'modules/relatorios/components/reportParamsModal/reportParamsModal';
import { useUser } from 'client/hooks';
import Swal from 'sweetalert2';

type ReportParamProps = {
  codrel: number;
  ordem: number;
  obrigatorio: string;
  nome_param: string;
  legenda: string;
  componente: string;
  valor_padrao: string;
  itens: string;
  consulta: string;
  busca: string;
};

type ReportProps = {
  codrel: number;
  descricao: string;
  script: string;
  layout: string;
  layout_p: string;
  ativo: string;
  tipo: string;
  exibir_fv: string;
  parametros: ReportParamProps[];
};

type FilterProps = {
  name: string;
  code: string;
};

export default function Relatorios7000List() {
  const { data: user } = useUser();

  const navigate = useNavigate();

  const { setTitlePage } = useTitlePageStore();

  useEffect(() => {
    setTitlePage('7000 - Relatórios');
  }, []);

  const [reports, setReports] = useState<any[]>([]);
  const [reportsFiltered, setReportsFiltered] = useState<any[]>([]);

  const [showParamsModal, setShowParamsModal] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    handleGetReportsList();
  }, []);

  async function handleGetReportsList() {
    const relatorios = await getRelatoriosList();
    setReports(relatorios.data ?? []);
    setReportsFiltered(relatorios.data?.filter((r: ReportProps) => r.ativo == 'S') ?? []);
  }

  const reportTableScriptField = (report: ReportProps) => {
    return <S.ReportTableScriptField>{report.script}</S.ReportTableScriptField>;
  };

  const reportTableAtivoField = (report: ReportProps) => {
    return (
      <S.ReportTableAtivoField ativo={report.ativo ?? 'S'}>
        {report.ativo == 'S' ? <IoMdCheckmark /> : <IoClose />}
      </S.ReportTableAtivoField>
    );
  };

  const reportTableActionFields = (report: ReportProps) => {
    return (
      <S.ReportTableActionField>
        <VmButton
          options={{
            icon: <IoPlayOutline />,
            iconPosition: 'left',
            style: 'btn-none',
            rounded: true,
            tooltip: {
              position: 'top',
              show: true,
              text: 'Acessar Relatório',
            },
            disabled: report.ativo == 'N',
          }}
          onClick={() => {
            navigate(`/relatorios/${report.codrel}`);
          }}
        />{' '}
        <VmButton
          options={{
            icon: <FiEdit2 />,
            iconPosition: 'left',
            style: 'btn-none',
            rounded: true,
            tooltip: {
              position: 'top',
              show: true,
              text: 'Editar Relatório',
            },
          }}
          onClick={() => {
            navigate(`/relatorios/editar/${report.codrel}`);
          }}
        />{' '}
        <VmButton
          options={{
            icon: <RiFileEditLine />,
            iconPosition: 'left',
            style: 'btn-none',
            rounded: true,
            tooltip: {
              position: 'top',
              show: true,
              text: 'Editar Design',
            },
          }}
          onClick={() => {
            if (report.parametros.length > 0) {
              setShowParamsModal(true);
              setSelectedReport(report);
            } else {
              handleOpenDesigner(report);
            }
          }}
        />
      </S.ReportTableActionField>
    );
  };

  const statusFilters = [
    { name: 'Todos', code: 'T' },
    { name: 'Ativos', code: 'A' },
    { name: 'Inativos', code: 'I' },
  ];

  const [filters, setFilters] = useState({
    text: '',
    status: { name: 'Ativos', code: 'A' },
  });

  useEffect(() => {
    if (reports) {
      handleReportFilter();
    }
  }, [filters]);

  const handleReportFilter = () => {
    let reportListFiltered =
      reports?.filter(
        (rep: ReportProps) =>
          rep.descricao.includes(filters.text) ||
          rep.script.includes(filters.text) ||
          rep.codrel.toString().includes(filters.text),
      ) ?? [];

    if (reportListFiltered && filters.status.code === 'A') {
      reportListFiltered = reportListFiltered.filter((rep: ReportProps) => rep.ativo == 'S') ?? [];
    } else if (reportListFiltered && filters.status.code === 'I') {
      reportListFiltered = reportListFiltered.filter((rep: ReportProps) => rep.ativo == 'N') ?? [];
    }

    reportListFiltered =
      reportListFiltered?.filter(
        (rep: ReportProps) =>
          rep.descricao.includes(filters.text) ||
          rep.script.includes(filters.text) ||
          rep.codrel.toString().includes(filters.text),
      ) ?? [];

    setReportsFiltered(reportListFiltered ?? []);
  };

  const handleOpenDesigner = (report: ReportProps, params?: string) => {
    const userTokenHashString = localStorage.getItem('vmais_token')
      ? localStorage.getItem('vmais_token').replace('"', '')
      : '';
    const userTokenBearer = `Bearer ${userTokenHashString}`;
    const b64TokenData = Buffer.from(userTokenBearer, 'utf-8').toString('base64');

    const paramsString =
      params ??
      Buffer.from(
        JSON.stringify([
          {
            nome_param: 'codempresa',
            valor_parametro: user.codEmpresa.toString() ?? '0',
          },
        ]),
        'utf-8',
      ).toString('base64');

    Swal.fire({
      icon: 'question',
      title: 'Qual o layout?',
      text: 'Escolha a versão do layout a editar:',
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'G - Desktop',
      showCancelButton: true,
      cancelButtonText: 'P - Mobile',
    }).then((res) => {
      if (res.isConfirmed) {
        window.open(
          `${process.env.REACT_APP_REPORT_BASE_URL}/designer?rel=${report.codrel}&t=${b64TokenData}&lft=layout&pl=${paramsString}`,
        );
      } else if (res.isDismissed) {
        window.open(
          `${process.env.REACT_APP_REPORT_BASE_URL}/designer?rel=${report.codrel}&t=${b64TokenData}&lft=layout_p&pl=${paramsString}`,
        );
      }
    });
  };

  return (
    <S.ReportMainBox>
      <S.ReportHeaderBox>
        <S.ReportInputBox>
          <label htmlFor="report">Relatório</label>
          <InputText
            id="report"
            placeholder="Buscar pelo código, descrição ou SQL"
            style={{
              width: 'clamp(200px, 250px, 300px)',
            }}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase();
              setFilters((prevState: any) => {
                return {
                  ...prevState,
                  text: e.target.value,
                };
              });
            }}
          />
        </S.ReportInputBox>
        <S.ReportInputBox>
          <label>Ativo/Inativo</label>
          <Dropdown
            value={filters.status}
            onChange={(e) => {
              setFilters((prevState: any) => {
                return {
                  ...prevState,
                  status: e.value,
                };
              });
            }}
            options={statusFilters}
            optionLabel="name"
            className="f-lg-4"
          />
        </S.ReportInputBox>
        <VmButton
          options={{
            style: 'btn-success',
            label: 'Novo',
            icon: <FiPlus />,
            iconPosition: 'left',
          }}
          onClick={() => {
            navigate(`/relatorios/novo`);
          }}
        />
      </S.ReportHeaderBox>
      <S.ReportBodyBox>
        <DataTable
          value={reportsFiltered ?? []}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rows={100}
          rowHover
          stripedRows
          scrollable
          scrollHeight="68dvh"
          style={{ minWidth: '50rem' }}
        >
          <Column
            field="codrel"
            header="Cód. Rel"
            sortable
            style={{
              maxWidth: '10rem',
            }}
          />
          <Column field="descricao" header="Descrição" sortable />
          <Column
            field="script"
            header="Script"
            sortable
            body={reportTableScriptField}
            style={{
              maxWidth: '20rem',
            }}
          />
          <Column
            field="ativo"
            header="Ativo"
            sortable
            body={reportTableAtivoField}
            style={{
              maxWidth: '10rem',
            }}
          />
          <Column field="" header="Ações" body={reportTableActionFields} />
        </DataTable>
      </S.ReportBodyBox>
      <Dialog
        header="Informe os parâmetros do relatório:"
        visible={showParamsModal}
        style={{ width: '50vw' }}
        onHide={() => {
          if (!showParamsModal) return;
          setShowParamsModal(false);
          setSelectedReport(null);
        }}
      >
        <ReportParamsModal
          report={selectedReport}
          handleOpenDesigner={handleOpenDesigner}
          setShowParamsModal={setShowParamsModal}
        />
      </Dialog>
    </S.ReportMainBox>
  );
}
