import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useContext, useEffect, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { createCliSetorVinculo } from 'client/api/areaMapa';
import { IMapaClient } from 'client/interfaces/index';
import VmButton from 'components/VmButton/VmButton';
import { MapContext } from 'providers/map';

import * as S from './styles';
import Swal from 'sweetalert2';

interface IVinculoSetor {
  showVinculoSetor: boolean;
  setShowVinculoSetor: React.Dispatch<React.SetStateAction<boolean>>;
  cliente: IMapaClient;
  codEmpresa: number;
}

export default function VinculoCliSetorModal({
  showVinculoSetor,
  setShowVinculoSetor,
  cliente,
  codEmpresa,
}: IVinculoSetor) {
  const { setores, vendedores, reloadAllData } = useContext(MapContext);

  const [actualSetor, setActualSetor] = useState<any>(null);
  const [actualVendedor, setActualVendedor] = useState<any>(null);

  const DialogFooterPolygon = (
    <S.ModalButtonsBox>
      <VmButton
        options={{
          icon: 'x Cancelar',
          iconPosition: 'left',
          rounded: false,
          style: 'btn-danger',
          tooltip: {
            show: false,
            text: 'Cancelar',
            position: 'bottom',
          },
        }}
        style={{ paddingRight: '10px' }}
        onClick={() => setShowVinculoSetor(false)}
      />
      <VmButton
        options={{
          icon: <BsCheck />,
          label: 'Salvar',
          iconPosition: 'left',
          rounded: false,
          style: 'btn-success',
          tooltip: {
            show: false,
            text: 'Cancelar',
            position: 'bottom',
          },
        }}
        style={{ paddingRight: '10px' }}
        onClick={(e: any) => {
          if (actualSetor !== null) {
            handleCreateCliSetorVinculo();
            setShowVinculoSetor(false);
          }
        }}
      />
    </S.ModalButtonsBox>
  );

  const handleCreateCliSetorVinculo = () => {
    let toCreateCliSetorVinculo = {
      codSetor: actualSetor.codSetor,
      codCli: cliente.codCli,
      codFilial: 1,
      codEmpresa: codEmpresa,
      inclusaoManual: 'S',
    };
    if (+cliente.codSetor == +actualSetor.codSetor) {
      handleCLiInSameSectorWarn();
    } else {
      createCliSetorVinculo(toCreateCliSetorVinculo)
        .then((res: any) => {
          reloadAllData();
          toast.success('Vinculo do cliente x setor criado!');
          setShowVinculoSetor(false);
        })
        .catch((err: Error) => {
          toast.error('Falha ao criar vinculo do cliente x setor!');
        });
    }
  };

  const handleCLiInSameSectorWarn = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Cliente já vinculado!',
      html: `O cliente <b>[${cliente.codCli}] ${cliente.razaosocial}</b> já está vinculado so setor <b>[${actualSetor.codSetor}] ${actualSetor.setor}</b>`,
    });
  };

  const sectorItemTemplate = (setor: any) => {
    return (
      <S.SectorItemTemplateBox>
        <span>{`[${setor.codSetor}] ${setor.setor}`}</span>
        <S.SectorItemVendedor>
          {setor.codRca
            ? `[${setor.codRca}] ${setor.nomeRca}`
            : 'Nenhum vendedor vinculado'}
        </S.SectorItemVendedor>
      </S.SectorItemTemplateBox>
    );
  };

  const [setoresOtions, setSetoresOptions] = useState(
    setores.filter((setor: any) => setor.polygono !== undefined && setor.polygono.length === 0 && setor.codSetor != -1),
  );

  useEffect(() => {
    setActualSetor(null);
    const newSectors = setores.filter(
      (setor: any) => setor.polygono !== undefined && setor.polygono.length === 0 && setor.codSetor != -1,
    );
    for (let i = 0; i < newSectors.length; i++) {
      newSectors[i].nomeRca = vendedores.filter((rca: any) => newSectors[i].codRca == rca.codVendedor)[0]
        ? vendedores.filter((rca: any) => newSectors[i].codRca == rca.codVendedor)[0].nome
        : '';
    }
    setSetoresOptions(newSectors);
  }, [showVinculoSetor]);

  return (
    <Dialog
      visible={showVinculoSetor}
      style={{ width: '30rem' }}
      breakpoints={{ '960px': '75vw', '641px': '90vw' }}
      header={`Vincular Cliente em Setor:`}
      modal
      className="p-fluid"
      onHide={() => setShowVinculoSetor(false)}
      footer={DialogFooterPolygon}
    >
      <S.ModalBody>
        <p>
          Cliente: {cliente?.razaosocial} [{cliente?.codCli}]
        </p>
        <S.Row>
          <Dropdown
            options={setoresOtions}
            optionLabel="setor"
            placeholder={(actualSetor && actualSetor.setor) || 'Vincule a um Setor'}
            filter
            filterBy="codSetor,setor,codRca,nomeRca"
            style={{ width: '100%' }}
            onChange={(e) => {
              setActualSetor(e.target.value);
              setActualVendedor(vendedores.filter((rca: any) => e.target.value.codRca == rca.codVendedor)[0] ?? null);
            }}
            itemTemplate={sectorItemTemplate}
          />
        </S.Row>
        {actualSetor && actualSetor.setor && actualVendedor && (
          <p>
            Vendedor: {actualVendedor?.nome} [{actualVendedor?.codVendedor}]
          </p>
        )}
      </S.ModalBody>
    </Dialog>
  );
}
