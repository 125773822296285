import { create } from 'zustand';

interface ITitlePageStore {
  titlePage: string;
  setTitlePage(titlePage: string): void;
}

export const useTitlePageStore = create<ITitlePageStore>()((set) => ({
  titlePage: '',
  setTitlePage: (titlePage) => set(() => ({ titlePage })),
}));