import ContainerPage from 'components/ContainerPage';

import { FormularioCabTable } from '../components';

const FormularioPage = () => {
  return (
    <ContainerPage>
      <FormularioCabTable />
    </ContainerPage>
  );
};

export default FormularioPage;
