import { IPontoDelete } from 'client/interfaces/ponto.interface';
import { IParamsPontoAgenda, IPontoAgenda, IPontoAgendaCompany } from 'client/interfaces/pontoAgenda.interface';
import api from 'services/api';

const _controller = 'rotaPontoAgenda';

export const getRotaPontoAgenda = async (CodEmpresa: number, CodVendedor: number): Promise<IPontoAgenda[]> => {
  const { data } = await api.get(`RotaPontoAgenda`, { params: { CodEmpresa, CodVendedor } });
  return data;
};

export const getClientsCompany = async (CodEmpresa: number, CodSetor: number): Promise<IPontoAgendaCompany[]> => {
  const { data } = await api.get('RotaPontoAgenda/GetClientesPorEmpresa', { params: { CodEmpresa, CodSetor } });
  return data;
};

export const postRotaPontoAgenda = async (ponto: IParamsPontoAgenda): Promise<void> => {
  await api.post(_controller, ponto);
};

export const deleteRotaPonto = async (data: IPontoDelete): Promise<void> => {
  await api.delete(`${_controller}?CodAgenda=${data.CodAgenda}&CodEmpresa=${data.CodEmpresa}`);
};
