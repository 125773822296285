import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface IMapSector {
  codUser: number;
  setCodUser(codUser: number): void;
  codCompanyUser: number;
  setCodCompanyUser(codCompanyUser: number): void;
}

export const useMapSectorsStore = create<IMapSector>()(
  persist(
    (set) => ({
      codUser: 0,
      setCodUser: (codUser) => set(() => ({ codUser: codUser })),
      codCompanyUser: 0,
      setCodCompanyUser: (codCompanyUser) => set(() => ({ codCompanyUser: codCompanyUser })),
    }),
    {
      name: '@vmais:codUserByCompany',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
