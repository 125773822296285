import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getUserData, getUsers } from 'client/api';
import { UserData } from 'client/interfaces';

export const useUser = (enabled = true) =>
  useQuery<UserData, AxiosError>(['user-data'], getUserData, {
    enabled,
  });

export const useUserByCompany = (company: number | string, enabled = true) =>
  useQuery<UserData[], AxiosError>(['user-data-by-company', company], async () => await getUsers(company), {
    staleTime: 1000 * 60 * 5,
    enabled,
  });

  export const useUsers = (company: number) =>
  useQuery<UserData[], AxiosError>(['users', company], async () => await getUsers(company), {
    staleTime: 1000 * 60 * 5,
    enabled: !company,
  });
